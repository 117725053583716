
// Links to NCAA Court Layouts
// critical measurements (e.g. lines are 2 inches) https://ballercoach.com/game-info/mens-ncaa-basketball-court-dimensions/
// mens new court https://ncaaorg.s3.amazonaws.com/championships/sports/basketball/rules/common/PRXBB_CourtDiagram.pdf
// mens old court https://www.maplefloor.org/MFMAMainSite/media/Game-Markings-Files/NCAA-Basketball-Court-Diagram_FINAL_06212017-(003).pdf
// nba + intl court layouts https://www.recunlimited.com/blog/diagrams-basketball-courts/

// https://codepen.io/alextebbs/pen/tHhrz a great great gear animation
import React, { useState, useEffect, useContext, useRef } from 'react';
import GlobalContext from '../../context/GlobalContext';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import d3 from '../../assets/d3/index.js';
import { allCompetitionIds } from '../../harddata/NcaaStructures';
import * as d3Hexbin from 'd3-hexbin';
// import RotatingGears from './utils/RotatingGears';

// import zone structures
import { whiteBlack, zonesMouseOver, zonesMouseOut, checkIn7HexRadius, transformHexagon } from './utils/GraphHelpers';
import { zones6Array, zones13Array, zones17Array, dists7Array } from '../../harddata/ZoneSchemas';
import { teamLogoUrl, playerImageUrl } from '../../utils/GetLogos';
import LoadingSpinner from '../../components/uiUxItems/LoadingSpinner';
import questionMark from '../../images/question-mark.png';
import SvgInfoModal from './utils/SvgInfoModal';
import CBBBranding from './utils/CBBBranding';
import imageUrls from '../../images/gcsImages';
import Tooltip, { useTooltipState, getTooltipLeftTop } from './utils/Tooltip';
import { getTooltipHtml } from '../shared/helpers';
import { sentryIsArray } from '../../utils/sentryExceptions.js';

function ShotChartsD3({ // Thats a Lotta Props...
    newShotData = [],
    leagueHexData = [],
    leagueZoneData = [],
    ptgc = 'team', // ['player', 'team', 'team-opp', 'game', 'player-game', 'on-off', 'player-combo']
    tooltipName = 'This guy',
    chartType = 'hex', // ['hex', 'zone', 'marker']
    zoneSchema = 'zones6', // ['zones6', 'zones13', 'zones17', 'dists7']
    zoneMetric = 'absFgPct', // ['netFgPct', 'absFgPct', 'netFgaFreq', 'absFgaFreq']
    zoneValues = {}, // manually fill in shot chart zones
    showFgmFga = false,
    hasBoxShadow = true,
    showIcons = true,
    isDemo = false,
    isOffense = true,
    isLoading = false,
    competitionId = null,
    isBlankChart = false,
    headerText = '',
    subHeaderText = '',
    override = {}
    // theme = 'none' // ['none', 'bigten']
}) {
    // use() hooks
    const svgRef = useRef(null);
    const navigate = useNavigate();
    const params = useParams(); // note params are all strings, have to wrap with Number()
    const { teamInfosObj, userData } = useContext(GlobalContext);
    const tooltipState = useTooltipState();

    // useState
    const [hideButtons, setHideButtons] = useState(false);
    const [markerLegendType, setMarkerLegendType] = useState(3); // [2, 3], for number of markers
    const [headerStyle, setHeaderStyle] = useState({ blue: false, fontColor: '#000', backgroundColor: '#DDD' });
    const [chartStyle, setChartStyle] = useState('baseStyles');
    const [imageExists, setImageExists] = useState(true);

    // styleOptions & extraStyles (e.g. bigTenStyles)
    let extraStyles = userData?.user?.userAbilities || [];
    extraStyles = extraStyles.filter(row => row.type === 'shotChartStyles').map((row, idx) => ({ ...row, count: idx + 1 }));
    let styleOptions = [{ count: 0, value: 'baseStyles' }, ...extraStyles];

    // shot chart colors
    const shotChartColors = userData?.user?.shotChartColors || { value: 'blueToRed', label: 'Blue (Bad) to Red (Good)' };
    const selectedScale = shotChartColors.value;

    // Set Constants
    const myDarkGrey = '#333';
    const TLP = 4; // top line padding
    const SLP = 0; // side line padding
    const BLR = 1; // back lined removed (not really working)

    // useState hook to help with a hack that tells us if a player-image exists in GCS
    function checkFileExists2(url, teamId, playerId) {
        if (!teamId || !playerId) { return <></>; } // need both!
        return (
            <img
                style={{ display: 'none' }}
                alt='player-face'
                src={url}
                onError={ev => {
                    // console.log('missing player, use backup image')
                    ev.target.src = imageUrls.missingPerson;
                    setImageExists(false);
                }}
            />
        );
    }

    // Zone Values?
    let zoneValuesArray = Object.keys(zoneValues).map(zone => { return { ...zoneValues[zone], zone: zone }; });

    // Constants (Identify Which 3 Point Line to use (this wont work for shot charts app))
    let logoId = override?.logoId || null;
    let teamId = override?.teamId || (newShotData[0] ? (isOffense ? newShotData[0].teamId : newShotData[0].teamIdAgst) : null);
    let playerId = override?.playerId || (!['player', 'player-game'].includes(ptgc) ? null : (newShotData[0] ? newShotData[0].playerId : null));
    let teamDivisionId = teamInfosObj?.[teamId]?.divisionId || null;
    let divisionId = teamDivisionId === 0 ? 3 : teamDivisionId; // replace D-0 with D-III for labels
    // console.log('ids: ', { logoId, teamId, playerId, teamDivisionId, divisionId });

    const thisCompetitionId = params.competitionId ? Number(params.competitionId) : competitionId;
    const isNew3Line = divisionId === 1
        ? allCompetitionIds.d1New3.includes(thisCompetitionId) // D-I for mens starting 2019-2020, womens starting 2021-2022
        : allCompetitionIds.d2New3.includes(thisCompetitionId); // D-II, III starting 2021-2022

    let playerImgSrc = `${imageUrls.headshotPrefix}/${teamId}-${playerId}.png`;
    let imgHtml = checkFileExists2(playerImgSrc, teamId, playerId);

    const graphType = chartType === 'hex' ? 'shotChartHex'
        : (['netFgPct', 'absFgPct'].includes(zoneMetric) ? 'shotChartZoneFgPct'
            : (['netEfgPct', 'absEfgPct'].includes(zoneMetric) ? 'shotChartZoneEfgPct'
                : (['netFgaFreq', 'absFgaFreq'].includes(zoneMetric) ? 'shotChartZoneFgaFreq' : 'something else, maybe marker')));

    // Note adding 1 to the end of each domain, to use to cap
    // const colorDomain = ['netEfgPct', 'absEfgPct'].includes(zoneMetric) ? [0, 0.4, 0.45, 0.5, 0.55, 0.6, 1.5] :
    const colorDomain = (['player', 'player-game'].includes(ptgc) ? [-1, -0.15, -0.075, 0, 0.075, 0.15, 1] :
        (ptgc === 'game' ? [-1, -0.25, -0.125, 0, 0.125, 0.25, 1] :
            [-1, -0.10, -0.05, 0, 0.05, 0.10, 1]));

    const colorScales = {
        // blueYellowRedScale: ['#1147FF', '#86D8FF', '#FFEF67', '#FF7D11', '#F30000'],
        // kirkGoldsScale: ['#2EABE1', '#A1CEEB', '#E4E5E7', '#E48C76', '#E03630'],
        // blueWhiteRedScale: ['#0932f2', '#a58ef3', '#eeeeee', '#f69177', '#dd0702'],
        // blueWhiteRedScale2: ['#22F', '#99F', '#EEE', '#F99', '#F22'],
        // blueWhiteRedScale3: ['#0000F3', '#7777F3', '#EEE', '#F37777', '#F30000'],
        // blueToRed: ['#0066cc', '#77aadd', '#eeeeee', '#dd7777', '#cc0000'],
        // blueToRed: ['#001a80', '#0066CC', '#77AADD', '#EEEEEE', '#DD7777', '#CC0000', '#800000'], // length 7
        // redToGreen: ['#000000', '#E77C73', '#F3BEB9', '#FFFFFF', '#AADDC4', '#57BB8A', '#000000'] // length 7
        blueToRed: ['#0066CC', '#0066CC', '#77AADD', '#EEEEEE', '#DD7777', '#CC0000', '#CC0000'], // length 7
        redToGreen: ['#E77C73', '#E77C73', '#F3BEB9', '#FFFFFF', '#AADDC4', '#57BB8A', '#57BB8A'] // length 7
    };

    const zonesColorScale = d3.scaleLinear()
        .domain(colorDomain)
        .range(colorScales[selectedScale]);

    const hexLegendColorScale = d3.scaleLinear()
        .domain([1, 4.5, 8, 11.5, 15])
        .range(colorScales[selectedScale].slice(1, 6)); // middle 5 values

    const hexColorScale = d3.scaleLinear()
        .domain(colorDomain)
        .range(colorScales[selectedScale]);

    const missColor = '#002acd'; // '#001a80'; // colorScales[selectedScale][0];
    const astMakeColor = '#fd5500'; // '#e34c00'; // colorScales[selectedScale][4];
    const isoMakeColor = '#a50000'; // '#720000';
    const bigTenBlue = '#082d50';

    // Helper Functions
    // =====================
    const setShotsForMaxHex = () => {
        // A Function of Game Ct (most charts) or shot attempts (for on-off & player-combo)
        let shotsEq = Math.min(17, Math.floor(Math.pow(newShotData.length, 0.34) + 2)); // works for now
        const gameCt = [...new Set(newShotData.map(shot => shot.gameId))].length;
        let hexSize = 0;

        // Separate Sizes For Players + Teams (+ Perhaps Games)
        if (['game', 'player-game'].includes(ptgc)) { hexSize = 3; }
        else if (ptgc === 'player' && gameCt <= 10) { hexSize = 4; }
        else if (ptgc === 'player' && gameCt <= 18) { hexSize = 5; }
        else if (ptgc === 'player' && gameCt <= 26) { hexSize = 6; }
        else if (ptgc === 'player' && gameCt <= 34) { hexSize = 7; }
        else if (ptgc === 'player') { hexSize = 8; }
        else if (ptgc === 'player-combo') { hexSize = shotsEq; }
        else if (ptgc === 'on-off') { hexSize = shotsEq; }
        else if (gameCt <= 2) { hexSize = 4; }
        else if (gameCt <= 5) { hexSize = 6; }
        else if (gameCt <= 10) { hexSize = 8; }
        else if (gameCt <= 15) { hexSize = 9; }
        else if (gameCt <= 20) { hexSize = 10; }
        else if (gameCt <= 25) { hexSize = 11; }
        else if (gameCt <= 30) { hexSize = 12; }
        else if (gameCt <= 35) { hexSize = 13; }
        else if (gameCt <= 40) { hexSize = 14; }
        else if (gameCt <= 45) { hexSize = 15; }
        else if (gameCt <= 50) { hexSize = 16; }
        else { hexSize = 17; }

        // and return
        return hexSize;
    };


    // Draw The Court Lines
    // =====================
    const drawCBBCourt = () => {
        // could put this back into a function now that it is refactored... also, wrong 4.75 somewhere?
        let lineWidth = chartStyle === 'bigTenStyles' ? 0.275 : 0.1;
        let hoopColor = chartStyle === 'bigTenStyles' ? bigTenBlue : '#111111';
        let keyLinesColor, threeLinePaint;
        let hoopOpacity = chartStyle === 'bigTenStyles' ? 0.35 : 1;
        if (chartStyle === 'bigTenStyles') {
            keyLinesColor = threeLinePaint = bigTenBlue;
        } else if (chartType === 'marker') {
            keyLinesColor = threeLinePaint = 'black';
        } else if (chartType === 'hex') {
            keyLinesColor = threeLinePaint = 'black';
        } else { keyLinesColor = threeLinePaint = '#EEE'; }

        // Defs & ClipPath that cutoff lines where needed
        const courtDefsClipPath = [ // .append(g.className).append('defs').append('clipPath').attr(id).append(shape...)
            { g: 'foul-circle dashed', id: 'cut-off-top', shape: 'rect', attrs: { x: 19, y: 13, width: 12, height: 6 } },
            { g: 'foul-circle solid', id: 'cut-off-bottom', shape: 'rect', attrs: { x: 18.875, y: 19, width: 12.25, height: 6.125 } },
            { g: 'restricted-area', id: 'restricted-cut-off', shape: 'rect', attrs: { width: 8, height: 4, x: 21, y: 5.25 } },
            { bool: isNew3Line === true, g: 'three-point-area', id: 'three-point-cut-off-new3', shape: 'rect', attrs: { x: 3, y: 10.175, width: 44, height: 23.75 } },
            { bool: isNew3Line === false, g: 'three-point-area', id: 'three-point-cut-off-old3', shape: 'rect', attrs: { x: 3, y: 4.75, width: 44, height: 23.75 } }
        ];

        const courtStructure = [
            { g: 'backboard', shape: 'line', attrs: { x1: 22, x2: 28, y1: 3.75, y2: 3.75, stroke: hoopColor, opacity: hoopOpacity, strokeWidth: 0.5 } }, // backboard
            { g: 'rim', shape: 'circle', attrs: { r: 0.95, cx: 25, cy: 5.1, opacity: hoopOpacity, fill: hoopColor } }, // the circle part of rim
            { g: 'back-iron', shape: 'rect', attrs: { x: 24.625, y: 3.95, width: 0.75, height: 0.5, stroke: 0, opacity: hoopOpacity, fill: hoopColor } }, // back iron on rim
            { g: 'baseline-ticks', shape: 'line', attrs: { x1: 16, x2: 16, y1: 0, y2: 1, stroke: hoopColor, strokeWidth: lineWidth } },
            { g: 'baseline-ticks', shape: 'line', attrs: { x1: 34, x2: 34, y1: 0, y2: 1, stroke: hoopColor, strokeWidth: lineWidth } },
            { bool: chartStyle !== 'bigTenStyles', g: 'legend-rect', shape: 'rect', attrs: { x: 0, y: TLP + 34, width: 50, height: 8, fill: headerStyle.backgroundColor } }, // rectangle behind legend
            { bool: chartStyle !== 'bigTenStyles', g: 'legend-divider', shape: 'line', attrs: { x1: 0, x2: 50, y1: 38, y2: 38, stroke: '#333', strokeWidth: lineWidth } },
            { bool: chartType !== 'zone', g: 'inner-court-paint', shape: 'line', attrs: { x1: 19, x2: 19, y1: 0, y2: 19, stroke: keyLinesColor, strokeWidth: lineWidth } },
            { bool: chartType !== 'zone', g: 'inner-court-paint', shape: 'line', attrs: { x1: 31, x2: 31, y1: 0, y2: 19, stroke: keyLinesColor, strokeWidth: lineWidth } },
            { bool: chartType !== 'zone', g: 'foul-circle dashed', shape: 'circle', attrs: { r: 6, cx: 25, cy: 19, fill: 'transparent', stroke: keyLinesColor, strokeWidth: lineWidth, strokeDasharray: 1 + ',' + 1, clipPath: 'url(#cut-off-top)' } },
            { bool: chartType !== 'zone', g: 'foul-circle solid', shape: 'circle', attrs: { r: 6, cx: 25, cy: 19, fill: 'transparent', stroke: keyLinesColor, strokeWidth: lineWidth, clipPath: 'url(#cut-off-bottom)' } },
            { bool: chartType !== 'zone', g: 'restricted-area', shape: 'circle', attrs: { r: 4, cx: 25, cy: 5.25, fill: 'transparent', stroke: keyLinesColor, strokeWidth: lineWidth, clipPath: 'url(#restricted-cut-off)' } },
            { bool: chartType !== 'zone', g: 'ft-line', shape: 'line', attrs: { x1: 18.875, x2: 31.125, y1: 19, y2: 19, stroke: keyLinesColor, strokeWidth: lineWidth } }, // back iron on rim
            { bool: isNew3Line === true && chartType !== 'zone', g: 'three-point-area', shape: 'circle', attrs: { r: 22.14583, cx: 25, cy: 5.25, fill: 'transparent', stroke: threeLinePaint, strokeWidth: lineWidth, clipPath: 'url(#three-point-cut-off-new3)' } },
            { bool: isNew3Line === true && chartType !== 'zone', g: 'three-point-area', shape: 'line', attrs: { x1: 3.34375 + 0.1, x2: 3.34375 + 0.1, y1: 10.175, y2: 0, stroke: threeLinePaint, strokeWidth: lineWidth } },
            { bool: isNew3Line === true && chartType !== 'zone', g: 'three-point-area', shape: 'line', attrs: { x1: 50 - 3.34375 - 0.1, x2: 50 - 3.34375 - 0.1, y1: 10.175, y2: 0, stroke: threeLinePaint, strokeWidth: lineWidth } },
            { bool: isNew3Line === false && chartType !== 'zone', g: 'three-point-area', shape: 'circle', attrs: { r: 20.75, cx: 25, cy: 4.75, fill: 'transparent', stroke: threeLinePaint, strokeWidth: lineWidth, clipPath: 'url(#three-point-cut-off-old3)' } },
            { bool: isNew3Line === false && chartType !== 'zone', g: 'three-point-area', shape: 'line', attrs: { x1: 4.25, x2: 4.25, y1: 4.75, y2: 0, stroke: threeLinePaint, strokeWidth: lineWidth } },
            { bool: isNew3Line === false && chartType !== 'zone', g: 'three-point-area', shape: 'line', attrs: { x1: 45.75, x2: 45.75, y1: 4.75, y2: 0, stroke: threeLinePaint, strokeWidth: lineWidth } },
            { bool: chartType !== 'zone', g: 'key-lines', shape: 'rect', attrs: { x: 18.33, y: 7, width: 0.67, height: 1, stroke: 'none', fill: keyLinesColor } },
            { bool: chartType !== 'zone', g: 'key-lines', shape: 'rect', attrs: { x: 18.33, y: 11, width: 0.67, height: 0.17, stroke: 'none', fill: keyLinesColor } },
            { bool: chartType !== 'zone', g: 'key-lines', shape: 'rect', attrs: { x: 18.33, y: 14.17, width: 0.67, height: 0.17, stroke: 'none', fill: keyLinesColor } },
            { bool: chartType !== 'zone', g: 'key-lines', shape: 'rect', attrs: { x: 18.33, y: 17.33, width: 0.67, height: 0.17, stroke: 'none', fill: keyLinesColor } },
            { bool: chartType !== 'zone', g: 'key-lines', shape: 'rect', attrs: { x: 31, y: 7, width: 0.67, height: 1, stroke: 'none', fill: keyLinesColor } },
            { bool: chartType !== 'zone', g: 'key-lines', shape: 'rect', attrs: { x: 31, y: 11, width: 0.67, height: 0.17, stroke: 'none', fill: keyLinesColor } },
            { bool: chartType !== 'zone', g: 'key-lines', shape: 'rect', attrs: { x: 31, y: 14.17, width: 0.67, height: 0.17, stroke: 'none', fill: keyLinesColor } },
            { bool: chartType !== 'zone', g: 'key-lines', shape: 'rect', attrs: { x: 31, y: 17.33, width: 0.67, height: 0.17, stroke: 'none', fill: keyLinesColor } },
            { bool: chartType !== 'zone', g: 'court-baseline', shape: 'line', attrs: { x1: 0, x2: 50, y1: 0, y2: 0, stroke: '#333', strokeWidth: lineWidth } }
        ];

        const shapeMap = {
            rect: (props) => <rect {...props} />,
            line: (props) => <line {...props} />,
            circle: (props) => <circle {...props} />
        };

        const courtShapes =
            (<g pointerEvents='none' transform={`translate(${SLP}, ${TLP})`}>
                {/* Court Defs & ClipPaths */}
                {courtDefsClipPath.map((obj, idx) => {
                    const Element = shapeMap[obj.shape];
                    const defClip = (<defs key={`def-${idx}`}>
                        <clipPath id={obj.id}>
                            <Element {...obj.attrs} />
                        </clipPath>
                    </defs>);
                    return obj.bool !== false ? defClip : null;
                })}

                {/* Court Shapes */}
                {courtStructure.map((obj, idx) => {
                    const Element = shapeMap[obj.shape];
                    return obj.bool !== false
                        ? <g key={`g-${idx}`} className={obj.g}><Element {...obj.attrs} /></g>
                        : null;
                })}

                {/* Shots <g> */}
                <g className='shots' pointerEvents='auto' />
            </g>);

        return courtShapes;
    };
    const courtShapes = drawCBBCourt();
    // ========


    const drawLogoAndEfg = () => {
        // and title...

        // select SVG
        let svg = d3.select(svgRef.current);

        // Team Logo
        if ((!['player', 'player-game'].includes(ptgc) && teamId) || logoId) {
            // append team logo into svg image
            let logoUrl = teamDivisionId !== 0 ? teamLogoUrl(teamId || logoId) : questionMark;
            svg.select('g.team-logo').append('image')
                .attr('x', 0.25).attr('y', TLP + 38.5)
                .attr('width', 7)
                .attr('height', 7)
                .attr('xlink:href', logoUrl)
                .style('cursor', 'pointer')
                .on('click', () => {
                    navigate(`/stats/${thisCompetitionId}/teams/${teamId || logoId}/shooting`);
                });
        }

        // Player Image
        if (['player', 'player-game'].includes(ptgc) && playerId) {
            const isMale = allCompetitionIds.male.includes(thisCompetitionId);
            let playerImageSrc = imageExists
                ? playerImageUrl(playerId, teamId)
                : (isMale ? imageUrls.missingMale : imageUrls.missingFemale);

            svg.select('g.player-image').append('image')
                .attr('x', 0.5).attr('y', TLP + 38.5)
                .attr('width', 6.2)
                .attr('height', 8.2)
                .attr('xlink:href', playerImageSrc)
                .attr('mask', 'url(#ellipse-mask)')
                .style('cursor', 'pointer')
                .on('click', () => {
                    navigate(`/stats/${thisCompetitionId}/players/${playerId}/shooting`);
                });

            let logoUrl = teamDivisionId !== 0 ? teamLogoUrl(teamId) : questionMark;
            svg.select('g.team-logo').append('image')
                .attr('x', 43.8).attr('y', TLP + 31.7)
                .attr('width', 6)
                .attr('height', 6)
                .attr('xlink:href', logoUrl)
                .style('cursor', 'pointer')
                .on('click', () => {
                    navigate(`/stats/${thisCompetitionId}/teams/${teamId}/shooting`);
                });
        }

        // eFG% Value
        let fga = newShotData.length;
        let fgm2 = newShotData.filter(shot => shot.actionType === '2pt' & shot.success === true).length;
        let fgm3 = newShotData.filter(shot => shot.actionType === '3pt' & shot.success === true).length;
        if (Object.keys(zoneValues).length > 0) {
            fga = zoneValuesArray.map(z => z.fga).reduce((a, b) => a + b, 0);
            fgm2 = zoneValuesArray.filter(z => z.zoneValue === 2).map(z => z.fgm).reduce((a, b) => a + b, 0);
            fgm3 = zoneValuesArray.filter(z => z.zoneValue === 3).map(z => z.fgm).reduce((a, b) => a + b, 0);
        }
        let efgPct = (100 * (fgm2 + 1.5 * fgm3) / fga);
        let efgPctLabel = `${(efgPct).toFixed(1)}%`;

        if (efgPct) {
            svg.select('g.efg').append('text')
                .attr('transform', `translate(${9.8}, ${TLP + 41.5})`)
                .attr('x', 0).attr('y', 0)
                .attr('font-size', '0.12em')
                .attr('font-weight', 700)
                .attr('text-anchor', 'middle')
                .attr('letter-spacing', -0.1)
                .text(efgPctLabel);

            svg.select('g.efg').append('text')
                .attr('transform', `translate(${9.8}, ${TLP + 43.6})`)
                .attr('x', 0).attr('y', 0)
                .attr('font-size', '0.11em')
                .attr('font-style', 'italic')
                .attr('text-anchor', 'middle')
                .attr('letter-spacing', -0.1)
                .text('eFG%');
        }

        // add title + sub-title + background rect
        svg.select('g.title').append('rect')
            .attr('x', 0).attr('y', 0)
            .attr('width', 50)
            .attr('height', TLP)
            .attr('fill', headerStyle.backgroundColor)
            .attr('opacity', 1);

        let thisTitle = svg.select('g.title')
            .append('text')
            .attr('x', 0.4).attr('y', 1.85) // TLP - 0.45
            // .attr('dx', 0).attr('dy', 0)
            .attr('letter-spacing', -0.08)
            .style('fill', headerStyle.fontColor)
            .style('cursor', 'pointer')
            .on('click', () => headerStyle.blue === false
                ? setHeaderStyle({ blue: true, fontColor: '#111', backgroundColor: 'rgba(0, 102, 204, 0.4)' })
                : setHeaderStyle({ blue: false, fontColor: '#111', backgroundColor: '#DDD' })
            );

        let headerTextClean = headerText ? `${headerText}` : (tooltipName !== 'This guy' ? `${tooltipName}: ` : '');
        thisTitle.append('tspan')
            .style('font-size', '1.85px')
            .style('font-weight', 'bold')
            .text(headerTextClean);

        // let subHeadSize = subHeaderText.length;
        let subHeaderSize = subHeaderText.length < 75 ? 1.55 : (subHeaderText.length > 105 ? 1.4 : (1.55 - (subHeaderText.length - 75) * 0.005));
        thisTitle.append('tspan')
            .style('font-size', `${subHeaderSize}px`)
            .attr('x', 0.4).attr('y', 3.65) // TLP - 0.45
            .text(`${subHeaderText}` || '');

        // and dividing line
        svg.select('g.line-divide').append('line')
            .attr('x1', 12.8).attr('y1', TLP + 47)
            .attr('x2', 12.8).attr('y2', TLP + 38)
            .style('stroke', '#222222')
            .style('stroke-width', 0.1);
    };

    // Hex Mapping + Legend
    const drawHexLegend = () => {
        // 0. Setup - Load Constants
        // ============================
        const svg = d3.select(svgRef.current);
        const legendShift = chartStyle === 'bigTenStyles' ? '5, -1' : `${SLP + 12}, ${TLP - BLR}`;
        const heatmapLegend = svg
            .select('g.heatmap-legend')
            .attr('transform', `translate(${legendShift})`);
        // ======

        // 1. Create the Color Scale Vector
        // =================================
        let seventeenHexColors = [];
        let legendLabels = [
            `${(100 * colorDomain[1]).toFixed(1)}%`, '', '', '', '', '', '',
            `${(100 * colorDomain[3]).toFixed(1)}%`, '', '', '', '', '', '',
            `+${(100 * colorDomain[5]).toFixed(1)}%`];

        for (let j = 1; j <= 15; j++) {
            seventeenHexColors.push({ col: hexLegendColorScale(j), text: legendLabels[j - 1] });
        }

        // Create Hexbin Object
        const hexbin = d3Hexbin.hexbin().radius(2)
            .x(d => d.key[0])
            .y(d => d.key[1]);
        // ======

        // Append Efficiency 'Hot', 'Cold' Legend
        // ========================================
        // append hexagons
        let xStart = chartStyle === 'bigTenStyles' ? 4.75 : 4;
        heatmapLegend.selectAll('path')
            .data(seventeenHexColors)
            .enter().append('path')
            .attr('transform', (d, i) => `translate(${xStart + (i * 2.2)}, ${43.35})`)
            .attr('d', hexbin.hexagon(1.1))
            .attr('cursor', 'pointer')
            .attr('stroke', myDarkGrey)
            .attr('stroke-width', 0.225)
            .attr('fill', d => d.col);

        heatmapLegend.selectAll('.hex-label')
            .data(seventeenHexColors)
            .enter().append('text')
            .attr('transform', (d, i) => `translate(${3.8 + (i * 2.22)}, ${46.25})`)
            .attr('class', 'hex-label')
            .attr('font-size', 1.45)
            .attr('font-weight', '700')
            .attr('text-anchor', 'middle')
            .attr('fill', myDarkGrey)
            .style('display', () => chartStyle === 'bigTenStyles' ? 'none' : null)
            .text(d => d.text);

        let weakTranslate = chartStyle === 'bigTenStyles' ? '2.75, 41.5' : '3.5, 41.4';
        heatmapLegend.append('text')
            .attr('transform', `translate(${weakTranslate})`)
            .attr('font-size', chartStyle === 'bigTenStyles' ? 1.8 : 1.5)
            .attr('font-weight', '700')
            .attr('text-anchor', 'middle')
            .attr('fill', myDarkGrey)
            .text('Weak');

        let strongTranslate = chartStyle === 'bigTenStyles' ? '36.75, 41.5' : '35.25, 41.4';
        heatmapLegend.append('text')
            .attr('transform', `translate(${strongTranslate})`)
            .attr('font-size', chartStyle === 'bigTenStyles' ? 1.8 : 1.5)
            .attr('font-weight', '700')
            .attr('text-anchor', 'middle')
            .attr('fill', myDarkGrey)
            .text('Strong');

        heatmapLegend.append('text')
            .attr('transform', `translate(${20}, ${41.3})`)
            .attr('font-size', chartStyle === 'bigTenStyles' ? 1.85 : 1.6)
            .attr('font-weight', '700')
            .attr('text-anchor', 'middle')
            .attr('fill', myDarkGrey)
            .text(`FG%${isOffense ? '' : ' Allowed'} vs. Division${divisionId === null ? '' : `-${divisionId}`} Avg`);
    };

    // Hex Grid
    const drawCBBHex = () => {
        // 0. Set up functions / chart / constants
        // ========================================
        // Compute Array Mode / Compute Surround Pts
        const computeMode = function (arr) {
            let numMapping = {};
            for (let i = 0; i < arr.length; i++) {
                // add to object if missing
                let numMissingFromObject = (typeof numMapping[arr[i]] === 'undefined');
                if (numMissingFromObject) { numMapping[arr[i]] = 0; }
                numMapping[arr[i]] += 1; // increment count of number appearances
            }

            let greatestFreq = 0;
            let outputValue;
            for (let prop in numMapping) {
                if (numMapping[prop] > greatestFreq) {
                    greatestFreq = numMapping[prop];
                    outputValue = prop;
                }
            }
            return Number(outputValue);
        };
        const computeNeighborStats = function (hexbinData, xHex, yHex, radius) {
            // Computes: FGM, FGA, FG% for this xHex/yHex + all neighboring hexes

            // Filter & Reduce Using Sums
            let zed = hexbinData
                .filter(shot => shot.x < (xHex + radius) & shot.x > (xHex - radius))
                .filter(shot => shot.y < (yHex + radius) & shot.y > (yHex - radius))
                .reduce((accum, value) => {
                    return {
                        fgm: accum.fgm + value.makes,
                        fga: accum.fga + value.length
                    };
                }, { fgm: 0, fga: 0 });

            // Compute FG%
            zed.fgPct = zed.fgm / zed.fga;

            // And Return
            return zed;
        };

        const svg = d3.select(svgRef.current);
        const shots = svg.select('g.shots').attr('pointer-events', 'auto');
        // ======

        // 1. Filter Out Heaves + Create Points Array
        // ===========================================
        let shotData = newShotData.filter(shot => shot.y > 8); // not a heave
        // shotData = shotData.filter(shot => ['atb3', 'c3'].includes(shot.zones6)); // temp for neat 3P only chart
        // shotData = shotData.filter(shot => ['lng3'].includes(shot.zones7)); // temp for neat 3P only chart

        // Create Points Array
        // Note: Position of keys in array impacts later calculations (e.g. points[4] for shotDist), not great
        const points = shotData.map((d) => [d.x, d.y, d.zoneGenius, d.success, d.shotDist, d.hexX, d.hexY]);
        // ======


        // 2. Setup Hexes, Scales, Hex Sizes
        // ==================================
        const shotsForMaxHex = setShotsForMaxHex(this);
        const hexRadius = 1.35; // DONT CHANGE THIS - WOULD REQUIRE UPDATING R CODE
        const neighborRadius = 2.45 * hexRadius; // PROBABLY DONT EVER CHANGE THIS EITHER

        // Set Color, Radius Scales
        const radius = d3.scaleSqrt()
            .domain([0, shotsForMaxHex])
            .range([0, hexRadius]);

        let hexbin = d3Hexbin.hexbin()
            .radius(hexRadius + 0.05) // DONT CHANGE THIS - WOULD REQUIRE UPDATING R CODE
            .extent([[0, 0], [50, 52]]);
        // ======

        // Add Tooltip / Color Info to Hexbinned Obect
        // =============================================
        // Note: Should replace these with ES6 .forEach() eventually...
        let hexbinData = hexbin(points);
        for (let i = 0; i < hexbinData.length; i++) {
            // Flip Y-Axis for 0 = TopLeft SVG Coord System
            hexbinData[i].y = (47 - hexbinData[i].y);
            // hexbinData[i].x = (hexbinData[i].x);

            // Set Hex Max Size
            hexbinData[i].newlength = (hexbinData[i].length > shotsForMaxHex) ? shotsForMaxHex : hexbinData[i].length;

            // Get Average Shot Distance at Hex (cheating using mode for now)
            let allDistsAtHex = hexbinData[i].map(shot => shot[4]);
            let thisDist = computeMode(allDistsAtHex);
            hexbinData[i].shotDist = thisDist;

            // get the make pct in zone (shot[3] is for the statsType)
            let makesCt = hexbinData[i].filter(shot => shot[3] === true).length;
            let makesPct = makesCt / hexbinData[i].length;
            hexbinData[i].makes = makesCt;
            hexbinData[i].pct = makesPct;
        }
        // ======

        // Compute Colors
        // =================
        for (let i = 0; i < hexbinData.length; i++) {
            // Filter League Hex Data for this Hex
            const thisHexLeagueData = leagueHexData
                .filter(hex => Math.abs(hex.hexX - hexbinData[i].x) < 0.02)
                .filter(hex => Math.abs(hex.hexY - (47 - hexbinData[i].y)) < 0.02);

            // Compute This Hex's 7-Hex Area Stats (fgm, fga, fgPct)
            const thisX = hexbinData[i].x;
            const thisY = hexbinData[i].y;
            const hex7stats = computeNeighborStats(hexbinData, thisX, thisY, neighborRadius);

            // Extract League-Average 7-Hex Values
            hexbinData[i].lgFgPct7Hex = thisHexLeagueData.map(hex => hex.hex7FgPct)[0];
            hexbinData[i].lgFgm7Hex = thisHexLeagueData.map(hex => hex.hex7Fgm)[0];
            hexbinData[i].lgFga7Hex = thisHexLeagueData.map(hex => hex.hex7Fga)[0];

            // Extract This Players/Teams 7-Hex and Add to Main hexbinData Array
            hexbinData[i].fgm7Hex = hex7stats.fgm;
            hexbinData[i].fga7Hex = hex7stats.fga;
            hexbinData[i].fgPct7Hex = hex7stats.fgPct;

            // Use Above To Compute Performance
            hexbinData[i].netFgPct7Hex = hexbinData[i].fgPct7Hex - hexbinData[i].lgFgPct7Hex;
        }

        // Apparently here is the right spot and time to hide the previous tooltip.
        tooltipState.delayHideTooltip();

        // Append the Hexagons
        // ====================
        shots.append('clipPath')
            .attr('id', 'clip')
            .append('rect')
            .attr('width', 50)
            .attr('height', 47);

        shots.append('g')
            .attr('class', 'hexagon')
            .attr('clip-path', 'url(#clip)')
            .selectAll('path')
            .data(hexbinData)
            .enter().append('path')
            .attr('d', (d) => hexbin.hexagon(radius(d.newlength)))
            .attr('transform', d => 'translate(' + d.x + ',' + d.y + ')')
            .attr('fill', d => hexColorScale(d.netFgPct7Hex))
            .attr('stroke', 'black')
            .attr('cursor', 'pointer')
            .attr('stroke-width', 0.175)
            .on('mousemove', function (event, d) {
                // handle a few things on mouseover here

                // show tooltip with correct background color
                const { left, top } = getTooltipLeftTop({ window, event });
                tooltipState.baseShowTooltip({ d, left, top });
                tooltipState.setColorScheme({ background: hexColorScale(d.netFgPct7Hex), color: whiteBlack(hexColorScale(d.netFgPct7Hex), 'rgb') });

                // handle very challenging hexagon in7 hover effect
                let dx = d.x, dy = d.y, r = neighborRadius;
                svg.select('g.hexagon').selectAll('path')
                    .attr('d', a => { // set new path for in7 hexagons
                        let in7 = checkIn7HexRadius(a, dx, dy, r);
                        let newRadius = in7 ? a.newlength * 1.6 : a.newlength;
                        return hexbin.hexagon(radius(newRadius));
                    })
                    .attr('stroke-width', a => { // make in7 lines thicker
                        let in7 = checkIn7HexRadius(a, dx, dy, r);
                        return in7 ? 0.250 : 0.175;
                    })
                    .attr('transform', a => {
                        let in7 = checkIn7HexRadius(a, dx, dy, r);
                        return in7 ? transformHexagon(a, dx, dy) : `translate(${a.x},${a.y})`;
                    })
                    .sort(a => {
                        let in7 = checkIn7HexRadius(a, dx, dy, r);
                        return in7 ? 1 : -1;
                    });
            }).
            on('mouseout', function () {
                // hide tooltip
                tooltipState.delayHideTooltip();

                // Restore Hexagon-Radiuses to normal
                d3.select(svgRef.current)
                    .selectAll('.hexagon').selectAll('path')
                    .attr('d', f => hexbin.hexagon(radius(f.newlength)))
                    .attr('transform', f => `translate(${f.x}, ${f.y})`);
            });
    };

    // Markers + Hardwood (or team logo) Floor (this whole thing hasnt been updated since Genius Sports...)
    const drawCBBMarkers = () => {
        // yScale actually needed here, since markers are other way
        const yScale = d3.scaleLinear()
            .domain([0, 47.0])
            .rangeRound([47 + TLP, TLP]);

        const xScale = d3.scaleLinear()
            .domain([0, 50.0])
            .range([SLP, 50.0 + SLP]);

        // Helper Functions
        function handleYSpot(shot) {
            if (shot.y) { return yScale(shot.y); }
            else if (shot.foulLocationY) { return yScale(shot.foulLocationY); }
            return null;
        }
        function handleXSpot(shot) {
            if (shot.x) { return xScale(shot.x); }
            else if (shot.foulLocationX) { return xScale(shot.foulLocationX); }
            return null;
        }
        function handleFill(shot) {
            if (markerLegendType === 2 && shot.success) { return astMakeColor; }
            else if (shot.assisterId !== null) { return astMakeColor; }
            else if (shot.success === true) { return isoMakeColor; }
            return missColor;
        }
        function handleClassNaming(shot) {
            if (shot.assisterId !== null) { return 'shot-ast-make'; }
            else if (shot.success === true) { return 'shot-iso-make'; }
            return 'shot-miss';
        }
        // ====

        // 1. Filter Out Assists and Heaves
        // ==================================
        let graphMarkersData = newShotData.filter(shot => shot.y > 8);
        // =====

        // 2. Append the Make AND Miss Markers
        // =======================================
        const svg = d3.select(svgRef.current);
        const allMarkers = svg.select('g.allmarkers')
            .append('g');

        // let shotsToFontSizeScale = d3.scaleLinear().domain([0, 50, 500, 2000]).range([3.2, 3.2, 2.6, 2]);
        let shotsToOpacityScale = d3.scaleLinear().domain([0, 50, 500, 2000]).range([1, 1, 0.85, 0.7]);
        let numShots = graphMarkersData.length;

        allMarkers
            .selectAll('.shot-markers')
            .data(graphMarkersData)
            .enter()
            .append('circle')
            .attr('class', d => `shot-markers ${handleClassNaming(d)}`)
            .attr('fill', d => handleFill(d)) // REMOVE "THIS"
            .attr('stroke', '#222222') // REMOVE "THIS"
            .attr('cx', d => handleXSpot(d))
            .attr('cy', d => handleYSpot(d))
            .attr('r', 1)
            .attr('opacity', shotsToOpacityScale(numShots))
            .attr('stroke-width', '0.14');
        // ======


        // Draw The Legend
        // ================
        let legendTranslate = chartStyle === 'bigTenStyles'
            ? (markerLegendType === 3 ? `-5.5, 44.75` : `-11.25, 44`)
            : `${(SLP + 12.5)}, ${42.85 + (TLP - BLR)}`;
        let shotTypeInfo = markerLegendType === 2
            ? [
                { class: 'shot-iso-make', color: astMakeColor, text: { top: 'Makes', bottom: '' } },
                { class: 'shot-miss', color: missColor, text: { top: 'Misses', bottom: '' } }
            ] : [
                { class: 'shot-iso-make', color: isoMakeColor, text: { top: 'Unassisted', bottom: 'Makes' } },
                { class: 'shot-ast-make', color: astMakeColor, text: { top: 'Assisted', bottom: 'Makes' } },
                { class: 'shot-miss', color: missColor, text: { top: 'Misses', bottom: '' } }
            ];

        // Append Data To the Legend
        let itemTranslateY = markerLegendType === 3 ? 0 : 0.75;
        let itemTranslateX = markerLegendType === 3 ? 8 : 13.5;
        let itemGap = chartStyle === 'bigTenStyles' ? (markerLegendType === 3 ? 17 : 10) : 11;
        let legend = svg.select('g.marker-legend')
            .attr('transform', `translate(${legendTranslate})`)
            .selectAll('.legend')
            .data(shotTypeInfo)
            .enter()
            .append('g')
            .attr('class', d => `legend ${d.class}`)
            .attr('cursor', 'pointer')
            .attr('transform', (d, i) => `translate(${itemTranslateX + (i * itemGap)}, ${itemTranslateY})`);

        // For Each Legend Item, append text
        let yShift = chartStyle === 'bigTenStyles' ? -2.2 : 0;
        let xShift = chartStyle === 'bigTenStyles' ? 1.25 : 0;
        legend.append('text')
            .attr('x', 0 + xShift).attr('y', 1.2 + yShift)
            .style('text-anchor', chartStyle === 'bigTenStyles' ? 'start' : 'middle')
            .style('fill', myDarkGrey)
            .style('font-weight', 'bold')
            .style('font-size', '0.1em')
            .attr('cursor', 'none')
            .text(d => chartStyle === 'bigTenStyles' ? `${d.text.top} ${d.text.bottom}` : d.text.top);

        legend.append('text')
            .attr('x', 0).attr('y', 2.9)
            .style('text-anchor', 'middle')
            .style('fill', myDarkGrey)
            .style('font-weight', 'bold')
            .style('font-size', '0.1em')
            .attr('cursor', 'none')
            .text(d => d.text.bottom);

        // And the Circles
        legend.append('circle')
            .attr('cx', 0).attr('cy', -1.65)
            .attr('r', 1)
            .attr('fill', d => d.color)
            .attr('stroke', '#222')
            .attr('stroke-width', '0.14')
            .attr('cursor', 'none')
            .style('opacity', 1);

        // For Each Legend Item, append rect (improves clickability)
        legend.append('rect')
            .attr('class', d => `${d.class}`)
            .attr('x', -4.5).attr('y', -2.5)
            .attr('width', 9).attr('height', 6)
            .attr('stroke', 'none')
            .attr('stroke-width', 0)
            .attr('fill', 'transparent');

        // Click Handlers (hover commented out in Jan 2021 because not working as intended)
        legend
            .selectAll('rect')
            .on('click', d => {
                let targetClass = d.target.className.baseVal;
                let isHidden = targetClass.includes('marker-hidden');
                targetClass = targetClass.replace(' marker-hidden', '');

                let selectedMarkers = svg.selectAll(`.${targetClass}`);
                selectedMarkers.classed('marker-hidden', () => isHidden ? false : true);
            });

        // Toggle Button (All Makes vs Assisted/Unassisted Makes)
        svg.select('g.marker-legend').append('circle')
            .attr('class', 'legend-toggle')
            .attr('cx', 36.3).attr('cy', 3)
            .attr('r', 1)
            .attr('fill', '#DDD')
            .attr('stroke', '#888')
            .attr('stroke-width', 0.1)
            .attr('cursor', 'pointer')
            .attr('opacity', hideButtons ? 0 : 1)
            .on('click', () => setMarkerLegendType(markerLegendType === 2 ? 3 : 2));


        //     .on('mouseover', function (d) {
        //         svg.selectAll('.shot-markers').style('opacity', 0.075);
        //         svg.selectAll('.legend').style('opacity', 0.075);
        //         svg.selectAll(`.${d.class}`).style('opacity', shotsToOpacityScale(numShots));
        //     })
        //     .on('mouseout', function () {
        //         svg.selectAll(`.shot-markers`).style('opacity', 0.9);
        //         svg.selectAll(`.legend`).style('opacity', 1.0);
        //     });
        // ======
    };

    const computeStats = () => {
        // error handling until we can figure this one out
        sentryIsArray({ arr: newShotData, page: 'ShotChartsD3', name: 'newShotData' });
        if (Array.isArray(newShotData) === false) {
            return { fgm: 0, fga: 0, fgm2: 0, fga2: 0, fgm3: 0, fga3: 0, fg2Pct: 0, fg3Pct: 0, fgPct: 0, efgPct: 0 };
        }

        // Add Shooting Stats as Text Above Line
        let fga2 = newShotData.filter(shot => shot.actionType === '2pt').length;
        let fgm2 = newShotData.filter(shot => shot.actionType === '2pt' & shot.success === true).length;
        let fga3 = newShotData.filter(shot => shot.actionType === '3pt').length;
        let fgm3 = newShotData.filter(shot => shot.actionType === '3pt' & shot.success === true).length;
        if (Object.keys(zoneValues).length > 0) {
            fga2 = zoneValuesArray.filter(z => z.zoneValue === 2).map(z => z.fga).reduce((a, b) => a + b, 0);
            fgm2 = zoneValuesArray.filter(z => z.zoneValue === 2).map(z => z.fgm).reduce((a, b) => a + b, 0);
            fga3 = zoneValuesArray.filter(z => z.zoneValue === 3).map(z => z.fga).reduce((a, b) => a + b, 0);
            fgm3 = zoneValuesArray.filter(z => z.zoneValue === 3).map(z => z.fgm).reduce((a, b) => a + b, 0);
        }

        // Things derived from fga2, fgm2, fga3, fgm3
        let fg2Pct = fga2 === 0 ? '0%' : `${(100 * (fgm2 / fga2)).toFixed(1)}%`;
        let fg3Pct = fga3 === 0 ? '0%' : `${(100 * (fgm3 / fga3)).toFixed(1)}%`;
        let fgm = fgm2 + fgm3;
        let fga = fga2 + fga3;
        let fgPct = fga === 0 ? '0%' : `${(100 * (fgm / fga)).toFixed(1)}%`;
        let efgPct = fga === 0 ? '0%' : `${(100 * ((fgm2 + 1.5 * fgm3) / fga)).toFixed(1)}%`;

        // console.log('stats: ', { fgm, fga, fgm2, fga2, fgm3, fga3, fg2Pct, fg3Pct, fgPct, efgPct });
        return { fgm, fga, fgm2, fga2, fgm3, fga3, fg2Pct, fg3Pct, fgPct, efgPct };
    };

    const drawStats = () => {
        // Grab SVG, color, and compute stats
        const svg = d3.select(svgRef.current);
        const { fgm, fga, fgm2, fga2, fgm3, fga3, fg2Pct, fg3Pct, fgPct, efgPct } = computeStats();

        // Draw stats onto SVG
        svg.select('g.basic-stats').append('text')
            .attr('x', 0.5).attr('y', 38.7)
            .style('fill', myDarkGrey)
            .style('font-weight', 'bold')
            .style('font-size', '0.082em')
            .text(`2s: ${fgm2}/${fga2} (${fg2Pct})`);

        svg.select('g.basic-stats').append('text')
            .attr('x', 0.5).attr('y', 40.1)
            .style('fill', myDarkGrey)
            .style('font-weight', 'bold')
            .style('font-size', '0.082em')
            .text(`3s: ${fgm3}/${fga3} (${fg3Pct})`);

        svg.select('g.basic-stats').append('text')
            .attr('x', 0.5).attr('y', 41.5)
            .style('fill', myDarkGrey)
            .style('font-weight', 'bold')
            .style('font-size', '0.082em')
            .text(`All: ${fgm}/${fga} (${fgPct} FG%, ${efgPct} eFG%)`);
    };

    // Draw Hardwood For Old Marker Graphs
    const drawCBBHardwood = () => {
        const svg = d3.select(svgRef.current);
        const hardwood = svg.select('g.hardwood');
        const colors = ['#F8CD90', '#E7BB80', '#F4C589', '#E7BD7F', '#F7C985', '#F7C985'];

        for (let i = SLP; i < (50 + SLP); i++) {
            let randColor = colors[Math.floor(Math.random() * colors.length)];
            hardwood.append('rect')
                .attr('x', i)
                .attr('y', 0)
                .attr('width', 1)
                .attr('height', 50 + TLP)
                .attr('fill', randColor)
                .attr('opacity', 0.5)
                .attr('stroke', 'black')
                .attr('stroke-width', 0.02);
        }
    };

    // Zones Helper: Coloring In The Zones
    const computeFillColor = (regionInfo, thisColorScale) => {
        // Grab Range of Colors
        let isFgaFreq = ['netFgaFreq', 'absFgaFreq'].includes(zoneMetric);
        let isEfgPct = ['netEfgPct', 'absEfgPct'].includes(zoneMetric);
        // let minOfRange = isEfgPct ? 0.40 : colorDomain[1];
        // let middleOfRange = isEfgPct ? 0.5 : colorDomain[3];
        // let maxOfRange = isEfgPct ? 0.60 : colorDomain[5];

        // we hardcap to 1,3,5, zones cannot be darker or lighter than these
        let minOfRange = colorDomain[0];
        let middleOfRange = colorDomain[3];
        let maxOfRange = colorDomain[6];

        // Dont Color In Heaves
        if (regionInfo.acc === 'heave3') { return thisColorScale(middleOfRange); }

        // Grab correct stat key which determines how to color the zones
        let colorKey;
        if (isFgaFreq) { colorKey = 'fgaFreqDiff'; }
        // else if (isEfgPct) { colorKey = 'efgPct'; } // note not Diff
        else if (isEfgPct) { colorKey = 'efgPctDiff'; } // note not Diff
        else { colorKey = 'fgPctDiff'; }

        // And Color The Zones
        let fillPctVal = regionInfo[colorKey] > maxOfRange ? maxOfRange : (regionInfo[colorKey] < minOfRange ? minOfRange : regionInfo[colorKey]);
        fillPctVal = isNaN(fillPctVal) ? middleOfRange : fillPctVal; // handle NaN
        let fillColor = thisColorScale(fillPctVal);

        return fillColor;
    };
    // Zones Helper: Setting The Text
    const formatDisplayText = (regionInfo, textMetric) => {
        // Where are we using isDemo === true?
        if (isDemo === true) { return regionInfo.name; }
        if (textMetric === '') { return ''; }

        // No Label for Heaves on FgaFreq Shot Charts
        let isFgaFreq = ['netFgaFreq', 'absFgaFreq'].includes(zoneMetric);
        if (regionInfo.acc === 'heave3' & isFgaFreq) { return ''; }

        // If (a) Single Game, (b) Explicit Fraction, or (c) Low Shot Attempts, return as fraction
        let outputText = '';
        let { fgm, fga, fgaFreq, lgFgaFreq, fgPct, lgFgPct, efgPct, lgEfgPct } = regionInfo;
        let sign = isFgaFreq ? (fgaFreq > lgFgaFreq ? '+' : '') : (fgPct > lgFgPct ? '+' : '');
        switch (textMetric) {
            case 'fraction': outputText = `${fgm}/${fga}`; break;
            case 'netFgaFreq': outputText = `${sign}${(100 * (fgaFreq - lgFgaFreq)).toFixed(1)}%`; break;
            case 'absFgaFreq': outputText = `${(100 * (fgaFreq)).toFixed(1)}%`; break;
            case 'netFgPct': outputText = `${sign}${(100 * (fgPct - lgFgPct)).toFixed(1)}%`; break;
            case 'absFgPct': outputText = `${(100 * (fgPct)).toFixed(1)}%`; break;
            case 'netEfgPct': outputText = `${sign}${(100 * (efgPct - lgEfgPct)).toFixed(1)}%`; break;
            case 'absEfgPct': outputText = `${(100 * (efgPct)).toFixed(1)}%`; break;
            default: console.log('ERROR: BAD ZONEMETRIC VALUE');
        }

        outputText = (outputText === 'NaN%' ? '0/0' : outputText);
        return outputText;
    };
    // Legend For Zones Graphs
    const drawZoneLegend = () => {
        // Set Stuff Up
        const svg = d3.select(svgRef.current);
        const legendShift = chartStyle === 'bigTenStyles' ? '-6, -1.5' : `${SLP}, ${TLP - 2.5}`;
        const shadesLegend = svg
            .select('g.shades-legend')
            .attr('transform', `translate(${legendShift})`);

        // Compute Stuff
        let lgEfgPct = leagueZoneData.filter(row => row.zoneSchema === 'all').map(row => row.efgPct);
        let lgEfgPctText = (lgEfgPct && lgEfgPct[0]) ? `${(100 * lgEfgPct[0]).toFixed(1)}%` : '';
        let legendMin = `${(100 * colorDomain[1]).toFixed(1)}%`;
        let legendMax = `+${(100 * colorDomain[5]).toFixed(1)}%`;

        let text1 = '', text2 = '';
        switch (zoneMetric) {
            case 'netFgaFreq': text1 = `Zone % of Shots vs. D-${divisionId} Avg in Zone`; text2 = `D-${divisionId} Zone FGA%`; break;
            case 'absFgaFreq': text1 = `Zone % of Shots vs. D-${divisionId} Avg in Zone`; text2 = `D-${divisionId} Zone FGA%`; break;
            case 'netFgPct': text1 = `Zone FG% vs. D-${divisionId} Avg in Zone`; text2 = `D-${divisionId} Zone FG%`; break;
            case 'absFgPct': text1 = `Zone FG% vs. D-${divisionId} Avg in Zone`; text2 = `D-${divisionId} Zone FG%`; break;
            case 'netEfgPct': text1 = `Zone eFG% vs. Overall D-${divisionId} eFG%`; text2 = `D-${divisionId} eFG%: ${lgEfgPctText}`; break;
            case 'absEfgPct': text1 = `Zone eFG% vs. Overall D-${divisionId} eFG%`; text2 = `D-${divisionId} eFG%: ${lgEfgPctText}`; break;
            default: console.log('Error: Bad zoneMetric Value!');
        }

        // Gradient Box
        let x1 = 15, x2 = 48;
        shadesLegend.append('rect')
            .attr('x', x1).attr('y', 43.75)
            .attr('width', x2 - x1).attr('height', 2)
            .attr('stroke', chartStyle === 'bigTenStyles' ? bigTenBlue : '#444')
            .attr('stroke-width', chartStyle === 'bigTenStyles' ? '0.015em' : '0.005em')
            .attr('fill', 'url(#selectedGradient)');

        // Legend Text
        shadesLegend.append('text')
            .attr('x', x1 + 1).attr('y', 47.5)
            .attr('fill', myDarkGrey)
            .attr('font-weight', '700')
            .attr('text-anchor', 'middle')
            .attr('font-size', '0.085em')
            .style('display', chartStyle === 'bigTenStyles' ? 'none' : null)
            .text(legendMin);

        shadesLegend.append('text')
            .attr('x', (x1 + x2) / 2).attr('y', 43.1)
            .attr('fill', myDarkGrey)
            .attr('font-weight', '700')
            .attr('text-anchor', 'middle')
            .attr('font-size', chartStyle === 'bigTenStyles' ? 1.85 : '0.095em')
            .text(text1);

        shadesLegend.append('text')
            .attr('x', x2 - 1).attr('y', 47.5)
            .attr('fill', myDarkGrey)
            .attr('font-weight', '700')
            .attr('text-anchor', 'middle')
            .attr('font-size', '0.085em')
            .style('display', chartStyle === 'bigTenStyles' ? 'none' : null)
            .text(legendMax);

        shadesLegend.append('text')
            .attr('x', (x1 + x2) / 2).attr('y', 47.6)
            .attr('fill', myDarkGrey)
            .attr('font-weight', '700')
            .attr('text-anchor', 'middle')
            .attr('font-size', '0.085em')
            .style('display', chartStyle === 'bigTenStyles' ? 'none' : null)
            .text(text2);

        if (chartStyle === 'bigTenStyles') {
            shadesLegend.append('text')
                .attr('x', x1 - 0.5).attr('y', 43.2)
                .attr('fill', myDarkGrey)
                .attr('font-weight', '700')
                .attr('text-anchor', 'middle')
                .attr('font-size', 1.8)
                .text('Weak');

            shadesLegend.append('text')
                .attr('x', x2 + 0.5).attr('y', 43.2)
                .attr('fill', myDarkGrey)
                .attr('font-weight', '700')
                .attr('text-anchor', 'middle')
                .attr('font-size', 1.8)
                .text('Strong');
        }
    };

    // New + Improved Zones Layer
    const drawCourtZones = () => {
        // 0. Setup - Create Functions and Load Props/ChartProps
        // ========================================================
        // Filters For Shots + League Zone Schema Arrays
        let zoneSchemasData = leagueZoneData.filter(zone => zone.zoneSchema === zoneSchema);
        let leagueOverallData = leagueZoneData.filter(zone => zone.zoneSchema === 'all');
        if (leagueOverallData.length > 1) { console.log('ERROR: too many leagueOverallData rows!!'); }
        let leagueOverallDataObj = leagueOverallData[0];
        let shotData = newShotData.filter(shot => shot.y > 8);

        // Additional Constants
        const borderStroke = chartStyle === 'bigTenStyles' ? bigTenBlue : '#333333';
        const borderStrokeWidth = chartStyle === 'bigTenStyles' ? 0.275 : 0.15;

        // Set SVG and Other Important G Elements
        const svg = d3.select(svgRef.current);
        const shadesText = svg.select('g.shades-text').attr('transform', `translate(${SLP}, ${TLP})`);
        const shadesText2 = svg.select('g.shades-text2').attr('transform', `translate(${SLP}, ${TLP})`);
        const shades = svg.select('g.shades').attr('transform', `translate(${SLP}, ${TLP})`).append('g');
        // ======

        // 1. Create Object(s) with all Zone Data
        // =======================================
        // let rawZonesArray;
        // let keyFor3 = isNew3Line ? 'new3' : 'old3';
        // switch (zoneSchema) {
        //     case 'zones6': rawZonesArray = zones6Array[keyFor3]; break;
        //     case 'zones13': rawZonesArray = zones13Array[keyFor3]; break;
        //     case 'zones17': rawZonesArray = zones17Array[keyFor3]; break;
        //     case 'dists7': rawZonesArray = dists7Array[keyFor3]; break;
        //     default: rawZonesArray = zones6Array[keyFor3];
        // }
        // // ======

        // const zoneSchemaArray = rawZonesArray.map(zone => {
        //     // zone constants
        //     const thisZone = zone.acc;
        //     const zoneAcc = zone.acc;
        //     const zoneValue = (0.5 * zone.zoneValue);

        //     // this players/teams/games zone shooting stats (fgm, fga)
        //     let fgm = shotData.filter(shot => shot[zoneSchema] === thisZone).filter(shot => shot.success === true).length;
        //     let fga = shotData.filter(shot => shot[zoneSchema] === thisZone).length;
        //     let fgaFreq = zone.fga / shotData.length;

        //     // override with manually submitted zone FGM, FGA
        //     if (Object.keys(zoneValues).length > 0) {
        //         const zoneFgas = Object.keys(zoneValues).map(key => zoneValues[key].fga);
        //         const totalShots = zoneFgas.reduce((a, b) => a + b, 0);
        //         fgm = zoneValues?.[zoneAcc]?.fgm ?? 0;
        //         fga = zoneValues?.[zoneAcc]?.fga ?? 0;
        //         fgaFreq = fga / totalShots;
        //     }

        //     // this players/teams/games zones shooting stats, continued
        //     const fgPct = fgm / fga;
        //     const efgPct = zoneValue * (fgm / fga);

        //     // league zone averages
        //     const lgFga = zoneSchemasData.filter(z => z.zoneName === thisZone).map(z => z.fga)[0];
        //     const lgFgm = zoneSchemasData.filter(z => z.zoneName === thisZone).map(z => z.fgm)[0];
        //     const lgFgaFreq = zoneSchemasData.filter(z => z.zoneName === thisZone).map(z => z.fgaFreq)[0];
        //     const lgFgPct = zoneSchemasData.filter(z => z.zoneName === thisZone).map(z => z.fgPct)[0];

        //     // league overall averages
        //     const lgEfgPct = leagueOverallDataObj?.efgPct ?? null;

        //     // net difference between player/team/game stats vs the league averages
        //     const fgaFreqDiff = fgaFreq - lgFgaFreq;
        //     const fgPctDiff = fgPct - lgFgPct;
        //     const efgPctDiff = efgPct - lgEfgPct;

        //     // compute zone fill color, zone display text (PPP, PPS in future would be nice)
        //     let colorFill = computeFillColor(zone, zonesColorScale);
        //     const textColor = whiteBlack(zone.colorFill, 'rgb');
        //     const displayText = formatDisplayText(zone, zoneMetric);
        //     let displaySubText = (showFgmFga && zone.fga > 0) ? formatDisplayText(zone, 'fraction') : '';

        //     // big handler for Big Ten Styles
        //     let fontSize, textY, textY2, textX, textX2; // (these override rawZonesArray defaults, only add them if )
        //     if (chartStyle === 'bigTenStyles') {
        //         colorFill = fga === 0 ? 'transparent' : colorFill; //                               update colors (transparent zones)
        //         displaySubText = zone.acc === 'heave3' ? '' : zone.displaySubText; //               no heave3 text
        //         fontSize = zone.acc === 'atr2' ? 2.15 * zone.fontSize : 1.1 * zone.fontSize; //     bigger font
        //         textY = zone.acc === 'atr2' ? 3.8 : ['lc3', 'rc3', 'c3'].includes(zone.acc) ? 4 : zone.textY; //    move textY a bit
        //         textY2 = zone.acc === 'atr2' ? 7 : ['lc3', 'rc3', 'c3'].includes(zone.acc) ? 6.25 : zone.textY2; // move textY2 a bit
        //         // zone.textY2 = ['lc3', 'rc3'].includes(zone.acc) ? 8 : zone.textY2;
        //         textX2 = zone.acc === 'lc3' ? 3.6 : ['c3', 'rc3'].includes(zone.acc) ? 46.4 : zone.textX2;
        //         textX = zone.acc === 'lc3' ? 3.6 : ['c3', 'rc3'].includes(zone.acc) ? 46.4 : zone.textX;
        //         // update baseline coordinates
        //         zone.textY = ['lb2', 'rb2'].includes(zone.acc) ? 6.0 : zone.textY;
        //         zone.textColor = ['lc3', 'rc3', 'c3'].includes(zone.acc) ? '#111' : zone.textColor;
        //         // indicate that we want a grey rect background
        //         zone.useRectBackground = ['lc3', 'rc3', 'c3'].includes(zone.acc) ? true : false;
        //     }

        //     return {
        //         ...zone
        //     };
        // });


        let zoneSchemaArray;
        let keyFor3 = isNew3Line ? 'new3' : 'old3';
        switch (zoneSchema) {
            case 'zones6': zoneSchemaArray = JSON.parse(JSON.stringify(zones6Array[keyFor3])); break;
            case 'zones13': zoneSchemaArray = JSON.parse(JSON.stringify(zones13Array[keyFor3])); break;
            case 'zones17': zoneSchemaArray = JSON.parse(JSON.stringify(zones17Array[keyFor3])); break;
            case 'dists7': zoneSchemaArray = JSON.parse(JSON.stringify(dists7Array[keyFor3])); break;
            default: zoneSchemaArray = JSON.parse(JSON.stringify(zones6Array[keyFor3]));
        }
        // ======

        // Compute Zone Stats & Define Zone Text Content
        // ================================================
        zoneSchemaArray.forEach(zone => {
            let thisZone = zone.acc;
            let zoneAcc = zone.acc;
            let zoneValue = (0.5 * zone.zoneValue);

            // Get This Persons/Teams Basics (fgm, fga, fgaFreq, fgPct, efgPct):
            zone.fgm = shotData.filter(shot => shot[zoneSchema] === thisZone).filter(shot => shot.success === true).length;
            zone.fga = shotData.filter(shot => shot[zoneSchema] === thisZone).length;
            zone.fgaFreq = zone.fga / shotData.length;
            if (Object.keys(zoneValues).length > 0) {
                let zoneFgas = Object.keys(zoneValues).map(key => zoneValues[key].fga);
                let totalShots = zoneFgas.reduce((a, b) => a + b, 0);
                zone.fgm = zoneValues && zoneValues[zoneAcc] ? zoneValues[zoneAcc].fgm : 0;
                zone.fga = zoneValues && zoneValues[zoneAcc] ? zoneValues[zoneAcc].fga : 0;
                zone.fgaFreq = zone.fga / totalShots;
            }
            zone.fgPct = zone.fgm / zone.fga;
            zone.efgPct = zoneValue * (zone.fgm / zone.fga);

            // League Zone Averages
            zone.lgFga = zoneSchemasData.filter(z => z.zoneName === thisZone).map(z => z.fga)[0];
            zone.lgFgm = zoneSchemasData.filter(z => z.zoneName === thisZone).map(z => z.fgm)[0];
            zone.lgFgaFreq = zoneSchemasData.filter(z => z.zoneName === thisZone).map(z => z.fgaFreq)[0];
            zone.lgFgPct = zoneSchemasData.filter(z => z.zoneName === thisZone).map(z => z.fgPct)[0];

            // Legue Overall Averages
            // zone.lgEfgPct = zoneValue * zoneSchemasData.filter(zone => zone.zoneName === thisZone).map(zone => zone.fgPct)[0];
            zone.lgEfgPct = leagueOverallDataObj && leagueOverallDataObj.efgPct;

            // Net Difference Between Teams Stats and League Averages
            zone.fgaFreqDiff = zone.fgaFreq - zone.lgFgaFreq;
            zone.fgPctDiff = zone.fgPct - zone.lgFgPct;
            zone.efgPctDiff = zone.efgPct - zone.lgEfgPct;

            // Compute Fill Color and Display Text (Att Freq vs. FG% vs. PPS vs. PPP (PPS and PPS in future))
            // computeFillColor(regionInfo, colorScale, minPct, maxPct)
            zone.colorFill = computeFillColor(zone, zonesColorScale);
            zone.textColor = whiteBlack(zone.colorFill, 'rgb');
            zone.displayText = formatDisplayText(zone, zoneMetric);
            zone.displaySubText = (showFgmFga && zone.fga > 0) ? formatDisplayText(zone, 'fraction') : '';

            // Handle bigten themes HERE!
            if (chartStyle === 'bigTenStyles') {
                // update colors (transparent zones)
                zone.colorFill = zone.fga === 0 ? 'transparent' : zone.colorFill;
                // update text (tweak rim, corners)
                zone.rotation = 0;
                // zone.displayText = ['lc3', 'rc3'].includes(zone.acc) ? (zone.fga === 0 ? '0/0' : Math.round(Number(zone.displayText.replace('%', ''))) + '%') : zone.displayText;
                zone.displaySubText = zone.acc === 'heave3' ? '' : zone.displaySubText;
                // update fontsize
                zone.fontSize = 1.1 * zone.fontSize;
                zone.fontSize = zone.acc === 'atr2' ? 1.95 : zone.fontSize;
                // update rim coordinates
                zone.textY = zone.acc === 'atr2' ? 3.8 : zone.textY;
                zone.textY2 = zone.acc === 'atr2' ? 7 : zone.textY2;
                // update corner 3 coordinates
                zone.textY = ['lc3', 'rc3', 'c3'].includes(zone.acc) ? 4 : zone.textY;
                zone.textY2 = ['lc3', 'rc3', 'c3'].includes(zone.acc) ? 6.25 : zone.textY2;
                zone.textColor = ['lc3', 'rc3', 'c3'].includes(zone.acc) ? '#111' : zone.textColor;
                // zone.textY2 = ['lc3', 'rc3'].includes(zone.acc) ? 8 : zone.textY2;
                zone.textX = ['c3', 'rc3'].includes(zone.acc) ? 46.4 : zone.textX;
                zone.textX2 = ['c3', 'rc3'].includes(zone.acc) ? 46.4 : zone.textX2;
                zone.textX = zone.acc === 'lc3' ? 3.6 : zone.textX;
                zone.textX2 = zone.acc === 'lc3' ? 3.6 : zone.textX2;
                // update baseline coordinates
                zone.textY = ['lb2', 'rb2'].includes(zone.acc) ? 6.0 : zone.textY;
                // indicate that we want a grey rect background
                zone.useRectBackground = ['lc3', 'rc3', 'c3'].includes(zone.acc) ? true : false;
            }
        });

        // Filter away heaves for "bigten" theme
        zoneSchemaArray = zoneSchemaArray.filter(row => chartStyle !== 'bigTenStyles' ? true : row.acc !== 'heave3');

        // here is right spot to hide the previous tooltip
        tooltipState.delayHideTooltip();

        // draw The Zone Shapes
        shades.selectAll('path')
            .data(zoneSchemaArray)
            .enter()
            .append('path')
            .attr('class', d => `zone-path ${d.acc}-path`)
            .attr('d', d => d.path)
            .attr('fill', d => d.colorFill)
            .attr('stroke', borderStroke)
            .attr('stroke-width', borderStrokeWidth)
            .attr('opacity', 0.95)
            .attr('cursor', 'pointer')
            .on('mousemove', (event, d) => zonesMouseOver(event, d, tooltipState, svgRef, borderStrokeWidth))
            .on('mouseout', d => {
                tooltipState.delayHideTooltip();
                zonesMouseOut(d, borderStrokeWidth);
            });

        // If Background Rect is Needed
        shadesText.selectAll('rect')
            .data(zoneSchemaArray).enter().append('rect')
            .attr('x', d => +d.textX - (0.65 * d.displayText.length))
            .attr('y', d => +d.textY - 1.75)
            .attr('width', d => 1.3 * d.displayText.length)
            .attr('height', d => d.displaySubText !== '' ? 5 : 3.75)
            .attr('rx', 1)
            .attr('fill', '#EEE')
            .attr('pointer-events', 'none')
            .attr('opacity', 0.95)
            .attr('stroke', '#111')
            .attr('stroke-width', 0.05)
            .style('display', d => d.useRectBackground === true ? null : 'none');

        // Append The Text
        if (isBlankChart === false) {
            shadesText.selectAll('text')
                .data(zoneSchemaArray).enter().append('text')
                .attr('transform', d => d.rotation ? `rotate(${d.rotation},${d.textX},${d.textY})` : null)
                .attr('x', d => +d.textX)
                .attr('y', d => +d.textY)
                .attr('fill', d => d.textColor)
                .attr('text-anchor', 'middle')
                .attr('dominant-baseline', 'middle')
                .attr('font-size', d => chartStyle === 'bigTenStyles' ? 1.8 * d.fontSize : d.fontSize)
                .attr('font-weight', 700)
                .attr('font-family', chartStyle === 'bigTenStyles' ? 'Rift' : 'Verdana')
                .attr('pointer-events', 'none')
                // .attr('stroke', d => d.textColor === '#EEE' ? 'black' : null)
                // .attr('stroke-width', 0)
                // .attr('stroke-width', d => d.textColor === '#EEE' ? 0.01 : 0)
                .text(d => d.displayText);

            // If zoneMetric includes FGM/A as Sub Text, show sub-text
            if (showFgmFga === true) {
                let yGapSize = chartStyle === 'bigTenStyles' ? 1.4 : 1.14;
                shadesText2.selectAll('text')
                    .data(zoneSchemaArray).enter().append('text')
                    .attr('x', d => d.textX2 ? +d.textX2 : d.textX)
                    .attr('y', d => d.textY2 ? +d.textY2 : d.textY + (yGapSize * d.fontSize))
                    .attr('fill', d => d.textColor)
                    .attr('text-anchor', 'middle')
                    .attr('dominant-baseline', 'middle')
                    .attr('font-size', d => chartStyle === 'bigTenStyles' ? 1.1 * d.fontSize : 0.8 * d.fontSize)
                    .attr('font-weight', 700)
                    .attr('font-family', chartStyle === 'bigTenStyles' ? 'Rift' : 'Verdana')
                    .attr('pointer-events', 'none')
                    .text(d => d.displaySubText);
            }
        }
    };


    // Selected Chart and Lifecycle Functions
    const drawSelectedChart = () => {
        const clearGraph = function () {
            // grab THIS svg that we want to clear
            const svg = d3.select(svgRef.current);
            // remove current drawing of court
            svg.select('g.background').selectAll('*').remove();
            svg.select('g.hardwood').selectAll('*').remove();
            // svg.select('g.court').selectAll('*').remove();
            svg.select('g.oob').selectAll('*').remove();
            svg.select('g.team-logo').selectAll('*').remove();
            svg.select('g.player-image').selectAll('*').remove();
            svg.select('g.player-images').selectAll('*').remove();
            svg.select('g.efg').selectAll('*').remove();
            svg.select('g.line-divide').selectAll('*').remove();
            svg.select('g.title').selectAll('*').remove();
            svg.select('g.sub-title').selectAll('*').remove();

            // remove shades
            svg.select('g.shades').selectAll('*').remove();
            svg.select('g.shades-text').selectAll('*').remove();
            svg.select('g.shades-text2').selectAll('*').remove();
            svg.select('g.shades-legend').selectAll('*').remove();

            // remove hex
            svg.select('g.heatmap-legend').selectAll('*').remove();
            svg.select('g.shots').selectAll('*').remove();
            svg.select('g.basic-stats').selectAll('*').remove();

            // remove markers
            svg.select('g.allmarkers').selectAll('*').remove();
            svg.select('g.marker-legend').selectAll('*').remove();

            // remove defs
            svg.select('defs#circle-masks').selectAll('*').remove();
        };

        // And redraw graph
        clearGraph();
        if (isLoading === true || (chartType === 'hex' && !leagueHexData)) {
            return;
        }

        drawLogoAndEfg();
        if (isBlankChart === false) {
            drawStats();
        }
        // If Demo Graph
        if (isDemo === true) {
            if (chartType === 'zone') {
                drawCourtZones();
            } else if (chartType === 'dist') {
                drawCourtZones();
            }
            return;
        }

        if (chartType === 'hex') {
            drawCBBHex();
            drawHexLegend();
        } else if (chartType === 'marker') {
            drawCBBMarkers();
        } else if (chartType === 'zone') {
            drawCourtZones();
            if (isBlankChart === false) {
                drawZoneLegend();
            }
        } else if (chartType === 'dist') {
            drawZoneLegend();
        }
    };

    // Setting Some Stuff
    const svgWidth = 2 * SLP + 50;
    const xView = svgWidth;
    const yView = 47 + TLP - BLR;
    const viewBox = chartStyle === 'bigTenStyles' ? '0 4 50 41' : `0 0 ${xView} ${yView}`;

    // On Mount: Initialize The SVG Element
    useEffect(() => {
        // Viewbox Width based on whether image and stats are present on the sides
        if (svgRef.current) {
            d3.select(svgRef.current)
                .attr('width', '100%')
                .attr('viewBox', viewBox);
        }
    }, []);

    // On Update: Redraw The Chart
    useEffect(() => {
        drawSelectedChart();
        if (svgRef.current) {
            d3.select(svgRef.current)
                .attr('width', '100%')
                .attr('viewBox', viewBox);
        }
    }, [
        newShotData, leagueHexData, leagueZoneData, chartType, ptgc, tooltipName, chartType, zoneSchema, zoneMetric,
        showFgmFga, hasBoxShadow, showIcons, isDemo, isOffense, isLoading,
        competitionId, isBlankChart, headerText, subHeaderText,
        headerStyle, hideButtons, markerLegendType, chartStyle, imageExists
    ]); // every single prop EXCEPT for "zoneValues", "override", something about empty objects...
    // this whole useEffect is problematic...

    // Save Elements to Variables
    const loadingSpinner =
        (<LoadingSpinner
            wrapperStyle={{ margin: '0 0 -25px 2px' }}
            size='small'
            text='Shot chart data is loading...'
        />);
    const svgInfoModal =
        (<SvgInfoModal
            modalType={['hex', 'marker'].includes(chartType) ? `${chartType}ShotChart` : `${zoneMetric}ShotChart`}
            teamId={teamId}
            divisionId={divisionId}
            cx={48.6}
            cy={1.6}
            r={1}
            strokeWidth={0.008}
            fontSize={0.11}
        />);

    const handleStylesToggle = () => {
        const currentStyle = styleOptions.filter(row => row.value === chartStyle);
        const styleNumber = currentStyle[0].count;
        const nextStyle = styleNumber === styleOptions.length - 1 ? styleOptions[0].value : styleOptions[styleNumber + 1].value;
        setChartStyle(nextStyle);
    };
    const stylesButton1 =
        (<circle
            className='hide-print'
            cx={44.1} cy={1.6} r={1} fill='#DDD' stroke='#888' strokeWidth={0.1} cursor='pointer'
            opacity={hideButtons ? 0 : 1}
            onClick={() => handleStylesToggle()}
        />);
    const stylesButton2 =
        (<button
            className='shot-chart-styles-toggle'
            onClick={() => handleStylesToggle()}
        />);
    const hideButtonsButton =
        (<circle
            className='hide-print'
            cx={46.3} cy={1.6} r={1} fill='#DDD' stroke='#888' strokeWidth={0.1} cursor='pointer'
            opacity={hideButtons ? 0 : 1}
            onClick={() => setHideButtons(!hideButtons)}
        />);

    const courtBorderOnTop = (
        <rect
            transform='translate(0,4)'
            x={0} y={0}
            width={50} height={41}
            stroke={bigTenBlue}
            strokeDasharray={'91, 50'}
            strokeWidth={0.6}
            fill='transparent'
            pointerEvents='none'
        />);

    // Save SVG to a variable
    const backgroundColor = chartStyle === 'bigTenStyles' ? null : '#F2F2F2';
    const svgClass = chartStyle === 'bigTenStyles' ? null : (hasBoxShadow ? 'cbb-box-shadowed' : '');
    const hideForBigTen = { display: chartStyle === 'bigTenStyles' ? 'none' : null };
    const shotChart =
        (<svg ref={svgRef} className={`${svgClass} print-border`} style={{ backgroundColor: backgroundColor }}>
            <defs>
                {/* linear gradient needed for zone legend (middle 5 values) */}
                <linearGradient id='selectedGradient' x1='0%' y1='0%' x2='100%' y2='0%'>
                    <stop offset='0%' style={{ stopColor: colorScales[selectedScale][1] }} />
                    <stop offset='25%' style={{ stopColor: colorScales[selectedScale][2] }} />
                    <stop offset='50%' style={{ stopColor: colorScales[selectedScale][3] }} />
                    <stop offset='75%' style={{ stopColor: colorScales[selectedScale][4] }} />
                    <stop offset='100%' style={{ stopColor: colorScales[selectedScale][5] }} />
                </linearGradient>
            </defs>
            <defs>
                <mask id='ellipse-mask'>
                    <ellipse cx='3.6' cy='45.5' rx='3.1' ry='4.1' fill='white' />
                </mask>
            </defs>
            <defs id='circle-masks' />

            <g className='hardwood' />
            <g className='title' />
            <g className='sub-title' />
            <g className='allmarkers' />
            {!isLoading && <g className='court'>{courtShapes}</g>}
            <g className='legend'>
                <g className='efg' style={hideForBigTen} />
                <g className='line-divide' style={hideForBigTen} />
                <g className='marker-legend' />
                <g className='heatmap-legend' />
            </g>

            <g className='shades' />
            <g className='shades-text' />
            <g className='shades-text2' />
            <g className='shades-legend' />
            {chartStyle === 'bigTenStyles' &&
                <g className='court-border-on-top'>{courtBorderOnTop}</g>
            }
            <g className='charttype' />
            <g className='basic-stats' style={hideForBigTen} />

            <g className='team-logo' style={hideForBigTen} />
            <g className='player-image' style={hideForBigTen} />
            <g className='player-images' style={hideForBigTen} />

            <CBBBranding // Hidden By Default
                x={['player', 'player-game'].includes(ptgc) ? 34.4 : 38.6}
                y={39.4}
                width={11}
                type='black' // white, logo
            />

            {hideButtonsButton}
            {styleOptions.length >= 2 && showIcons && <g className='styles-button'>{stylesButton1}</g>}
            {!isLoading && showIcons && !hideButtons && svgInfoModal}
        </svg>);

    // Our one singlular tooltip now!
    const tooltipConfig = { divisionId, tipName: tooltipName, displayName: tooltipName, isOffense };
    const graphTooltip =
        (<Tooltip
            tooltipState={tooltipState}
            renderer={d => getTooltipHtml({ d, graphType, config: tooltipConfig })}
        />);


    // And Finally, Return!
    const s = computeStats();
    return (<div style={{ position: 'relative', ...(override.containerStyles && override.containerStyles) }}>
        {/* Standard Shot Chart */}
        {chartStyle === 'baseStyles' &&
            <React.Fragment>
                {imgHtml}
                {isLoading && loadingSpinner}
                {/* {loadingSpinner} */}
                {shotChart}
            </React.Fragment>
        }

        {/* Big Ten Styling */}
        {chartStyle === 'bigTenStyles' &&
            <Row className='big-ten-styles'>
                <Col xs={9} style={{ padding: '10px 3px 0px 30px', opacity: 1.0 }}>
                    {imgHtml}
                    {styleOptions.length >= 2 && stylesButton2}
                    {isLoading && loadingSpinner}
                    {shotChart}
                </Col>
                <Col xs={3} style={{ padding: '10px 16px 0px 19px', textAlign: 'center', color: bigTenBlue }}>
                    <svg width='100%' viewBox='0 0 32 99' fontFamily='Rift'>
                        <g fill={bigTenBlue} textAnchor='middle' transform='translate(0,0)'>
                            <text x={8} y={8} fontWeight={500} fontSize={7.75} fill={bigTenBlue}>2</text>
                            <text x={17} y={8} fontWeight={500} fontSize={6.75} fill={bigTenBlue}>PT FG</text>
                            <line x1={0} x2={30} y1={9} y2={9} stroke={bigTenBlue} strokeWidth={0.5} />
                            <text x={15} y={17} fontWeight={700} fontSize={8.65} fill={bigTenBlue}>{s.fgm2}/{s.fga2}</text>
                            <text x={15} y={25} fontWeight={700} fontSize={8.65} fill={bigTenBlue}>{s.fg2Pct}</text>

                            <text x={8} y={43} fontWeight={500} fontSize={7.75} fill={bigTenBlue}>3</text>
                            <text x={17} y={43} fontWeight={500} fontSize={6.75} fill={bigTenBlue}>PT FG</text>
                            <line x1={0} x2={30} y1={44} y2={44} stroke={bigTenBlue} strokeWidth={0.5} />
                            <text x={15} y={52} fontWeight={700} fontSize={8.65} fill={bigTenBlue}>{s.fgm3}/{s.fga3}</text>
                            <text x={15} y={60} fontWeight={700} fontSize={8.65} fill={bigTenBlue}>{s.fg3Pct}</text>

                            <text x={15} y={78} fontWeight={500} fontSize={7.75} fill={bigTenBlue}>ALL</text>
                            <line x1={0} x2={30} y1={79} y2={79} stroke={bigTenBlue} strokeWidth={0.5} />
                            <text x={15} y={87} fontWeight={700} fontSize={8.65} fill={bigTenBlue}>{s.fgm}/{s.fga}</text>
                            <text x={15} y={95} fontWeight={700} fontSize={8.65} fill={bigTenBlue}>{s.fgPct}</text>
                        </g>
                    </svg>
                </Col>
            </Row>
        }

        {/* Single Rendering of Tooltip!! */}
        {tooltipState.showTooltip && graphTooltip}
    </div>);
}


export default ShotChartsD3;
// 1965 -> (refactor drawCBBCourt) -> 1570 (very good)


// Handle images to render and where
// let [playerImageIds, setPlayerImageIds] = useState({ onCourt: [], offCourt: [], combos: [] });
// let playerIds = [... new Set(newShotData.map(row => row.playerId))];
// // let [playerImages, setPlayerImages] = useState({});
// function checkFileExists(url, teamId, playerId) {
//     if (!teamId || !playerId) { return <></>; }
//     return (
//         <img
//             style={{ display: 'none' }}
//             alt='player-face'
//             src={url}
//             onError={ev => {
//                 ev.target.src = backupImage;
//                 playerImages({ ...playerImages, [playerId]: false });
//             }}
//         />
//     );
// }
