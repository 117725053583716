
import React, { useState } from 'react';
import styled from 'styled-components';
import imageUrls from '../../../images/gcsImages';

const StyledDiv = styled.div`
    display: ${props => props.$hide === 'true' ? 'none' : 'flex'};
    justify-content: space-between;
    align-items: center;
    width: calc(100% + 16px);
    margin-left: -8px;
    margin-top: 4px;
    background: #555555;
    padding: 0px 5px;

    img {
        height: 33px;
        padding: 3px 0px;
    }

    span {
        color: #EEEEEE;
        font-style: italic;
        font-size: 0.925em;
    }
`;

const BrandedFooter = () => {
    // set state
    const [hide, setHide] = useState(false);

    // and return
    return (
        <StyledDiv
            onClick={() => setHide(!hide)}
            $hide={hide ? 'true' : 'false'}
        >
            <img alt='company logo' src={imageUrls.cbbLogoWhite} />
            <span>@CBBAnalytics</span>
        </StyledDiv>
    );
};

export default BrandedFooter;
