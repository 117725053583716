
import React, { useEffect, useContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Axios from 'axios';
import GlobalContext from '../../context/GlobalContext';
import config from '../../config';

const RouteListenerLayout = ({ children }) => {
    const { pathname } = useLocation();
    const { userData } = useContext(GlobalContext);

    // Create Logging Function
    let userId = userData && userData.user && userData.user._id;
    let userEmail = userData && userData.user && userData.user.email;
    const sendEventLog = async (shortPath, longPath) => {
        let newEventLog = { userId, email: userEmail, type: 'pagechange', shortPath, longPath };
        try {
            let apiBaseUrl = config.url.API_URL;
            await Axios.post(`${apiBaseUrl}/users/add-event-log`, newEventLog);
        } catch (err) {
            console.log('err: ', err);
        }
    };

    // UseEffect to log page change on each new pathname
    useEffect(() => {
        let shortPath = pathname.split('/').map(row => isNaN(row) ? row : '/').join('');
        let longPath = pathname;
        if (userId && pathname !== '/') {
            sendEventLog(shortPath, longPath);
        }
    }, [pathname]);

    // And Return children, which are the <Route />'s
    return children ?? <Outlet />;
};

export default RouteListenerLayout;
