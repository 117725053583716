import React, { useState } from 'react';
import d3 from '../../../assets/d3';
import { allCompetitionIds } from '../../../harddata/NcaaStructures';
import imageUrls from '../../../images/gcsImages';
import { getTooltipLeftTop } from './Tooltip';

// Format X-Axis, Y-Axis for unit type
export function unitFormatter(statUnit) {
    // console.log('stat: ', stat);
    let yUnits, tipUnits; // tipUnits needs additional decimal for pct
    switch (statUnit) {
        case 'pct':
            yUnits = d3.format('.0%');
            tipUnits = d3.format('.1%');
            break;
        case 'int':
            yUnits = d3.format('d');
            tipUnits = d3.format('d');
            break;
        case 'num':
            yUnits = d3.format('d'); // replace '.1f' with 'd'
            tipUnits = d3.format('.1f');
            break;
        case 'num2':
            yUnits = d3.format('.2f');
            tipUnits = d3.format('.2f');
            break;
        case 'ratio':
            yUnits = (n) => d3.format('.1f')(n) + 'x';
            tipUnits = (n) => d3.format('.1f')(n) + 'x';
            break;
        default:
            yUnits = d3.format('d');
            tipUnits = d3.format('d');
    }

    return { yUnits, tipUnits };
}

// Use white font on dark, black on light backgrounds
export function whiteBlack(colorValue, colorFormat) {
    // console.log('whiteBlack params: ', { colorValue, colorFormat });
    if (!colorValue) { return '#111'; }

    let rgb;
    if (colorFormat === 'hex') {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(colorValue);
        rgb = `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
            result[3],
            16
        )})`;
    } else {
        rgb = colorValue;
    }

    if (!rgb) {
        return '#EEE';
    }
    let rgbArray = rgb
        .substring(4, rgb.length - 1)
        .replace(/ /g, '')
        .split(',');

    let r = +rgbArray[0] / 255.0;
    r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);

    let g = +rgbArray[1] / 255.0;
    g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);

    let b = +rgbArray[2] / 255.0;
    b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

    const L = 0.2026 * r + 0.7152 * g + 0.0722 * b;

    if (L > 0.185) {
        return '#111';
    }
    return '#EEE';
}

export function getFallbackPlayerImage(competitionId) {
    // grab generic male/female placeholder images from GCS
    let fallbackImage = allCompetitionIds.male.includes(competitionId) ? imageUrls.missingMale : imageUrls.missingFemale;
    return (fallbackImage);
}

// Shot Chart Tooltip Helper Functions
// ====================================
export function zonesMouseOver(event, d, tooltipState, svgRef, borderStrokeWidth) {
    // Funciton for handling "mouseover" events in by-zone shot charts.

    // No Tooltip or other mouseover activity for "heaves"
    if (d.acc === 'heave3') { return; }

    // show tooltip
    const { left, top } = getTooltipLeftTop({ window, event });
    tooltipState.baseShowTooltip({ d, left, top });
    tooltipState.setColorScheme({ background: d.colorFill, color: whiteBlack(d.colorFill, 'rgb') });

    // De-Emphasize all other Zones
    d3.select(svgRef.current)
        .selectAll('.zone-path')
        // // NC<>JO: to remove zones flickering on mousemove, I tried to exclude hovered zone from these styling changes, but didn't help.
        // // Not what is needed. Problem was moreso that zones were going BACK to opacity 1 when they shouldn't have (since no mouse out)
        // .selectAll('.zone-path:not(.' + d.acc + '-path)')
        // .filter(() => !d3.select(this).classed(d.acc + '-path'))
        // .filter(function() { // no arrow function allowed, messes up "this" scoping for some reason
        //     return !d3.select(this).classed(d.acc + '-path');
        // })
        .attr('stroke-opacity', 0.8)
        .attr('opacity', 0.2)
        .attr('stroke-width', borderStrokeWidth / 2);

    // Emphasize this Zone
    d3.select(svgRef.current)
        .selectAll(`.${d.acc}-path`)
        .attr('stroke-opacity', 1)
        .attr('opacity', 0.95)
        .attr('stroke-width', borderStrokeWidth * 2.5);

    // Highlight Table Rows - not in use, this is for highlighting SBZ table rows when shot chart zone is hovered
    // Requires "tableId" field, and "colClass" field
    // Previously, colClass = ['netFgaFreq', 'absFgaFreq'].includes(zoneMetric) ? '.fga-freq-col' : '.fg-pct-col';
    // Previously, tableId passed as parameter to ShotChartsD3

    // let rowClass = `.${d.acc}-row`;
    // d3.selectAll(`#${tableId}`)
    //     .selectAll(rowClass)
    //     .selectAll(colClass)
    //     .style('background', 'rgba(0, 102, 204, 0.4)');
}
export function zonesMouseOut(d, borderStrokeWidth) {
    // Funciton for handling "mouseover" events in by-zone shot charts.

    // No Tooltip or other mouseover activity for "heaves"
    if (d.acc === 'heave3') { return; }

    // Reset all Zone Emphases to Base
    d3.selectAll('.zone-path')
    // d3.select(svgRef.current)
    //     .selectAll('.zone-path')
        .attr('stroke-opacity', 1)
        .attr('stroke-width', borderStrokeWidth)
        .attr('opacity', 0.95);

    // Un-Highlight Table Rows - not in use, this is for unhighlighting SBZ table rows when shot chart zone is mouseout'd
    // Requires "tableId" field, and "colClass" field
    // Previously, colClass = ['netFgaFreq', 'absFgaFreq'].includes(zoneMetric) ? '.fga-freq-col' : '.fg-pct-col';
    // Previously, tableId passed as parameter to ShotChartsD3

    // // un-highlight table rows
    // let rowClass = `.${d.acc}-row`;
    // d3.selectAll(`#${tableId}`).selectAll(rowClass).selectAll(colClass).style('background', null);
}
export function hexMouseOver(event, d, tipDiv, tipHtml, hexbin, neighborRadius, radius, colorScale, svg) {
    tipDiv.transition().duration(0).style('display', 'block').style('opacity', 1);
    tipDiv.html(tipHtml)
        .style('left', (event.pageX - 10) + 'px')
        .style('top', (event.pageY + 35) + 'px');
    tipDiv
        .style('background', colorScale(d.netFgPct7Hex))
        .style('color', whiteBlack(colorScale(d.netFgPct7Hex), 'rgb'));

    // Hexagon Hover Action
    let dx = d.x, dy = d.y, r = neighborRadius;
    svg.select('g.hexagon').selectAll('path')
        .attr('d', function (f) {
            if ((f.x < (dx + r / 4) & f.x > (dx - r / 4)) & (f.y < (dy + r / 4) & f.y > (dy - r / 4))) {
                return hexbin.hexagon(radius(f.newlength * 1.6));
            } else if ((f.x < (dx + r) & f.x > (dx - r)) & (f.y < (dy + r) & f.y > (dy - r))) {
                return hexbin.hexagon(radius(f.newlength * 1.6));
            }
            return hexbin.hexagon(radius(f.newlength));
        })
        .attr('stroke-width', function (a) {
            let in7 = ((a.x < (dx + r) & a.x > (dx - r)) & (a.y < (dy + r) & a.y > (dy - r)));
            if (in7) { return 0.250; }
            return 0.175;
        })
        .attr('stroke', function (a) {
            let in7 = ((a.x < (dx + r) & a.x > (dx - r)) & (a.y < (dy + r) & a.y > (dy - r)));
            if (in7) { return 'black'; }
            return 'black';
        })
        .attr('transform', function (f) {
            if ((f.x < (dx + r) & f.x > (dx - r)) & (f.y < (dy + r) & f.y > (dy - r))) {
                if (f.y === dy & f.x < dx) { // left border hex
                    return `translate(${f.x - 0.65},${f.y})`;
                } else if (f.y === dy & f.x > dx) { // right border hex
                    return `translate(${f.x + 0.65},${f.y})`;
                } else if (f.y > dy & f.x > dx) { // top right hex
                    return `translate(${f.x + 0.31},${f.y + 0.475})`;
                } else if (f.y > dy & f.x < dx) { // top left hex
                    return `translate(${f.x - 0.31},${f.y + 0.475})`;
                } else if (f.y < dy & f.x > dx) { // bot right hex
                    return `translate(${f.x + 0.31},${f.y - 0.475})`;
                } else if (f.y < dy & f.x < dx) { // bot left hex
                    return `translate(${f.x - 0.31},${f.y - 0.475})`;
                }
                return `translate(${f.x}, ${f.y})`;
            }
            return `translate(${f.x},${f.y})`;
        })
        .sort(function (a) {
            let in7 = ((a.x < (dx + r) & a.x > (dx - r)) & (a.y < (dy + r) & a.y > (dy - r)));
            if (in7) { return 1; }
            return -1;
        });
}
export function hexMouseOut() {
    //
}


// Shot Chart Hexagon Mouseover Helper Function
export function checkIn7HexRadius(f, dx, dy, r) {
    return ((f.x < (dx + r) & f.x > (dx - r)) & (f.y < (dy + r) & f.y > (dy - r)));
}
export function transformHexagon(f, dx, dy) {
    if (f.y === dy & f.x < dx) { // left border hex
        return `translate(${f.x - 0.65},${f.y})`;
    } else if (f.y === dy & f.x > dx) { // right border hex
        return `translate(${f.x + 0.65},${f.y})`;
    } else if (f.y > dy & f.x > dx) { // top right hex
        return `translate(${f.x + 0.31},${f.y + 0.475})`;
    } else if (f.y > dy & f.x < dx) { // top left hex
        return `translate(${f.x - 0.31},${f.y + 0.475})`;
    } else if (f.y < dy & f.x > dx) { // bot right hex
        return `translate(${f.x + 0.31},${f.y - 0.475})`;
    } else if (f.y < dy & f.x < dx) { // bot left hex
        return `translate(${f.x - 0.31},${f.y - 0.475})`;
    } else {
        return `translate(${f.x},${f.y})`; // this shouldn't really ever run
    }
}

// Linear Gradient Defs
export function getLinearGradientDef(id, color = null) {
    let linearGradient;

    if (color) {
        linearGradient = (
            <linearGradient id={color} x1='0%' y1='0%' x2='100%' y2='0%'>
                <stop offset='0%' style={{ stopColor: '#FFF' }} />
                <stop offset='100%' style={{ stopColor: color }} />
            </linearGradient>
        );

        return (
            <defs>
                {linearGradient}
            </defs>
        );
    }

    // otherwise use this
    switch (id) {
        case 'redbluegradient': linearGradient =
            (<linearGradient id='redbluegradient' x1='0%' y1='0%' x2='100%' y2='0%'>
                <stop offset='0%' style={{ stopColor: '#22F' }} />
                <stop offset='25%' style={{ stopColor: '#99F' }} />
                <stop offset='50%' style={{ stopColor: '#EEE' }} />
                <stop offset='75%' style={{ stopColor: '#F99' }} />
                <stop offset='100%' style={{ stopColor: '#F22' }} />
            </linearGradient>);
            break;
        case 'blackwhitegradient': linearGradient =
            (<linearGradient id='blackwhitegradient' x1='0%' y1='0%' x2='100%' y2='0%'>
                <stop offset='0%' style={{ stopColor: '#DDD' }} />
                <stop offset='25%' style={{ stopColor: '#AAA' }} />
                <stop offset='50%' style={{ stopColor: '#888' }} />
                <stop offset='75%' style={{ stopColor: '#444' }} />
                <stop offset='100%' style={{ stopColor: '#111' }} />
            </linearGradient>);
            break;
        case 'yellowredgradient': linearGradient =
            (<linearGradient id='yellowredgradient' x1='0%' y1='0%' x2='100%' y2='0%'>
                <stop offset='0%' style={{ stopColor: '#FFFFE0' }} />
                <stop offset='25%' style={{ stopColor: '#FFBD84' }} />
                <stop offset='50%' style={{ stopColor: '#F47461' }} />
                <stop offset='75%' style={{ stopColor: '#CB2F44' }} />
                <stop offset='100%' style={{ stopColor: '#8B0000' }} />
            </linearGradient>);
            break;
        case 'bluegradient': linearGradient =
            (<linearGradient id='bluegradient' x1='0%' y1='0%' x2='100%' y2='0%'>
                <stop offset='0%' style={{ stopColor: '#ddddff' }} />
                <stop offset='25%' style={{ stopColor: '#b6a6eb' }} />
                <stop offset='50%' style={{ stopColor: '#8b71d5' }} />
                <stop offset='75%' style={{ stopColor: '#5c3fc0' }} />
                <stop offset='100%' style={{ stopColor: '#0000aa' }} />
            </linearGradient>);
            break;
        case 'yelloworangeredgradient': linearGradient =
            (<linearGradient id='yelloworangeredgradient' x1='0%' y1='0%' x2='100%' y2='0%'>
                <stop offset='0%' style={{ stopColor: '#fef0d9' }} />
                <stop offset='25%' style={{ stopColor: '#fdcc8a' }} />
                <stop offset='50%' style={{ stopColor: '#fc8d59' }} />
                <stop offset='75%' style={{ stopColor: '#e34a33' }} />
                <stop offset='100%' style={{ stopColor: '#b30000' }} />
            </linearGradient>);
            break;
        default: console.log('Error: bad linear gradient ID!');
    }

    return (
        <defs>
            {linearGradient}
        </defs>
    );
}

// Distinct Colors
export function getDistinctColors(n) {
    let colorsArray = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000'];
    return colorsArray.slice(0, n);
}


// Utils for Scatter Plots ((+, -), mean line, x=y line, best fit line)
// ====================================================================
// Create lines (with arrows) through xMean and yMean
export function getMeanArrows(styles, scales, values) {
    // NC: replacing [xMin, xMax, yMin, yMax] with [chartWidth, chartHeight] leeds to a cleaner, more consistent set of arrows
    // Extract Props
    let { xScale, yScale } = scales;
    let { xMean, yMean, chartWidth, chartHeight, topGap } = values;

    // Arrow Tips (triangles)
    // let arrowPathTop = `M${xScale(xMean)} ${yScale(yMax) + 3} L${xScale(xMean) - 5} ${yScale(yMax) + 10} L${xScale(xMean) + 5} ${yScale(yMax) + 10}`;
    // let arrowPathBot = `M${xScale(xMean)} ${yScale(yMin) - 3} L${xScale(xMean) - 5} ${yScale(yMin) - 10} L${xScale(xMean) + 5} ${yScale(yMin) - 10}`;
    // let arrowPathLeft = `M${xScale(xMin) - 17} ${yScale(yMean)} L${xScale(xMin) - 10} ${yScale(yMean) - 5} L${xScale(xMin) - 10} ${yScale(yMean) + 5}`;
    // let arrowPathRight = `M${xScale(xMax) + 17} ${yScale(yMean)} L${xScale(xMax) + 10} ${yScale(yMean) - 5} L${xScale(xMax) + 10} ${yScale(yMean) + 5}`;
    // console.log('paths: ', { arrowPathTop, arrowPathBot, arrowPathLeft, arrowPathRight });

    let arrowPathTop = `M${xScale(xMean)} ${topGap} L${xScale(xMean) - 6} ${topGap + 8} L${xScale(xMean) + 6} ${topGap + 8}`;
    let arrowPathBot = `M${xScale(xMean)} ${chartHeight} L${xScale(xMean) - 5} ${chartHeight - 8} L${xScale(xMean) + 5} ${chartHeight - 8}`;
    let arrowPathLeft = `M${0} ${yScale(yMean)} L${8} ${yScale(yMean) - 6} L${8} ${yScale(yMean) + 6}`;
    let arrowPathRight = `M${chartWidth} ${yScale(yMean)} L${chartWidth - 8} ${yScale(yMean) - 6} L${chartWidth - 8} ${yScale(yMean) + 6}`;

    // Arrow Elements
    let arrowElements = (<>
        <line
            x1={2}
            y1={yScale(yMean)}
            x2={chartWidth - 2}
            y2={yScale(yMean)}
            stroke={styles.arrowColor}
            strokeWidth={1}
        />
        <line
            x1={xScale(xMean)}
            y1={chartHeight - 2}
            x2={xScale(xMean)}
            y2={topGap + 2}
            stroke={styles.arrowColor}
            strokeWidth={1}
        />
        <path d={arrowPathTop} fill={styles.arrowColor} />
        <path d={arrowPathBot} fill={styles.arrowColor} />
        <path d={arrowPathLeft} fill={styles.arrowColor} />
        <path d={arrowPathRight} fill={styles.arrowColor} />
    </>);

    return arrowElements;
}

// Create Line of Best Fit
export function getLineOfBestFit(graphData, scales, options, values) {
    let { xScale, yScale } = scales;
    let { xOption, yOption } = options;
    let { xMin, xMax, yMin, yMax } = values;

    // console.log('graphData, xOption, yOption, xMin, xMax: ', { graphData, xOption, yOption, xMin, xMax });
    let linearRegression = d3.regressionLinear()
        .x(d => +d[xOption.key])
        .y(d => +d[yOption.key])
        .domain([xMin, xMax]);

    let regressionOutput = linearRegression(graphData);
    // console.log('regressionOutput: ', regressionOutput);

    // Draw Line, handle yScale being flipped in D3Scatter when sign === "neg"
    const legendLineY = yOption.sign === 'pos' ? yMax + (0.075 * (yMax - yMin)) : yMin + (0.075 * (yMin - yMax));
    const legendTextY = yOption.sign === 'pos' ? yMax + (0.06 * (yMax - yMin)) : yMin + (0.06 * (yMin - yMax));

    // yOption
    return (<g className='line-of-best-fit'>
        <line // main line of best fit
            className='best-fit'
            stroke='#0066CC'
            strokeWidth={2.25}
            opacity={1}
            x1={xScale(regressionOutput[0][0])}
            x2={xScale(regressionOutput[1][0])}
            y1={yScale(regressionOutput[0][1])}
            y2={yScale(regressionOutput[1][1])}
        />
        <line // legend line
            className='best-fit'
            stroke='#0066CC'
            strokeWidth={2.25}
            opacity={1}
            x1={xScale(xMin + (0.70 * (xMax - xMin)))}
            x2={xScale(xMin + (0.80 * (xMax - xMin)))}
            y1={yScale(legendLineY)}
            y2={yScale(legendLineY)}
        />
        <text // legend text
            className='best-fit'
            x={xScale(xMin + (0.81 * (xMax - xMin)))}
            y={yScale(legendTextY)}
            fill='#0066CC'
            fontSize='1em'
            fontWeight={700}
            pointerEvents='none'
        >Line of Best Fit</text>
    </g>);
}

// Create Diagonal X = Y Line
export function getXYLine(scales, values) {
    // Extract Props
    let { xScale, yScale } = scales;
    let { xMin, xMax, yMin, yMax } = values;

    // and Return
    return (<>
        <line
            x1={xMin > yMin ? xScale(xMin) : xScale(yMin)}
            y1={xMin > yMin ? yScale(xMin) : yScale(yMin)}
            x2={xMax > yMax ? xScale(yMax) : xScale(xMax)}
            y2={xMax > yMax ? yScale(yMax) : yScale(xMax)}
            stroke='#0066CC'
            strokeWidth={2.4}
        />
        <line
            stroke={'#0066CC'}
            strokeWidth={2.5}
            opacity={1}
            x1={xScale(xMin + (0.74 * (xMax - xMin)))}
            x2={xScale(xMin + (0.84 * (xMax - xMin)))}
            y1={yScale(yMax + (0.075 * (yMax - yMin)))}
            y2={yScale(yMax + (0.075 * (yMax - yMin)))}
        />
        <text
            x={xScale(xMin + (0.85 * (xMax - xMin)))}
            y={yScale(yMax + (0.06 * (yMax - yMin)))}
            fill='#0066CC'
            fontSize='1em'
            fontWeight={700}
            pointerEvents='none'
        >X=Y Line</text>
    </>);
}

// Add (+) and (-) to Corners
export function getPlusMinuses(margin, values) {
    // useState: onClick to hide!
    const [show, setShow] = useState(true);

    // grab values, derive stuff
    let { chartWidth, chartHeight } = values;
    let rightX = chartWidth + margin.right - 18;
    let leftX = -margin.left + 18;
    let topY = -12 + margin.top;
    let bottomY = chartHeight + margin.bottom - 16;
    let offAndDefLabels = [
        { x: rightX, y: topY, color: '#0066cc', text: '+' },
        { x: leftX, y: bottomY, color: '#cc0000', text: '-' }
    ];

    return (
        offAndDefLabels.map((d, idx) => (
            <g
                key={`${idx}-label-g`}
                style={{ cursor: 'pointer', opacity: show ? 1 : 0 }} // display: show ? null : 'none' }}
                onClick={() => setShow(!show)}
            >
                <text
                    x={d.x}
                    y={d.y}
                    fill={d.color}
                    fontSize='2.0em'
                    fontWeight={700}
                    pointerEvents='none'
                    textAnchor='middle'
                    dominantBaseline='middle'
                >{d.text}</text>
                <circle
                    cx={d.x}
                    cy={d.y - 2}
                    r={12}
                    fill={d.color}
                    stroke='#222'
                    strokeWidth={1}
                    opacity={0.5}
                />
            </g>)
        )
    );
}
