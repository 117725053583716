
// Import React Stuff
import React, { useContext } from 'react';
import GlobalContext from '../../context/GlobalContext';

// Import D3 + Graph-Helper Components
import d3 from '../../assets/d3';
import { allCompetitionIds } from '../../harddata/NcaaStructures';

const createRectangleLabels = (fullName, actionDuration, netPts, clock) => {
    let text1 = '', text2 = '', text3 = '', yAdj = 0, yGap = 0, size = 14;

    if (!fullName) { return { text1, text2, text3: '???', yAdj, yGap, size }; } // handle ~6K missing playerIds / fullNames...

    let nameArray = fullName.split(' ');
    nameArray = nameArray.filter(Boolean); // quick way to remove "" empty strings in arary
    let fNameLetter = nameArray[0].charAt(0);
    let lNameLetter = nameArray[1].charAt(0);
    let lastName = nameArray[nameArray.length - 1].slice(0, 5);
    let shortName = (nameArray[0] === 'Total') ? 'Total' : (nameArray[0] === 'blank' ? '' : `${fNameLetter}.${lastName}`);


    if (actionDuration > 180) {
        size = 18;
        yAdj = 0;
        yGap = 0;
        text1 = `${shortName}: (${netPts >= 0 ? '+' : ''}${netPts}) (${clock})`;
        text2 = '';
    } else if (actionDuration <= 180 & actionDuration > 80) {
        size = 16;
        yAdj = -0.225;
        yGap = 0.4;
        text1 = `${shortName}`;
        text2 = `(${netPts >= 0 ? '+' : ''}${netPts}) (${clock})`;
    } else if (actionDuration <= 80 & actionDuration > 35) {
        yAdj = -0.3;
        yGap = 0.3;
        text1 = `${fNameLetter}.${lNameLetter}.`;
        text2 = `${netPts >= 0 ? '+' : ''}${netPts}`;
        text3 = clock.slice(1);
    } else if (actionDuration <= 35 & actionDuration > 10) {
        yAdj = -0.3;
        yGap = 0.3;
        text1 = fNameLetter;
        text2 = lNameLetter;
        text3 = `${netPts >= 0 ? '+' : ''}${netPts}`;
    } else {
        // else leave as blank strings
    }

    return { text1, text2, text3, yAdj, yGap, size };
};

function D3LineupFlow({ graphData, colorType = 'netRtgScale' }) {
    // return if missing data
    if (graphData.length === 0) { return <div />; }
    let competitionId = graphData[0].competitionId;
    if (!competitionId) { return <div />; }


    // Identify Game Type (regulation vs. OT 1,2,3,4)
    const isMale = allCompetitionIds.male.includes(competitionId);
    let starterSplits = isMale ? ['First Half', 'Second Half'] : ['First Quarter', 'Second Quarter', 'Third Quarter', 'Fourth Quarter'];
    let maxSecs = Math.max(...graphData.map(row => row.sEnd));
    let gameType = '', otMinutes = [], gameSplits = [];
    switch (maxSecs) {
        case 2400: gameType = 'reg'; otMinutes = []; gameSplits = starterSplits; break;
        case 2700: gameType = 'ot1'; otMinutes = [5]; gameSplits = [...starterSplits, 'OT 1']; break;
        case 3000: gameType = 'ot2'; otMinutes = [0, 5, 10]; gameSplits = [...starterSplits, 'OT 1', 'OT 2']; break;
        case 3300: gameType = 'ot3'; otMinutes = [0, 5, 10, 15]; gameSplits = [...starterSplits, 'OT 1', 'OT 2', 'OT 3']; break;
        case 3600: gameType = 'ot4'; otMinutes = [0, 5, 10, 15, 20]; gameSplits = [...starterSplits, 'OT 1', 'OT 2', 'OT 3', 'OT 4']; break;
        default: gameType = 'reg'; console.log(`ERROR: bad gameType # of seconds: ${maxSecs}`);
    }

    // Set Constants
    const teamId = graphData[0].teamId;
    const { teamInfosObj } = useContext(GlobalContext);
    const teamHex = teamInfosObj && teamInfosObj[teamId] ? teamInfosObj[teamId].hexColor1 : '#0066CC';
    const svgWidth = 1600;
    const svgHeight = ['ot2', 'ot3', 'ot4'].includes(gameType) ? 810 : 645;
    const margin = { left: 0, top: 45, bottom: 10, right: 40 };
    const width = svgWidth - margin.right - margin.left;
    const height = svgHeight - margin.top - margin.bottom;


    // Scales
    // =========
    const xScale = d3.scaleLinear()
        .domain([0, ['ot2', 'ot3', 'ot4'].includes(gameType) ? 1200 : maxSecs - 1200])
        .range([margin.left, width - margin.right]);

    const yScale = d3.scaleLinear()
        .domain([0, ['ot2', 'ot3', 'ot4'].includes(gameType) ? 19.5 : 12.5]) // for now, 10 rows + 2 blank rows between halfs
        .range([0, height]);

    const fillStintScale = d3.scaleLinear()
        .domain([12, 6, 0, -6, -12])
        .range(['#57BB8A', '#AADDC4', '#DDDDDD', '#F3BEB9', '#E77C73']);
    // ========


    let sortedGraphData = graphData
        .slice()
        .sort((a, b) => { return a.playerStint > b.playerStint ? 1 : -1; });

    const colorsArray = ['#e6194B', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#42d4f4', '#f032e6', '#fabed4', '#469990', '#dcbeff', '#9A6324', '#fffac8', '#800000', '#aaffc3', '#000075', '#a9a9a9', '#911eb4', '#bfef45', '#808000', '#ffd8b1'];
    const playerIds = [...new Set(sortedGraphData.map(row => row.playerId))];

    const gameRects = sortedGraphData.map((d) => {
        let isH1 = isMale ? (d.periodNumber === 1 & d.sStart <= 1200) : ([1, 2].includes(d.periodNumber) & d.sStart <= 1200);
        let isOt = gameType !== 'reg' && d.sStart >= 2400;
        let xStart, yRow;
        switch (gameType) {
            case 'reg': yRow = isH1 ? 0 : 7; xStart = isH1 ? d.sStart : d.sStart - 1200; break;
            case 'ot1': yRow = isH1 ? 0 : 7; xStart = isH1 ? d.sStart : d.sStart - 1200; break;
            case 'ot2': yRow = isH1 ? 0 : (isOt ? 14 : 7); xStart = isH1 ? d.sStart : (isOt ? d.sStart - 2400 : d.sStart - 1200); break;
            case 'ot3': yRow = isH1 ? 0 : (isOt ? 14 : 7); xStart = isH1 ? d.sStart : (isOt ? d.sStart - 2400 : d.sStart - 1200); break;
            case 'ot4': yRow = isH1 ? 0 : (isOt ? 14 : 7); xStart = isH1 ? d.sStart : (isOt ? d.sStart - 2400 : d.sStart - 1200); break;
            default:
        }

        let xMid = xStart + (d.secs / 2);

        // Styles
        let playerColor = colorsArray[playerIds.indexOf(d.playerId)];
        let ratingColor = fillStintScale(d.netPts);
        let fillColor = colorType === 'teamHex' ? teamHex : (colorType === 'playerColors' ? playerColor : ratingColor);

        // Text Labels For Each Rect
        let { text1, text2, text3, yAdj, yGap, size } = createRectangleLabels(d.fullName, d.secs, d.netPts, d.cDiff);
        // And Return The Labels
        return (
            <g key={`game-rect-${d.playerStint}`}>
                <rect
                    className={`${d.pos}-${d.sStart}-${d.sEnd}`}
                    x={xScale(xStart)}
                    y={yScale(d.pos + yRow - 1)}
                    width={xScale(d.secs) - xScale(0)}
                    height={yScale(1) - yScale(0)}
                    style={{ opacity: 0.6, stroke: '#222222', strokeWidth: 1, fill: fillColor }}
                />
                <text
                    x={xScale(xMid)}
                    y={yScale(d.pos + yRow + yAdj - 0.5)}
                    textAnchor='middle'
                    dominantBaseline='middle'
                    fontSize={size}
                    style={{ fill: '#111111' }}
                >{text1}</ text>
                <text
                    x={xScale(xMid)}
                    y={yScale(d.pos + yRow + yAdj - 0.5 + yGap)}
                    textAnchor='middle'
                    dominantBaseline='middle'
                    fontSize={size}
                    style={{ fill: '#111111' }}
                >{text2}</text>
                <text
                    x={xScale(xMid)}
                    y={yScale(d.pos + yRow + yAdj - 0.5 + 2 * yGap)}
                    textAnchor='middle'
                    dominantBaseline='middle'
                    fontSize={size}
                    style={{ fill: '#111111' }}
                >{text3}</text>
            </g>
        );
    });

    // Header Rectangles
    const splitLocations = {
        reg: isMale ? { secs: [0, 1200, 2400], x: [0, 0], y: [0, 7] } : { secs: [0, 600, 1200, 1800, 2400], x: [0, 600, 0, 600], y: [0, 0, 7, 7] },
        ot1: isMale ? { secs: [0, 1200, 2400, 2700], x: [0, 0, 1200], y: [0, 7, 7] } : { secs: [0, 600, 1200, 1800, 2400, 2700], x: [0, 600, 0, 600, 1200], y: [0, 0, 7, 7, 7] },
        ot2: isMale ? { secs: [0, 1200, 2400, 2700, 3000], x: [0, 0, 0, 300], y: [0, 7, 14, 14] } : { secs: [0, 600, 1200, 1800, 2400, 2700, 3000], x: [0, 600, 0, 600, 0, 300], y: [0, 0, 7, 7, 14, 14] },
        ot3: isMale ? { secs: [0, 1200, 2400, 2700, 3000, 3300], x: [0, 0, 0, 300, 600], y: [0, 7, 14, 14, 14] } : { secs: [0, 600, 1200, 1800, 2400, 2700, 3000, 3300], x: [0, 600, 0, 600, 0, 300, 600], y: [0, 0, 7, 7, 14, 14, 14] },
        ot4: isMale ? { secs: [0, 1200, 2400, 2700, 3000, 3300, 3600], x: [0, 0, 0, 300, 600, 900], y: [0, 7, 14, 14, 14, 14] } : { secs: [0, 600, 1200, 1800, 2400, 2700, 3000, 3300, 3600], x: [0, 600, 0, 600, 0, 300, 600], y: [0, 0, 7, 7, 14, 14, 14] }
    };

    const gameSplitRects = gameSplits.map((split, idx) => {
        let halfWidth = (splitLocations[gameType].secs[idx + 1] - splitLocations[gameType].secs[idx]) / 2;
        let xStart = splitLocations[gameType].x[idx];
        let yStart = splitLocations[gameType].y[idx];
        return (
            <g key={`game-split-${split}`}>
                <rect
                    x={xScale(xStart)}
                    y={yScale(yStart - 0.75)}
                    width={xScale(2 * halfWidth)}
                    height={yScale(0.75) - yScale(0)}
                    style={{ stroke: '#222222', strokeWidth: 1, fill: '#AAAAAA' }}
                />
                <text
                    x={xScale(xStart + halfWidth)}
                    y={1 + yScale(yStart - 0.375)}
                    textAnchor='middle'
                    dominantBaseline='middle'
                    fontSize={23}
                    style={{ fill: '#222222' }}
                >{split}</text>
            </g>
        );
    });


    // Layout Minutes Labels (Add Ticks Eventually)
    // =============================================
    const minutesText = [0, 5, 10, 15, 20].map(thisMinute => {
        return (<g key={`half-min-${thisMinute}`}>
            <text
                x={xScale(60 * thisMinute)}
                y={yScale(5.45)}
                fontSize={19}
                textAnchor='middle'
                style={{ fontWeight: 700, fill: '#222222' }}
            >{thisMinute}</ text>
            <text
                x={xScale(60 * thisMinute)}
                y={yScale(12.45)}
                fontSize={19}
                textAnchor='middle'
                style={{ fontWeight: 700, fill: '#222222' }}
            >{thisMinute}</ text>
        </g>);
    });
    const otMinutesText = otMinutes.map(min => {
        let xStart = gameType === 'ot1' ? xScale(1200) : xScale(60 * min);
        let yStart = gameType === 'ot1' ? yScale(12.45) : yScale(19.45);
        return (<text
            key={`ot-min-${min}`}
            x={xStart}
            y={yStart}
            fontSize={18}
            textAnchor='middle'
            style={{ fontWeight: 700, fill: '#222222' }}
        >{min}</ text>
        );
    });

    const positionsText = ['1', '2', '3', '4', '5', '', '', '1', '2', '3', '4', '5', '', '', '1', '2', '3', '4', '5'].map((pos, idx) => {
        return (<g key={`pos-${idx}-${pos}`}>
            {idx < 12 | ['ot2', 'ot3', 'ot4'].includes(gameType) &&
                <text
                    x={19}
                    y={yScale(idx + 0.5)}
                    fontSize={21}
                    textAnchor='middle'
                    dominantBaseline='middle'
                    style={{ fill: '#222222', fontWeight: 700 }}
                >{pos}</ text>
            }
            {pos !== '' & (idx < 12 | ['ot2', 'ot3', 'ot4'].includes(gameType)) &&
                <line
                    x1={2}
                    x2={40}
                    y1={yScale(idx)}
                    y2={yScale(idx)}
                    stroke='#222222'
                    strokeWidth={1}
                />
            }
        </g>);
    });
    // ======


    // And Return
    return (
        <div style={{ width: '100%' }}>
            <svg width='100%' viewBox={`0 0 ${svgWidth} ${svgHeight}`}>
                <g transform={`translate(${margin.left},${margin.top})`}>
                    <g className='game-rectangles' transform={`translate(${40},${0})`} >
                        {gameSplitRects}
                        {gameRects}
                    </g>
                    <g className='minutes-text' transform={`translate(${40}, ${0})`}>
                        {minutesText}
                        {otMinutesText}
                    </g>
                    <g className='support-text'>
                        {positionsText}
                    </g>
                </g>
            </svg>
        </div>
    );
}

export default D3LineupFlow;

// update to scale
// update colors / remove background
// smarter font sizes (bigger where possible)
// smarter positioning / overall make cleaner
