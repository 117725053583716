
import { useState, useEffect, useMemo } from 'react';

function useScreenSize() {
    // do not change breakpoints 576, 768, 992, 1200 without also handling app.scss breakpoints.

    // keep track of window innerWidth
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    // useEffect to create "resize" event listener, remove in garbage collection return
    useEffect(() => {
        const updateWindowDimensions = () => {
            const newWidth = window.innerWidth;
            setInnerWidth(newWidth);
        };
        window.addEventListener('resize', updateWindowDimensions);
        return () => window.removeEventListener('resize', updateWindowDimensions);
    }, []);

    // console.log('innerWidth: ', innerWidth);
    const isMobS = useMemo(() => innerWidth < 320, [innerWidth]);
    const isMobM = useMemo(() => innerWidth < 375, [innerWidth]);
    const isMobL = useMemo(() => innerWidth < 425, [innerWidth]);
    const isXs = useMemo(() => innerWidth < 576, [innerWidth]);
    const isSm = useMemo(() => innerWidth < 768, [innerWidth]);
    const isMd = useMemo(() => innerWidth < 992, [innerWidth]);
    const isLg = useMemo(() => innerWidth < 1200, [innerWidth]);
    return { isMobS, isMobM, isMobL, isXs, isSm, isMd, isLg };
}

export default useScreenSize;
