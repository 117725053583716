
import React, { useContext } from 'react';
import GlobalContext from '../../../context/GlobalContext';
import { showInfoModal, hideInfoModal } from '../../../utils/TableHelpers';

const getBulletsObj = (divisionId) => {
    const bulletsObj = {
        // shot chart info bullets
        hexSize: { title: 'Size:', desc: `Hexagon size represents the player or team's absolute frequency of shots at each individual hexagon` },
        hexColor: { title: 'Color:', desc: `Hexagon color represents the player or team's net FG% at a hexagon + its bordering 6 hexagons (7-hexagon area), compared to D-${divisionId}'s FG% across the 7-hexagon area.` },
        shotMarkers: { title: 'Markers:', desc: `Markers represent missed shots, assisted made shots, and unassisted made shots.` },
        netEfgPctText: { title: 'Text:', desc: `Text values for each zone represent the player or team's net effective FG% (eFG%) in each zone, compared to the singular, overall D-${divisionId} eFG% across all zones.` },
        absEfgPctText: { title: 'Text:', desc: `Text values for each zone represent the player or team's total effective FG% (eFG%) in each zone.` },
        efgPctColor: { title: 'Color:', desc: `Colors for each zone represent the player or team's net eFG% in each zone, compared to the singular, overall D-${divisionId}'s eFG% across all zones. This color helps gauge how efficient shots are in each zone, compared to a baseline level of efficiency for any given shot in D-${divisionId}.` },
        netFgPctText: { title: 'Text:', desc: `Text values for each zone represent the player or team's net FG% in each zone, compared to the average D-${divisionId} FG% in each zone.` },
        absFgPctText: { title: 'Text:', desc: `Text values for each zone represent the player or team's total FG% in each zone.` },
        fgPctColor: { title: 'Color:', desc: `Colors for each zone represent the player or team's net FG% in each zone, compared to the average D-${divisionId} FG% in each zone. This color helps gauge how well the player or team shoots in each zone, compared to the rest of D-${divisionId}.` },
        netFgaFreqText: { title: 'Text:', desc: `Text values for each zone represent the player or team's net % of shots in each zone, compared to the average D-${divisionId} % of shots taken in the zone.` },
        absFgaFreqText: { title: 'Text:', desc: `Text values for each zone represent the player or team's total % of shots in each zone.` },
        fgaFreqColor: { title: 'Color:', desc: `Colors for each zone represent the player or team's net % of shots in each zone, compared to the average D-${divisionId} % of shots taken in each zone. This color helps gauge whether the player or team takes more or less shots in each zone, compared to the D-${divisionId} average in each zone.` }
    };

    return bulletsObj;
};
const getModalInfo = (divisionId) => {
    const bulletsObj = getBulletsObj(divisionId);
    return {
        // Shot Charts
        hexShotChart: { title: 'Heat Map Shot Chart', bullets: [bulletsObj.hexSize, bulletsObj.hexColor] },
        markerShotChart: { title: 'Marker Shot Chart', bullets: [bulletsObj.shotMarkers] },
        netFgPctShotChart: { title: 'Shooting By Zone: Net FG%', bullets: [bulletsObj.netFgPctText, bulletsObj.fgPctColor] },
        absFgPctShotChart: { title: 'Shooting By Zone: Total FG%', bullets: [bulletsObj.absFgPctText, bulletsObj.fgPctColor] },
        netEfgPctShotChart: { title: 'Shooting By Zone: Net eFG%', bullets: [bulletsObj.netEfgPctText, bulletsObj.efgPctColor] },
        absEfgPctShotChart: { title: 'Shoting By Zone: Total eFG%', bullets: [bulletsObj.absEfgPctText, bulletsObj.efgPctColor] },
        netFgaFreqShotChart: { title: 'Shoting By Zone: Net % Shots', bullets: [bulletsObj.netFgaFreqText, bulletsObj.fgaFreqColor] },
        absFgaFreqShotChart: { title: 'Shoting By Zone: Total % shots', bullets: [bulletsObj.absFgaFreqText, bulletsObj.fgaFreqColor] }
    };
};


function SvgInfoModal({ modalType, teamId, divisionId, cx, cy, r, strokeWidth, fontSize }) {
    // Grab modal color from teamId
    const { teamInfosObj } = useContext(GlobalContext);
    let modalColor = teamInfosObj && teamInfosObj[teamId] ? teamInfosObj[teamId].hexColor1 : '#444444';

    // Create circle and text
    let circle =
        (<circle
            cx={cx}
            cy={cy}
            r={r}
            stroke='#222222'
            strokeWidth={`${strokeWidth}em`}
            fill={modalColor}
        />);
    let text =
        (<text
            x={cx - 0.1 * r} // -0.1*r to improve centering
            y={cy + 0.1 * r} // +0.1*r to improve centering
            dominantBaseline='middle'
            textAnchor='middle'
            pointerEvents='none'
            fontFamily='cursive'
            fontWeight='700'
            fontSize={`${fontSize}em`}
            fill='white'
        >
            i
        </text>);

    // Get body of text for info modal
    let modalInfoObj = getModalInfo(divisionId);
    let modalObject = modalInfoObj[modalType];

    // Use global div for tooltip here
    return (
        <g
            className='svg-info-modal hide-print'
            style={{ cursor: 'pointer' }}
            onMouseOver={(e) => showInfoModal({ modalObject, e })}
            onMouseOut={() => hideInfoModal()}
        >
            {circle}
            {text}
        </g>
    );
}

export default SvgInfoModal;
