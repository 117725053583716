

const pppcQualifiersMap = (possOrChnc) => {
    const possOrChncUpper = possOrChnc === 'poss' ? 'Possession' : 'Chance';
    const possOrChncLower = possOrChnc === 'poss' ? 'possessions' : 'chances';
    const possOrChncAcronym = possOrChnc === 'poss' ? 'PPP' : 'PPC';

    // after REDEPLOY, resolve dumpe jumpBall/jumpHeldBall, and remove extra secs0to6, etc.
    return {
        secs0to10: { key: '0-10 Secs', order: 1, tipTitle: `${possOrChncUpper} Length: 0 - 10 seconds`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that were completed in 0-10 seconds` },
        secs10to20: { key: '10-20 Secs', order: 2, tipTitle: `${possOrChncUpper} Length: 10 - 20 seconds`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that were completed in 10-20 seconds` },
        secs20to30: { key: '20-30 Secs', order: 3, tipTitle: `${possOrChncUpper} Length: 20 - 30 seconds`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that were completed in 20-30 seconds` },
        secs0to6: { key: '0-6 Secs', order: 1, tipTitle: `${possOrChncUpper} Length: 0 - 6 seconds`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that were completed in 0-6 seconds` },
        secs6to12: { key: '6-12 Secs', order: 2, tipTitle: `${possOrChncUpper} Length: 6 - 12 seconds`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that were completed in 6-12 seconds` },
        secs12to18: { key: '12-18 Secs', order: 3, tipTitle: `${possOrChncUpper} Length: 12 - 18 seconds`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that were completed in 12-18 seconds` },
        secs18to24: { key: '18-24 Secs', order: 4, tipTitle: `${possOrChncUpper} Length: 18 - 24 seconds`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that were completed in 18-24 seconds` },
        secs24to30: { key: '24-30 Secs', order: 5, tipTitle: `${possOrChncUpper} Length: 24 - 30 seconds`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that were completed in 24-30 seconds` },
        secs30plus: { key: '30+ Secs', order: 6, tipTitle: `${possOrChncUpper} Length: 30+ seconds`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that were completed in 30+ seconds` },
        oppFgm: { key: 'Made FG', order: 1, tipTitle: `${possOrChncUpper} Start Type: Made FG`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that started following a made field goal.` },
        oppFtm: { key: 'Made FT', order: 2, tipTitle: `${possOrChncUpper} Start Type: Made FT`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that started following a made free throw.` },
        drbFg: { key: 'DRB off FG', order: 3, tipTitle: `${possOrChncUpper} Start Type: DRB off FG`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that started following a defensive rebound from a missed field goal.` },
        drbFt: { key: 'DRB off FT', order: 4, tipTitle: `${possOrChncUpper} Start Type: DRB off FT`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that started following a defensive rebound from a missed free throw.` },
        drbDead: { key: 'Dead DRB', order: 5, tipTitle: `${possOrChncUpper} Start Type: Dead DRB`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that started following a deadball defensive rebound.` },
        orbFg: { key: 'ORB off FG', order: 6, tipTitle: `${possOrChncUpper} Start Type: ORB off FG`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that started following an offensive rebound from a missed field goal.` },
        orbFt: { key: 'ORB off FT', order: 7, tipTitle: `${possOrChncUpper} Start Type: ORB off FT`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that started following an offensive rebound from a missed free throw.` },
        orbDead: { key: 'Dead ORB', order: 8, tipTitle: `${possOrChncUpper} Start Type: Dead ORB`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that started following a deadball offensive rebound.` },
        periodStart: { key: 'Period Start', order: 9, tipTitle: `${possOrChncUpper} Start Type: Period Start`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that started a new period began.` },
        jumpBall: { key: 'Jump Ball', order: 10, tipTitle: `${possOrChncUpper} Start Type: Jump Ball`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that started following a jump ball.` },
        jumpHeldBall: { key: 'Jump Ball', order: 11, tipTitle: `${possOrChncUpper} Start Type: Jump Ball`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that started following a jump ball.` },
        oppTovDead: { key: 'Dead TOV', order: 12, tipTitle: `${possOrChncUpper} Start Type: Deadball TOV`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that started following a deadball turnover.` },
        oppTovLive: { key: 'Live TOV', order: 13, tipTitle: `${possOrChncUpper} Start Type: Liveball TOV`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that started following a liveball turnover.` },
        timeout: { key: 'Timeout', order: 14, tipTitle: `${possOrChncUpper} Start Type: Timeout`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that started following a timeout.` },
        floorFoul: { key: 'Floor Foul', order: 15, tipTitle: `${possOrChncUpper} Start Type: Floor Foul`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that started following a floor foul.` },
        other: { key: 'Other', order: 16, tipTitle: `${possOrChncUpper} Start Type: Other`, tipDesc: `${possOrChncAcronym} and shooting stats across all ${possOrChncLower} that started following either a deadball offensive or defensive rebound, or via the possession arrow.` }
    };
};


// let competitionInfo =
module.exports = {
    pppcQualifiersMap: pppcQualifiersMap
};
