
import React from 'react';

function TableHeaderTooltip() {
    return (
        <div className='table-tip' style={{ opacity: 0, display: 'none' }} >
            <div className='table-tip__title'>
                <span className='table-tip__title-text' />
            </div>
            <div className='table-tip__desc'>
                <span style={{ fontWeight: 700, paddingRight: 5 }}>
                    Description:
                </span>
                <span className='table-tip__desc-text' />
            </div>
            <div className='table-tip__eq'>
                <span style={{ fontWeight: 700, paddingRight: 5 }}>
                    Equation:
                </span>
                <span className='table-tip__eq-text' />
            </div>
        </div>
    );
}

export default TableHeaderTooltip;
