
// Import React Stuff
import React, { useContext } from 'react';
import GlobalContext from '../../context/GlobalContext';

// Import D3 + Graph-Helper Components
import d3 from '../../assets/d3';
import { allCompetitionIds } from '../../harddata/NcaaStructures';

// Import Other CBB Components
// import Logo from '../uiUxItems/Logo';

const uniqueArray = (array, propertyName) => {
    return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i);
};

const secstoClock = (secs, padZero = true) => {
    let minsDisplay = padZero ? Math.floor(secs / 60).toString().padStart(2, '0') : Math.floor(secs / 60).toString();
    let secsDisplay = (secs % 60).toString().padStart(2, '0');
    return `${minsDisplay}:${secsDisplay}`;
};

const reducePlayerStats = (array) => {
    let playerStats = array.reduce((accum, currVal) => {
        if (!accum[currVal.playerId]) {
            accum[currVal.playerId] = { fullName: currVal.fullName, secs: currVal.secs, netPts: currVal.netPts };
        } else {
            // accum[currVal.playerId].secs += currVal.secs; // was causing FE issue 24.20000000009 secs. rounding every iteration seems okay?
            accum[currVal.playerId].secs = Math.round(accum[currVal.playerId].secs + currVal.secs, 2);
            accum[currVal.playerId].netPts += currVal.netPts;
        }

        // maybe better to round here?

        // and return
        return accum;
    }, {});

    // Add Sort Key, and MM:SS time played
    Object.keys(playerStats).forEach((currentValue) => { // currentValue == playerId here since it's the Object.keys() which are playerIds. Don't need index
        playerStats[currentValue].clock = secstoClock(playerStats[currentValue].secs);
        playerStats[currentValue].netPts = `${playerStats[currentValue].netPts > 0 ? '+' : ''}${playerStats[currentValue].netPts}`;
    });

    return playerStats;
};

function D3LineupFlowV2({
    graphData = [],
    colorType = 'teamHex' // teamHex, netRtgScale
}) {
    // return early if missing data
    if (graphData.length === 0) { return <div />; }
    let competitionId = graphData[0].competitionId;
    if (!competitionId) { return <div />; }


    // Setup Tooltip State
    // const [tooltipDisplay, setTooltipDisplay] = useState(false);
    // const [tooltipCoords, setTooltipCoords] = useState({ x: null, y: null });
    // const [tooltipInfo, setTooltipInfo] = useState(null);


    // Grab game type from minutes
    const isMale = allCompetitionIds.male.includes(competitionId);
    let maleBreaks = ['First Half', 'Second Half'];
    let femaleBreaks = ['First Quarter', 'Second Quarter', 'Third Quarter', 'Fourth Quarter'];
    let maxSecs = Math.max(...graphData.map(row => row.sEnd));
    let gameType = '', breaks = [], xStarts = [];
    switch (maxSecs) {
        case 2400:
            gameType = 'regulation';
            breaks = isMale ? maleBreaks : femaleBreaks; breaks = [...breaks, 'Mins', '+/-'];
            xStarts = isMale ? [0, 1200, 2400, 2640, 2880] : [0, 600, 1200, 1800, 2400, 2640, 2880]; break;
        case 2700:
            gameType = 'ot1';
            breaks = isMale ? maleBreaks : femaleBreaks; breaks = [...breaks, 'OT 1', 'Mins', '+/-'];
            xStarts = isMale ? [0, 1200, 2400, 2700, 2940, 3180] : [0, 600, 1200, 1800, 2400, 2700, 2940, 3180]; break;
        case 3000:
            gameType = 'ot2';
            breaks = isMale ? maleBreaks : femaleBreaks; breaks = [...breaks, 'OT 1', 'OT 2', 'Mins', '+/-'];
            xStarts = isMale ? [0, 1200, 2400, 2700, 3000, 3240, 3480] : [0, 600, 1200, 1800, 2400, 2700, 3000, 3240, 3480]; break;
        case 3300:
            gameType = 'ot3';
            breaks = isMale ? maleBreaks : femaleBreaks; breaks = [...breaks, 'OT 1', 'OT 2', 'OT 3', 'Mins', '+/-'];
            xStarts = isMale ? [0, 1200, 2400, 2700, 3000, 3300, 3540, 3780] : [0, 600, 1200, 1800, 2400, 2700, 3000, 3300, 3540, 3780]; break;
        case 3600:
            gameType = 'ot4';
            breaks = isMale ? maleBreaks : femaleBreaks; breaks = [...breaks, 'OT 1', 'OT 2', 'OT 3', 'OT 4', 'Mins', '+/-'];
            xStarts = isMale ? [0, 1200, 2400, 2700, 3000, 3300, 3600, 3840, 4080] : [0, 600, 1200, 1800, 2400, 2700, 3000, 3300, 3600, 3840, 4080]; break;
        default: gameType = 'regulation'; console.log(`ERROR: bad gameType # of seconds: ${maxSecs}`);
    }

    // Set Constants
    const teamId = graphData[0].teamId;
    const { teamInfosObj } = useContext(GlobalContext);
    const teamHex = teamInfosObj && teamInfosObj[teamId] ? teamInfosObj[teamId].hexColor1 : '#0066CC';
    const svgWidth = 1200;
    const svgHeight = ['ot2', 'ot3', 'ot4'].includes(gameType) ? 245 : 300;
    const margin = { left: 135, top: 3, bottom: 5, right: 5 };
    const width = svgWidth - margin.right - margin.left;
    const height = svgHeight - margin.top - margin.bottom;

    // Sorted Player Stats & Player IDs
    let uniquePlayers = uniqueArray(graphData, 'playerId');
    let playerStats = reducePlayerStats(graphData);
    let sortedPlayers = Object.keys(playerStats)
        .map(playerId => ({ playerId: Number(playerId), fullName: playerStats[playerId].fullName, secs: playerStats[playerId].secs, netPts: playerStats[playerId].netPts }))
        .sort((a, b) => a.secs > b.secs ? 1 : -1);
    let sortedPlayerIds = sortedPlayers.map(row => row.playerId);


    // Scales
    const xScale = d3.scaleLinear()
        .domain([0, d3.max(graphData, (d) => d.sEnd + 240 + 240)])
        .range([0, width]);

    let numPlayers = uniquePlayers.length;
    let yScale = d3.scaleLinear()
        .domain([0, numPlayers + 1])
        .range([height, 0]);

    const fillStintScale = d3.scaleLinear()
        .domain([12, 6, 0, -6, -12])
        .range(['#57BB8A', '#AADDC4', '#DDDDDD', '#F3BEB9', '#E77C73']);

    const fillTotalScale = d3.scaleLinear()
        .domain([16, 8, 0, -8, -16])
        .range(['#57BB8A', '#AADDC4', '#DDDDDD', '#F3BEB9', '#E77C73']);

    let sortedGraphData = graphData
        .slice()
        .sort((a, b) => { return a.playerStint > b.playerStint ? 1 : -1; });

    // Player Names and Dividing Lines
    const playerNames = sortedPlayers.map((player, idx) => {
        const { fullName } = player;
        return (<g key={'player-name-' + player.playerId} className={'player-name-' + player.playerId}>
            <text
                key={'player-text-' + player.playerId}
                x={xScale(0) - 4}
                y={yScale(idx + 0.5)}
                textAnchor='end'
                dominantBaseline='middle'
                fontSize={13.5}
                style={{ fill: '#222222' }}
            >{fullName}</text>
            <line
                key={'player-line-' + player.playerId}
                x1={xScale(0) - margin.left}
                y1={yScale(idx + 1)}
                x2={xScale(0)}
                y2={yScale(idx + 1)}
                stroke='#222222'
                strokeWidth={1}
            />
        </g>);
    });


    // Layout Grid (Rect for each quarter / player)
    // =============================================
    // let xStarts = isMale ? [0, 1200, 2400, 2700, 3000, 3300, 3600] : [0, 600, 1200, 1800, 2400, 2700, 3000, 3300, 3600];
    // xStarts = xStarts.slice(0, breaks.length + 1);

    const gridRects = sortedPlayers.map(player => {
        return breaks.map((breakLabel, idx) => {
            return (
                <rect
                    key={`grid-${breakLabel}-${player.playerId}`}
                    x={xScale(xStarts[idx])}
                    y={yScale(sortedPlayerIds.indexOf(player.playerId) + 1)}
                    width={xScale(xStarts[idx + 1]) - xScale(xStarts[idx])}
                    height={yScale(0) - yScale(1)}
                    style={{ stroke: '#666666', strokeWidth: 1, fill: '#F7F7F7' }}
                />
            );
        });
    });

    const minutesSummaryRects = sortedPlayers.map(player => {
        let { playerId } = player; // handle ~6K null player IDs (lineup recorded w/ only 4 players)
        if (!playerId) { return <g key={`mins-rect-${playerId}`} />; }

        let playerClock = playerStats[playerId].clock; // NC: COULD ROUND HERE, BUT FIND CLOSER TO ROOT
        let playerPlusMinus = playerStats[playerId].netPts;
        let minsStart = xStarts.slice(-3)[0];
        let netPtsStart = xStarts.slice(-2)[0];
        let yStart = yScale(sortedPlayerIds.indexOf(Number(playerId)) + 1);
        let opacity = colorType === 'teamHex' ? 0.6 : 1;
        let minsFillColor = colorType === 'teamHex' ? teamHex : '#F7F7F7';
        let ptsFillColor = colorType === 'teamHex' ? teamHex : fillTotalScale(playerPlusMinus);
        return (<g key={`mins-rect-${playerId}`}>
            <rect
                x={xScale(minsStart)}
                y={yStart}
                width={xScale(240) - xScale(0)}
                height={yScale(0) - yScale(1)}
                style={{ opacity: opacity, stroke: '#222222', strokeWidth: 1, fill: minsFillColor }}
            />
            <text
                x={xScale(minsStart) + (xScale(minsStart + 240) - xScale(minsStart)) / 2}
                y={1 + yStart + yScale(0) - yScale(0.5)}
                textAnchor='middle'
                dominantBaseline='middle'
                fontSize={15}
            >{playerClock}</text>

            <rect
                x={xScale(netPtsStart)}
                y={yStart}
                width={xScale(240) - xScale(0)}
                height={yScale(0) - yScale(1)}
                style={{ opacity: opacity, stroke: '#222222', strokeWidth: 1, fill: ptsFillColor }}
            />
            <text
                x={xScale(netPtsStart) + (xScale(netPtsStart + 240) - xScale(netPtsStart)) / 2}
                y={1 + yStart + yScale(0) - yScale(0.5)}
                textAnchor='middle'
                dominantBaseline='middle'
                fontSize={15}
            >{playerPlusMinus}</text>
        </g>);
    });


    // Rectangles Using Graph Data
    const gameRects = sortedGraphData.map((d) => {
        let opacity = colorType === 'teamHex' ? 0.6 : 1;
        let fillColor = colorType === 'teamHex' ? teamHex : fillStintScale(d.netPts);
        let yStart = yScale(sortedPlayerIds.indexOf(d.playerId) + 1);
        return (
            <g key={'game-rect-' + d.playerStint}>
                <rect
                    x={xScale(d.sStart)}
                    y={yStart}
                    width={xScale(d.sEnd) - xScale(d.sStart)}
                    height={yScale(0) - yScale(1)}
                    style={{ opacity: opacity, stroke: '#222222', strokeWidth: 1, fill: fillColor }}
                />
                <text
                    x={xScale(d.sStart) + (xScale(d.sEnd) - xScale(d.sStart)) / 2}
                    y={1 + yStart + yScale(0) - yScale(0.5)}
                    textAnchor='middle'
                    dominantBaseline='middle'
                    fontSize={15}
                >{`${d.netPts >= 0 ? '+' : ''}${d.netPts}`}</text>
            </g>

        );
    });

    const breaksRects = breaks.map((breakLabel, idx) => {
        return (<g key={`break-rect-${breakLabel}`} className={`break-rect-${breakLabel}`}>
            <rect
                x={xScale(xStarts[idx])}
                y={yScale(uniquePlayers.length + 0.84)}
                width={xScale(xStarts[idx + 1]) - xScale(xStarts[idx])}
                height={yScale(0) - yScale(0.84)}
                style={{ stroke: '#222222', strokeWidth: 1, fill: '#AAAAAA' }}
            />
            <text
                x={xScale(xStarts[idx]) + (xScale(xStarts[idx + 1]) - xScale(xStarts[idx])) / 2}
                y={1 + yScale(uniquePlayers.length + 0.42)}
                textAnchor='middle'
                dominantBaseline='middle'
                fontSize={16}
                style={{ fill: '#222222' }}
            >{breakLabel}</text>
        </g>);
    });


    // Set Tooltip HTML
    // ==================
    // create object with team logos
    // const teamLogo =
    //     (<Logo
    //         style={{ width: 35, height: 35, marginRight: 6 }}
    //         ptgc='team'
    //         teamId={teamId}
    //     />);


    // create tipHtml function
    // const tooltipHtml = (d, yUnits) => {
    //     return (
    //         <>
    //             <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #222222', marginBottom: '6px' }}>
    //                 <h3 style={{ fontSize: '1.2em', margin: '0 0 0 0px' }}>
    //                     Header of tooltip
    //                 </h3>
    //             </div>
    //             <div style={{ display: 'flex', alignItems: 'center' }}>
    //                 {teamLogo}
    //                 <p style={{ margin: 0, lineHeight: 1.4 }}>
    //                     {d.length} games ({(100 * (d.length / graphData.length)).toFixed(0)}% of games) with __something__ in this range.
    //                 </p>
    //             </div>
    //         </>
    //     );
    // };
    // ======

    // And Return
    return (
        <div style={{ width: '100%' }}>
            <svg width='100%' viewBox={`0 0 ${svgWidth} ${svgHeight}`}>
                <g transform={`translate(${margin.left}, ${margin.top})`}>
                    <g className='grid'>
                        {gridRects}
                    </g>
                    <g className='rects'>
                        {gameRects}
                        {breaksRects}
                        {minutesSummaryRects}
                    </g>
                    <g className='player-names'>
                        {playerNames}
                    </g>
                </g>
            </svg>
        </div>
    );
}

export default D3LineupFlowV2;
