
// these are utils that create the GCS urls to our player headshots, team logos and conference logos
import imageUrls from '../images/gcsImages';

export const playerImageUrl = (playerId, teamId) => {
    // creates complete url to player image in GCS
    return `${imageUrls.headshotPrefix}/${teamId}-${playerId}.png`;
};

export const teamLogoUrl = (teamId) => {
    // creates complete url to team logo in GCS
    return `${imageUrls.teamLogosPrefix}/${teamId}.png`; // .png?folder&organizationId, what we used to have in config.js
};

export const conferenceLogoUrl = (conferenceId) => {
    // creates complete url to conference logo in GCS
    return `${imageUrls.confLogosPrefix}/conf-${conferenceId}.png`; // .png?folder&organizationId, what we used to have in config.js
};
