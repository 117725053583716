
import React from 'react';
import { Container } from 'react-bootstrap';
import imageUrls from '../../images/gcsImages';
import { Link } from 'react-router-dom';

function SectionHeader({
    title,
    subtitle,
    returnTo = null, //                 one of ['tools', 'stats', null], if null then show no button
    wrapperStyles = {},
    bodyStyles = { width: '100%' },
    titleStyles = {},
    subtitleStyles = {},
    children
}) {
    // console.log('SectionHeader Props:', { title, subtitle });

    // header div styles
    const defaultWrapperStyles = {
        position: 'sticky',
        top: 48,
        zIndex: 1000,
        maxWidth: '100%',
        background: '#D7D7D7',
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url(${imageUrls.hexGridLight})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: 0,
        borderBottom: '1px solid #CCC'
    };

    // title styles
    const defaultTitleStyles = {
        display: 'inline-block',
        fontFamily: 'Roboto Slab',
        fontWeight: 700,
        fontSize: 'calc(0.9vw + 18px)',
        lineHeight: 1.2
    };

    // subtitle styles
    const defaultSubtitleStyles = {
        fontFamily: 'Roboto Slab',
        fontWeight: 700,
        fontSize: 'calc(1.2vw + 11px)',
        lineHeight: 1.3
    };

    // return to tools button
    const returnToToolsLink = (
        <Link
            to='/tools/home'
            style={{ color: '#0066CC' }}
        >
            {'< Tools'}
        </Link>
    );

    // return to stats button
    const returnToStatsLink = (
        <Link
            to='/stats/home'
            style={{ color: '#0066CC' }}
        >
            {'< Stats'}
        </Link>
    );

    // return the header section
    return (
        // Wrapper Container mainly for background gradient
        <Container style={{ ...defaultWrapperStyles, ...wrapperStyles }}>

            {/* Inner Container for everything else */}
            <div className='padded-inner-container' style={{ margin: 'auto', paddingTop: 10, paddingBottom: 15, ...bodyStyles }}>

                {/* Return to Tools or Stats */}
                <div>
                    {returnTo === 'tools' && returnToToolsLink}
                    {returnTo === 'stats' && returnToStatsLink}
                </div>

                {/* Title and Subtitle */}
                <p style={{ ...defaultTitleStyles, ...titleStyles }}>{title}</p>
                <p style={{ ...defaultSubtitleStyles, ...subtitleStyles }}>{subtitle}</p>

                {/* Return Children */}
                {children}

                {/* Return Buttom Group at Bottom */}
            </div>
        </Container>
    );
}

export default SectionHeader;
