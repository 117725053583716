
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaCommentDots } from 'react-icons/fa';
import imageUrls from '../../images/gcsImages';
import ContactUsForm from '../uiUxItems/ContactUsForm';

function AppFooter() {
    // useState: handle showing form
    const [showContactForm, setShowContactForm] = useState(false);

    // and return!
    return (
        <div className='app-footer-container'>
            {/* Genius Sports LiveStats Logo */}
            <div className='livestats-logo-wrapper'>
                <img
                    className='livestats-logo'
                    alt='geniussports logo'
                    src={imageUrls.liveStatsByGenius}
                    loading='lazy'
                />
            </div>

            {/* Text With 3 Buttons */}
            <div className='footer-links'>
                <p className='mobile-hide'>&#169; Canova Analytics, LLC</p>
                <span>
                    <Link style={{ color: 'white', padding: '1px 3px' }} to='/privacy-policy'>Privacy Policy</Link>
                    <span style={{ color: '#0066CC' }}>  ||  </span>
                    <Link style={{ color: 'white', padding: '1px 3px' }} to='/terms-of-use'> Terms of Use</Link>
                    <span style={{ color: '#0066CC' }}>  ||  </span>
                    <Link style={{ color: 'white', padding: '1px 3px' }} to='/disclaimer'> Disclaimer</Link>
                </span>
            </div>

            {/* Button & Form Stuff */}
            <div className='chat-button-wrapper'>
                <button
                    className='chat-button'
                    onClick={() => {
                        setShowContactForm(old => !old);
                    }}
                    style={{ background: 'none', border: 'none' }}
                    aria-label='Open contact us form'
                >
                    <FaCommentDots
                        className='chat-icon'
                        stroke='#222'
                        strokeWidth={12}
                    />
                </button>

                {showContactForm &&
                    <ContactUsForm
                        className='footer-form'
                    />
                }
            </div>
        </div>
    );
}

export default AppFooter;
