// TEAM_LOGOS_PREFIX
const prod = {
    isDev: false,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    url: {
        API_URL: process.env.REACT_APP_PRODUCTION_NODE_API
    },
    gaTrackingId: process.env.REACT_APP_GA_TRACKING_ID,
    stripePublishableKey: process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY,
    streamingApi: process.env.REACT_APP_PRODUCTION_STREAMING_API
};

const dev = {
    isDev: true,
    dsn: null,
    url: {
        API_URL: process.env.REACT_APP_LOCALHOST_NODE_API
    },
    gaTrackingId: process.env.REACT_APP_GA_TRACKING_ID,
    // stripePublishableKey: process.env.REACT_APP_STRIPE_LIVE_PUBLISHABLE_KEY,
    stripePublishableKey: process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_KEY,
    streamingApi: process.env.REACT_APP_LOCALHOST_STREAMING_API
};

const config = process.env.NODE_ENV === 'development' ? dev : prod;
// console.log('config: ', config);
export default config;
