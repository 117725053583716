
// Import React Components
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import GlobalContext from '../../context/GlobalContext';

import { useParams } from 'react-router-dom';
import { Container, Row, Alert } from 'react-bootstrap';
import Axios from 'axios';
import config from '../../config';

// Create The Component
function LoginWithLink() {
    // useParams, useContext
    const params = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const { setUserData, setShowLogin } = useContext(GlobalContext);
    const { loginToken } = params;


    useEffect(() => {
        // TODO: show some loading UI during the API call
        const callLogin = async () => {
            try {
                // execute the login, and set user data
                const loginResponse = await Axios.post(`${config.url.API_URL}/users/login-with-link`, { loginToken });
                setUserData({
                    token: loginResponse.data.token,
                    user: loginResponse.data.user
                });
                localStorage.setItem('auth-token', loginResponse.data.token);

                // update users lastLoginAt
                const apiBaseUrl = config.url.API_URL;
                const userId = loginResponse?.data?.user?._id;
                await Axios.post(`${apiBaseUrl}/users/update-last-login`, { userId });

                // and navigate to settings
                navigate('/my-account/settings');
            } catch (err) {
                setError(err.response?.data?.msg || 'Error Logging In');
            }
        };

        callLogin();
    }, []);


    // And Return
    return (
        <Container style={{ maxWidth: '100%' }}>
            {(error) &&
                <Row style={{ margin: '10px auto', maxWidth: 550 }}>
                    <Alert variant='danger'>
                        <span>
                            {error}.
                        </span>
                        <span className='cbb-span' onClick={() => setShowLogin(true)}>
                            {` `}Try logging in again, or
                        </span>
                        <span>{` `}email nick@cbbanalytics.com if you cannot access your account.</span>
                    </Alert>
                </Row>
            }
        </Container>
    );
}

export default LoginWithLink;
