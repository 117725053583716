
// Import React Stuff
import React from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';

// Import Select Styles
import useCBBQuery from '../../hooks/useCBBQuery.js';
import { blackSelectStyles, smallSelectStyles, filterSelectStyles } from '../../utils/ReactSelectStyles';
import { allCompetitionIds } from '../../harddata/NcaaStructures.js';
import { isNil } from '../../utils/ReshapeData.js';

const optionsDict = {
    ncaamw: {
        1: { width: 160, options: [
            { value: 'ncaam', label: 'NCAA Men' },
            { value: 'ncaaw', label: 'NCAA Women' }
        ] }
    },
    divisions: {
        1: { width: 110, placeholder: 'Select Division...', options: [
            { value: 1, label: 'Division I' },
            { value: 2, label: 'Division II' },
            { value: 3, label: 'Division III' }
        ] }
    },
    divisionIds: {
        1: { width: 110, placeholder: 'Select Division...', options: [
            { value: 1, label: 'Division I' },
            { value: 2, label: 'Division II' },
            { value: 3, label: 'Division III' }
        ] }
    },
    conferences: { 1: { width: 285, placeholder: 'Select Conference...' } },
    competitions: { 1: { width: 240, placeholder: 'Select Competition...' } },
    tournaments: { 1: { width: 325, placeholder: 'Select Tournament...' } },
    teams: { 1: { width: 210, optionClass: 'team-', placeholder: 'Select Team...' } },
    players: { 1: { width: 175, optionClass: 'player-', placeholder: 'Select Player...' } },
    games: { 1: { width: 240, optionClass: 'game-', placeholder: 'Select Game...' } }
};

function CBBPusher({
    selectType,
    stylesType = 'default',
    value,
    optionGroup = 1,
    optionsArray = null,
    isDisabled = false,
    placeholder = 'Select...',
    wrapperStyle = {},
    keeperOptions = [],
    updateRoute = null,
    handleChange = null,
    gender = null,
    ids = { competitionId: null, divisionId: null, conferenceId: null, teamId: null }

}) {
    // console.log('CBBSelect Params: ', { selectType, value, setValue, optionGroup, optionsArray });

    // create fetch endpoint, fetch config
    let { competitionId = null, divisionId = null, conferenceId = null, teamId = null } = ids || {};
    let fetch;
    switch (selectType) {
        case 'conferences': fetch = { pass: false, ep: 'conferences', config: {} }; break;
        case 'competitions': fetch = { pass: false, ep: 'competitions', config: {} }; break;
        case 'tournaments': fetch = { pass: false, ep: 'tournaments', config: {} }; break;
        case 'teams': fetch = { pass: false, ep: 'team-agg-stats', config: { competitionId, scope: 'season', isOffense: true } }; break; // why team-agg-stats? to ensure this years teams.
        case 'players': fetch = { pass: !(competitionId && teamId), ep: 'players', config: { competitionId, teamId } }; break;
        default: fetch = { pass: true, ep: '', config: {} };
    }

    // React Query Data Fetching!
    const { data: fetchedOptions = [], isLoading, isError } = useCBBQuery({ ep: fetch.ep, cfg: { ...fetch.config, pass: fetch.pass } });
    // console.log('CBB Pusher: ', fetchedOptions);
    if (isError) { console.log('Data Fetching Error!'); }

    // Load hooks for navigation (pusher)
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    // grab correct source of selects (fetch vs optionsArray vs optionsDict)
    let selectOptions = fetch.pass === false ? fetchedOptions : (optionsArray || optionsDict[selectType][optionGroup].options || []);
    switch (selectType) {
        // competitions: (if needed), filter for keeperConferences, filter for years in data, sort and map to { value, label }
        case 'competitions':
            selectOptions = [...(selectOptions ?? [])]
                .filter(row => keeperOptions.length === 0 ? true : keeperOptions.includes(row.competitionId))
                .filter(row => allCompetitionIds.all.includes(row.competitionId))
                .filter(row => gender === null ? true : row.gender === gender)
                .sort((a, b) => a.endYear > b.endYear ? -1 : 1)
                .sort((a, b) => a.gender > b.gender ? -1 : 1)
                .map(row => { return { value: row.competitionId, label: row.competitionName }; }); break;

        // tournaments: (if needed), filter for tournaments for the relevant competitionId
        case 'tournaments':
            selectOptions = [...(selectOptions ?? [])]
                .filter(row => row.competitionId === ids.competitionId); break;

        // teams: (if needed), filter for keeperTeams, divisionId, conferenceId
        case 'teams':
            selectOptions = [...(selectOptions ?? [])]
                .filter(row => keeperOptions.length === 0 ? true : keeperOptions.includes(row.teamId))
                .filter(row => isNil({ d: divisionId }) ? true : row.divisionId === divisionId)
                .filter(row => isNil({ d: conferenceId }) ? true : row.conferenceId === conferenceId)
                .sort((a, b) => a.teamMarket.localeCompare(b.teamMarket));
            break;

        // default, do nada, keep selectOptions as is.
        default: // selectOptions = selectOptions;
    }


    // create select using options defined above
    let selectStyles;
    switch (stylesType) {
        case 'default': selectStyles = blackSelectStyles(); break;
        case 'small': selectStyles = smallSelectStyles; break;
        case 'filter': selectStyles = filterSelectStyles; break;
        default: selectStyles = blackSelectStyles();
    }

    // handle navigation (push to new page)
    const handleNavigation = (event) => {
        // console.log('Routing Help: ', { event, params, location });
        let oldRoute = location.pathname;
        let newRoute = '';
        switch (selectType) {
            case 'ncaamw': newRoute = oldRoute.replace(params.ncaamw, event.value); break;
            case 'divisions': newRoute = oldRoute.replace(params.divisionLabel, `d${event.value}`); break;
            case 'divisionIds': newRoute = oldRoute.replace(params.divisionId, `${event.value}`); break;
            case 'conferences': newRoute = oldRoute.replace(`/${params.conferenceId}/`, `/${event.conferenceId}/`); break;
            case 'competitions': newRoute = oldRoute.replace(`/${params.competitionId}`, `/${event.value}`); break;
            case 'tournaments': newRoute = oldRoute.replace(`/${params.tournamentId}`, `/${event.tournamentId}`); break;
            case 'teams': newRoute = oldRoute.replace(`/${params.teamId}`, `/${event.teamId}`); break;
            case 'players': newRoute = oldRoute.replace(`/${params.playerId}`, `/${event.playerId}`); break;
            case 'games': newRoute = oldRoute === '/streaming' ? `/streaming/${event.value}` : oldRoute.replace(`/${params.gameId}`, `/${event.value}`); break;
            default: newRoute = '';
        }

        // For <Scoreboard />
        if (selectType === 'competitions' && params.gameDate && params.divisionLabel) {
            let newCompetitionInfo = fetchedOptions.filter(row => row.competitionId === event.value)[0];
            let newDate = newCompetitionInfo[`endDate${params.divisionLabel.toUpperCase()}`];
            newDate = newDate.replaceAll('-', '');
            newRoute = newRoute.replace(`/${params.gameDate}`, `/${newDate}`);
        }

        // any updateRoute() function must return an updated route string for navigation
        if (updateRoute !== null) {
            newRoute = updateRoute(event, newRoute);
        }

        // generic handler function to call on behalf of the parent (e.g. resetting parent state)
        if (handleChange !== null) {
            handleChange();
        }

        // navigate to new page
        navigate(newRoute);
    };

    // create getOptionValue, getOptionLabel functions
    let valueFunc = null, labelFunc = null;
    switch (selectType) {
        case 'conferences': valueFunc = d => d.conferenceId; labelFunc = d => `${d.conferenceLongName} ${d.conferenceShortName ? `(${d.conferenceShortName})` : ''}`; break;
        case 'tournaments': valueFunc = d => d.tournamentId; labelFunc = d => d.tournamentName; break;
        case 'teams': valueFunc = d => d.teamId; labelFunc = d => `${d.teamMarket} ${d.teamName ? ` ${d.teamName}` : ''}`; break;
        case 'players': valueFunc = d => d.playerId; labelFunc = d => d.fullName; break;
        default: valueFunc = d => d.value; labelFunc = d => d.label;
    }
    // add override valueFuncSS, labelFuncSS

    // "/([a-z])([A-Z])/g" matches any lowercase letter followed by an uppercase letter and replaces it with the matched lowercase letter followed by a dash and the matched uppercase letter. Finally, use toLowerCase() method to convert the string to lowercase.
    let className = selectType ? selectType.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() : 'nada-select-type';
    const currentValue = selectOptions.filter(row => valueFunc(row) === value)[0];
    const optionsClass = optionsDict[selectType][optionGroup].optionClass || '';
    const thisSelect =
        (<Select
            className={`cbb-select cbb-${className}-pusher`}
            // classNames={{ option: d => `cbb-option-${optionsClass}${d.data.value}` }}
            classNames={{ option: d => `cbb-option-${optionsClass}${valueFunc(d.data)}` }}
            styles={selectStyles}
            options={selectOptions}
            value={currentValue}
            onChange={e => handleNavigation(e)}
            isDisabled={isLoading || isDisabled}
            placeholder={placeholder} // could add fallback placeholder for each selectType (e.g. placeholder || optionsDict[selectType].placeholder)
            {...(typeof valueFunc === 'function' && { getOptionValue: valueFunc })}
            {...(typeof labelFunc === 'function' && { getOptionLabel: labelFunc })}
        />);

    // grab width
    let selectWidth = optionsDict[selectType][optionGroup].width;
    if (stylesType === 'small') { selectWidth = 0.89 * selectWidth; }

    // and return
    return (
        <div style={{ width: selectWidth, marginRight: 3, marginTop: 3, ...wrapperStyle }}>
            {thisSelect}
        </div>
    );
}

export default CBBPusher;
