
// CMD + Shift + P to see these commands from Command Palette (Palette, P, get it?)
// CMD + K, then CMD + 0 (zero) to fold all
// CMD + K, then CMD + J to unfold all

/* eslint-disable max-params */

// These are used in ScoutingPages/TeamReport.Js only...
// Used for reshaping from wide to long for <VerticalStatsTable /> I think??
import { dDict } from '../harddata/DataDictionary';
import { zones6Array, zones13Array, zones17Array, dists7Array } from '../harddata/ZoneSchemas';
import { tidy, summarize, sum, groupBy, rename, select, mutate, leftJoin, pivotLonger, filter, first } from '@tidyjs/tidy';
import { sentryIsArray, sentryCaptureError } from '../utils/sentryExceptions';
import { competitionIdMap } from '../harddata/NcaaStructures';

function fLLower({ s }) {
    let newString = s.charAt(0).toLowerCase() + s.slice(1);
    return newString;
}

// Currently for team-agg-stats, widens by 2x for isOffense
export function arrayToObject({ array, key }) {
    // handle bad params (array is array, length > 0, key exists)
    if (!array || array.length === 0) { return []; }
    // if (!Array.isArray(array) || array.length === 0 || !key) {return {}; } // get to bottom of this issue first!

    // for troubleshooting what array is, due to t.reduce() is not a function error
    sentryIsArray({ arr: array, page: 'arrayToObject', name: 'array' });

    // use "key" to convert "array" into an object
    const outputObj = array.reduce((obj, item) => {
        obj[item[key]] = item;
        return obj;
    }, {});

    // and return
    return outputObj;
}

// Pivot Testing used by Comparison app, reshapes wide by "wideCols" (handles multiple competitionIds, scopes, for same teamId, playerID)
export function pivotTesting({ array, wideCols = ['competitionId', 'teamId', 'scope'] }) {
    // console.log('pivotTesting params: ', { array, wideCols });

    // (1) Pivot Long: row for each unique key (except wideCol keys, and fields ending in Pctile, Rank)
    let pivotFields = [...new Set(array.map(Object.keys).flat())]
        .filter(d => !(wideCols.includes(d)))
        .filter(d => !(d.substring(d.length - 6) === 'Pctile' || d.substring(d.length - 4) === 'Rank'));

    let pivot1 = tidy(array,
        pivotLonger({ cols: pivotFields, namesTo: 'key', valuesTo: 'val' }));

    // (2) Widen by computing "value", "pctile", "rank" for each metric - resulting array is super long
    let pivot2 = pivot1.map(row => {
        let suffix = '';
        wideCols.forEach((col, idx) => {
            suffix = suffix + row[col];
            if (idx !== wideCols.length - 1) { suffix = suffix + '-'; }
        });

        // create and return new row
        const newRow = {
            ...row, // spread the original row
            suffix,
            [ `value-${suffix}`]: row.val,
            [`pctile-${suffix}`]: row[`${row.key}Pctile`],
            [`rank-${suffix}`]: row[`${row.key}Rank`]
        };
        return newRow;
    });

    // (3) Delete Useless Keys
    let pivot3 = pivot2.map(obj => {
        return Object.keys(obj)
            .filter(key => !key.endsWith('Pctile') && !key.endsWith('Rank') && key !== 'val' && !wideCols.includes(key))
            .reduce((newObj, key) => {
                newObj[key] = obj[key];
                return newObj;
            }, {});
    });


    // (4) Group By (Reduce) Key, removing the unnecessary wide keys
    const pivot4 = pivot3.reduce((acc, curr) => {
        const existingIndex = acc.findIndex(item => item.key === curr.key);
        if (existingIndex > -1) {
            // create a new object by combining the existing object and the new values
            const updatedObj = {
                ...acc[existingIndex],
                [`value-${curr.suffix}`]: curr[`value-${curr.suffix}`],
                [`pctile-${curr.suffix}`]: curr[`pctile-${curr.suffix}`],
                [`rating-${curr.suffix}`]: curr[`rating-${curr.suffix}`]
            };
            // replace the object at existingIndex with the updated object
            acc[existingIndex] = updatedObj;
        } else {
            // push a new object if it doesn't exist
            acc.push({ ...curr });
        }
        return acc;
    }, []);


    // and return
    return pivot4;
}

export function reshapeGameMatchups({ array }) {
    // if we have 20 games of data (5 games, 2 games per team, isOffense true/false per  team)
    // this gets written into [5 games * all metrics] long, and wide by teams and wide by isOffense

    // (0) handle bad params
    if (!array || array.length === 0) { return []; }

    // (1) define new array
    let newArray = JSON.parse(JSON.stringify(array));
    // newArray = newArray.filter(row => row.isOffense === true);
    let longStats = [];

    // (2) grab gameIds, keysArray (all keys in first object)
    let gameIds = [...new Set(newArray.map(row => row.gameId))];
    // let keysArray = Object.keys(array[0]);
    let keysArray = ['ortg', 'drtg', 'efgPct', 'orbPct', 'ftaRate', 'tovPct'];
    gameIds.forEach(gameId => {
        let gameData = newArray.filter(row => row.gameId === gameId); // rows for single game
        let teamIds = [... new Set(gameData.map(row => row.teamId))]; // teamIds in the game
        let teamMarkets = [... new Set(gameData.map(row => row.teamMarket))]; // teamIds in the game
        keysArray.forEach(key => {
            let longTableRow = {
                key: key,
                unit: dDict[key] ? dDict[key].unit : 'num',
                gameId: gameData[0].gameId,
                teamIds: teamIds,
                teamId1: teamIds[0],
                teamMarket1: teamMarkets[0],
                teamId2: teamIds[1],
                teamMarket2: teamMarkets[1]
            };

            teamIds.forEach(teamId => {
                let teamData = gameData.filter(row => row.teamId === teamId);
                if (teamData.length !== 1) { console.log('reshapeGameMatchups Error: Bad Sized Table'); return; }
                teamData = teamData[0];
                longTableRow[`value${teamId}`] = teamData[key];
                longTableRow[`pctile${teamId}`] = teamData[`${key}Pctile`];
            });

            longStats.push(longTableRow);
        });
    });

    // console.log('longStats: ', longStats);
    return longStats;
}

export function reshapeZoneStatsWideToLong({ array }) {
    // console.log('reshapeZoneStatsWideToLong: ', { array });

    // (0) handle bad params
    if (!array || array.length === 0) { return []; }

    // (0) keep relevant columns only
    let newArray = JSON.parse(JSON.stringify(array));
    let basicZones = ['atr2', 'paint2', 'mid2', 'atb3', 'c3'];

    // columns that get ranks & pctiles
    let pctileRankCols = [
        'OrbPct', 'DrbPct', 'RebPct', // rebounding
        'FgmAPg', 'FgmAP40', 'FgmAP100', 'AstdPct', 'FgPct', 'FgaFreq', 'FgaPg', 'FgaP40', 'FgaP100', // shooting
        'FgPctS01', 'FgPctS12', 'FgPctS23', 'FgaFreqAllS01', 'FgaFreqAllS12', 'FgaFreqAllS23' // shooting by shot clock
    ];
    let zonesSixMetrics = [
        // rebounding
        `Orb`, `OrbChnc`, `OrbPct`, `Drb`, `DrbChnc`, `DrbPct`, `Reb`, `RebChnc`, `RebPct`, `RebPct`,
        // shooting, assisted shooting
        `Fgm`, `Fga`, `FgPct`, `FgaFreq`, `FgaPg`, `FgaP40`, `FgaP100`,
        `FgmA`, `FgmAPg`, `FgmAP40`, `FgmAP40`, `FgmAP100`, `AstdPct`,
        // shooting by shot clock
        `FgaS01`, `FgaS12`, `FgaS23`, `FgmS01`, `FgmS12`, `FgmS23`, // shooting by shot clock
        `FgPctS01`, `FgPctS12`, `FgPctS23`, `FgPctS01`, `FgPctS12`, `FgPctS23`, // shooting by shot clock
        `FgaFreqAllS01`, `FgaFreqAllS12`, `FgaFreqAllS23`, `FgaFreqAllS01`, `FgaFreqAllS12`, `FgaFreqAllS23` // shooting by shot clock
    ];

    let maxRank = Math.max(...array.map(row => row.maxRank).filter(row => typeof row !== 'undefined'));

    // (1) .flatMap(transformRow) is about 3x as fast as a double-nested forEach()
    const lcFirst = (string) => string.charAt(0).toLowerCase() + string.slice(1);
    const transformRow = (row) => {
        return basicZones.map(zone => {
            let zoneU = zone.charAt(0).toUpperCase() + zone.slice(1); // Atr2, Paint2, Mid2, Atb3, C3;
            let newRow = {
                competitionId: row.competitionId,
                tournamentId: row.tournamentId,
                playerId: row.playerId,
                fullName: row.fullName,
                teamId: row.teamId,
                teamMarket: row.teamMarket,
                teamName: row.teamName,
                isOffense: row.isOffense,
                zoneName: zone,
                zoneSchemas: ['zones6'], // just zones6 for now
                scope: row.scope,
                gpPbp: row.gpPbp,
                maxRank: maxRank,

                // a few metrics with different naming pattern (zone as suffix, not prefix)
                pctAst: row[`pctAst${zoneU}`],
                pctAstPctile: row[`pctAst${zoneU}Pctile`],
                pctAstRank: row[`pctAst${zoneU}Rank`]
            };

            zonesSixMetrics.forEach(subMetric => {
                let fieldKey = lcFirst(subMetric);
                newRow[fieldKey] = row[`${zone}${subMetric}`];

                // Add rank and percentile if the metric is in pctileRankCols
                if (pctileRankCols.includes(subMetric)) {
                    newRow[`${fieldKey}Rank`] = row[`${zone}${subMetric}Rank`];
                    newRow[`${fieldKey}Pctile`] = row[`${zone}${subMetric}Pctile`];
                }
            });

            return newRow;
        });
    };

    // run and return
    const longArray = newArray.flatMap(transformRow);
    return longArray;
}

export function reshapeStatsLongScopeWide({ array, optionalKeys = [], gamePgSuffix = false }) {
    // Expecting 1 Row Per Team+Scope (cannot have isOffense: true & false in same array)
    // console.log('reshape params: ', { array, optionalKeys, gamePgSuffix });
    let newArray = JSON.parse(JSON.stringify(array));
    if (newArray === null || typeof newArray === 'undefined' || newArray.length === 0) { return []; }

    // Create object of scope: [arrays] filtered by scope
    let scopeDataObj = {};
    let scopesInData = [... new Set(newArray.map(row => row.scope))].filter(scope => scope !== 'tourney'); // (are we accounting for conferenceAvg, divisionAvg)
    scopesInData.forEach(scope => {
        scopeDataObj[scope] = newArray.filter(row => row.scope === scope);
    });
    let tourneysInData = [...new Set(newArray.map(row => row.tournamentId))].filter(id => id !== 0);
    tourneysInData.forEach(tourneyId => {
        scopeDataObj[`tourney${tourneyId}`] = newArray.filter(row => row.scope === 'tourney' && row.tournamentId === tourneyId);
    });

    // console.log('scopeDataObj: ', scopeDataObj);
    // Grab row 1 for keys?
    let row1Stats = newArray[0];

    // If gameStats exist && any other stats exist, add 'Pg' suffix to counting stats
    // let gameStats = newArray.filter(row => row.gameId && row.gameId !== 999999)[0];
    if (scopesInData.includes('game') && gamePgSuffix === true) {
        if (!(scopeDataObj.game && scopeDataObj.game[0])) { console.log('Error: missing array for "game" scope. This is a problem.'); }
        else {
            let keysToPg = ['fga', 'fga2', 'fga3', 'fta', 'pitp', 'fbpts', 'scp', 'potov', 'benchPts', 'ast', 'orb', 'drb', 'reb', 'tov', 'stl', 'blk', 'pf', 'pfd'];
            keysToPg.forEach(key => {
                scopeDataObj.game[0][`${key}Pg`] = scopeDataObj.game[0][key];
                scopeDataObj.game[0][`${key}PgPctile`] = scopeDataObj.game[0][`${key}Pctile`];
                scopeDataObj.game[0][`${key}PgRank`] = scopeDataObj.game[0][`${key}Rank`];
            });
        }
    }

    // Grab set of keys from tables, based on availability of scope stats (dont use game stats)
    let longStats = [];
    let keysArray = optionalKeys.length > 0 ? optionalKeys : Object.keys(row1Stats);
    let maxRank = Math.max(...array.map(row => row.maxRank).filter(row => typeof row !== 'undefined'));
    keysArray.forEach(key => {
        let isRatingCol = key.substring(key.length - 6) === 'Pctile' || key.substring(key.length - 4) === 'Rank';
        if (isRatingCol) { return; } // skip pctile & rank rows
        let longTableRow = {
            key: key,
            unit: dDict[key] ? dDict[key].unit : 'num',
            isOffense: row1Stats.isOffense,
            teamId: row1Stats.teamId,
            playerId: row1Stats.playerId,
            divisionId: row1Stats.divisionId,
            conferenceId: row1Stats.conferenceId,
            rankType: row1Stats.rankType || 'division',
            maxRank: maxRank
        };

        scopesInData.forEach(scope => {
            if (!scopeDataObj[scope]) { return; }
            if (scopeDataObj[scope].length > 1) { console.log(`reshapeStatsLongScopeWide(): too many rows (${scopeDataObj[scope].length}) for scope ${scope}, should be 1`); }
            let scopeStats = scopeDataObj[scope][0];
            let scopeUp = scope.charAt(0).toUpperCase() + scope.slice(1);
            longTableRow[`value${scopeUp}`] = scopeStats[key];
            longTableRow[`pctile${scopeUp}`] = scopeStats[`${key}Pctile`];
            longTableRow[`rank${scopeUp}`] = scopeStats[`${key}Rank`];
        });

        tourneysInData.forEach(tourneyId => {
            let tourneyStats = newArray.filter(row => row.scope === 'tourney' & row.tournamentId === tourneyId)[0];
            longTableRow[`valueTourney${tourneyId}`] = tourneyStats ? tourneyStats[key] : null;
            longTableRow[`pctileTourney${tourneyId}`] = tourneyStats ? tourneyStats[`${key}Pctile`] : null;
            longTableRow[`rankTourney${tourneyId}`] = tourneyStats ? tourneyStats[`${key}Rank`] : null;
        });

        // and push row onto longStats
        longStats.push(longTableRow);
    });

    // console.log('longStats: ', longStats);
    return longStats;
}

export function reshapeSbzWideToZoneLong({ array }) {
    // Start: (long-by-scope, wide-by-zone, wide-by-metrics). Change to long-by-zone.

    // grab data, handle no params
    const ogArray = JSON.parse(JSON.stringify(array));
    if (ogArray.length === 0) { return []; }

    // define zone constants
    let zones6 = ['atr2', 'paint2', 'mid2', 'atb3', 'c3', 'heave3'];
    let zones13 = ['atr2', 'paint2', 'lb2', 'rb2', 'le2', 're2', 'lc3', 'rc3', 'lw3', 'rw3', 'tok3', 'heave3'];
    let zones17 = ['atr2', 'behindHoop', 'slp2', 'srp2', 'flp2', 'frp2', 'lb2', 'rb2', 'le2', 're2', 'lc3', 'rc3', 'lw3', 'rw3', 'tok3', 'heave3'];
    let dists7 = ['atr2', 'sht2', 'med2', 'lng2', 'sht3', 'lng3'];
    let allZones = [...new Set([...zones6, ...zones13, ...zones17, ...dists7])];

    // Loop zones to reshape data
    let longStats = [];
    allZones.forEach(zone => {
        let zoneSchemas = [
            ...(zones6.includes(zone) ? ['zones6'] : []),
            ...(zones13.includes(zone) ? ['zones13'] : []),
            ...(zones17.includes(zone) ? ['zones17'] : []),
            ...(dists7.includes(zone) ? ['dists7'] : [])
        ];

        let newRows = ogArray.map(row => {
            // error handle
            if (!row.scope && !row.gameId) { console.log('Error: Missing Scope When Reshaping Data!'); }

            // setting keys for row scope & zone
            let outputRow = {
                scope: row.scope || (row.gameId ? 'game' : 'nada'),
                zoneName: zone,
                zoneSchemas: zoneSchemas // note plural, zone can belong to multiple zoneSchemas
            };

            // keys from input to direct copy into long-output for each row
            let keysToDirectCopy = ['competitionId', 'tournamentId', 'playerId', 'fullName', 'teamId', 'teamMarket', 'teamName', 'divisionId', 'isOffense', 'gpPbp', 'conferenceId', 'maxRank'];
            keysToDirectCopy.forEach(key => { outputRow[key] = row[key]; });

            // metrics to reshape for each row
            let metricsToReshape = ['Fgm', 'Fga', 'FgPct', 'FgaFreq', 'FgaPg', 'FgaP40', 'FgaP100'];
            let rowKeys = Object.keys(row);
            metricsToReshape.forEach(metric => {
                let metricKey = fLLower({ s: metric });
                outputRow[metricKey] = row[`${zone}${metric}`];
                if (rowKeys.includes(`${zone}${metric}Pctile`)) {
                    outputRow[`${metricKey}Pctile`] = row[`${zone}${metric}Pctile`];
                }
                if (rowKeys.includes(`${zone}${metric}Rank`)) {
                    outputRow[`${metricKey}Rank`] = row[`${zone}${metric}Rank`];
                }
                // outputRow[`${metricKey}Pctile`] = row[`${zone}${metric}Pctile`];
                // outputRow[`${metricKey}Rank`] = row[`${zone}${metric}Rank`];
            });

            return outputRow;
        });

        longStats.push(...newRows);
    });

    // and return
    // console.log('longStats: ', longStats);
    return longStats;
}

export function reshapeSbzLongScopeWide({ array, zoneSchema = 'zones6' }) {
    // console.log('reshapeSbzLongScopeWide props: ', { array, zoneSchema });

    // array: [playerAggSbz, teamAggSbz]
    // 1 row per zone-scope (so pre-filter so array has only a single isOffense (true or false, not both!))
    if (array.length === 0) { return []; }
    let scopes = ['game', 'l5g', 'l10g', 'season', 'confReg', 'confAll', 'nonconfReg', 'nonconfAll', 'post', 'home', 'away', 'neutral', 'h1', 'h2', 'wins', 'losses', 'quad1', 'quad2', 'quad3', 'quad4', 'custom', 'daterange'];
    let zoneMetrics = ['fgm', 'fga', 'fgaPg', 'fgaP40', 'fgaP100', 'fgPct', 'fgaFreq'];
    let tournamentIds = [...new Set(array.map(row => row.tournamentId))].filter(id => id !== 0);
    let zoneSchemaInfo;
    let allZones = [];
    switch (zoneSchema) {
        case 'zones6':zoneSchemaInfo = zones6Array.new3; allZones = ['atr2', 'paint2', 'mid2', 'c3', 'atb3']; break;
        case 'zones13': zoneSchemaInfo = zones13Array.new3; allZones = ['atr2', 'paint2', 'lb2', 'rb2', 'le2', 're2', 'lc3', 'rc3', 'lw3', 'rw3', 'tok3']; break;
        case 'zones17': zoneSchemaInfo = zones17Array.new3; allZones = ['atr2', 'behindHoop', 'slp2', 'srp2', 'flp2', 'frp2', 'lb2', 'rb2', 'le2', 're2', 'lc3', 'rc3', 'lw3', 'rw3', 'tok3']; break;
        case 'dists7': zoneSchemaInfo = dists7Array.new3; allZones = ['atr2', 'sht2', 'med2', 'lng2', 'sht3', 'lng3']; break;
        default: console.log('Error Bad zoneSchema value!'); break;
    }

    // Increase Performance by filtering for Scopes Only Once, saving array in partitions in sbzByScope
    let sbzByScope = {};
    scopes.forEach(scope => {
        sbzByScope[scope] = array.filter(row => row.scope === scope);
    });

    // and begin loop (triple nested forEach is not great, but going to leave this for now)
    let teamId = array[0] && array[0].teamId;
    let isOffense = array[0] && array[0].isOffense; // whole array is either isOffense: true, or false, NOT BOTH.
    let longStats = [];
    let maxRank = Math.max(...array.map(row => row.maxRank).filter(row => typeof row !== 'undefined'));

    allZones.forEach(zoneName => {
        zoneMetrics.forEach(metric => {
            // 1 row per zone + metric
            let longTableRow = {
                key: zoneName + metric.slice(0, 1).toUpperCase() + metric.slice(1),
                teamId,
                zoneName,
                zoneMetric: metric,
                unit: ['fgm', 'fga'].includes(metric) ? 'int' : (['fgPct', 'fgaFreq'].includes(metric) ? 'pct' : 'num'),
                isOffense: isOffense,
                maxRank: maxRank
            };

            scopes.forEach(scope => {
                let scopeUp = scope.charAt(0).toUpperCase() + scope.slice(1);
                let dataRow = sbzByScope[scope].filter(row => row.zoneName === zoneName)[0];
                longTableRow[`value${scopeUp}`] = dataRow ? dataRow[metric] : null;

                let hasPctile = dataRow && typeof dataRow[`${metric}Pctile`] !== 'undefined';
                if (hasPctile === true) {
                    longTableRow[`pctile${scopeUp}`] = dataRow[`${metric}Pctile`];
                }

                let hasRank = dataRow && typeof dataRow[`${metric}Pctile`] !== 'undefined';
                if (hasRank === true) {
                    longTableRow[`rank${scopeUp}`] = dataRow[`${metric}Rank`];
                }
                // longTableRow[`pctile${scopeUp}`] = dataRow && dataRow[`${metric}Pctile`] ? dataRow[`${metric}Pctile`] : null;
                // longTableRow[`rank${scopeUp}`] = dataRow && dataRow[`${metric}Rank`] ? dataRow[`${metric}Rank`] : null;
            });

            // create value/rating for each tournament too
            if (tournamentIds.length > 0) {
                tournamentIds.forEach(id => {
                    let tourneyStats = array.filter(row => row.scope === 'tourney' & row.tournamentId === id);
                    let tourneyRow = tourneyStats.filter(row => row.zoneName === zoneName)[0];
                    longTableRow[`valueTourney${id}`] = tourneyRow ? tourneyRow[metric] : null;
                    longTableRow[`pctileTourney${id}`] = tourneyRow && tourneyRow[`${metric}Pctile`] ? tourneyRow[`${metric}Pctile`] : null;
                    longTableRow[`rankTourney${id}`] = tourneyRow && tourneyRow[`${metric}Rank`] ? tourneyRow[`${metric}Rank`] : null;
                });
            }

            // and push to stack
            longStats.push(longTableRow);
        });
    });

    // sort stats based on ZoneSchema
    let sortedStats = zoneSchemaInfo.slice().reverse().flatMap(zone => {
        let zoneStats = longStats.filter(row => row.zoneName === zone.acc);
        return zoneStats.length === 0
            ? [] // return empty array for concat if there are no zoneStats
            : zoneStats.map(row => ({ ...row, zoneValue: zone.zoneValue }));
    });

    // And Return
    return sortedStats;
}

export function reshapeOnOffStats({ array }) {
    // Expects single player, rows for multiple scopes & rows for on/off/diff
    // Output: long by metric, long by isOffense, wide by on/off/diff, wide by scope
    //      wide by scope is probably better, but does make things a bit tricky when rendering tables
    //      have row keys valueSeasonOn, ratingSeasonOn, valueConferenceDiff, ratingConferenceDif, etc.

    // Handle bad or missing array
    let newArray = JSON.parse(JSON.stringify(array));
    if (newArray === null || typeof newArray === 'undefined') { return []; }
    if (newArray.length === 0) { return []; }

    // Get scopes, IDs, metrics (keys) to loop over
    let isOffenses = [true, false];
    let scopes = ['l5g', 'l10g', 'season', 'confReg'];
    let onOffDiffs = ['On', 'Off', 'Diff'];
    let keysArray = Object.keys(newArray[0]);

    // Loop each key, creating new row for each key
    let outputTable = [];
    keysArray.forEach(key => {
        isOffenses.forEach(isOffense => {
            if (key.substring(key.length - 6) === 'Pctile') { return; } // skip pctile rows
            let longTableRow = {
                key: key,
                unit: dDict[key] ? dDict[key].unit : 'num',
                isOffense: isOffense,
                divisionId: newArray[0].divisionId,
                teamId: newArray[0].teamId
            };

            // append keys wide for scope + IDs
            scopes.forEach(scope => {
                let scopeStats = newArray.filter(row => row.scope === scope & row.isOffense === isOffense);
                let scopeUp = scope.charAt(0).toUpperCase() + scope.slice(1);
                onOffDiffs.forEach(ood => {
                    let oodLower = ood.toLowerCase();
                    let rowStats = scopeStats.filter(row => row.onOffDiff === oodLower);
                    if (rowStats.length > 1) { console.log('Error too many rows in rowStats when reshaping on-off stats!'); return; }
                    if (rowStats.length === 0) { return; }
                    longTableRow[`value${scopeUp}${ood}`] = rowStats[0] ? rowStats[0][key] : null;
                    longTableRow[`rating${scopeUp}${ood}`] = rowStats[0] ? rowStats[0][`${key}Pctile`] : null;
                });
            });

            // and push row onto longStats
            outputTable.push(longTableRow);
        });
    });

    return outputTable;
}

export function reshapeTeamRecords({ array }) {
    // Converts [overallWins, overallLosses, winPct] from gs__team_agg_stats from long-by-scope to wide-by-scope

    // Handle Empty Array
    let newArray = JSON.parse(JSON.stringify(array));
    if (newArray === null || typeof newArray === 'undefined') { return []; }
    if (newArray.length === 0) { return []; }

    // Fix Some Arrays & Objects to help with Reshaping
    const teamIds = [...new Set(newArray.map(row => row.teamId))];
    const arrayScopes = [...new Set(array.map(row => row.scope))];
    // let scopes = ['l5g', 'l10g', 'season', 'confReg', 'confAll', 'nonconfReg', 'nonconfAll', 'post', 'home', 'away', 'neutral', 'quad1', 'quad2', 'quad3', 'quad4'];
    // console.log('scopes: ', { arrayScopes, scopes });
    const teamInfos = arrayToObject({ array: newArray, key: 'teamId' });

    // Loop to Reshape Wider
    let outputData = [];
    teamIds.forEach(teamId => {
        let teamArray = newArray.filter(row => row.teamId === teamId);
        let teamObj = arrayToObject({ array: teamArray, key: 'scope' });
        let { competitionId, divisionId, conferenceId, teamMarket } = teamInfos[teamId];
        let outputRow = { competitionId, divisionId, conferenceId, teamId, teamMarket };
        arrayScopes.forEach(scope => {
            // handle no scope (e.g. no quad 2 games)
            if (!teamObj[scope]) {
                outputRow[`${scope}Gp`] = 0;
                outputRow[`${scope}Wins`] = 0;
                outputRow[`${scope}Losses`] = 0;
                outputRow[`${scope}WinPct`] = 0;
            } else {
                outputRow[`${scope}Gp`] = (teamObj[scope].overallWins + teamObj[scope].overallLosses);
                outputRow[`${scope}Wins`] = teamObj[scope].overallWins;
                outputRow[`${scope}Losses`] = teamObj[scope].overallLosses;
                outputRow[`${scope}WinPct`] = outputRow[`${scope}Gp`] === 0 ? 0 : (outputRow[`${scope}Wins`] / outputRow[`${scope}Gp`]);
            }
        });

        outputData.push(outputRow);
    });

    // and return
    // console.log('outputData: ', outputData);
    return outputData;
}

// Currently for team-agg-stats, widens by 2x for isOffense
export function widenForIsOffense({ array, key }) {
    // console.log('widen params: ', { array, widenKey });

    // handle bad params (array is array, length > 0, key exists)
    if (!array || array.length === 0) { return []; }
    // if (!Array.isArray(array) || array.length === 0 || !key) {return []; } // this is better, but want to get to the bottom of this issue first!

    // sentry logging to troubleshoot t.filter is not a function. t is array, but if it's not an array than what is it?
    sentryIsArray({ arr: array, page: 'widenForIsOffense', name: 'array' });

    // Split Stats into O & D
    let oStats = array.filter(row => row.isOffense === true); // t.filter is not an array
    let dStats = array.filter(row => row.isOffense === false);

    // Rename DStats to Agst & AgstPctile
    let keyNames = Object.keys(Object.assign({}, ...array)) // grab all unique keys in array
        .filter(row => ![key, 'teamId'].includes(row)); // except key, teamId

    // Build a Mapping Object to rename dStats
    let renameMap = {};
    keyNames.forEach(keyName => {
        let newKey = `${keyName}Agst`;
        if (keyName.slice(-6) === 'Pctile') { newKey = `${keyName.slice(0, -6)}AgstPctile`; }
        if (keyName.slice(-4) === 'Rank' && keyName !== 'maxRank') { newKey = `${keyName.slice(0, -4)}AgstRank`; }
        renameMap[keyName] = newKey;
    });

    // And Rename, and left join
    let dStatsRenamed = tidy(dStats, rename(renameMap));
    let outputData = tidy(oStats, leftJoin(dStatsRenamed, { by: key }));

    // And Return the New Array
    return outputData;
}

export function widenForIsHome({ array, key }) {
    // console.log('widenForIsHome params: ', { array, key });

    // Loop to Create New Array (this will be tougher if >1 PK for another table...)
    let outputArray = [];
    let allIds = Array.from(new Set(array.map(item => item[key])));
    allIds.forEach(id => {
        let homeStats = array.filter(row => row[key] === id & row.isHome === true);
        let awayStats = array.filter(row => row[key] === id & row.isHome === false);

        if (homeStats.length !== 1 || awayStats.length !== 1) {
            return;
        }

        let homeStatsObj = homeStats.length === 1 ? homeStats[0] : {};
        let awayStatsObj = awayStats.length === 1 ? awayStats[0] : {};
        let combinedObj = {};
        Object.keys(homeStatsObj).forEach(thisKey => {
            // home stats (without suffix)
            combinedObj[thisKey] = homeStatsObj[thisKey];
            // away stats
            let awayKey = `${thisKey}Away`;
            if (thisKey.slice(-6) === 'Pctile') { awayKey = `${thisKey.slice(0, -6)}AwayPctile`; }
            combinedObj[awayKey] = awayStatsObj[thisKey];
        });

        // And Push to the Output Array;
        outputArray.push(combinedObj);
    });

    // And Return the New Array
    return outputArray;
}

// Need to filter players w/ low minutes played / cutoffs not met for scatter plots
export function filterPlayerScatter({ playerStats, xStat = null, yStat = null, minsPct = null }) {
    // console.log('filterPlayerScatter Params: ', { playerStats, xStat, yStat });
    // Note that 2 Arrays are returnd: [graphData, graphFilters]

    // A. Handle Missing Parameters + Initialize Arrays
    if (typeof playerStats === 'undefined' || playerStats === null || playerStats.length === 0) { return [[], []]; }
    let graphData = JSON.parse(JSON.stringify(playerStats));
    let graphFilters = [];
    // ======


    // B. Filter Top 60% of Minutes Played (would prefer to over-fetch and handle this filter here)
    let minsCutoff = minsPct ? minsPct : 0.40;
    let minutesArray = graphData.map(player => Number(player.mins)).sort((a, b) => a - b);
    let minutesThresh = minutesArray[(minutesArray.length * minsCutoff).toFixed(0)];
    graphData = graphData.filter(player => player.mins >= minutesThresh);
    graphFilters.push(`>= ${minutesThresh.toFixed(0)} minutes played`);
    // ======

    // If xStat and yStat are null, no further filters --> return
    if (!xStat & !yStat) {
        return [graphData, graphFilters];
    }

    // C. Filter By Not NA in Selected X and Y Stats
    graphData = graphData
        .filter(player => player[xStat.key])
        .filter(player => player[yStat.key]);
    // ======

    // Before Next Filters, Return if Length === 0 to avoid errors
    if (graphData.length === 0) {
        return [graphData, graphFilters];
    }

    // D. If FG%, FT%, 3P%, 2P%, TS%, eFG% - filter by top % of 2PA, 3PA, FGA, FTA
    let cutoffPlayerIds = []; // use this array to ensure filtering on both x-stat and y-stat occur on the same pre-filtered graphData
    if ([xStat.key, yStat.key].includes('fg3Pct')) {
        let fga3Array = graphData.map(player => player.fga3).sort((a, b) => a - b);
        let fga3Thresh = fga3Array[(fga3Array.length * 0.50).toFixed(0)];
        let fga3CutoffIds = graphData.filter(player => player.fga3 <= fga3Thresh).map(player => player.playerId);
        cutoffPlayerIds = cutoffPlayerIds.concat(fga3CutoffIds);
        graphFilters.push(`>= ${fga3Thresh.toFixed(1)} 3PA`);
    }
    if ([xStat.key, yStat.key].includes('fg2Pct')) {
        let fga2Array = graphData.map(player => player.fga2).sort((a, b) => a - b);
        let fga2Thresh = fga2Array[(fga2Array.length * 0.50).toFixed(0)];
        let fga2CutoffIds = graphData.filter(player => player.fga2 <= fga2Thresh).map(player => player.playerId);
        cutoffPlayerIds = cutoffPlayerIds.concat(fga2CutoffIds);
        graphFilters.push(`>= ${fga2Thresh.toFixed(1)} 2PA`);
    }
    if ([xStat.key, yStat.key].includes('ftPct')) {
        let ftaArray = graphData.map(player => player.fta).sort((a, b) => a - b);
        let ftaThresh = ftaArray[(ftaArray.length * 0.50).toFixed(0)];
        let ftaCutoffIds = graphData.filter(player => player.fta <= ftaThresh).map(player => player.playerId);
        cutoffPlayerIds = cutoffPlayerIds.concat(ftaCutoffIds);
        graphFilters.push(`>= ${ftaThresh.toFixed(1)} FTA`);
    }
    if (['fgPct', 'efgPct', 'tsPct'].includes(xStat.key) || ['fgPct', 'efgPct', 'tsPct'].includes(yStat.key)) {
        let fgaArray = graphData.map(player => player.fga).sort((a, b) => a - b);
        let fgaThresh = fgaArray[(fgaArray.length * 0.50).toFixed(0)];
        let fgaCutoffIds = graphData.filter(player => player.fga <= fgaThresh).map(player => player.playerId);
        cutoffPlayerIds = cutoffPlayerIds.concat(fgaCutoffIds);
        graphFilters.push(`>= ${fgaThresh.toFixed(1)} FGA`);
    }

    // Filter away any players who missed one of the stat cutoffs
    graphData = graphData.filter(player => !cutoffPlayerIds.includes(player.playerId));

    // And Return!
    return [graphData, graphFilters];
}

// For Team Overview Pages, compute in-conference ranks
export function computeInConferenceRanks({ array, metrics }) {
    // console.log('computeInConferenceRanks params: ', { data, metrics });
    const groups = {};

    // Group data by isOffense and scope
    // partitionKey: "true-season", "false-l5g". we compute separate ranks within each partitionKey
    array.forEach(record => {
        const partitionKey = `${record.isOffense}-${record.scope}`;
        if (!groups[partitionKey]) { groups[partitionKey] = []; }
        groups[partitionKey].push(record);
    });

    // Helper function to compute rank for given metric
    function computeRank(key, group, metric) {
        // Sort by metric value based on the ranking direction
        const sign = dDict[metric].sign;
        const isOffense = key.startsWith('true');
        const isPositiveSort = ((sign === 'pos' && isOffense) || (sign === 'neg' && !isOffense));
        const sortedGroup = [...group].sort((a, b) => {
            if (isPositiveSort) { return b[metric] - a[metric];
            } else { return a[metric] - b[metric]; }
        });

        let rank = 1;
        let previousValue;
        return sortedGroup.map((item, index) => {
            if (index > 0 && item[metric] !== previousValue) {
                rank = index + 1;
            }
            previousValue = item[metric];
            return { ...item, [`${metric}Rank`]: rank };
        });
    }

    // Compute rankings for each group and metric
    Object.keys(groups).forEach(key => {
        let rankedGroup = groups[key];
        metrics.forEach(metric => {
            rankedGroup = computeRank(key, rankedGroup, metric);
        });
        groups[key] = rankedGroup;
    });

    // Combine the groups back into a single array
    let output = Object.values(groups).flat();

    // flag that these are conference ranks
    output = output.map(row => { return { ...row, rankType: 'conference' }; });

    // add "maxRank" to each row, with number of teams in conference
    const numTeamsInConference = [...new Set(output.map(row => row.teamId))].length;
    output = output.map(row => ({ ...row, maxRank: numTeamsInConference }));
    return output;
}


// Helper for computeAssistNetData
function findWithAttr({ array, attr, value }) {
    for (let i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

// For creating Arrays w/ Links and Nodes for Assist Networks Graph
// we should move this into DBT...
export function computeAssistNetData({ rawData, numPlayers, includeGenerics }) {
    // console.log('computeAssistNetData params: ', {rawData, numPlayers, includeGenerics });
    // This Function involves calculating 2 sets of nodes and links:
    //      1st set of links from rawData
    //      1st set of nodes from 1st set of links
    //          filter top N players in first set of nodes
    //          rename all other players to topN-b, 2nd set of links

    // Grab PBP Data and Filter for Assisted Baskets Only
    let copiedRawData = JSON.parse(JSON.stringify(rawData))
        .filter(row => row.assisterId !== null && typeof row.assisterId !== 'undefined');

    // console.log('copiedRawData: ', copiedRawData);
    // Create array of unique assister-scorer links
    // ==============================================
    let linksObj = copiedRawData.reduce((accum, currVal) => {
        // Create keeper keys (not all of the pbpShots endpoint)
        const actionInfo = {
            // assister info
            assisterId: currVal.assisterId,
            assisterNum: currVal.assisterJerseyNum,
            assisterName: currVal.assisterName,
            // shooter info
            scorerId: currVal.playerId,
            scorerNum: currVal.jerseyNum,
            scorerName: currVal.fullName
        };

        // Check if Assister-Shooter already in accum
        const key = actionInfo.assisterId + '-' + actionInfo.scorerId;
        const thisLink = accum[key] || actionInfo;

        // Increment assist total by 1
        thisLink.hookup = (!thisLink.hookup) ? 1 : thisLink.hookup + 1;

        // Update in accum and return
        accum[key] = thisLink;
        return accum;
    }, {});

    // Convert links from object to array
    let links = Object.values(linksObj);

    // Compute metric objects for the table (Ast %, Sco %, etc.)
    let astTeam = links.reduce((accum, currVal) => accum + currVal.hookup, 0);
    let playerAssists = links.reduce((accum, currVal) => {
        if (!accum[currVal.assisterId]) { // Initialize this assister
            accum[currVal.assisterId] = { assisterId: currVal.assisterId, assistCt: 0 };
        }
        accum[currVal.assisterId].assistCt += currVal.hookup;
        return accum;
    }, {});

    let playerScores = links.reduce((accum, currVal) => {
        if (!accum[currVal.scorerId]) { // Initialize this shooter
            accum[currVal.scorerId] = { scorerId: currVal.scorerId, scoreCt: 0 };
        }
        accum[currVal.scorerId].scoreCt += currVal.hookup;
        return accum;
    }, {});

    // Compute All %, Ast %, Sco %
    links.forEach((currVal, idx, theArray) => {
        theArray[idx].astTeam = astTeam;
        theArray[idx].hookupPct = currVal.hookup / astTeam;

        theArray[idx].astAssister = playerAssists[currVal.assisterId].assistCt;
        theArray[idx].astToScoPct = currVal.hookup / playerAssists[currVal.assisterId].assistCt;

        theArray[idx].afgmScorer = playerScores[currVal.scorerId].scoreCt;
        theArray[idx].scoFromAstPct = currVal.hookup / playerScores[currVal.scorerId].scoreCt;
    });
    // ======


    // Compute All Nodes
    // ===================
    let nodesObj = {};
    links.forEach(d => {
        // If shooter or assistor not already in nodesObj, add them
        // handle issue with names missing
        let assisterName = d.assisterName ? d.assisterName : 'Missing Name';
        let assisterInitials = d.assisterName ? d.assisterName.match(/\b(\w)/g).join('') : '';
        let assisterNum = d.assisterNum ? d.assisterNum : '';
        if (!nodesObj[d.assisterId]) {
            nodesObj[d.assisterId] = { id: d.assisterId, fullName: assisterName, initials: assisterInitials, jerseyNum: assisterNum, group: 1, scores: 0, assists: 0, total: 0 };
        }
        let scorerName = d.scorerName ? d.scorerName : 'Missing Name';
        let scorerInitials = d.scorerName ? d.scorerName.match(/\b(\w)/g).join('') : '';
        let scorerNum = d.scorerNum ? d.scorerNum : '';
        if (!nodesObj[d.scorerId]) {
            nodesObj[d.scorerId] = { id: d.scorerId, fullName: scorerName, initials: scorerInitials, jerseyNum: scorerNum, group: 1, scores: 0, assists: 0, total: 0 };
        }

        // add their scores as well
        nodesObj[d.assisterId].assists += d.hookup;
        nodesObj[d.scorerId].scores += d.hookup;
        nodesObj[d.assisterId].total += d.hookup;
        nodesObj[d.scorerId].total += d.hookup;

        // Want to Add IPT and 3s Assisted
    });
    let nodes = Object.values(nodesObj);
    // console.log('nodes: ', nodes);
    // =======


    // Grab Top N Nodes & Corresponding Links
    // ========================================
    nodes = nodes.sort((n1, n2) => n2.total - n1.total);

    // Using Top N, rename bottom players to ropnodes
    let topNodes = nodes.slice(0, numPlayers);
    let topPlayerIds = topNodes.map(node => node.id);
    let topLinks = JSON.parse(JSON.stringify(links))
        .filter(link => {
            return topPlayerIds.includes(link.assisterId) && topPlayerIds.includes(link.scorerId);
        });
    // ======

    // If Standard Graph (no ropLinks), Return
    // ========================================
    let graphData;
    if (includeGenerics === false) {
        graphData = { links: topLinks, nodes: topNodes };
        return graphData;
    }
    // ======


    // Create Set of Links For +ROP-Links Graphs
    // =============================================
    // notTopLinks is a links array where either of the 2 players are not top N players
    // notTopLinks + topLinks === all links
    let notTopLinks = JSON.parse(JSON.stringify(links))
        .filter(link => {
            return !topPlayerIds.includes(link.assisterId) || !topPlayerIds.includes(link.scorerId);
        });

    // using notTopLinks and topLinks, create 2N ropLinks
    let ropLinks = [];
    notTopLinks.forEach((notTopLink) => {
        // use these simple arrays to see which rop-links have been created already within the loop
        let mainShooters = ropLinks.map(thisRopLink => thisRopLink.scorerId);
        let mainAssisters = ropLinks.map(thisRopLink => thisRopLink.assisterId);

        // if assister is top player, shooter == rop
        if (topPlayerIds.includes(notTopLink.assisterId)) {
            if (mainAssisters.includes(notTopLink.assisterId)) { // if assister <--> assister-rop link already created
                let thisIdx = findWithAttr({ array: ropLinks, attr: 'assisterId', value: notTopLink.assisterId });
                // console.log('ropLinks: ', { ropLinks, notTopLink, thisIdx, thisLink: ropLinks[thisIdx] });
                ropLinks[thisIdx].astToScoPct += notTopLink.astToScoPct;
                ropLinks[thisIdx].hookup += notTopLink.hookup;
            } else {
                let ropLink = notTopLink;
                ropLink.scorerName = 'Rest of Team';
                ropLink.scorerId = ropLink.assisterId + '-rop-id';
                ropLink.scorerNum = '';
                ropLinks.push(ropLink);
            }
        }

        // if shooter is top player, assister == rop
        if (topPlayerIds.includes(notTopLink.scorerId)) {
            if (mainShooters.includes(notTopLink.scorerId)) { // if assister <--> assister-rop link already created
                let thisIdx = findWithAttr({ array: ropLinks, attr: 'scorerId', value: notTopLink.scorerId });
                ropLinks[thisIdx].scoFromAstPct += notTopLink.scoFromAstPct;
                ropLinks[thisIdx].hookup += notTopLink.hookup;
            } else {
                let ropLink = notTopLink;
                ropLink.assisterName = 'Rest of Team';
                ropLink.assisterId = ropLink.scorerId + '-rop-id';
                ropLink.assisterNum = '';
                ropLinks.push(ropLink);
            }
        }
    });

    let links2 = topLinks.concat(ropLinks);
    // ======


    // Create 2nd Set of Nodes (Top N Players + Their ROP Nodes)
    // ===========================================================
    let nodes2Obj = {};
    links2.forEach(d => {
        // Create the nodes
        if (!nodes2Obj[d.assisterId]) {
            if (typeof (d.assisterId) === 'string' && d.assisterId.includes('ropnode')) {
                nodes2Obj[d.assisterId] = { id: d.assisterId, fullName: '', initials: '', jerseyNum: '', group: 1, scores: 0, assists: 0, total: 0 };
            } else {
                nodes2Obj[d.assisterId] = { id: d.assisterId, fullName: d.assisterName, initials: d.assisterName ? d.assisterName.match(/\b(\w)/g).join('') : '', jerseyNum: d.assisterNum, group: 1, scores: 0, assists: 0, total: 0 };
            }
        }

        if (!nodes2Obj[d.scorerId]) {
            if (typeof (d.scorerId) === 'string' && d.scorerId.includes('ropnode')) {
                nodes2Obj[d.scorerId] = { id: d.scorerId, fullName: '', initials: '', jerseyNum: '', group: 1, scores: 0, assists: 0, total: 0 };
            } else {
                nodes2Obj[d.scorerId] = { id: d.scorerId, fullName: d.scorerName, initials: d.scorerName.match(/\b(\w)/g).join(''), jerseyNum: d.scorerNum, group: 1, scores: 0, assists: 0, total: 0 };
            }
        }

        // num scores / num assists needed in nodes for their tooltips
        nodes2Obj[d.assisterId].assists += d.hookup;
        nodes2Obj[d.scorerId].scores += d.hookup;

        nodes2Obj[d.assisterId].total += d.hookup;
        nodes2Obj[d.scorerId].total += d.hookup;
    });

    let nodes2 = Object.values(nodes2Obj);
    // ======

    // And Return The GraphData
    // ==========================
    graphData = { links: links2, nodes: nodes2 };
    return graphData;
}

// For similarity scores that are used to determine Player Comps + Radar Graphs to Display
export function computeSimScores({
    playerStats, // object of individual player agg stats
    allPlayersStats, // array of player agg stat
    radarHierarchy // info on stats/keys to include in calcs
}) {
    let statKeys = JSON.parse(JSON.stringify(radarHierarchy));
    statKeys = statKeys.map(stat => stat.key);

    // Not sure Why the Filter for TS%
    let simPlayerStats = JSON.parse(JSON.stringify(allPlayersStats))
        .filter(player => player.tsPct && player.tsPctPctile); // this was here...

    // For Each Comparison Player, Compute MAE of Diffs in Stat Values
    simPlayerStats.forEach((thisPlayer, index, theArray) => {
        let maeDiffs = 0;
        // let mseDiffs = 0;
        statKeys.forEach(key => {
            // Handle Stat Missing (e.g. 3P%) (0th %ile is a problem here...)
            let thisPlayersPctile = (typeof playerStats[key] === 'undefined' ? null : playerStats[`${key}Pctile`]);
            let iterPlayerPctile = (typeof thisPlayer[key] === 'undefined' ? null : thisPlayer[`${key}Pctile`]);
            let thisDiff = thisPlayersPctile && iterPlayerPctile ? Math.abs(iterPlayerPctile - thisPlayersPctile) : 0.50;

            // Compute stat value
            // mseDiffs += Math.pow(thisPlayer[stat].PctileNcaa - playerStats[stat].PctileNcaa, 2);
            maeDiffs += thisDiff;
            theArray[index][`${key}MaeDiff`] = Math.round(Math.abs(iterPlayerPctile - thisPlayersPctile) * 100) / 100;
        });

        // Inverse of the mean percetile difference, and scaled, and rounded to 1 decimal
        let meanAbsErr = maeDiffs / statKeys.length;
        let meanAbsErrScaled = 100 * (1 - meanAbsErr); // turn MAE [0=best, 1=worst] into [0=wrst, 100=best]

        theArray[index].similarity = meanAbsErrScaled.toFixed(1);
    });

    // Sort by Similarity
    simPlayerStats.sort((a, b) => b.similarity - a.similarity); // may not sort correct due to similary type === 'string'

    // And Return
    return simPlayerStats;
}

// For computing "Totals" Row in Lineup Stats Table
let eqs = {
    getMins: (obj) => { return (obj.mins && obj.mins > 0) ? obj.mins : ((obj.minsPbp && obj.minsPbp > 0 ? obj.minsPbp : 0)); },
    getPoss: (obj) => { return (obj.poss && obj.poss > 0) ? obj.poss : ((obj.possPbp && obj.possPbp > 0 ? obj.possPbp : 0)); },
    getGp: (obj) => { return (obj.gp && obj.gp > 0) ? obj.gp : ((obj.gpPbp && obj.gpPbp > 0 ? obj.gpPbp : 0)); },
    // Basic Shooting Equations
    fgPct: (obj) => { return obj.fgm / obj.fga; },
    fgaFreq: (obj) => { return obj.fga / obj.fgaTotal; },
    fg2Pct: (obj) => { return obj.fgm2 / obj.fga2; },
    fg3Pct: (obj) => { return obj.fgm3 / obj.fga3; },
    ftPct: (obj) => { return obj.ftm / obj.fta; },
    fga3Rate: (obj) => { return obj.fga3 / obj.fga; },
    efgPct: (obj) => { return (obj.fgm2 + 1.5 * obj.fgm3) / obj.fga; },
    tsPct: (obj) => { return obj.ptsScored / (2 * (obj.fga + 0.44 * obj.fta)); },
    ftaRate: (obj) => { return obj.fta / obj.fga; },
    ftmRate: (obj) => { return obj.ftm / obj.fga; },
    // Adv Stats Equations
    astPct: (obj) => { return obj.ast / obj.fgm; },
    astPct2: (obj) => { return obj.ast / ((obj.mins / (obj.minsTm / 5) * obj.fgmTm) - obj.fgm); },
    astRatio: (obj) => { return obj.ast / (obj.fga + 0.44 * obj.fta + obj.ast + obj.tov); },
    blkPct: (obj) => { return obj.blk / (obj.fgaAgst - obj.fga3Agst); },
    blkPct2: (obj) => { return (obj.blk * (obj.minsTm / 5)) / (obj.mins * (obj.fgaAgst - obj.fga3Agst)); },
    stlPct: (obj) => { return obj.stl / eqs.getPoss(obj); },
    stlPct2: (obj) => { return (obj.stl * (obj.minsTm / 5)) / (obj.mins * obj.dPossTm); },
    // ,round(safe_divide(stl * minsTmBy5, mins * dPossTm), 3) as stlPct
    hkmPct: (obj) => { return eqs.stlPct(obj) + eqs.blkPct(obj); },
    hkmPct2: (obj) => { return eqs.stlPct2(obj) + eqs.blkPct2(obj); },
    astTov: (obj) => { return obj.ast / obj.tov; },
    blkdPerFga: (obj) => { return obj.blkd / obj.fga; },
    pfdPerFga: (obj) => { return obj.pfd / obj.fga; },
    drbPct: (obj) => { return obj.drb / (obj.drb + obj.orbAgst); },
    drbPct2: (obj) => { return (obj.drb * (obj.minsTm / 5)) / (obj.mins * (obj.drbTm + obj.orbAgst)); },
    orbPct: (obj) => { return obj.orb / (obj.orb + obj.drbAgst); },
    orbPct2: (obj) => { return (obj.orb * (obj.minsTm / 5)) / (obj.mins * (obj.orbTm + obj.drbAgst)); },
    rebPct: (obj) => { return (obj.orb + obj.drb) / (obj.orb + obj.drb + obj.orbAgst + obj.drbAgst); },
    tovPct: (obj) => { return obj.tov / (obj.fga + 0.44 * obj.fta + obj.tov); },
    pfEff: (obj) => { return (obj.stl + obj.blk) / obj.pf; },
    stlPerPf: (obj) => { return obj.stl / obj.pf; },
    blkPerPf: (obj) => { return obj.blk / obj.pf; },
    ortg: (obj) => { return 100 * (obj.ptsScored / eqs.getPoss(obj)); },
    drtg: (obj) => { return 100 * (obj.ptsAgst / eqs.getPoss(obj)); },
    netRtg: (obj) => { return 100 * (obj.ptsScored - obj.ptsAgst) / eqs.getPoss(obj); },
    usagePct: (obj) => { return ((obj.minsTm / 5) * (obj.fga + 0.44 * obj.fta + obj.tov)) / (eqs.getMins(obj) * (obj.fgaTm + 0.44 * obj.ftaTm + obj.tovTm)); },
    astUsage: (obj) => { return eqs.astPct2(obj) / eqs.usagePct(obj); },
    // pace: (obj) => { return 40 * (obj.poss / (obj.minsPbp / 5)); },
    pace: (obj) => { return 40 * eqs.getPoss(obj) / eqs.getMins(obj); },
    // Scoring Type Stats (% of points)
    pitpPctPts: (obj) => { return obj.pitp / obj.ptsScored; },
    fbptsPctPts: (obj) => { return obj.fbpts / obj.ptsScored; },
    scpPctPts: (obj) => { return obj.scp / obj.ptsScored; },
    // Per Game Equations
    minsPg: (obj) => { return obj.mins / eqs.getGp(obj); },
    ptsScoredPg: (obj) => { return obj.ptsScored / eqs.getGp(obj); },
    ptsScoredTmPg: (obj) => { return obj.ptsScoredTm / eqs.getGp(obj); },
    ptsAgstPg: (obj) => { return obj.ptsAgst / eqs.getGp(obj); },
    ptsScoredBoxPg: (obj) => { return obj.ptsScoredBox / eqs.getGp(obj); },
    ptsAgstBoxPg: (obj) => { return obj.ptsAgstBox / eqs.getGp(obj); },
    fgaPg: (obj) => { return obj.fga / eqs.getGp(obj); },
    fgmPg: (obj) => { return obj.fgm / eqs.getGp(obj); },
    fga2Pg: (obj) => { return obj.fga2 / eqs.getGp(obj); },
    fgm2Pg: (obj) => { return obj.fgm2 / eqs.getGp(obj); },
    fga3Pg: (obj) => { return obj.fga3 / eqs.getGp(obj); },
    fgm3Pg: (obj) => { return obj.fgm3 / eqs.getGp(obj); },
    ftmPg: (obj) => { return obj.ftm / eqs.getGp(obj); },
    ftaPg: (obj) => { return obj.fta / eqs.getGp(obj); },
    astPg: (obj) => { return obj.ast / eqs.getGp(obj); },
    orbPg: (obj) => { return obj.orb / eqs.getGp(obj); },
    orbChncPg: (obj) => { return obj.orbChnc / eqs.getGp(obj); },
    drbPg: (obj) => { return obj.drb / eqs.getGp(obj); },
    drbChncPg: (obj) => { return obj.drbChnc / eqs.getGp(obj); },
    rebPg: (obj) => { return obj.reb / eqs.getGp(obj); },
    stlPg: (obj) => { return obj.stl / eqs.getGp(obj); },
    blkPg: (obj) => { return obj.blk / eqs.getGp(obj); },
    tovPg: (obj) => { return obj.tov / eqs.getGp(obj); },
    pfPg: (obj) => { return obj.pf / eqs.getGp(obj); },
    opfPg: (obj) => { return obj.opf / eqs.getGp(obj); },
    pfdPg: (obj) => { return obj.pfd / eqs.getGp(obj); },
    pitpPg: (obj) => { return obj.pitp / eqs.getGp(obj); },
    fbptsPg: (obj) => { return obj.fbpts / eqs.getGp(obj); },
    scpPg: (obj) => { return obj.scp / eqs.getGp(obj); },
    potovPg: (obj) => { return obj.potov / eqs.getGp(obj); },
    benchPtsPg: (obj) => { return obj.benchPts / eqs.getGp(obj); },
    pitpFgaPg: (obj) => { return obj.pitpFga / eqs.getGp(obj); },
    fbptsFgaPg: (obj) => { return obj.fbptsFga / eqs.getGp(obj); },
    scpFgaPg: (obj) => { return obj.scpFga / eqs.getGp(obj); },
    pitpFgmPg: (obj) => { return obj.pitpFgm / eqs.getGp(obj); },
    fbptsFgmPg: (obj) => { return obj.fbptsFgm / eqs.getGp(obj); },
    scpFgmPg: (obj) => { return obj.scpFgm / eqs.getGp(obj); },
    // Per 40 Minutes Equations
    ptsScoredP40: (obj) => { return 40 * (obj.ptsScored / eqs.getMins(obj)); },
    fgaP40: (obj) => { return 40 * (obj.fga / eqs.getMins(obj)); },
    fga2P40: (obj) => { return 40 * (obj.fga2 / eqs.getMins(obj)); },
    fga3P40: (obj) => { return 40 * (obj.fga3 / eqs.getMins(obj)); },
    ftaP40: (obj) => { return 40 * (obj.fta / eqs.getMins(obj)); },
    astP40: (obj) => { return 40 * (obj.ast / eqs.getMins(obj)); },
    orbP40: (obj) => { return 40 * (obj.orb / eqs.getMins(obj)); },
    orbChncP40: (obj) => { return 40 * (obj.orbChnc / eqs.getMins(obj)); },
    drbP40: (obj) => { return 40 * (obj.drb / eqs.getMins(obj)); },
    drbChncP40: (obj) => { return 40 * (obj.drbChnc / eqs.getMins(obj)); },
    rebP40: (obj) => { return 40 * (obj.reb / eqs.getMins(obj)); },
    stlP40: (obj) => { return 40 * (obj.stl / eqs.getMins(obj)); },
    blkP40: (obj) => { return 40 * (obj.blk / eqs.getMins(obj)); },
    tovP40: (obj) => { return 40 * (obj.tov / eqs.getMins(obj)); },
    pfP40: (obj) => { return 40 * (obj.pf / eqs.getMins(obj)); },
    opfP40: (obj) => { return 40 * (obj.opf / eqs.getMins(obj)); },
    pfdP40: (obj) => { return 40 * (obj.pfd / eqs.getMins(obj)); },
    pitpP40: (obj) => { return 40 * (obj.pitp / eqs.getMins(obj)); },
    fbptsP40: (obj) => { return 40 * (obj.fbpts / eqs.getMins(obj)); },
    scpP40: (obj) => { return 40 * (obj.scp / eqs.getMins(obj)); },
    potovP40: (obj) => { return 40 * (obj.potov / eqs.getMins(obj)); },
    benchPtsP40: (obj) => { return 40 * (obj.benchPts / eqs.getMins(obj)); },
    pitpFgaP40: (obj) => { return 40 * (obj.pitpFga / eqs.getMins(obj)); },
    fbptsFgaP40: (obj) => { return 40 * (obj.fbptsFga / eqs.getMins(obj)); },
    scpFgaP40: (obj) => { return 40 * (obj.scpFga / eqs.getMins(obj)); },
    pitpFgmP40: (obj) => { return 40 * (obj.pitpFgm / eqs.getMins(obj)); },
    fbptsFgmP40: (obj) => { return 40 * (obj.fbptsFgm / eqs.getMins(obj)); },
    scpFgmP40: (obj) => { return 40 * (obj.scpFgm / eqs.getMins(obj)); },
    // Per 100 Possession Equations
    ptsScoredP100: (obj) => { return 100 * (obj.ptsScored / eqs.getPoss(obj)); },
    fgaP100: (obj) => { return 100 * (obj.fga / eqs.getPoss(obj)); },
    fga2P100: (obj) => { return 100 * (obj.fga2 / eqs.getPoss(obj)); },
    fga3P100: (obj) => { return 100 * (obj.fga3 / eqs.getPoss(obj)); },
    ftaP100: (obj) => { return 100 * (obj.fta / eqs.getPoss(obj)); },
    astP100: (obj) => { return 100 * (obj.ast / eqs.getPoss(obj)); },
    orbP100: (obj) => { return 100 * (obj.orb / eqs.getPoss(obj)); },
    orbChncP100: (obj) => { return 100 * (obj.orbChnc / eqs.getPoss(obj)); },
    drbChncP100: (obj) => { return 100 * (obj.drbChnc / eqs.getPoss(obj)); },
    drbP100: (obj) => { return 100 * (obj.drb / eqs.getPoss(obj)); },
    rebP100: (obj) => { return 100 * (obj.reb / eqs.getPoss(obj)); },
    stlP100: (obj) => { return 100 * (obj.stl / eqs.getPoss(obj)); },
    blkP100: (obj) => { return 100 * (obj.blk / eqs.getPoss(obj)); },
    tovP100: (obj) => { return 100 * (obj.tov / eqs.getPoss(obj)); },
    pfP100: (obj) => { return 100 * (obj.pf / eqs.getPoss(obj)); },
    opfP100: (obj) => { return 100 * (obj.opf / eqs.getPoss(obj)); },
    pfdP100: (obj) => { return 100 * (obj.pfd / eqs.getPoss(obj)); },
    pitpP100: (obj) => { return 100 * (obj.pitp / eqs.getPoss(obj)); },
    fbptsP100: (obj) => { return 100 * (obj.fbpts / eqs.getPoss(obj)); },
    scpP100: (obj) => { return 100 * (obj.scp / eqs.getPoss(obj)); },
    potovP100: (obj) => { return 100 * (obj.potov / eqs.getPoss(obj)); },
    benchPtsP100: (obj) => { return 100 * (obj.benchPts / eqs.getPoss(obj)); },
    pitpFgaP100: (obj) => { return 100 * (obj.pitpFga / eqs.getPoss(obj)); },
    fbptsFgaP100: (obj) => { return 100 * (obj.fbptsFga / eqs.getPoss(obj)); },
    scpFgaP100: (obj) => { return 100 * (obj.scpFga / eqs.getPoss(obj)); },
    pitpFgmP100: (obj) => { return 100 * (obj.pitpFgm / eqs.getPoss(obj)); },
    fbptsFgmP100: (obj) => { return 100 * (obj.fbptsFgm / eqs.getPoss(obj)); },
    scpFgmP100: (obj) => { return 100 * (obj.scpFgm / eqs.getPoss(obj)); },
    // Team Agg PBP Metrics
    pctAst2: (obj) => { return obj.playerId ? obj.ast2 / obj.ast : obj.fgm2A / obj.ast; },
    pctAst3: (obj) => { return obj.playerId ? obj.ast3 / obj.ast : obj.fgm3A / obj.ast; },
    pctAstAtr2: (obj) => { return obj.playerId ? obj.atr2Ast / obj.ast : obj.atr2FgmA / obj.ast; },
    pctAstPaint2: (obj) => { return obj.playerId ? obj.paint2Ast / obj.ast : obj.paint2FgmA / obj.ast; },
    pctAstMid2: (obj) => { return obj.playerId ? obj.mid2Ast / obj.ast : obj.mid2FgmA / obj.ast; },
    pctAstAtb3: (obj) => { return obj.playerId ? obj.atb3Ast / obj.ast : obj.atb3FgmA / obj.ast; },
    pctAstC3: (obj) => { return obj.playerId ? obj.c3Ast / obj.ast : obj.c3FgmA / obj.ast; },
    ptsAstdPct: (obj) => { return obj.ptsAstd / obj.ptsScored; },
    fgmAstdPct: (obj) => { return obj.fgmA / obj.fgm; },
    fgm2AstdPct: (obj) => { return obj.fgm2A / obj.fgm2; },
    fgm3AstdPct: (obj) => { return obj.fgm3A / obj.fgm3; },
    dunkAstdPct: (obj) => { return obj.dunkFgmA / obj.dunkFgm; },
    orbFgPct: (obj) => { return obj.orbFg / obj.orbFgChnc; },
    orbFtPct: (obj) => { return obj.orbFt / obj.orbFtChnc; },
    drbFgPct: (obj) => { return obj.drbFg / obj.drbFgChnc; },
    drbFtPct: (obj) => { return obj.drbFt / obj.drbFtChnc; },
    sflDrawnPct: (obj) => { return obj.sflDrawn / obj.shotAtt; },
    sfl2DrawnPct: (obj) => { return obj.sfl2Drawn / obj.shotAtt2P; },
    sfl3DrawnPct: (obj) => { return obj.sfl3Drawn / obj.shotAtt3P; },
    fflDrawnPct: (obj) => { return obj.fflDrawn / eqs.getPoss(obj); },
    opfdPct: (obj) => { return obj.opfd / eqs.getPoss(obj); },
    and1Pct: (obj) => { return obj.and1 / obj.sflDrawn; },
    ft1Pct: (obj) => { return obj.ftm1 / obj.fta1; },
    ft2Pct: (obj) => { return obj.ftm2 / obj.fta2; },
    ft3Pct: (obj) => { return obj.ftm3 / obj.fta3; },
    // pace metrics (secs per poss/chnc/putback)
    oSecsPerPoss: (obj) => { return obj.oSecsPoss / obj.oPoss; },
    oSecsPerFirstChnc: (obj) => { return obj.oSecsFirstChnc / obj.oFirstChnc; },
    oSecsPerPbkChnc: (obj) => { return obj.oSecsPbkChnc / obj.oPbkChnc; },
    dSecsPerPoss: (obj) => { return obj.dSecsPoss / obj.dPoss; },
    dSecsPerFirstChnc: (obj) => { return obj.dSecsFirstChnc / obj.dFirstChnc; },
    dSecsPerPbkChnc: (obj) => { return obj.dSecsPbkChnc / obj.dPbkChnc; },
    ast2Pg: (obj) => { return obj.ast2 / eqs.getGp(obj); },
    fgmAPg: (obj) => { return obj.fgmA / eqs.getGp(obj); },
    fgmUPg: (obj) => { return obj.fgmU / eqs.getGp(obj); },
    fgm2APg: (obj) => { return obj.fgm2A / eqs.getGp(obj); },
    fgm2UPg: (obj) => { return obj.fgm2U / eqs.getGp(obj); },
    ast3Pg: (obj) => { return obj.ast3 / eqs.getGp(obj); },
    fgm3APg: (obj) => { return obj.fgm3A / eqs.getGp(obj); },
    fgm3UPg: (obj) => { return obj.fgm3U / eqs.getGp(obj); },
    dunkFgmAPg: (obj) => { return obj.dunkFgmA / eqs.getGp(obj); },
    dunkFgmUPg: (obj) => { return obj.dunkFgmU / eqs.getGp(obj); },
    atr2FgmUPg: (obj) => { return obj.atr2FgmU / eqs.getGp(obj); },
    sflDrawnPg: (obj) => { return obj.sflDrawn / eqs.getGp(obj); },
    sfl2DrawnPg: (obj) => { return obj.sfl2Drawn / eqs.getGp(obj); },
    sfl3DrawnPg: (obj) => { return obj.sfl3Drawn / eqs.getGp(obj); },
    fflDrawnPg: (obj) => { return obj.fflDrawn / eqs.getGp(obj); },
    opfdPg: (obj) => { return obj.opfd / eqs.getGp(obj); },
    and1Pg: (obj) => { return obj.and1 / eqs.getGp(obj); },
    shotAttPg: (obj) => { return obj.shotAtt / eqs.getGp(obj); },
    shotAtt2PPg: (obj) => { return obj.shotAtt2P / eqs.getGp(obj); },
    shotAtt3PPg: (obj) => { return obj.shotAtt3P / eqs.getGp(obj); },
    orbFgPg: (obj) => { return obj.orbFg / eqs.getGp(obj); },
    orbFtPg: (obj) => { return obj.orbFt / eqs.getGp(obj); },
    drbFgPg: (obj) => { return obj.drbFg / eqs.getGp(obj); },
    drbFtPg: (obj) => { return obj.drbFt / eqs.getGp(obj); },
    orbFgChncPg: (obj) => { return obj.orbFgChnc / eqs.getGp(obj); },
    orbFtChncPg: (obj) => { return obj.orbFtChnc / eqs.getGp(obj); },
    drbFgChncPg: (obj) => { return obj.drbFgChnc / eqs.getGp(obj); },
    drbFtChncPg: (obj) => { return obj.drbFtChnc / eqs.getGp(obj); },
    ast2P40: (obj) => { return 40 * (obj.ast2 / eqs.getMins(obj)); },
    fgmAP40: (obj) => { return 40 * (obj.fgmA / eqs.getMins(obj)); },
    fgm2AP40: (obj) => { return 40 * (obj.fgm2A / eqs.getMins(obj)); },
    ast3P40: (obj) => { return 40 * (obj.ast3 / eqs.getMins(obj)); },
    fgm3AP40: (obj) => { return 40 * (obj.fgm3A / eqs.getMins(obj)); },
    dunkFgmAP40: (obj) => { return 40 * (obj.dunkFgmA / eqs.getMins(obj)); },
    sflDrawnP40: (obj) => { return 40 * (obj.sflDrawn / eqs.getMins(obj)); },
    sfl2DrawnP40: (obj) => { return 40 * (obj.sfl2Drawn / eqs.getMins(obj)); },
    sfl3DrawnP40: (obj) => { return 40 * (obj.sfl3Drawn / eqs.getMins(obj)); },
    fflDrawnP40: (obj) => { return 40 * (obj.fflDrawn / eqs.getMins(obj)); },
    opfdP40: (obj) => { return 40 * (obj.opfd / eqs.getMins(obj)); },
    and1P40: (obj) => { return 40 * (obj.and1 / eqs.getMins(obj)); },
    shotAttP40: (obj) => { return 40 * (obj.shotAtt / eqs.getMins(obj)); },
    shotAtt2PP40: (obj) => { return 40 * (obj.shotAtt2P / eqs.getMins(obj)); },
    shotAtt3PP40: (obj) => { return 40 * (obj.shotAtt3P / eqs.getMins(obj)); },
    // rebounding by FTA vs FGA
    orbFgP40: (obj) => { return 40 * (obj.orbFg / eqs.getMins(obj)); },
    orbFtP40: (obj) => { return 40 * (obj.orbFt / eqs.getMins(obj)); },
    drbFgP40: (obj) => { return 40 * (obj.drbFg / eqs.getMins(obj)); },
    drbFtP40: (obj) => { return 40 * (obj.drbFt / eqs.getMins(obj)); },
    orbFgChncP40: (obj) => { return 40 * (obj.orbFgChnc / eqs.getMins(obj)); },
    orbFtChncP40: (obj) => { return 40 * (obj.orbFtChnc / eqs.getMins(obj)); },
    drbFgChncP40: (obj) => { return 40 * (obj.drbFgChnc / eqs.getMins(obj)); },
    drbFtChncP40: (obj) => { return 40 * (obj.drbFtChnc / eqs.getMins(obj)); },
    orbFgP100: (obj) => { return 100 * (obj.orbFg / eqs.getPoss(obj)); },
    orbFtP100: (obj) => { return 100 * (obj.orbFt / eqs.getPoss(obj)); },
    drbFgP100: (obj) => { return 100 * (obj.drbFg / eqs.getPoss(obj)); },
    drbFtP100: (obj) => { return 100 * (obj.drbFt / eqs.getPoss(obj)); },
    orbFgChncP100: (obj) => { return 100 * (obj.orbFgChnc / eqs.getPoss(obj)); },
    orbFtChncP100: (obj) => { return 100 * (obj.orbFtChnc / eqs.getPoss(obj)); },
    drbFgChncP100: (obj) => { return 100 * (obj.drbFgChnc / eqs.getPoss(obj)); },
    drbFtChncP100: (obj) => { return 100 * (obj.drbFtChnc / eqs.getPoss(obj)); },
    ast2P100: (obj) => { return 100 * (obj.ast2 / eqs.getPoss(obj)); },
    fgmAP100: (obj) => { return 100 * (obj.fgmA / eqs.getPoss(obj)); },
    fgm2AP100: (obj) => { return 100 * (obj.fgm2A / eqs.getPoss(obj)); },
    ast3P100: (obj) => { return 100 * (obj.ast3 / eqs.getPoss(obj)); },
    fgm3AP100: (obj) => { return 100 * (obj.fgm3A / eqs.getPoss(obj)); },
    dunkFgmAP100: (obj) => { return 100 * (obj.dunkFgmA / eqs.getPoss(obj)); },
    sflDrawnP100: (obj) => { return 100 * (obj.sflDrawn / eqs.getPoss(obj)); },
    sfl2DrawnP100: (obj) => { return 100 * (obj.sfl2Drawn / eqs.getPoss(obj)); },
    sfl3DrawnP100: (obj) => { return 100 * (obj.sfl3Drawn / eqs.getPoss(obj)); },
    fflDrawnP100: (obj) => { return 100 * (obj.fflDrawn / eqs.getPoss(obj)); },
    opfdP100: (obj) => { return 100 * (obj.opfd / eqs.getPoss(obj)); },
    and1P100: (obj) => { return 100 * (obj.and1 / eqs.getPoss(obj)); },
    shotAttP100: (obj) => { return 100 * (obj.shotAtt / eqs.getPoss(obj)); },
    shotAtt2PP100: (obj) => { return 100 * (obj.shotAtt2P / eqs.getPoss(obj)); },
    shotAtt3PP100: (obj) => { return 100 * (obj.shotAtt3P / eqs.getPoss(obj)); },
    // half court, transition, putbacks (shooting, efficiency)
    fga2HcPg: (obj) => { return obj.fga2Hc / eqs.getGp(obj); },
    fga3HcPg: (obj) => { return obj.fga3Hc / eqs.getGp(obj); },
    fga2TrPg: (obj) => { return obj.fga2Tr / eqs.getGp(obj); },
    fga3TrPg: (obj) => { return obj.fga3Tr / eqs.getGp(obj); },
    fgaPbPg: (obj) => { return obj.fgaPb / eqs.getGp(obj); },
    fgPctHc: (obj) => { return (obj.fgmHc / obj.fgaHc); },
    fg2PctHc: (obj) => { return (obj.fgm2Hc / obj.fga2Hc); },
    fg3PctHc: (obj) => { return (obj.fgm3Hc / obj.fga3Hc); },
    fgaFreqHc: (obj) => { return (obj.fgaHc / obj.fga); },
    fgPctTr: (obj) => { return (obj.fgmTr / obj.fgaTr); },
    fg2PctTr: (obj) => { return (obj.fgm2Tr / obj.fga2Tr); },
    fg3PctTr: (obj) => { return (obj.fgm3Tr / obj.fga3Tr); },
    fgaFreqTr: (obj) => { return (obj.fgaTr / obj.fga); },
    fgPctPb: (obj) => { return (obj.fgmPb / obj.fgaPb); },
    fgaFreqPb: (obj) => { return (obj.fgaPb / obj.fga); },
    ppcHc: (obj) => { return obj.ptsChncHc / obj.chncHc; },
    ppcTr: (obj) => { return obj.ptsChncTr / obj.chncTr; },
    ppcPb: (obj) => { return obj.ptsChncPb / obj.chncPb; },
    freqChncHc: (obj) => { return obj.chncHc / (obj.chncHc + obj.chncTr + obj.chncPb); },
    freqChncTr: (obj) => { return obj.chncTr / (obj.chncHc + obj.chncTr + obj.chncPb); },
    freqChncPb: (obj) => { return obj.chncPb / (obj.chncHc + obj.chncTr + obj.chncPb); },
    chncHcPg: (obj) => { return obj.chncHc / eqs.getGp(obj); },
    chncTrPg: (obj) => { return obj.chncTr / eqs.getGp(obj); },
    chncPbPg: (obj) => { return obj.chncPb / eqs.getGp(obj); },
    ptsChncHcPg: (obj) => { return obj.ptsChncHc / eqs.getGp(obj); },
    ptsChncTrPg: (obj) => { return obj.ptsChncTr / eqs.getGp(obj); },
    ptsChncPbPg: (obj) => { return obj.ptsChncPb / eqs.getGp(obj); },

    // Extra Player Agg PBP Metrics
    pctOfTeamAst: (obj) => { return obj.ast / obj.astTm; },
    pctOfTeamFga: (obj) => { return obj.fga / obj.fgaTm; },
    pctOfTeamFta: (obj) => { return obj.fta / obj.ftaTm; },
    pctOfTeamTov: (obj) => { return obj.tov / obj.tovTm; },
    // PPPC Metrics
    pppc: (obj) => { return obj.ptsScored / obj.ct; },
    qualifierFreq: (obj) => { return obj.ct / obj.totalCt; }
};

// Dynamically  creating equations saves lots of lines
const namedFn = (name, fn) => Object.defineProperty(fn, 'name', { value: name });

// define shot clock seconds intervals
const shotClockIntervals = ['S01', 'S12', 'S23']; // secs 0 - 10 left on shot clock, 10 - 20 left on shot clock, 20 - 30 left on shot clock

// define zones
const zones6 = ['atr2', 'paint2', 'mid2', 'atb3', 'c3', 'heave3'];
const zones13 = ['lb2', 'rb2', 'le2', 're2', 'lc3', 'rc3', 'lw3', 'rw3', 'tok3'];
const zones17 = ['behindHoop', 'slp2', 'srp2', 'flp2', 'frp2'];
const dists7 = ['sht2', 'med2', 'lng2', 'sht3', 'lng3'];
const allZones = [...zones6, ...zones13, ...zones17, ...dists7];

allZones.forEach(z => {
    // shooting by zone
    eqs[`${z}FgmPg`] = namedFn(`${z}FgmPg`, obj => obj[`${z}Fgm`] / eqs.getGp(obj));
    eqs[`${z}FgaPg`] = namedFn(`${z}FgaPg`, obj => obj[`${z}Fga`] / eqs.getGp(obj));
    eqs[`${z}FgPct`] = namedFn(`${z}FgPct`, obj => obj[`${z}Fgm`] / obj[`${z}Fga`]);
    eqs[`${z}FgaFreq`] = namedFn(`${z}FgaFreq`, obj => obj[`${z}Fga`] / obj.fga);
    eqs[`${z}FgaP40`] = namedFn(`${z}FgaP40`, obj => 40 * (obj[`${z}Fga`] / eqs.getMins(obj)));
    eqs[`${z}FgaP100`] = namedFn(`${z}FgaP100`, obj => 100 * (obj[`${z}Fga`] / eqs.getPoss(obj)));

    if (zones6.includes(z)) {
        // rebounding by zone
        eqs[`${z}OrbPct`] = namedFn(`${z}OrbPct`, obj => obj[`${z}Orb`] / obj[`${z}OrbChnc`]);
        eqs[`${z}DrbPct`] = namedFn(`${z}DrbPct`, obj => obj[`${z}Drb`] / obj[`${z}DrbChnc`]);
        eqs[`${z}RebPct`] = namedFn(`${z}RebPct`, obj => obj[`${z}Reb`] / obj[`${z}RebChnc`]);

        // assists/assisted by zone
        eqs[`${z}AstPg`] = namedFn(`${z}AstPg`, obj => obj[`${z}Ast`] / eqs.getGp(obj));
        eqs[`${z}AstP40`] = namedFn(`${z}AstP40`, obj => 40 * (obj[`${z}Ast`] / eqs.getMins(obj)));
        eqs[`${z}AstP100`] = namedFn(`${z}AstP100`, obj => 100 * (obj[`${z}Ast`] / eqs.getPoss(obj)));
        eqs[`${z}AstdPct`] = namedFn(`${z}AstdPct`, obj => obj[`${z}FgmA`] / obj[`${z}Fgm`]);

        // assisted/unassisted shooting by zone
        eqs[`${z}FgmAPg`] = namedFn(`${z}FgmAPg`, obj => obj[`${z}FgmA`] / eqs.getGp(obj));
        eqs[`${z}FgmAP40`] = namedFn(`${z}FgmAP40`, obj => 40 * (obj[`${z}FgmA`] / eqs.getMins(obj)));
        eqs[`${z}FgmAP100`] = namedFn(`${z}FgmAP100`, obj => 100 * (obj[`${z}FgmA`] / eqs.getPoss(obj)));

        // shooting by zone and shot clock
        shotClockIntervals.forEach(secsI => {
            eqs[`${z}FgPct${secsI}`] = namedFn(`${z}FgPct${secsI}`, obj => obj[`${z}Fgm${secsI}`] / obj[`${z}Fga${secsI}`]);
            eqs[`${z}FgaFreqAll${secsI}`] = namedFn(`${z}FgaFreqAll${secsI}`, obj => obj[`${z}Fga${secsI}`] / obj.fga);
            eqs[`${z}FgaFreqZone${secsI}`] = namedFn(`${z}FgaFreqZone${secsI}`, obj => obj[`${z}Fga${secsI}`] / obj[`${z}Fga`]);
            eqs[`${z}FgaFreqClock${secsI}`] = namedFn(`${z}FgaFreqClock${secsI}`, obj => obj[`${z}Fga${secsI}`] / obj[`fga${secsI}`]);
        });
    }
});

export { eqs }; // 1517 // 1369

// // example of how we could update eqs above to include deps (perhaps save this stuff in its own file...)
// export const eqsArray = [
//     { deps: ['mins', 'minsPbp'], fn: { getMins: (obj) => { return (obj.mins && obj.mins > 0) ? obj.mins : ((obj.minsPbp && obj.minsPbp > 0 ? obj.minsPbp : 0)); } } },
//     { deps: [], fn: { getPoss: (obj) => { return (obj.poss && obj.poss > 0) ? obj.poss : ((obj.possPbp && obj.possPbp > 0 ? obj.possPbp : 0)); } } },
// ];
// let eqsFuncsArray = arr.map(row => {
//     return Object.values(row.fn)[0]
// });


const summableVarsObj = {
    allSummableVars: [
        'secs', 'poss', 'minsPbp', 'ptsScored', 'ptsAgst', 'plusMinus',
        'fgm', 'fga', 'fgaAgst', 'fgm2', 'fga2', 'fgm3', 'fga3Agst', 'fga3', 'ftm', 'fta',
        'orb', 'orbAgst', 'drb', 'drbAgst', 'reb', 'ast', 'stl', 'blk', 'tov', 'pf', 'opf', 'dpf', 'tf', 'pfd',
        'atr2Fgm', 'paint2Fgm', 'mid2Fgm', 'c3Fgm', 'atb3Fgm', 'atr2Fga', 'paint2Fga', 'mid2Fga', 'c3Fga', 'atb3Fga',
        'sht2Fgm', 'med2Fgm', 'lng2Fgm', 'sht3Fgm', 'lng3Fgm', 'sht2Fga', 'med2Fga', 'lng2Fga', 'sht3Fga', 'lng3Fga'
    ],
    teamAggStats: {
        sumVars: [
            'gp', 'mins', 'poss', 'oPoss', 'dPoss', 'ptsScored', 'ptsAgst',
            'fgm', 'fga', 'fgm2', 'fga2', 'fgm3', 'fga3', 'fta', 'ftm',
            'drb', 'orb', 'tmDrb', 'tmOrb', 'deadDrb', 'deadOrb', 'reb', 'tmReb',
            'ast', 'stl', 'tov', 'tmTov', 'blk', 'blkd',
            'pitp', 'pitpFgm', 'pitpFga',
            'scp', 'scpFgm', 'scpFga',
            'fbpts', 'fbptsFga', 'fbptsFgm',
            'potov', 'benchPts',
            'pf', 'opf', 'pfd', 'tf',

            'ptsP1', 'ptsP2', 'ptsP3', 'ptsP4', 'ptsOt',
            'timesTied', 'leadChanges', 'timeLeading', 'biggestRun', 'biggestLead',

            'drbAgst', 'orbAgst', 'fgaAgst', 'fga3Agst',
            'overallWins', 'overallLosses', 'confWins', 'confLosses'
        ],
        ratioEqs: Object.values(eqs) // all for now
    },
    teamAggPbpStats: {
        sumVars: [
            'gpPbp', 'minsPbp', 'possPbp', 'fgaTotal', 'ptsScoredBox', 'ptsAgstBox', // high level stuff
            'ast', 'fgmA', 'fgmU', 'fgm2A', 'fgm2U', 'fgm3A', 'fgm3U', // assisted vs unassisted shooting
            'atr2FgmA', 'atr2FgmU', 'paint2FgmA', 'mid2FgmA', 'atb3FgmA', 'c3FgmA', 'dunkFgmA', 'dunkFgmU', // assisted vs unassisted shooting by zone
            'fga', 'fga2', 'fga3', 'fgm', 'fgm2', 'fgm3', // base shooting
            'atr2Fgm', 'paint2Fgm', 'mid2Fgm', 'atb3Fgm', 'c3Fgm', 'dunkFgm', 'ptsAstd', // base shooting by zone
            'defPoss', 'defPossStops', 'defChnc', 'defChncStops', 'stl', // defensive stats
            'ftm', 'fta', 'ftm1', 'fta1', 'ftm2', 'fta2', 'ftm3', 'fta3', // free throw stats
            'orb', 'orbChnc', 'drb', 'drbChnc', 'reb', 'rebChnc', // rebounding
            'orbFg', 'orbFt', 'drbFg', 'drbFt', 'orbFgChnc', 'orbFtChnc', 'drbFgChnc', 'drbFtChnc', // rebounding by FG vs FT
            'fgaHc', 'fgmHc', 'fga2Hc', 'fgm2Hc', 'fga3Hc', 'fgm3Hc', 'fgaTr', 'fgmTr', 'fga2Tr', 'fgm2Tr', 'fga3Tr', 'fgm3Tr', 'fgaPb', 'fgmPb', // shooting by halfcourt, transition, putbacks
            'chncHc', 'chncTr', 'chncPb', 'ptsChncHc', 'ptsChncTr', 'ptsChncPb', // efficiency by halfcourt, transition, shooting

            'shotAtt', 'shotAtt2P', 'shotAtt3P', 'sflDrawn', 'sfl2Drawn', 'sfl3Drawn', 'fflDrawn', 'opfd', 'and1', 'sfl2Pts', 'sfl3Pts', 'sflAnd1', // foul drawing stats
            'ptsScored', 'ptsAgst', 'tov',
            'overallWins', 'overallLosses', 'confWins', 'confLosses',

            'oSecsPoss', 'oPoss', 'oSecsFirstChnc', 'oFirstChnc', 'oSecsPbkChnc', 'oPbkChnc',
            'dSecsPoss', 'dPoss', 'dSecsFirstChnc', 'dFirstChnc', 'dSecsPbkChnc', 'dPbkChnc',

            // shooting by shot zone
            'atr2Fgm', 'paint2Fgm', 'mid2Fgm', 'atb3Fgm', 'c3Fgm', 'heave3Fgm', 'atr2Fga', 'paint2Fga', 'mid2Fga', 'atb3Fga', 'c3Fga', 'heave3Fga',
            'lb2Fgm', 'rb2Fgm', 'le2Fgm', 're2Fgm', 'lc3Fgm', 'rc3Fgm', 'lw3Fgm', 'rw3Fgm', 'tok3Fgm', 'lb2Fga', 'rb2Fga', 'le2Fga', 're2Fga', 'lc3Fga', 'rc3Fga', 'lw3Fga', 'rw3Fga', 'tok3Fga',
            'behindHoopFgm', 'slp2Fgm', 'srp2Fgm', 'flp2Fgm', 'frp2Fgm', 'behindHoopFga', 'slp2Fga', 'srp2Fga', 'flp2Fga', 'frp2Fga',
            'sht2Fgm', 'med2Fgm', 'lng2Fgm', 'sht3Fgm', 'lng3Fgm', 'sht2Fga', 'med2Fga', 'lng2Fga', 'sht3Fga', 'lng3Fga',

            // rebounding by shot zone
            ...zones6.flatMap(z => [`${z}Orb`, `${z}Drb`, `${z}Reb`, `${z}OrbChnc`, `${z}DrbChnc`, `${z}RebChnc`]),

            // shooting by shot clock
            ...zones6.flatMap(z => [`${z}FgaS01`, `${z}FgmS01`, `${z}FgaS12`, `${z}FgmS12`, `${z}FgaS23`, `${z}FgmS23`])
        ],
        ratioEqs: Object.values(eqs) // all for now
    },
    lineupAggStats: {
        sumVars: [
            'gpPbp', 'minsPbp', 'poss', 'ptsScored', 'ptsAgst', // poss instead of possPbp for lineupAgg...
            'plusMinus', 'fgm', 'fga', 'fgm2', 'fga2', 'fgm3', 'fga3', 'fta', 'ftm',
            'drb', 'orb', 'tmDrb', 'tmOrb', 'deadDrb', 'deadOrb', 'reb', 'tmReb',
            'ast', 'stl', 'tov', 'tmTov', 'blk', 'blkd',
            'pf', 'opf', 'pfd', 'tf',
            'drbAgst', 'orbAgst', 'fgaAgst', 'fga3Agst',
            // zones6
            'atr2Fgm', 'paint2Fgm', 'mid2Fgm', 'atb3Fgm', 'c3Fgm',
            'atr2Fga', 'paint2Fga', 'mid2Fga', 'atb3Fga', 'c3Fga'
        ],
        ratioEqs: [
            eqs.getPoss, eqs.getMins, eqs.netRtg, eqs.ortg, eqs.drtg, eqs.efgPct, eqs.orbPct, eqs.tovPct, eqs.ftaRate,
            eqs.tsPct, eqs.fga3Rate, eqs.fgaP40, eqs.fgaP100, eqs.fgPct, eqs.fga2P40, eqs.fga2P100, eqs.fg2Pct, eqs.fga3P40, eqs.fga3P100, eqs.fg3Pct, eqs.ftaP40, eqs.ftaP100, eqs.ftPct,
            eqs.ptsScoredP40, eqs.astP40, eqs.orbP40, eqs.drbP40, eqs.rebP40, eqs.stlP40, eqs.blkP40, eqs.tovP40, eqs.pfP40, eqs.opfP40, eqs.pfdP40,
            eqs.ptsScoredP100, eqs.astP100, eqs.orbP100, eqs.drbP100, eqs.rebP100, eqs.stlP100, eqs.blkP100, eqs.tovP100, eqs.pfP100, eqs.opfP100, eqs.pfdP100,
            eqs.astPct, eqs.astRatio, eqs.astTov, eqs.pace,
            eqs.stlPct, eqs.blkPct, eqs.drbPct, eqs.hkmPct, eqs.pfEff, eqs.stlPerPf, eqs.blkPerPf,
            eqs.atr2FgPct, eqs.atr2FgaP40, eqs.atr2FgaP100, eqs.atr2FgaFreq,
            eqs.paint2FgPct, eqs.paint2FgaP40, eqs.paint2FgaP100, eqs.paint2FgaFreq,
            eqs.mid2FgPct, eqs.mid2FgaP40, eqs.mid2FgaP100, eqs.mid2FgaFreq,
            eqs.atb3FgPct, eqs.atb3FgaP40, eqs.atb3FgaP100, eqs.atb3FgaFreq,
            eqs.c3FgPct, eqs.c3FgaP40, eqs.c3FgaP100, eqs.c3FgaFreq
        ] // all for now
        // ratioEqs: Object.values(eqs) // all for now
    },
    playerAggStats: {
        sumVars: [
            'gp', 'gs', 'mins', 'poss', 'ptsScored', 'ptsScoredTm', 'ptsAgst', 'dPossTm', 'plusMinus',
            'fgm', 'fga', 'fgm2', 'fga2', 'fgm3', 'fga3', 'fta', 'ftm',
            'drb', 'orb', 'drbTm', 'orbTm', 'reb', 'rebTm',
            'ast', 'stl', 'tov', 'tovTm', 'blk', 'blkd',
            'minsTm', 'fgaTm', 'fgmTm', 'ftaTm',
            'pitp', 'pitpFgm', 'pitpFga',
            'scp', 'scpFgm', 'scpFga',
            'fbpts', 'fbptsFga', 'fbptsFgm',
            'pf', 'opf', 'dpf', 'pfd',

            'drbAgst', 'orbAgst', 'fgaAgst', 'fga3Agst'
        ],
        ratioEqs: Object.values(eqs)
    },
    playerAggPbpStats: {
        sumVars: [
            'ptsScored', 'gsPbp', 'gpPbp', 'minsPbp', 'possPbp', 'fgaTotal',
            'ast', 'ast3', 'ast2', 'atr2Ast', 'paint2Ast', 'mid2Ast', 'atb3Ast', 'c3Ast',
            'fgmA', 'fgm2A', 'fgm2U', 'fgm3A', 'fgm3U', 'atr2FgmA', 'atr2FgmU', 'paint2FgmA', 'mid2FgmA', 'atb3FgmA', 'c3FgmA', 'dunkFgmA', 'dunkFgmU',
            'fgm', 'fgm2', 'fgm3', 'atr2Fgm', 'paint2Fgm', 'mid2Fgm', 'atb3Fgm', 'c3Fgm', 'dunkFgm', 'ptsAstd',
            'ftm', 'fta', 'ftm1', 'fta1', 'ftm2', 'fta2', 'ftm3', 'fta3',
            'orbFg', 'orbFt', 'drbFg', 'drbFt', 'orbFgChnc', 'orbFtChnc', 'drbFgChnc', 'drbFtChnc',

            'shotAtt', 'shotAtt2P', 'shotAtt3P', 'sflDrawn', 'sfl2Drawn', 'sfl3Drawn', 'fflDrawn', 'opfd', 'and1', 'sfl2Pts', 'sfl3Pts', 'sflAnd1',
            'fga', 'fga2', 'fga3', 'tov',
            'minsTm', 'astTm', 'fgaTm', 'ftaTm', 'tovTm',

            // shooting by shot zone
            'atr2Fgm', 'paint2Fgm', 'mid2Fgm', 'atb3Fgm', 'c3Fgm', 'heave3Fgm', 'atr2Fga', 'paint2Fga', 'mid2Fga', 'atb3Fga', 'c3Fga', 'heave3Fga',
            'lb2Fgm', 'rb2Fgm', 'le2Fgm', 're2Fgm', 'lc3Fgm', 'rc3Fgm', 'lw3Fgm', 'rw3Fgm', 'tok3Fgm', 'lb2Fga', 'rb2Fga', 'le2Fga', 're2Fga', 'lc3Fga', 'rc3Fga', 'lw3Fga', 'rw3Fga', 'tok3Fga',
            'behindHoopFgm', 'slp2Fgm', 'srp2Fgm', 'flp2Fgm', 'frp2Fgm', 'behindHoopFga', 'slp2Fga', 'srp2Fga', 'flp2Fga', 'frp2Fga',
            'sht2Fgm', 'med2Fgm', 'lng2Fgm', 'sht3Fgm', 'lng3Fgm', 'sht2Fga', 'med2Fga', 'lng2Fga', 'sht3Fga', 'lng3Fga',

            // half court, transition, putbacks
            'fgaHc', 'fgmHc', 'fga2Hc', 'fgm2Hc', 'fga3Hc', 'fgm3Hc', 'fgaTr', 'fgmTr', 'fga2Tr', 'fgm2Tr', 'fga3Tr', 'fgm3Tr', 'fgaPb', 'fgmPb',

            // shooting by shot clock
            'fgaS01', 'fgaS12', 'fgaS23',
            ...zones6.flatMap(z => [`${z}FgaS01`, `${z}FgmS01`, `${z}FgaS12`, `${z}FgmS12`, `${z}FgaS23`, `${z}FgmS23`])
        ],
        ratioEqs: Object.values(eqs) // all for now
    },
    pppcByQualifier: {
        sumVars: ['ct', 'ptsScored', 'gpPbp', 'minsPbp', 'fgm2', 'fga2', 'fgm3', 'fga3', 'fgm', 'fga'],
        ratioEqs: [eqs.pppc, eqs.qualifierFreq, eqs.fg2Pct, eqs.fg3Pct, eqs.efgPct, eqs.efgPct, eqs.fga2Pg, eqs.fga2P40, eqs.fga2P100, eqs.fga3Pg, eqs.fga3P40, eqs.fga3P100]
    },
    fourfact: {
        sumVars: [],
        ratioEqs: []
    },
    tradshot: {
        sumVars: ['secs', 'poss', 'ptsScored', 'fgm', 'fga', 'fgm2', 'fga2', 'fgm3', 'fga3', 'ftm', 'fta'],
        ratioEqs: [eqs.fgPct, eqs.fg2Pct, eqs.fg3Pct, eqs.ftPct, eqs.fga3Rate, eqs.efgPct, eqs.tsPct, eqs.fgaP40, eqs.fga2P40, eqs.fga3P40, eqs.ftaP40, eqs.fgaP100, eqs.fga2P100, eqs.fga3P100, eqs.ftaP100]
    },
    tradbox: {
        sumVars: ['secs', 'fgm', 'fga', 'fgm2', 'fga2'],
        ratioEqs: []
    },
    offensive: {
        sumVars: [],
        ratioEqs: []
    },
    defensive: {
        sumVars: [],
        ratioEqs: []
    },
    shotregion: {
        sumVars: [],
        ratioEqs: []
    }
};


// the answer is to save orbChnc and drbChnc as metrics on 2/3/4/5-man lineup stats
export function computeLineupTotals({ tableData = [] }) { // tableType = 'tradshot'
    // This currenty works only for a single team's stats, grouping by isOffense. Returns an array w/ two objects.
    // console.log('computeLineupTotals props: ', { tableData });

    // Group By: Sum Each Metric
    let emptyObj = {};
    let summableVars = summableVarsObj.allSummableVars; // summableVarsObj[tableType].sumVars;
    summableVars.forEach(metric => { emptyObj[metric] = 0; });
    let groupedStatsObj = tableData.reduce((accum, currVal) => {
        if (!accum[currVal.isOffense]) { // group by is_offense
            accum[currVal.isOffense] = { isOffense: currVal.isOffense, ...emptyObj };
        }

        summableVars.forEach(metric => {
            accum[currVal.isOffense][metric] += currVal[metric];
        });

        return accum;
    }, {});

    // console.log('groupedStatsObj: ', groupedStatsObj);
    // Convert From Object To Length-1 Array
    let groupedStatsArray = [groupedStatsObj.true, groupedStatsObj.false].filter(row => typeof row !== 'undefined' && row !== null);
    // console.log('groupedStatsArray: ', groupedStatsArray);

    // Compute Ratio Metrics
    let equationFuncs = Object.values(eqs); // summableVarsObj[tableType].ratioEqs;
    groupedStatsArray = groupedStatsArray.map(row => {
        row.isPinned = true;
        row.pId1 = 9999999;
        row.pName1 = 'Totals:';
        equationFuncs.forEach(eqFunc => {
            row[eqFunc.name] = eqFunc(row);
        });
        return row;
    });

    // And Return
    // console.log('groupedStatsArray: ', groupedStatsArray);
    return groupedStatsArray;
}

const createNestedRatingsObj = ({ ratingsArray, dataType }) => {
    // This function converts the 2D (pctiles, ranks) table into a nested object, allowing for faster access of (0-100 pctiles, ranks) for each metric

    // damnit damnit damnit
    sentryIsArray({ arr: ratingsArray, page: 'createNestedRatingsObj', name: 'ratingsArray' });

    // Split immediately into isOffense true/false
    let nestedRatingsObj = {};
    nestedRatingsObj.true = { array: ratingsArray.filter(row => row.isOffense !== false) }; // === true
    nestedRatingsObj.false = { array: ratingsArray.filter(row => row.isOffense === false) }; // !== true

    // grab metrics receiving %iles
    let metricsArray = ratingsArray && ratingsArray[0] ? Object.keys(ratingsArray[0]) : [];
    metricsArray = metricsArray.filter(row => !['_id', 'competitionId', 'divisionId', 'position', 'isOffense', 'pctile', 'rank', 'zoneName', 'zoneSchema', 'metricType', 'qualifierType', 'qualifier'].includes(row));

    // nestedRatingsObj[true/false][zoneName][metric]
    if (['teamGameRbz', 'teamAggSbz', 'playerAggSbz'].includes(dataType)) {
        let zonesArray = [...new Set(ratingsArray.map(row => row.zoneName))];
        zonesArray.forEach(zoneName => { // loop each zone
            nestedRatingsObj.true[zoneName] = { array: nestedRatingsObj.true.array.filter(row => row.zoneName === zoneName) };
            nestedRatingsObj.false[zoneName] = { array: nestedRatingsObj.false.array.filter(row => row.zoneName === zoneName) };
            metricsArray.forEach(metric => { // loop each metric for each zone
                nestedRatingsObj.true[zoneName][metric] = nestedRatingsObj.true[zoneName].array.map(row => { return { pctile: row.pctile, value: row[metric] }; });
                nestedRatingsObj.false[zoneName][metric] = nestedRatingsObj.false[zoneName].array.map(row => { return { pctile: row.pctile, value: row[metric] }; });
            });
        });
    }

    // nestedRatingsObj[true/false][onStreaks][streakBucket][metric]
    if (['teamAggStreakStats'].includes(dataType)) {
        let onStreakOptions = [true, false];
        onStreakOptions.forEach(optionA => { // loop each zone
            nestedRatingsObj.true[optionA] = { array: nestedRatingsObj.true.array.filter(row => row.onStreak === optionA) };
            nestedRatingsObj.false[optionA] = { array: nestedRatingsObj.false.array.filter(row => row.onStreak === optionA) };
            metricsArray.forEach(metric => {
                nestedRatingsObj.true[optionA][metric] = nestedRatingsObj.true[optionA].array.map(row => { return { pctile: row.pctile, value: row[metric] }; });
                nestedRatingsObj.false[optionA][metric] = nestedRatingsObj.false[optionA].array.map(row => { return { pctile: row.pctile, value: row[metric] }; });
            });
        });
    }

    // nestedRatingsObj[true/false][onOffDiff][metric]
    else if (['onOffAggStats'].includes(dataType)) {
        let onOffOptions = ['on', 'diff'];
        onOffOptions.forEach(option => { // loop each zone
            nestedRatingsObj.true[option] = { array: nestedRatingsObj.true.array.filter(row => row.onOffDiff === option) };
            nestedRatingsObj.false[option] = { array: nestedRatingsObj.false.array.filter(row => row.onOffDiff === option) };
            metricsArray.forEach(metric => { // loop each metric for each zone
                nestedRatingsObj.true[option][metric] = nestedRatingsObj.true[option].array.map(row => { return { pctile: row.pctile, value: row[metric] }; });
                nestedRatingsObj.false[option][metric] = nestedRatingsObj.false[option].array.map(row => { return { pctile: row.pctile, value: row[metric] }; });
            });
        });
    }

    // nestedRatingsObj[true/false][poss/chnc][qualifiers][metric] (oh boy...)
    else if (['pppcByQualifier'].includes(dataType)) {
        let metricTypeOptions = ['poss', 'chnc'];
        let qualifiers = [...new Set(ratingsArray.map(row => row.qualifier))];
        metricTypeOptions.forEach(option => {
            nestedRatingsObj.true[option] = { array: nestedRatingsObj.true.array.filter(row => row.metricType === option) };
            nestedRatingsObj.false[option] = { array: nestedRatingsObj.false.array.filter(row => row.metricType === option) };
            qualifiers.forEach(qual => {
                nestedRatingsObj.true[option][qual] = { array: nestedRatingsObj.true[option].array.filter(row => row.qualifier === qual) };
                nestedRatingsObj.false[option][qual] = { array: nestedRatingsObj.false[option].array.filter(row => row.qualifier === qual) };
                metricsArray.forEach(metric => {
                    nestedRatingsObj.true[option][qual][metric] = nestedRatingsObj.true[option][qual].array.map(row => { return { pctile: row.pctile, value: row[metric] }; });
                    nestedRatingsObj.false[option][qual][metric] = nestedRatingsObj.false[option][qual].array.map(row => { return { pctile: row.pctile, value: row[metric] }; });
                });
            });
        });
    }

    // nestedRatingsObj[true/false][metric]
    else {
        metricsArray.forEach(metric => {
            nestedRatingsObj.true[metric] = nestedRatingsObj.true.array.map(row => { return { pctile: row.pctile, value: row[metric] }; });
            nestedRatingsObj.false[metric] = nestedRatingsObj.false.array.map(row => { return { pctile: row.pctile, value: row[metric] }; });
        });
    }

    // and return
    return { nestedRatingsObj, metricsArray };
};

const indexOfMin = ({ array }) => {
    // array of integers, for which we want the index of the lowest value
    // mainly used for %iles and ranks determinations

    // perhaps we want index of max?
    if (array.length === 0) { return -1; }
    let minValue = array[0];
    let minIndex = 0;

    // for loop to determine min index in an array
    for (let i = 1; i < array.length; i++) {
        if (array[i] <= minValue) { //          "<=" vs "<" has major impact on handling ties. "<= min" leads to rounding up on %ile, "< min" leads to rounding down
            minIndex = i; //                    e.g. 21% of players have BLK% == 0. with "<= min", 21st %ile. With "< min", 0th %ile.
            minValue = array[i]; //             set the new value
        }
    }

    // return index that remains
    return minIndex;
};

export function leftJoinPctiles({ tableData, pctilesData, ranksData, dataType }) {
    let typeToPrint = 'teamAggStreakStatsZed';
    // console.log('leftJoinPctiles props: ', { tableData, pctilesData, ranksData, dataType });
    // only pass 1 of [pctilesData, ranksData]

    // Handle Missing Data
    let noTableData = tableData.length === 0 || !tableData;
    if (noTableData) { return []; } // if no stats, return empty array
    const noPctiles = !pctilesData || pctilesData.length === 0;
    const noRanks = !ranksData || ranksData.length === 0;
    if (noPctiles && noRanks) { return tableData; } // if no ratings, return stats array

    // Grab Ratings (ranks or pctiles)
    let ratingsType = noRanks ? 'pctiles' : 'ranks';
    let ratingsData = ratingsType === 'pctiles' ? pctilesData : ranksData;
    let ratingsKey = ratingsType === 'pctiles' ? 'Pctile' : 'Rank';

    // Convert "ratingsData" into nested object for faster rating retrievals
    let { nestedRatingsObj: ratingsObj, metricsArray } = createNestedRatingsObj({ ratingsArray: ratingsData, dataType });
    if (dataType === typeToPrint) { console.log('ratingsObj: ', ratingsObj); }

    let joinedData = tableData.map((rowA, idxx) => {
        // get correct ratings obj for row (extract from nestedRatingsObj)
        let isOffenseRow = rowA.isOffense !== false; // bam
        let rowRatings = isOffenseRow ? ratingsObj.true : ratingsObj.false;
        if (idxx === 0 && dataType === typeToPrint) { console.log('rowRatings: ', { rowA, isOffenseRow, rowRatings }); }

        // if shooting-by-zone, ratings are nested one layer deeper (for zoneName)
        if (['playerAggSbz', 'teamAggSbz', 'teamGameRbz'].includes(dataType)) {
            let thisZone = rowA.zoneName;
            if (!Object.keys(rowRatings).includes(thisZone)) { return rowA; } // no rating if not the selected zoneSchema
            rowRatings = rowRatings[thisZone]; // extract for zone
        }

        // if on-off-agg stats, pctiles are nested one layer deeper (for 'on', 'diff')
        if (['onOffAggStats'].includes(dataType)) {
            let thisOnOffDiff = rowA.onOffDiff;
            if (!Object.keys(rowRatings).includes(thisOnOffDiff)) { return rowA; } // no rating if missing for some reason
            rowRatings = rowRatings[thisOnOffDiff]; // extract for on/off/diff
        }

        // if team-agg-streak-stats


        // handle player_agg_pbp_stats "isQualArray" (isQualified for most metrics, and isQualArray for shooting by zone metrics, with cutoffs for each zone)
        if (['playerAggPbpStats'].includes(dataType)) {
            let ratingZoneIsQualObj = {};
            if (rowA.isQualArray) {
                // isQualArray not available when agg-on-the-fly, so need to wrap in if() to avoid error
                ratingZoneIsQualObj = arrayToObject({ array: rowA.isQualArray, key: 'zoneName' });
            }

            metricsArray.forEach(metric => {
                let metricRoot = metric.replace('FgPct', '').replace('FgaFreq', '').replace('FgaPg', '').replace('FgaP40', '');
                let isZoneQualified = Object.keys(ratingZoneIsQualObj).includes(metricRoot) && ratingZoneIsQualObj[metricRoot].isQualified === true;
                if (rowA.isAggOnFly === true) {
                    let mappedRatings = rowRatings[metric].map(row => Math.abs(row.value - rowA[metric]));
                    let lowestIdx = indexOfMin({ array: mappedRatings });
                    rowA[`${metric}${ratingsKey}`] = lowestIdx / 100;
                    rowA.aaa = '1';
                }
                // using allZonesQualify when aggregating on the fly
                else if (rowA.allZonesQualify === true || isZoneQualified) {
                    let mappedRatings = rowRatings[metric].map(row => Math.abs(row.value - rowA[metric]));
                    let lowestIdx = indexOfMin({ array: mappedRatings }); // were just returning the index of the value closest to our row.metric (because of the abs (value diffs) mapping, lowest index in that array === correct pctile for that stat)
                    rowA[`${metric}${ratingsKey}`] = lowestIdx / 100;
                    rowA.aaa = '2';
                }
                else if (!rowA.isQualified || ['atr2', 'paint2', 'mid2', 'c3', 'atb3', 'heave3', 'sht2', 'med2', 'lng2', 'sht3', 'lng3'].includes(metricRoot)) {
                    rowA[`${metric}${ratingsKey}`] = null;
                    rowA.aaa = '3';
                }
                else {
                    // else apply normal percentile for metric
                    let mappedRatings = rowRatings[metric].map(row => Math.abs(row.value - rowA[metric]));
                    let lowestIdx = indexOfMin({ array: mappedRatings });
                    rowA[`${metric}${ratingsKey}`] = lowestIdx / 100;
                    rowA.aaa = '4';
                }
            });

            return rowA;
        }

        // handle teamAggStreakStats
        if (['teamAggStreakStats'].includes(dataType)) {
            let onStreak = rowA.onStreak; // one of ['poss', 'chnc']
            let pulledRowRatings = rowRatings?.[onStreak] || null;
            if (pulledRowRatings === null) { return rowA; }
            rowRatings = pulledRowRatings;
        }

        // handle pppc by qualifier (nested 2 layers deeper)
        if (['pppcByQualifier'].includes(dataType)) {
            let metricType = rowA.metricType; // one of ['poss', 'chnc']
            let qualifier = rowA.qualifier;
            let ratingsMissing1 = !Object.keys(rowRatings).includes(metricType);
            if (ratingsMissing1) { return rowA; } // no rating if missing for some reason
            let ratingsMissing2 = !Object.keys(rowRatings[metricType]).includes(qualifier);
            if (ratingsMissing2) { return rowA; } // no rating if missing for some reason
            rowRatings = rowRatings[metricType][qualifier]; // dig from metricType, qualifierType
        }

        // handle not qualifying for %iles (very tricky)!
        // want to comment this out, and apply at the table level. very tricky with long/reshaped tables, where isQualified is dropped. keep this for now.
        let doesRowQualify = true;
        let hasIsQualifiedKey = ['playerAggPbpStats', 'playerAggStats', 'teamAggSbz', 'lineupAggStats', 'onOffAggStats', 'playerGameSbzWide', 'playerGamePbp', 'playerGamePbpStats', 'playerGameStats', 'gameFlowLineups', 'teamAggPbpStats'].includes(dataType);
        if (hasIsQualifiedKey) { doesRowQualify = rowA.isQualified; }
        if (!doesRowQualify) {
            metricsArray.forEach(metric => {
                rowA[`${metric}${ratingsKey}`] = null;
            });
            return rowA;
        }

        // Implement the Fuzzy Left-Join Here
        // forEach() over each metric, find closest pctile based on abs(value - all 101 values)
        metricsArray.forEach(metric => {
            // grab row metric, handle early exit if metric is null/undefined
            let metricValue = rowA[metric];
            if (metricValue === null || typeof metricValue === 'undefined') {
                rowA[`${metric}${ratingsKey}`] = null;
                // return; // skip to the next iteration
            }

            let mappedRatings = rowRatings[metric].map(row => Math.abs(row.value - metricValue));
            let lowestIdx = indexOfMin({ array: mappedRatings }); // were just returning the index of the value closest to our row.metric (because of the abs (value diffs) mapping, lowest index in that array === correct pctile for that stat)
            // console.log('metric: ', { mappedRatings, metric, lowestIdx, metricValue });

            let rating = ratingsType === 'pctiles' ? lowestIdx / 100 : (lowestIdx + 1);
            rowA[`${metric}${ratingsKey}`] = rating;
            // rowA[`${metric}${ratingsKey}`] = null;
        });

        // Add Max Rank
        if (ratingsType === 'ranks') {
            let maxRank = rowRatings.maxRank && rowRatings.maxRank[0] && rowRatings.maxRank[0].value;
            rowA.maxRank = maxRank;
        }

        // And return row with ratings (pctiles, ranks) added
        return rowA;
    });

    if (dataType === typeToPrint) { console.log('joinedData: ', joinedData); }

    // Handle removing pctiles (pgNoScope, or isNoPg, then PgPctile is dropped)
    let noPgScopes = ['h1', 'h2', 'clutch'];
    joinedData = joinedData.map(row => {
        if (!noPgScopes.includes(row.scope)) { return row; } // if not in noPgScopes, do not change row
        let rowKeys = Object.keys(row).filter(key => key.endsWith('PgPctile') || key.endsWith('PgRank')); // get keys to replace (fgaPgPctile, etc.)
        rowKeys.forEach(key => { delete row[key]; }); // and drop these keys, as to indicate "no %ile exists"
        return row; // and return!
    });

    // Handle if Shooting Page using shot clock, player combos, player on-off
    joinedData = joinedData.map(row => {
        let rowKeys = Object.keys(row).filter(key => key.endsWith('PgPctile')); // get keys to replace (fgaPgPctile, astPgPctile, etc.)
        if (row.isNoPg === true) {
            rowKeys.forEach(key => { delete row[key]; }); // and drop these keys, as to indicate "no %ile exists"
        }
        return row; // and return!
    });

    // And return data with ratings (pctiles, ranks) joined
    // console.log('joinedData: ', joinedData);
    return joinedData;
}

export function applyPlayerPctiles({ statsArray, pctilesArray, dataType, pctilesType }) {
    // statsArray:          array of objects with player stats
    // pctilesArray:        array of objects with percentiles to join onto array
    // dataType:            string representing type of table, eg. 'playerGameStats', 'playerAggStats', etc.
    // pctilesType:         string, one of 'byPosition', 'allPlayers'

    // This function splits up the player stats by position (guard, forward), left joins pctiles, and re-combines stats into one array

    // console.log('applyPlayerPctiles props: ', { statsArray, pctilesArray, dataType, pctilesType });

    // handle bad params (array is array, length > 0, key exists)
    if (!statsArray || statsArray.length === 0) { return []; }
    // if (!Array.isArray(statsArray) || statsArray.length === 0) {return []; } // get to bottom of this issue first!

    // for troubleshooting what array is, due to t.filter() is not a function error
    sentryIsArray({ arr: statsArray, page: 'applyPlayerPctiles', name: 'statsArray' });

    // split the data, leftJoinPctiles for different positions, and rejoin
    let outputArray = [];
    if (pctilesType === 'byPosition') {
        let gStats = statsArray.filter(row => row.position === 'G');
        let gPctiles = pctilesArray.filter(row => row.position === 'G');
        let gJoined = leftJoinPctiles({ tableData: gStats, pctilesData: gPctiles, dataType: dataType });

        let fStats = statsArray.filter(row => row.position === 'F' || row.position === 'C'); // in streaming API, still have C position, should replace that in useLiveStreamApi
        let fPctiles = pctilesArray.filter(row => row.position === 'F');
        let fJoined = leftJoinPctiles({ tableData: fStats, pctilesData: fPctiles, dataType: dataType });

        let noPosStats = statsArray.filter(row => !['G', 'F', 'C'].includes(row.position)); // live stream data has all sorts of messed up positions...
        let noPosPctiles = pctilesArray.filter(row => row.position === 'all');
        let noPosJoined = leftJoinPctiles({ tableData: noPosStats, pctilesData: noPosPctiles, dataType: dataType });

        outputArray = [...gJoined, ...fJoined, ...noPosJoined];
    } else {
        let allPctiles = pctilesArray.filter(row => row.position === 'all');
        outputArray = leftJoinPctiles({ tableData: statsArray, pctilesData: allPctiles, dataType: dataType });
    }

    // and return
    return outputArray;
}

export function mutateOverallConfWinsLosses({ row, dataType }) {
    // console.log('row: ', row);
    const isPbp = ['teamAggPbpStats'].includes(dataType);
    return {
        ...row,
        overallWins: isPbp ? (row.ptsScoredBox > row.ptsAgstBox ? 1 : 0) : (row.ptsScored > row.ptsAgst ? 1 : 0),
        overallLosses: isPbp ? (row.ptsAgstBox > row.ptsScoredBox ? 1 : 0) : (row.ptsAgst > row.ptsScored ? 1 : 0),
        confWins: !row.inConferenceReg ? 0 : (isPbp ? (row.ptsScoredBox > row.ptsAgstBox ? 1 : 0) : (row.ptsScored > row.ptsAgst ? 1 : 0)),
        confLosses: !row.inConferenceReg ? 0 : (isPbp ? (row.ptsAgstBox > row.ptsScoredBox ? 1 : 0) : (row.ptsAgst > row.ptsScored ? 1 : 0))
    };
}

export function renamePinnedFields({ row, metricsArray = [] }) {
    // For pinned rows, we need to drop the Pg in the suffix for certain fields.
    // columns for which we set the value to its per/game value
    let metricsToRename = [
        'mins', 'ptsScored', 'ptsAgst', 'ptsScoredTm', 'ptsScoredBox', 'ptsAgstBox',
        'fgm', 'fga', 'fgm2', 'fga2', 'fgm3', 'fga3', 'ftm', 'fta', 'ast', 'orb', 'drb', 'reb', 'stl', 'blk', 'tov', 'pf', 'opf', 'pfd',
        'pitp', 'pitpFgm', 'pitpFga', 'fbpts', 'fbptsFgm', 'fbptsFga', 'scp', 'scpFgm', 'scpFga', 'benchPts', 'potov',
        'atr2Fgm', 'atr2Fga', 'paint2Fgm', 'paint2Fga', 'mid2Fgm', 'mid2Fga', 'c3Fgm', 'c3Fga', 'atb3Fgm', 'atb3Fga',
        'sht2Fgm', 'sht2Fga', 'med2Fgm', 'med2Fga', 'lng2Fgm', 'lng2Fga', 'sht3Fgm', 'sht3Fga', 'lng3Fgm', 'lng3Fga',
        'sflDrawn', 'sfl2Drawn', 'sfl3Drawn', 'and1', 'fflDrawn', 'opfd', 'shotAtt', 'shotAtt2P', 'shotAtt3P',
        'fgaHc', 'fgmHc', 'fga2Hc', 'fgm2Hc', 'fga3Hc', 'fgm3Hc', 'fgaTr', 'fgmTr', 'fga2Tr', 'fgm2Tr', 'fga3Tr', 'fgm3Tr', 'fgaPb', 'fgmPb', // halfcourt, transition, putbacks (shooting)
        // halfcourt, transition, putbacks (efficiency)
        'chncHc', 'chncTr', 'chncPb', 'ptsChncHc', 'ptsChncTr', 'ptsChncPb',
        'fgmA', 'fgmU', 'fgm2A', 'fgm2U', 'fgm3A', 'fgm3U', 'atr2FgmA', 'atr2FgmU', 'dunkFgmA', 'dunkFgmU'
    ];

    // rename astPg to ast, rename astPgPctile to astPg
    let outputRow = { ...row };
    let arrayToLoop = metricsArray.length > 0 ? metricsArray : metricsToRename;
    arrayToLoop.forEach(metric => {
        // only try to rename if field exists (for stat)
        if (typeof outputRow[`${metric}Pg`] !== 'undefined' && outputRow[`${metric}Pg`] !== null) {
            outputRow[metric] = outputRow[`${metric}Pg`];
        }
        // only try to rename if field exists (for pctile)
        if (typeof outputRow[`${metric}PgPctile`] !== 'undefined' && outputRow[`${metric}PgPctile`] !== null) {
            outputRow[`${metric}Pctile`] = outputRow[`${metric}PgPctile`];
        }
    });

    // and return
    return outputRow;
}

// Do We Want to SEPARATE left-joining %iles with aggregating stats on the fly (I think so. But then we have to left join 30 times instead of just once... hmm)
export function aggregateOnTheFly({
    dataArr = [], // the raw data (enh_games, enh_lineup_summaries) to aggregate
    dataType = 'teamAggStats', // what were aggregating INTO. if dataArray is teamGameStats, then dataType: teamAggStats
    isPinned = false, // when the aggregated row is a Totals row (top row for team/player game stats table, team lineups table)
    secsCutoff = 0 // used for lineups?
}) {
    // console.log('A: dataArr: ', JSON.parse(JSON.stringify(dataArr)));
    // handle no data
    if (dataArr.length === 0) { return []; }

    // Need games played to aggregate to be able to compute ratios correctly
    let dataArray = ['teamAggStats', 'teamAggPbpStats'].includes(dataType)
        ? dataArr.map(row => mutateOverallConfWinsLosses({ row, dataType }))
        : dataArr;

    // if game-level data, add gp: 1, gpPbp: 1 for the sum() functions to work
    let dataArrayWithGamesPlayed = dataArray // could add this into above func...
        .map(row => { return {
            ...row,
            gp: row.gameId && !row.gp ? 1 : row.gp,
            gpPbp: row.gameId && !row.gpPbp ? 1 : row.gpPbp
        }; });

    let pinLocation = dataArr?.[0]?.pinLocation || 0;

    // Group By: Sum Variables
    // ===========================
    // Create summarizeDict to pass to groupBy()
    let summableVarsKey = dataType === 'playerPostGame' ? 'playerAggStats' : dataType;
    let summableVars = summableVarsObj[summableVarsKey].sumVars;
    let summarizeDict = {};
    summableVars.forEach(metric => { summarizeDict[metric] = sum(metric); });
    // console.log('summarizeDict: ', { dataArr, summarizeDict });

    // Set extraFields to keep for dataType, add to summarizeDict
    let extraFields = [];
    switch (dataType) {
        case 'playerAggPbpStats': extraFields = ['hasImage', 'fullName', 'position', 'height', 'classYr', 'jerseyNum']; break;
        case 'playerAggStats': extraFields = ['hasImage', 'fullName', 'position', 'height', 'classYr', 'jerseyNum']; break;
        case 'teamAggStats': extraFields = ['teamMarket', 'teamName']; break;
        case 'teamAggPbpStats': extraFields = ['teamMarket', 'teamName']; break;
        default: break;
    }

    extraFields.forEach(field => {
        summarizeDict[field] = first(field);
    });


    // Set groupby columns for dataType
    let groupByArray = ['competitionId', 'teamId', 'isOffense', 'conferenceId'];
    switch (dataType) {
        case 'playerPostGame': groupByArray = groupByArray.concat('teamId', 'gameId'); break;
        case 'playerAggPbpStats': groupByArray = groupByArray.concat('playerId'); break;
        case 'playerAggStats': groupByArray = groupByArray.concat('playerId'); break;
        case 'teamAggSbz': groupByArray = groupByArray.concat('zoneSchema', 'zoneName'); break;
        case 'pppcByQualifier': groupByArray.concat('metricType', 'qualifierType', 'qualifier'); break;
        case 'lineupAggStats': groupByArray = groupByArray.concat('lineupId'); break;
        default: break;
    }

    // Use summarizeDict with groupByArray to perform group by & sum stats
    let groupedArray = tidy(
        dataArrayWithGamesPlayed,
        groupBy(groupByArray, [
            summarize(summarizeDict)
        ])
    );
    // console.log('groupedArray: ', groupedArray);

    // for pppcByQualifier, to get the qualifierFreq column, need to sum "ct" over all qualifiers
    if (dataType === 'pppcByQualifier') {
        let groupByArrayNoQualifier = groupByArray.filter(row => row !== 'qualifier');
        let groupedByCt = tidy(
            dataArrayWithGamesPlayed,
            groupBy(groupByArrayNoQualifier, [
                summarize({ totalCt: sum('ct') })
            ])
        );
        groupedArray = tidy(
            groupedArray,
            leftJoin(groupedByCt, { by: groupByArrayNoQualifier })
        );
    }
    // ========

    // Compute Ratio Stats & Adjustments
    // ====================================
    // grab equations for this "dataType"
    // console.log('b: groupedArray: ', JSON.parse(JSON.stringify(groupedArray)));
    let equationFuncs = summableVarsObj[summableVarsKey].ratioEqs;
    let mutatedArray = groupedArray.map(row => {
        equationFuncs.forEach(eqFunction => {
            row[eqFunction.name] = eqFunction(row);
        });

        // add/edit specific metrics
        row.drbPct = ['teamAggStats', 'lineupAggStats'].includes(dataType) ? row.drbPct : row.drbPct2;
        row.orbPct = ['playerAggStats'].includes(dataType) ? row.orbPct2 : row.orbPct;
        row.astPct = ['teamAggStats', 'lineupAggStats'].includes(dataType) ? row.astPct : row.astPct2;
        row.blkPct = ['teamAggStats', 'lineupAggStats'].includes(dataType) ? row.blkPct : row.blkPct2;
        row.stlPct = ['teamAggStats', 'lineupAggStats'].includes(dataType) ? row.stlPct : row.stlPct2;
        row.hkmPct = ['teamAggStats', 'lineupAggStats'].includes(dataType) ? row.hkmPct : row.hkmPct2;


        // set wins/losses for overall and conference
        row.temp1 = row.overallWins;
        row.overallWins = row.isOffense === true ? row.overallWins : row.overallLosses;
        row.overallLosses = row.isOffense === true ? row.overallLosses : row.temp1;
        row.temp2 = row.confWins;
        row.confWins = row.isOffense === true ? row.confWins : row.confLosses;
        row.confLosses = row.isOffense === true ? row.confLosses : row.temp2;

        // add other constant values
        row.divisionId = dataArr?.[0]?.divisionId ?? null; // needed for column header
        row.scope = dataArr?.[0]?.scope ?? null; // need for agg on the fly
        row.isPinned = isPinned; // pin on top if <= 2 rows grouped
        row.pinLocation = pinLocation; // 1 (top), 2 (top, 2nd row), -1 (bottom), -2 (bottom, 2nd-last row)
        row.isQualified = true; // ALWAYS SHOW %ILE, REGARDLESS OF QUALIFICATION RULES
        row.isAggOnFly = true; // boolean TRUE to help with left join %iles
        row.gameId = 999999; // widenByIsOffense() needs a gameId

        return row;
    });
    // ========

    // for lineupAggStats, add isQualifier based on new secsCutoff
    if (dataType === 'lineupAggStats' && secsCutoff !== null) {
        mutatedArray = mutatedArray.map(row => { return { ...row, isQualified: row.minsPbp > secsCutoff / 60 }; });
    }

    // When aggOnFly used for a pinned row -> need to use per/game value "asgPg" for counting/integer field "ast"
    let outputArray = JSON.parse(JSON.stringify(mutatedArray));
    // console.log('c: mutatedArray: ', JSON.parse(JSON.stringify(mutatedArray)));
    // console.log('D: outputArray: ', JSON.parse(JSON.stringify(outputArray)));
    if (isPinned === true) {
        // columns for which we set the value to its per/game value
        // let dropCols = [
        //     'mins', 'ptsScored', 'ptsAgst', 'ptsScoredTm',
        //     'fgm', 'fga', 'fgm2', 'fga2', 'fgm3', 'fga3', 'ftm', 'fta', 'ast', 'orb', 'drb', 'reb', 'stl', 'blk', 'tov', 'pf', 'opf', 'pfd',
        //     'pitp', 'pitpFgm', 'pitpFga', 'fbpts', 'fbptsFgm', 'fbptsFga', 'scp', 'scpFgm', 'scpFga', 'benchPts', 'potov',
        //     'atr2Fgm', 'atr2Fga', 'paint2Fgm', 'paint2Fga', 'mid2Fgm', 'mid2Fga', 'c3Fgm', 'c3Fga', 'atb3Fgm', 'atb3Fga',
        //     'sht2Fgm', 'sht2Fga', 'med2Fgm', 'med2Fga', 'lng2Fgm', 'lng2Fga', 'sht3Fgm', 'sht3Fga', 'lng3Fgm', 'lng3Fga'
        // ];

        // // do the replacing: e.g. "ast" value now gets "astPg"'s value. ("ast" will be the accessor used in table, but for this top row we want the per/game values)
        // outputArray = mutatedArray.map(row => {
        //     dropCols.forEach(metric => {
        //         row[metric] = row[`${metric}Pg`];
        //         row[`${metric}Pctile`] = row[`${metric}PgPctile`];
        //     });
        //     return row;
        // });

        // convert a few other columns into per/game values
        let mapToPerGameCols = ['ptsP1', 'ptsP2', 'ptsP3', 'ptsP4', 'ptsOt', 'timesTied', 'leadChanges', 'timeLeading', 'biggestRun', 'biggestLead'];
        outputArray = outputArray.map(row => {
            mapToPerGameCols.forEach(metric => {
                row[metric] = row[metric] / row.gp;
            });
            return row;
        });
    }

    // console.log('E: outputArray: ', JSON.parse(JSON.stringify(outputArray)));

    // remove NULL values (kinda handles issue of every single equation being computed)
    outputArray = outputArray.map(row => {
        let rowKeys = Object.keys(row);
        rowKeys.forEach(key => {
            if (row[key] === null) { delete row[key]; }
        });
        return row;
    });

    // And Return
    // console.log('F: outputArray: ', JSON.parse(JSON.stringify(outputArray)));
    return outputArray;
}
export function aggDateRangeOnTheFly({ dataArr = [], dateRange, dataType, isPinned = false }) {
// export function aggDateRangeOnTheFly({ dataArr = [], pctilesArray = [], ranksArray = [], dateRange, dataType, playerPctilesType = null, isPinned = false }) {
    // Filter for games in daterange
    // let dateRangeGameStats = JSON.parse(JSON.stringify(dataArr))
    let dateRangeGameStats = dataArr
        .filter(row => dateRange[0] ? row.gameDate >= dateRange[0].value : true)
        .filter(row => dateRange[1] ? row.gameDate <= dateRange[1].value : true);

    // Aggregate stats over date range
    let dateRangeAggStats = aggregateOnTheFly({ dataArr: dateRangeGameStats, dataType, isPinned });

    // Add Scope & Return
    dateRangeAggStats = dateRangeAggStats.map(row => { return { ...row, scope: 'daterange' }; });
    return dateRangeAggStats;
}
export function aggCustomGamesOnTheFly({ dataArr = [], customGameIds, dataType, isPinned = false }) {
    // Filter for custom games selected
    let customGameStats = dataArr
        .filter(row => customGameIds.includes(row.gameId));

    // Aggregate stats for selected custom games
    let customAggStats = aggregateOnTheFly({ dataArr: customGameStats, dataType, isPinned });

    // Add Scope & Return
    customAggStats = customAggStats.map(row => { return { ...row, scope: 'custom' }; });
    return customAggStats;
}

export function aggregatePppc({ teamGamePppc, scope }) {
    // Overview: Aggregates teamGamePppc up to teamAggPppc. NOte that teamGamePppc is already filtered for the relevant scopes
    // teamGamePppc is of a different structure than most other tables that use aggregateOnTheFly

    // Parameter:   teamGamePppc, fetched from CBB database
    // Parameter:   scope: solely for attaching to the aggregated output at the end

    // total counts (qualifier removed from groupBy) needed in the stat calculations
    let totalCts = tidy(
        teamGamePppc,
        groupBy(['teamId', 'isOffense', 'metricType', 'qualifierType'], [
            summarize({
                totalCt: sum('ct')
            })
        ])
    );

    // do the heavy lifting
    let outputArray = tidy(
        teamGamePppc,
        groupBy(['teamId', 'isOffense', 'metricType', 'qualifierType', 'qualifier'], [
            summarize({
                ct: sum('ct'),
                ptsScored: sum('ptsScored'),
                fga2: sum('fga2'),
                fgm2: sum('fgm2'),
                fga3: sum('fga3'),
                fgm3: sum('fgm3')
            })
        ]),
        leftJoin(totalCts, { by: ['teamId', 'isOffense', 'metricType', 'qualifierType'] }),
        mutate({
            pppc: d => d.ptsScored / d.ct,
            qualifierFreq: d => d.ct / d.totalCt,
            fg2Pct: d => d.fgm2 / d.fga2,
            fg3Pct: d => d.fgm3 / d.fga3,
            fga3Rate: d => d.fga3 / (d.fga2 + d.fga3)
        }),
        mutate({
            scope: scope
        })
    );

    // and return
    return (outputArray);
}

export function getPostGameTotalsRows({ teamGameStats, pctilesTeamGameStats, teamGamePbpStats = null, pctilesTeamGamePbpStats = null, teamId, rows = ['totals', 'percentages'] }) {
    // console.log('getPostGameTotalsRows params: ', { teamGameStats, pctilesTeamGameStats, teamGamePbpStats, pctilesTeamGamePbpStats, teamId });

    // 2 team totals rows at the bottom of the table
    // this function takes teamGameStats array for a game, and cleans them up in such a way to make them append-able to the bottom of playerGameStats.

    // boolean to include pbp stats or not
    if (!teamGameStats || teamGameStats.length === 0) { return []; }
    let incPbpStats = teamGamePbpStats && teamGamePbpStats.length > 0;

    // keep isOffense only, join %iles for both tables
    let teamStats = tidy(teamGameStats, filter(d => d.isOffense === true && d.teamId === teamId));
    teamStats = leftJoinPctiles({ tableData: teamStats, pctilesData: pctilesTeamGameStats, dataType: 'teamGameStats' });

    // teamPbpStats are optional, if  'layupDunks', 'goodTakeRate' are needed
    if (incPbpStats) {
        let teamPbpStats = tidy(teamGamePbpStats, filter(d => d.isOffense === true && d.teamId === teamId));
        teamPbpStats = leftJoinPctiles({ tableData: teamPbpStats, pctilesData: pctilesTeamGamePbpStats, dataType: 'teamGamePbpStats' });

        let pbpKeeperCols = ['teamId', 'gameId', 'layupDunks', 'goodTakeRate', 'goodTakeRatePctile'];
        let teamPbpStatsSkinny = teamPbpStats.map(row => { let obj = {}; pbpKeeperCols.forEach(k => (obj[k] = row[k])); return obj; });
        teamStats = tidy(teamStats, leftJoin(teamPbpStatsSkinny, { by: ['teamId', 'gameId'] }));
    }

    let keeperRatioStats = ['fgm', 'fga', 'fgm3', 'fga3', 'ftm', 'fta', 'rebPct', 'stlPct', 'blkPct', 'orbPct', 'drbPct', 'astPct', 'tovPct'];
    let percentagesRow = tidy(teamStats,
        select(keeperRatioStats),
        rename({
            astPct: 'ast',
            tovPct: 'tov',
            orbPct: 'orb',
            drbPct: 'drb'
        }),
        mutate({
            stl: d => d.stlPct
        }),
        rename({ // for metric % in metric int columns (show AST% at bottom of column with basic AST)
            blkPct: 'blk',
            rebPct: 'reb'
        }),
        mutate({
            pinLocation: -2, // -1 == pin location bottom, 2nd-last row
            isPinned: true,
            pinType: 'allPcts',
            fullName: 'Percentages',
            unit: 'pct0' // new unit, % with 0 decimal places
        }));

    // let totalsRow = tidy(teamGameStats,
    let totalsRow = tidy(teamStats,
        filter(d => d.isOffense === true && d.teamId === teamId),
        mutate({
            pinLocation: -1, // -1 == pin location bottom
            isPinned: true,
            pinType: 'totals',
            fullName: 'Totals',
            classYr: '',
            mins: '',
            plusMinus: d => d.ptsScored - d.ptsAgst
        }));

    // and return
    let outputRows = [
        ...(rows.includes('percentages') ? percentagesRow : []),
        ...(rows.includes('totals') ? totalsRow : [])
    ];
    return outputRows; // order matters
}

// Filtering (Array) Functions
// ============================
export function filterAndSortArray({ data, keysArray, key }) {
    if (data.length === 0) { return []; }

    // Filter
    let newData = JSON.parse(JSON.stringify(data));
    newData = newData.filter(row => keysArray.includes(row[key]));

    // And Sort...
    let outputArray = [];
    let orderedKeys = JSON.parse(JSON.stringify(keysArray));
    orderedKeys.reverse().forEach(thisKey => {
        let obj = newData.filter(row => row[key] === thisKey)[0];
        outputArray.push(obj);
    });

    // console.log('outputArray: ', outputArray);
    outputArray = outputArray.filter(Boolean);
    return outputArray;
}
export function filterGamesForScope({ row, scope, ptgc, dateRange = [null, null], customGameIds = [] }) {
    // row:     a row from [team game stats, team game pbp stats, player game stats, player game pbp stats, enh lineup game summaries]
    // row:     NOT A ROW FROM GAME INFO (at least I don't think)
    // ptgc:    one of [team, player, lineup]

    let keepRow = true;
    keepRow = (scope === 'season' ? true : keepRow);
    keepRow = (scope === 'confReg' ? row.inConferenceReg : keepRow);
    keepRow = (scope === 'confAll' ? row.inConferenceAll : keepRow);
    keepRow = (scope === 'nonconfReg' ? !row.inConferenceAll && !row.isPostSeason : keepRow);
    keepRow = (scope === 'post' ? row.isPostSeason : keepRow);
    keepRow = (scope === 'l5g' ? row.teamGameRecency <= 5 : keepRow);
    keepRow = (scope === 'l10g' ? row.teamGameRecency <= 10 : keepRow);
    keepRow = (scope === 'home' ? row.isHome === true && row.isNeutral === false : keepRow);
    keepRow = (scope === 'away' ? row.isHome === false && row.isNeutral === false : keepRow);
    keepRow = (scope === 'neutral' ? row.isNeutral === true : keepRow);
    keepRow = (scope === 'h1' ? row.period === 'h1' : keepRow);
    keepRow = (scope === 'h2' ? row.period === 'h2' : keepRow);
    keepRow = (scope === 'quad1' ? row.quadAgst === 'quad1' : keepRow);
    keepRow = (scope === 'quad2' ? row.quadAgst === 'quad2' : keepRow);
    keepRow = (scope === 'quad3' ? row.quadAgst === 'quad3' : keepRow);
    keepRow = (scope === 'quad4' ? row.quadAgst === 'quad4' : keepRow);
    keepRow = (scope === 'custom' ? customGameIds.includes(row.gameId) : keepRow);

    if (scope === 'daterange') {
        let checkStart = dateRange[0] === null || (row.gameDate >= dateRange[0].value);
        let checkEnd = dateRange[1] === null || (row.gameDate <= dateRange[1].value);
        keepRow = checkStart && checkEnd;
    }

    if (scope.includes('tourney')) {
        let tournamentId = Number(scope.slice(7));
        keepRow = row.tournamentId === tournamentId;
    }

    if (ptgc === 'team') {
        keepRow = (scope === 'wins' ? (
            (row.isOffense === true && row.ptsScored > row.ptsAgst) ||
            (row.isOffense === false && row.ptsScored < row.ptsAgst) ? true : false) : keepRow);
        keepRow = (scope === 'losses' ? (
            (row.isOffense === false && row.ptsScored > row.ptsAgst) ||
            (row.isOffense === true && row.ptsScored < row.ptsAgst) ? true : false) : keepRow);
    }

    if (ptgc === 'player') {
        keepRow = (scope === 'wins' ? (row.ptsScoredTm > row.ptsAgst ? true : false) : keepRow);
        keepRow = (scope === 'losses' ? (row.ptsScoredTm > row.ptsAgst ? true : false) : keepRow);
    }

    if (ptgc === 'lineup') {
        keepRow = (scope === 'wins' ? (row.isWin === true ? true : false) : keepRow);
        keepRow = (scope === 'losses' ? (row.isWin === true ? false : true) : keepRow);
    }

    if (ptgc === 'pppc') {
        keepRow = (scope === 'wins' ? (
            (row.isOffense === true && row.ptsScoredTm > row.ptsAgstTm) ||
            (row.isOffense === false && row.ptsScoredTm < row.ptsAgstTm) ? true : false) : keepRow);
        keepRow = (scope === 'losses' ? (
            (row.isOffense === false && row.ptsScoredTm > row.ptsAgstTm) ||
            (row.isOffense === true && row.ptsScoredTm < row.ptsAgstTm) ? true : false) : keepRow);
    }

    // console.log('filterGamesForScope props & output: ', { keepRow, row, tgr: row.teamGameRecency, inConf: row.inConference, selectedScope, ptgc, dateRange, customGameIds });
    // and return
    return keepRow;
}
export function filterForScope({ row, scope }) {
    let keepRow = scope.isTourney ? row.tournamentId === scope.tournamentId : row.scope === scope.value;
    return keepRow;
}
export function filterPbpForScope({ row, scope, teamId, dateRange = [null, null], customGameIds = [] }) {
    // Note: need to left-join columns missing from "enh_pbp", "pbp_shots" onto the data
    // "awayTgr", "homeTgr", "inConferenceReg", "inConferenceAll", "isPostSeason", etc...
    let keepRow = true;

    // if row.conferenceId in keeperConferences, keep the row (return true)
    switch (scope) {
        case 'season': keepRow = true; break;
        case 'l5g': keepRow = row.homeId === teamId ? (row.homeTgr <= 5 ? true : false) : (row.awayTgr <= 5 ? true : false); break;
        case 'l10g': keepRow = row.homeId === teamId ? (row.homeTgr <= 10 ? true : false) : (row.awayTgr <= 10 ? true : false); break;
        case 'confReg': keepRow = row.inConferenceReg === true; break;
        case 'confAll': keepRow = row.inConferenceAll === true; break;
        case 'nonconfReg': keepRow = !row.inConferenceAll && !row.isPostSeason; break;
        // case 'nonconfAll': keepRow = !row.inConferenceAll && !row.isPostSeason; break;
        case 'post': keepRow = row.isPostSeason; break;
        case 'home': keepRow = row.homeId === teamId & row.isNeutral === false; break;
        case 'away': keepRow = row.awayId === teamId & row.isNeutral === false; break;
        case 'neutral': keepRow = row.isNeutral === true; break;
        case 'h1': keepRow = ['h1', 'q1', 'q2'].includes(row.period); break;
        case 'h2': keepRow = ['h2', 'q3', 'q4'].includes(row.period); break;
        case 'quad1': keepRow = row.homeId === teamId ? row.awayQuad === 'quad1' : row.homeQuad === 'quad1'; break;
        case 'quad2': keepRow = row.homeId === teamId ? row.awayQuad === 'quad2' : row.homeQuad === 'quad2'; break;
        case 'quad3': keepRow = row.homeId === teamId ? row.awayQuad === 'quad3' : row.homeQuad === 'quad3'; break;
        case 'quad4': keepRow = row.homeId === teamId ? row.awayQuad === 'quad4' : row.homeQuad === 'quad4'; break;
        case 'wins': keepRow = (row.homeId === teamId && row.didHomeWin) || (row.awayId === teamId && !row.didHomeWin); break;
        case 'losses': keepRow = (row.homeId === teamId && !row.didHomeWin) || (row.awayId === teamId && row.didHomeWin); break;
        case 'custom': keepRow = customGameIds.includes(row.gameId); break;
        case 'daterange': keepRow = true; break; // use daterange below
        default: console.log(`ERROR: Bad scope to filter on: ${scope}`); keepRow = true;
    }

    if (scope === 'daterange') {
        let checkStart = dateRange[0] === null || (row.gameDate >= dateRange[0].value);
        let checkEnd = dateRange[1] === null || (row.gameDate <= dateRange[1].value);
        keepRow = checkStart && checkEnd;
    }

    return keepRow;
}
export function filterKeepers({ row, dataKey, stateKey = null, keeperArray, keepEmpty = true }) {
    // keys have to match
    // key1: key for the stats-data array
    // key2: key for the keeper-state array
    let keepRow = false;
    if (keeperArray.length === 0 && keepEmpty) { return true; }
    keeperArray.forEach(d => {
        if (row[dataKey] === d[stateKey || dataKey]) {
            keepRow = true; // fallback to "dataKey" if "stateKey" is null (most of the time - same key in stats array and keeper array)
        }
    });
    return keepRow;
}
export function filterKeeperConferences({ row, keeperConferences, keepEmpty = true }) {
    // if row.conferenceId in keeperConferences, keep the row (return true)
    let keepRow = false;
    if (keeperConferences.length === 0 && keepEmpty) { return true; }
    keeperConferences.forEach(conf => {
        if (conf.conferenceId === row.conferenceId) { keepRow = true; }
    });
    return keepRow;
}
export function filterKeeperTeams({ row, keeperTeams, keepEmpty = true }) {
    if (keeperTeams.length === 0 && keepEmpty) { return true; }
    let keepRow = false;
    keeperTeams.forEach(team => {
        if (team.teamId === row.teamId) { keepRow = true; }
    });
    return keepRow;
}
export function filterKeeperPlayers({ row, keeperPlayers, keepEmpty = true }) {
    if (keeperPlayers.length === 0 && keepEmpty) { return true; }
    let keepRow = false;
    keeperPlayers.forEach(player => {
        if (player.playerId === row.playerId) { keepRow = true; }
    });
    return keepRow;
}
export function filterKeeperPlayerSeasons({ row, keeperPlayerSeasons, keepEmpty = true }) {
    if (keeperPlayerSeasons.length === 0 && keepEmpty) { return true; }
    let keepRow = false;
    keeperPlayerSeasons.forEach(player => {
        if (player.playerId === row.playerId && player.competitionId === row.competitionId) { keepRow = true; }
    });
    return keepRow;
}
export function filterKeeperConferencesInGame({ row, keeperConferences, keepEmpty = true }) {
    // if row.conferenceId in keeperConferences, keep the row (return true)
    let keepRow = false;
    if (keeperConferences.length === 0 && keepEmpty) { return true; }
    keeperConferences.forEach(conf => {
        if ([row.conferenceId, row.conferenceIdAgst].includes(conf.conferenceId)) { keepRow = true; }
    });
    return keepRow;
}
export function filterKeeperTeamsInGame({ row, keeperTeams, keepEmpty = true }) {
    if (keeperTeams.length === 0 && keepEmpty) { return true; }
    let keepRow = false;
    keeperTeams.forEach(team => {
        if ([row.teamId, row.teamIdAgst].includes(team.teamId)) { keepRow = true; }
    });
    return keepRow;
}
export function filterPageFilters({ row, pageFilters }) {
    if (pageFilters.length === 0) { return true; }
    let keepRow = true;

    // eslint-disable-next-line consistent-return
    pageFilters.forEach(pageFilter => {
        let { stat, dir, amount } = pageFilter;
        if (amount === null) { return true; } // when filter amount not filled in, return true
        if (stat === null) { return false; } // when filter stat not selected, return true
        switch (dir.value) {
            case 'eq': keepRow = row[stat.key] === amount ? keepRow : false; break;
            case 'neq': keepRow = row[stat.key] !== amount ? keepRow : false; break;
            case 'gt': keepRow = row[stat.key] > amount ? keepRow : false; break;
            case 'gte': keepRow = row[stat.key] >= amount ? keepRow : false; break;
            case 'lt': keepRow = row[stat.key] < amount ? keepRow : false; break;
            case 'lte': keepRow = row[stat.key] <= amount ? keepRow : false; break;
            default: // no need to change keepRow; // keepRow = keepRow;
        }
    });

    return keepRow;
}
export function filterLineupsForOnOffCourt({ row, playersArray, filterType, prefix = 'p' }) {
    // row:                 row of play-by-play data
    // playersArray:        array of integers with playerIds [playerId, playerId, playerId]
    // filtertype:          one of "onCourt", "offCourt"
    // prefix:              prefix used for the Ids in the PBP data for the players on-court, one of "p", "ha"

    // Filter 5-Man Lineup in Team & Conf Lineup Stats
    let isKeeperCheck = true;
    playersArray.forEach(teammate => {
        let thisLineup = prefix === 'p'
            ? [row.pId1, row.pId2, row.pId3, row.pId4, row.pId5]
            : [row.hId1, row.hId2, row.hId3, row.hId4, row.hId5, row.aId1, row.aId2, row.aId3, row.aId4, row.aId5];
        let hasPlayer = thisLineup.includes(teammate.playerId);

        if (filterType === 'onCourt' && !hasPlayer) { isKeeperCheck = false; }
        else if (filterType === 'offCourt' && hasPlayer) { isKeeperCheck = false; }
    });
    return isKeeperCheck;
}
// ========


// Adhoc Utility Functions
// ========================
export function createTeamRecordLabel({ teamAggStats, scopeType, scope, multiScope, conferenceShortName }) {
    // this function creates the string "Overall (31-2) • Pac-12 (23-2)" for the ControllerNavbar
    if (teamAggStats.length === 0) { return ''; }

    // grab what we need from teamAggStats
    const teamSeasonStats = teamAggStats.filter(row => row.isOffense === true & row.scope === 'season');
    const { overallWins, overallLosses, confWins, confLosses } = teamSeasonStats[0] ? teamSeasonStats[0] : {};
    // let recordLabelText = isLoading ? '' : `Overall (${overallWins}-${overallLosses}) • ${conferenceShortName} (${confWins}-${confLosses})`;
    let recordLabelText = `Overall (${overallWins}-${overallLosses}) • ${conferenceShortName} (${confWins}-${confLosses})`;
    let scopeStats, scopeWins, scopeLosses, isTourney;

    // label for single split
    let scopesToExclude = ['season', 'confReg', 'divisionAvg', 'conferenceAvg', 'daterange', 'custom', 'h1', 'h2', 'clutch'];
    if (scopeType.value === 'single' & !scopesToExclude.includes(scope.value)) {
        isTourney = scope.value.includes('tourney');
        scopeStats = teamAggStats
            .filter(row => row.isOffense === true)
            .filter(row => isTourney ? row.scope === 'tourney' & row.tournamentId === Number(scope.value.slice(7)) : row.scope === scope.value);
        scopeWins = scopeStats[0] ? scopeStats[0].overallWins : '';
        scopeLosses = scopeStats[0] ? scopeStats[0].overallLosses : '';
        recordLabelText = `${recordLabelText} • ${scope.label} (${scopeWins}-${scopeLosses})`;
    }
    if (scopeType.value === 'multi') {
        multiScope.forEach(thisScope => {
            if (!scopesToExclude.includes(thisScope.value)) {
                isTourney = thisScope.value.includes('tourney');
                scopeStats = teamAggStats
                    .filter(row => row.isOffense === true)
                    .filter(row => isTourney ? row.scope === 'tourney' & row.tournamentId === Number(thisScope.value.slice(7)) : row.scope === thisScope.value);
                scopeWins = scopeStats[0] ? scopeStats[0].overallWins : '';
                scopeLosses = scopeStats[0] ? scopeStats[0].overallLosses : '';
                recordLabelText = `${recordLabelText} • ${thisScope.label} (${scopeWins}-${scopeLosses})`;
            }
        });
    }

    return recordLabelText;
}
export function isNil({ d }) {
    // isNil == is null or undefined
    // note this function cannot handle array, objects due is isNan() which returns true for arrays
    return typeof d === 'undefined' || d === null || isNaN(d);
    // isNaN here is problem?, means we can only check for numbers
}
export function createDateLabel({ dateRange }) {
    // handle no date range, empty array
    if (!dateRange || dateRange.length === 0) { return ''; }

    // create date label
    let dateLabel = (!dateRange[0] && !dateRange[1]) ? 'Full Season' :
        (!dateRange[0] ? `Up to ${dateRange[1].label}` :
            (!dateRange[1] ? `${dateRange[0].label} and after` : `Between ${dateRange[0].shortLabel} & ${dateRange[1].shortLabel}`));

    return dateLabel;
}
export function getDayOfWeek({ date }) {
    const dayOfWeek = new Date(date).getDay(); // assuming this in a [0,6] index
    const validDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    return isNaN(dayOfWeek) ? null : validDays[dayOfWeek];
}
export function formatDate({ date }) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }

    return [year, month, day].join('-');
}
export function getPlayerNameAbb({ fullName }) {
    // handle null or undefined name
    if (!fullName) { return 'MISSING'; }

    let strippedName = fullName
        .replace(' III', '')
        .replace(' II', '')
        .replace(' IV', '')
        .replace(' Jr.', '');

    // Turn String Into Clean Name Array
    let nameArray = strippedName.split(' ');
    nameArray = nameArray.filter(Boolean); // to remove "" empty strings in arary

    // Create Short Name From Array
    let firstLetter = nameArray[0].charAt(0);
    let lastName = nameArray[nameArray.length - 1].slice(0, 5);
    let shortName = (nameArray[0] === 'Total') ? 'Total' : (nameArray[0] === 'blank' ? '' : `${firstLetter}.${lastName}`);

    return shortName;
}
export function createNameString({ array }) {
    let nameString = array.map(row => row.fullName).join(', ');
    return nameString;
}
export function createShortNameString({ array, type = 'short' }) {
    // array:       array of objects with players, must have "fullName" field
    // type:        one of ['short', 'full'], for name types

    // console.log('array: ', array);
    // should replace this with reshapeData "getPlayerNameAbb()"
    let nameString = array
        .map(row => row.fullName.replace(' III', '').replace(' II', '').replace(' IV', '').replace(' Jr.', ''))
        .map(row => {
            if (type === 'short') {
                let nameArray = row.split(' ');
                nameArray = nameArray.filter(Boolean);
                let firstLetter = nameArray[0].charAt(0);
                let lastName = nameArray[nameArray.length - 1].slice(0, 5);
                let shortName = (nameArray[0] === 'Total') ? 'Total' : (nameArray[0] === 'blank' ? '' : `${firstLetter}.${lastName}`);
                return shortName;
            }
            if (type === 'full') {
                return row;
            }
            return row; // should never come to be
        })
        .join(', ');

    return nameString;
}
export function negateDictOptions({ dDictArray }) {
    // negate fileds
    let newArray = dDictArray.map(row => ({
        ...row,
        key: `${row.key}Agst`,
        label1: `${row.label1} Agst`,
        label2: `${row.label2} Agst`,
        label3: `${row.label3} Agst`,
        label4: `Opponent's ${row.label4}`,
        sign: row.sign === 'pos' ? 'neg' : 'pos',
        tipTitle: `Opponent's ${row.tipTitle}`
    }));

    return newArray;
}

export function splitLineupId({ row, key = 'lineupId', playerInfosObj = null }) {
    // row:             row with a '-' split lineupId, e.g. '238423-234823-923412'
    // key:             name of the lineupId row, typically lineupId
    // playerInfosObj:  optional, to get player names added as well

    // console.log('splitLineupId: ', { row, key, playerInfosObj });

    const prefix = ['homeLineupId', 'hLid'].includes(key) ? 'h' : ['awayLineupId', 'aLid'].includes(key) ? 'a' : 'p'; // pId1, hId1, aId1
    const lineupId = row[key];

    // handle no lineupId (not great)
    let newRow = row;
    if (!lineupId) {
        // console.log('row: ', { row });
        sentryCaptureError({ message: `Missing ${key} on row where ${key} is required.`, extra: { key, row } });
        // console.log('row: ', row);
        return newRow;
    }

    lineupId.split('-').forEach((id, idx) => {
        newRow[`${prefix}Id${idx + 1}`] = Number(id);
        if (playerInfosObj !== null) {
            newRow[`${prefix}Name${idx + 1}`] = playerInfosObj && playerInfosObj[id] ? playerInfosObj[id].fullName : '';
        }
    });
    return newRow;
}
export function compute1234LineupStats({ n = 4, lineupStats, playerInfosObj, secsCutoff, pctilesData }) {
    // lineupStats:         5-man lineup game stats
    // playerInfosObj:      { 2382342: { this players info }, 1854234: { this players info }, ...}
    // secsCutoff:          for determining isQualified in the aggregateOnTheFly()
    // pctilesData:         optional. if included, then percentiles will be joined

    // outputStats:         return 1234-man lineup game rows

    // split lineupId
    let outputStats = lineupStats.map(row => splitLineupId({ row, key: 'lineupId', playerInfosObj }));

    // convert 5-man lineup rows into 4-man lineup rows
    if (n === 4) {
        outputStats = [
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId5, pName5, ...rest }) => ({ lineupId: `${rest.pId1}-${rest.pId2}-${rest.pId3}-${rest.pId4}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId4, pName4, ...rest }) => ({ lineupId: `${rest.pId1}-${rest.pId2}-${rest.pId3}-${rest.pId5}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId3, pName3, ...rest }) => ({ lineupId: `${rest.pId1}-${rest.pId2}-${rest.pId4}-${rest.pId5}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId2, pName2, ...rest }) => ({ lineupId: `${rest.pId1}-${rest.pId3}-${rest.pId4}-${rest.pId5}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId1, pName1, ...rest }) => ({ lineupId: `${rest.pId2}-${rest.pId3}-${rest.pId4}-${rest.pId5}`, ...rest }))
        ];
    }

    // convert 5-man lineup rows into 3-man lineup rows
    if (n === 3) {
        outputStats = [
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId4, pName4, pId5, pName5, ...rest }) => ({ lineupId: `${rest.pId1}-${rest.pId2}-${rest.pId3}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId3, pName4, pId5, pName5, ...rest }) => ({ lineupId: `${rest.pId1}-${rest.pId2}-${rest.pId4}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId3, pName3, pId4, pName4, ...rest }) => ({ lineupId: `${rest.pId1}-${rest.pId2}-${rest.pId5}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId2, pName2, pId5, pName5, ...rest }) => ({ lineupId: `${rest.pId1}-${rest.pId3}-${rest.pId4}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId2, pName2, pId4, pName4, ...rest }) => ({ lineupId: `${rest.pId1}-${rest.pId3}-${rest.pId5}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId2, pName2, pId3, pName3, ...rest }) => ({ lineupId: `${rest.pId1}-${rest.pId4}-${rest.pId5}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId1, pName1, pId5, pName5, ...rest }) => ({ lineupId: `${rest.pId2}-${rest.pId3}-${rest.pId4}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId1, pName1, pId4, pName4, ...rest }) => ({ lineupId: `${rest.pId2}-${rest.pId3}-${rest.pId5}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId1, pName1, pId3, pName3, ...rest }) => ({ lineupId: `${rest.pId2}-${rest.pId4}-${rest.pId5}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId1, pName1, pId2, pName2, ...rest }) => ({ lineupId: `${rest.pId3}-${rest.pId4}-${rest.pId5}`, ...rest }))
        ];
    }

    // convert 5-man lineup rows into 2-man lineup rows
    if (n === 2) {
        outputStats = [
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId3, pName3, pId4, pName4, pId5, pName5, ...rest }) => ({ lineupId: `${rest.pId1}-${rest.pId2}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId2, pName2, pId4, pName4, pId5, pName5, ...rest }) => ({ lineupId: `${rest.pId1}-${rest.pId3}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId2, pName2, pId3, pName3, pId5, pName5, ...rest }) => ({ lineupId: `${rest.pId1}-${rest.pId4}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId2, pName2, pId3, pName3, pId4, pName4, ...rest }) => ({ lineupId: `${rest.pId1}-${rest.pId5}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId1, pName1, pId4, pName4, pId5, pName5, ...rest }) => ({ lineupId: `${rest.pId2}-${rest.pId3}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId1, pName1, pId3, pName3, pId5, pName5, ...rest }) => ({ lineupId: `${rest.pId2}-${rest.pId4}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId1, pName1, pId3, pName3, pId4, pName4, ...rest }) => ({ lineupId: `${rest.pId2}-${rest.pId5}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId1, pName1, pId2, pName2, pId5, pName5, ...rest }) => ({ lineupId: `${rest.pId3}-${rest.pId4}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId1, pName1, pId2, pName2, pId4, pName4, ...rest }) => ({ lineupId: `${rest.pId3}-${rest.pId5}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId1, pName1, pId2, pName2, pId3, pName3, ...rest }) => ({ lineupId: `${rest.pId4}-${rest.pId5}`, ...rest }))
        ];
    }

    // convert 5-man lineup rows into 1-man lineup rows
    if (n === 1) {
        outputStats = [
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId2, pName2, pId3, pName3, pId4, pName4, pId5, pName5, ...rest }) => ({ lineupId: `${rest.pId1}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId1, pName1, pId3, pName3, pId4, pName4, pId5, pName5, ...rest }) => ({ lineupId: `${rest.pId2}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId1, pName1, pId2, pName2, pId4, pName4, pId5, pName5, ...rest }) => ({ lineupId: `${rest.pId3}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId1, pName1, pId2, pName2, pId3, pName3, pId5, pName5, ...rest }) => ({ lineupId: `${rest.pId4}`, ...rest })),
            // eslint-disable-next-line no-unused-vars
            ...outputStats.map(({ lineupId, pId1, pName1, pId2, pName2, pId3, pName3, pId4, pName4, ...rest }) => ({ lineupId: `${rest.pId5}`, ...rest }))
        ];
    }

    // to this point, outputStats is simply the 5-man lineup stats with the rows duplicates by a factor of 5-10x.
    // still need to actually compute the 1-2-3-4 man lineup stats with an aggregateOnTheFly()
    outputStats = aggregateOnTheFly({ dataArr: outputStats, dataType: 'lineupAggStats', secsCutoff: secsCutoff });

    // if "pctiles" array passed, then leftJoinPctiles
    if (pctilesData && pctilesData.length > 0) {
        outputStats = leftJoinPctiles({ tableData: outputStats, pctilesData: pctilesData, dataType: 'lineupAggStats' });
    }

    // resplit the lineupId, so that we have the pIds again
    outputStats = outputStats.map(row => splitLineupId({ row, key: 'lineupId', playerInfosObj }));

    // and return
    return outputStats;
}
export function heightAsString({ height }) {
    if (!height) { return ''; }
    // const heightInches = Math.round(height / 2.54);
    const heightFeet = Math.floor(height / 12);
    const heightFeetInches = `${heightFeet}'${(height - (12 * heightFeet)).toFixed(0)}"`;
    return heightFeetInches;
}

export function getPriorCompetitionId({ competitionId }) {
    // console.log('competitionId: ', competitionId);
    const priorCompetitionId = competitionIdMap[competitionId]?.priorId || null;
    if (!priorCompetitionId) {
        console.log(`Error: no priorId for competitionId: ${competitionId}`);
        return null;
    }
    return priorCompetitionId;
}

export function getNextCompetitionId({ competitionId }) {
    // console.log('competitionId: ', competitionId);
    const nextCompetitionId = competitionIdMap[competitionId]?.nextId || null;
    if (!nextCompetitionId) {
        console.log(`Error: no nextId for competitionId: ${competitionId}`);
        return null;
    }
    return nextCompetitionId;
}

export function seasonLabel({ competitionId, size = 'sm', gender = 'NOGENDER', recency = 'current' }) {
    // type: ['sm', 'md', 'lg']
    // gender: ['NOGENDER', 'MALE', 'FEMALE']
    // recency: ['prior', 'current', 'next']

    let switchId;
    switch (recency) {
        case 'current': switchId = competitionId; break;
        case 'prior': switchId = getPriorCompetitionId({ competitionId }); break;
        case 'next': switchId = getNextCompetitionId({ competitionId }); break;
        default: console.log('error bad recency value');
    }

    // handle the case where the competitionId/switchId is not found in competitionIdMap
    if (!competitionIdMap[switchId]) {
        console.log('Error: Invalid competitionId');
        return '';
    }

    // grab the label
    let abbKey = size === 'sm' ? 'abb1' : (size === 'md' ? 'abb2' : 'abb3');
    let label = competitionIdMap?.[switchId]?.[abbKey] || '';
    label = gender === 'MALE' ? `Men's ${label}` : label;
    label = gender === 'FEMALE' ? `Women's ${label}` : label;

    // and return
    return label;
}
export function formatAMPM({ dateString }) {
    // If the toLocaleTimeString() method throws a RangeError, we catch it and check if the error message contains the string
    // 'timeZoneName'. If it does, we know that the 'shortGeneric' value is not supported, so we fallback to the 'short' value
    // and call the toLocaleTimeString() method again with the updated options object. If the error is not related to the timeZoneName
    // property, we re-throw the error.
    let options = { hour: 'numeric', minute: 'numeric' };
    let s;
    try {
        options.timeZoneName = 'shortGeneric';
        s = new Date(dateString).toLocaleTimeString('en-US', options);
    } catch (e) {
        if (e instanceof RangeError && e.message.includes('timeZoneName')) {
            options.timeZoneName = 'short';
            s = new Date(dateString).toLocaleTimeString('en-US', options);
        } else {
            throw e;
        }
    }

    // and return
    return s === 'Invalid Date' ? '' : s;
}
export function isValidDate({ dateString }) {
    // dateString should be of form YYYYMMDD

    // Check if the string is 8 characters long
    if (dateString.length !== 8) { return false; }

    // Extract the year, month, and day from the string
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    if (day > 31 || month > 12) { return false; }

    // Create a new date object using the year, month, and day
    const testDate = new Date(year, month - 1, day);

    // Check if the date is valid
    return !isNaN(testDate.getTime());
}
export function gridSortMetrics({ sortType = '', sortKey = '', tableInfos = [], reshapedStats = [] }) {
    // use: for sorting metrics within our <TableGrid /> component

    //  sortType:               one of 'allSort', 'tableSort', 'noSort'
    //      allSort:                shuffle metrics from all tables based on sortKey (rank, or pctile)
    //      tableSort:              shuffle metrics within each table based on sortKey (rank, or pctile)
    //  sortKey:                one of 'rank{Scope}', 'pctile{Scope}'
    //  tableInfos:             array of objects looking like { header: '', arr: [] }
    //  reshapedStats:          the array of stats that goes into the tables

    //  console.log('gridSortMetrics params: ', { sortType, sortKey, tableInfos, reshapedStats });

    // if bad sortType
    if (!['noSort', 'tableSort', 'allSort'].includes(sortType)) {
        console.log('Error: Bad sortType parameter');
        return tableInfos;
    }

    // return: sortedTableInfos
    let sortedTableInfos = [];

    // compute size of each table, for index breaks
    let tableSizes = tableInfos.map(row => row.arr.length);
    let breaks = [0];
    tableSizes.forEach((size, idx) => breaks.push(size + breaks[idx]));
    breaks.splice(-1); // e.g. [0, 8, 16, 24, 32, 40]

    // if allSort
    if (sortType === 'allSort') {
        // flatten all stats into a single array
        let allStats = tableInfos.map(info => info.arr).flat();

        // remove irrelevant keys, sort by the rating (rank or percentile)
        let reshapedSortedStats = reshapedStats
            .filter(row => (row[sortKey] !== null && typeof row[sortKey] !== 'undefined') || row.key === 'gp')
            .filter(row => allStats.includes(row.key))
            .sort((a, b) => { // sort pctiles descending, ranks ascending
                if (a.key === 'gp') { return -1; }
                if (b.key === 'gp') { return 1; }
                return sortKey.substring(0, 4) === 'rank'
                    ? (a[sortKey] > b[sortKey] ? 1 : -1)
                    : (a[sortKey] > b[sortKey] ? -1 : 1);
            });

        // re-group metrics into tables with headers
        let t1Rows = breaks[1] - breaks[0];
        breaks.forEach((bk, idx) => {
            sortedTableInfos = sortedTableInfos.concat([{ header: `Tier ${idx + 1} Team Stats`, arr: reshapedSortedStats.slice(bk, bk + t1Rows).map(row => row.key) }]);
        });
    }

    // if tableSort
    if (sortType === 'tableSort') {
        // have to filter & sort within each table
        tableInfos.forEach((table, idx) => {
            let rowKeys = tableInfos[idx].arr;

            // Team Stats Sorted By Table
            let reshapedSortedStats = reshapedStats
                .filter(row => (row[sortKey] !== null && typeof row[sortKey] !== 'undefined') || row.key === 'gp')
                .filter(row => rowKeys.includes(row.key))
                .sort((a, b) => { // sort pctiles descending, ranks ascending
                    if (a.key === 'gp') { return -1; }
                    if (b.key === 'gp') { return 1; }
                    return sortKey.substring(0, 4) === 'rank'
                        ? (a[sortKey] > b[sortKey] ? 1 : -1)
                        : (a[sortKey] > b[sortKey] ? -1 : 1);
                });

            sortedTableInfos = sortedTableInfos.concat([{ header: tableInfos[idx].header, arr: reshapedSortedStats.map(row => row.key) }]);
        });
    }

    // if noSort
    if (sortType === 'noSort') {
        sortedTableInfos = tableInfos;
    }

    // return tableInfos
    return sortedTableInfos;
}
export function convertSecsToClock({ secs, gender = 'FEMALE' }) {
    // secs:        seconds into game
    // gender:      one of MALE, FEMALE
    // return:      convert "2300" secsIntoGame as "01:40", return as "Q1 01:40"

    const periodLength = gender === 'FEMALE' ? 600 : 1200; // 10 minutes for female, 20 minutes for male
    let periodNumber = Math.floor(secs / periodLength) + 1;
    let periodType = gender === 'FEMALE' ? 'Q' : 'H';

    // Calculate the remaining time in the period
    let remainingSecs = secs % periodLength;
    let minutes = Math.floor((periodLength - remainingSecs) / 60);
    let seconds = Math.round((periodLength - remainingSecs) % 60); // rounds to nearest second (7.200000095 -> 7)

    // Format the time as MM:SS, and return
    let timeFormatted = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    return `${periodType}${periodNumber} ${timeFormatted}`;
}

// ========

// 2826, 2714
export function shotContextString({ shotContext }) {
    let shotContextValues = shotContext.map(row => row.value);
    let outputString = shotContext.map(row => `${row.label.toLowerCase()} ${row.value === 'pb' ? ' attempts' : ' offense'}`).join(' and ');
    outputString.replace('putbacks', 'putback');

    // excluded types in ()
    let part3 = '';
    if (shotContext.length <= 2) {
        part3 = ' (';
        if (!shotContextValues.includes('hc')) { part3 = `${part3}half court, `; }
        if (!shotContextValues.includes('tr')) { part3 = `${part3}transition, `; }
        if (!shotContextValues.includes('pb')) { part3 = `${part3}putbacks, `; }
        part3 = part3.slice(0, -2) + ' excluded)';
    }

    outputString = outputString + part3;
    return outputString;
}
export function getShotChartFiltersText({ ptgc = 'team', isComboPlayers = false, comboPlayers, isOnOffPlayers, isOnOffOpponents, onCourts, offCourts, shotClockSecs, gameClockSecs, shotsTaken, shotsAgst, scope, shotContext }) {
    // for TeamShooting, PlayerShooting when using adv filters (shot clock, game clock, players on court, player combos)
    let onCourtsOnly = (isOnOffPlayers || isOnOffOpponents) && (onCourts.length > 0) && (offCourts.length === 0);
    let offCourtsOnly = (isOnOffPlayers || isOnOffOpponents) && (offCourts.length > 0) && (onCourts.length === 0);
    let onOffMixed = (isOnOffPlayers && onCourts.length > 0 && offCourts.length > 0) || (isOnOffOpponents && !onCourtsOnly && !offCourtsOnly);
    // let shotContextPiece = ;
    let outputText = [
        isComboPlayers && `• Combined shot charts and shooting stats for ${createNameString({ array: comboPlayers })} (these players' combined shots and shooting stats)`,
        !isComboPlayers && `• ${scope.label} shot charts and shooting stats`,
        onCourtsOnly && `, with all selected players on-court (${createNameString({ array: onCourts })})`,
        offCourtsOnly && `, with all selected players off-court (${createNameString({ array: offCourts })})`,
        onOffMixed && `, with selected players both on-court (${createNameString({ array: onCourts })}) and off-court (${createNameString({ array: offCourts })})`,
        shotClockSecs.min !== null && shotClockSecs.max === null && `, with ${shotClockSecs.min} or more seconds remaining on the shot clock`,
        shotClockSecs.min === null && shotClockSecs.max !== null && `, with ${shotClockSecs.max} or fewer seconds remaining on the shot clock`,
        shotClockSecs.max !== null && shotClockSecs.min !== null && `, with ${shotClockSecs.min} to ${shotClockSecs.max} seconds remaining on the shot clock`,
        gameClockSecs.min !== null && gameClockSecs.max === null && `, for ${gameClockSecs.min} or more seconds into the game`,
        gameClockSecs.min === null && gameClockSecs.max !== null && `, for ${gameClockSecs.max} or fewer seconds into the game`,
        gameClockSecs.max !== null && gameClockSecs.min !== null && `, for ${gameClockSecs.min} to ${gameClockSecs.max} seconds into the game`,
        true && `. Includes shots from ${shotContextString({ shotContext })}`,
        `. Includes ${shotsTaken} shots taken on offense${isComboPlayers ? '. Player Combos is an offense-only analysis.' : (ptgc === 'team' && !isComboPlayers ? `, and ${shotsAgst} shots faced on defense.` : '.')}`
    ];

    // console.log(outputText);
    return outputText.filter(Boolean).join('');
}

export function getNoPctilesText({ usingAdvFilters, isComboPlayers, perWhat, ratingsType = { value: 'pctiles' } }) {
    // for TeamShooting, PlayerShooting when not able to show percentiles
    let outputText = [
        usingAdvFilters && ratingsType.value === 'pctiles' && perWhat.value === 'Pg' && `• No percentiles in tables for per-game stats when using the Player On/Off, Player Combos, or Shot Clock, Game Clock filters.`,
        usingAdvFilters && ['P40', 'P100'].includes(perWhat.value) && `• We cannot compute metrics per 40 minutes or per 100 possessions when utilizing the Player On/Off, Player Combos, or Shot Clock, Game Clock filters. `,
        isComboPlayers && ratingsType.value === 'ranks' && `• No ranks when using utilizing Player Combos, as we do not have ranks for any player (or player-combo) metrics.`
    ];

    // console.log('outputText: ', { usingAdvFilters, isComboPlayers, perWhat, ratingsType, outputText });
    return outputText.filter(Boolean).join('');
}

export function filterPbpForClockOnOffCombos({
    pbpShots, //                                        data: gs__pbp_shots from the CBB database
    gameRecencyMap, //                                  data: has team game recency, etc needed for filtering below
    shotClockSecs = { min: null, max: null }, //        filter: for filtering for shot clock seconds
    gameClockSecs = { min: null, max: null }, //        filter: for filtering for game clock seconds
    statsFor = { value: 'nada' }, //                    filter: is this for the badass "player combos" or "player on-off" functionality built into shooting pages
    onCourts = [], //                                   filter: players on-court
    offCourts = [], //                                  filter: players off-court
    comboPlayers = [], //                               filter: combo of these players on court (combo-player shot chart)
    oppoOnCourts = [], //                               filter: opposing players on-court
    oppoOffCourts = [] //                               filter: opposing players off-court
}) {
    // function filters "pbpShots" into "keeperShots"
    // filters for:
    //      shot clock
    //      game clock
    //      players on/off court
    //      player combos shooting
    //      opposing players on vs. off the court

    // filter pbp shots for a whole lot of stuff
    let comboPlayerIds = comboPlayers.map(row => row.playerId);
    let keeperShots = tidy(pbpShots, leftJoin(gameRecencyMap, { by: ['gameId'] }));
    keeperShots = keeperShots
        .map(row => splitLineupId({ row, key: 'homeLineupId' }))
        .map(row => splitLineupId({ row, key: 'awayLineupId' }))
        .filter(row => shotClockSecs.min !== null ? row.shotClock >= shotClockSecs.min : true)
        .filter(row => shotClockSecs.max !== null ? row.shotClock <= shotClockSecs.max : true)
        .filter(row => gameClockSecs.min !== null ? row.secsIntoGame >= gameClockSecs.min : true)
        .filter(row => gameClockSecs.max !== null ? row.secsIntoGame <= gameClockSecs.max : true)
        .filter(row => statsFor.value === 'onOff' && onCourts.length > 0 ? filterLineupsForOnOffCourt({ row, playersArray: onCourts, filterType: 'onCourt', prefix: 'ha' }) : true)
        .filter(row => statsFor.value === 'onOff' && offCourts.length > 0 ? filterLineupsForOnOffCourt({ row, playersArray: offCourts, filterType: 'offCourt', prefix: 'ha' }) : true)
        .filter(row => statsFor.value === 'players' && comboPlayers.length > 0 ? comboPlayerIds.includes(row.playerId) : true)
        .filter(row => oppoOnCourts.length > 0 ? filterLineupsForOnOffCourt({ row, playersArray: oppoOnCourts, filterType: 'onCourt', prefix: 'ha' }) : true)
        .filter(row => oppoOffCourts.length > 0 ? filterLineupsForOnOffCourt({ row, playersArray: oppoOffCourts, filterType: 'offCourt', prefix: 'ha' }) : true);

    // and return
    return keeperShots;
}

export function generateOnOffCombinations({ playerInfos }) {
    // Overview: generate array [{ on: [], off: [] }, { on: [], off: [] }], where
    // on: [], and off: [] arrays contains objects with player infos

    // initialize count and output array
    const total = Math.pow(2, playerInfos.length); // Total number of combinations
    const combinations = [];

    // loop number of combos, populate the on/off arrays
    for (let i = 0; i < total; i++) {
        let on = [];
        let off = [];

        for (let j = 0; j < playerInfos.length; j++) {
            if (i & (1 << j)) {
                on.push(playerInfos[j]); // If j-th bit is set, player is "on"
            } else {
                off.push(playerInfos[j]); // If j-th bit is not set, player is "off"
            }
        }

        combinations.push({ on, off });
    }

    // and return
    return combinations;
}

export function starterZoneCols({ perWhat }) {
    return [
        ...(perWhat.value === '' ? [{ value: 'fga', label: 'Field Goal Attempts', shortLabel: 'FGA' }] : []),
        ...(perWhat.value === '' ? [{ value: 'fgm', label: 'Field Goals Made', shortLabel: 'FGM' }] : []),
        ...(perWhat.value === 'Pg' ? [{ value: 'fgaPg', label: 'Field Goal Attempts / Game', shortLabel: 'FGA/G' }] : []),
        ...(perWhat.value === 'P40' ? [{ value: 'fgaP40', label: 'Field Goal Attempts / 40 Mins', shortLabel: 'FGA/40' }] : []),
        ...(perWhat.value === 'P100' ? [{ value: 'fgaP100', label: 'Field Goal Attempts / 100 Poss', shortLabel: 'FGA/100' }] : []),
        { value: 'fgaFreq', label: 'Field Goal Attempt Frequency', shortLabel: 'FGA%' },
        { value: 'fgPct', label: 'Field Goal Percentage', shortLabel: 'FG%' }
    ];
}

// add or remove player when their circle is clicked in the graph
export function handleScatterPlotClick({ player, playersArray, setPlayersArray }) {
    // console.log('handleScatterPlotClick: ', { player, playersArray, setPlayersArray });

    // player:                  object: for the player dot that was just clicked on (must have playerId, competitionId)
    // playersArray:            array: page state for players already selected for emphasis in the <D3Scatter/>
    // setPlayersArray:         function: setState to update the playersArray

    // used in the parent component of most pages that have player's version of <D3Scatter/>
    const playerIdentifier = `${player.playerId}-${player.competitionId}`;
    const isPlayerAlreadyIncluded = playersArray.includes(playerIdentifier);
    const newPlayersArray = isPlayerAlreadyIncluded
        ? playersArray.filter(p => p !== playerIdentifier) //   create a new array without the player
        : [...playersArray, playerIdentifier]; //               create a new array with the player added

    setPlayersArray(newPlayersArray);
}


export function doesTidyJsMutateTheArray() {
    // test if tidy.js mutates the original array.

    // Sample data
    const originalData = [
        { id: 1, name: 'Alice', age: 25 },
        { id: 2, name: 'Bob', age: 30 }
    ];

    // Clone the original data for comparison later
    const dataBefore = [...originalData];

    // Tidy.js transformations
    const transformedData = tidy(
        originalData,
        rename({ name: 'fullName' }),
        leftJoin(dataBefore, { by: 'id' })
    );

    // Test if the original data was mutated
    const isMutated = JSON.stringify(originalData) !== JSON.stringify(dataBefore);

    console.log('Original Data:', originalData);
    console.log('Transformed Data:', transformedData);
    console.log('Is Original Data Mutated?:', isMutated);
}

export function secondsToClock({ secsIn, gender, clockType = 'secsInto' }) {
    // clockType:       one of "secsInto", "secsLeft"
    // if desc: false, then show clock
    // if desc: true,  then show

    let period, periodSeconds, MM, SS;
    const regulationTime = 2400; // 40 minutes, 2400 seconds
    const overtimePeriodLength = 300; // 5 minutes, 300 seconds
    let periodLength;

    if (gender === 'MALE') {
        periodLength = 1200; // 20 minutes per half, 1200 seconds
        if (secsIn <= periodLength) {
            period = `H1`;
            periodSeconds = secsIn;
        } else if (secsIn <= 2 * periodLength) {
            period = `H2`;
            periodSeconds = secsIn - periodLength;
        } else {
            periodLength = 300;
            period = Math.floor((secsIn - regulationTime) / (overtimePeriodLength + 0.5)) + 1;
            periodSeconds = secsIn - regulationTime - (period - 1) * overtimePeriodLength;
            period = `OT${period}`;
        }
    }

    if (gender === 'FEMALE') {
        periodLength = 600; // 10 minutes per quarter, 600 seconds
        if (secsIn <= periodLength) {
            period = `Q1`;
            periodSeconds = secsIn;
        } else if (secsIn <= 2 * periodLength) {
            period = `Q2`;
            periodSeconds = secsIn - periodLength;
        } else if (secsIn <= 3 * periodLength) {
            period = `Q3`;
            periodSeconds = secsIn - 2 * periodLength;
        } else if (secsIn <= 4 * periodLength) {
            period = `Q4`;
            periodSeconds = secsIn - 3 * periodLength;
        } else {
            periodLength = 300;
            period = Math.floor((secsIn - regulationTime) / (overtimePeriodLength + 0.5)) + 1; // + 0.5 so that last second (secsIn: 2700) of OT1 counts as OT1
            periodSeconds = secsIn - regulationTime - (period - 1) * overtimePeriodLength;
            period = `OT${period}`;
        }
    }

    // flip for "clockType"
    if (clockType === 'secsLeft') {
        periodSeconds = periodLength - periodSeconds;
    }

    // Grab the initial MM and SS
    periodSeconds = Math.round(periodSeconds, 0);
    MM = Math.round(Math.floor(periodSeconds / 60), 2);
    SS = Math.round(periodSeconds % 60, 2);

    // Format MM and SS to be two digits
    MM = MM < 10 ? '0' + MM : MM;
    SS = SS < 10 ? '0' + SS : SS;

    // and return
    const output = `${period} ${MM}:${SS}`;
    return output;
}

// secondsToClock({ secsIn: 2400, gender: 'FEMALE', clockType: 'secsInto' }); //
// secondsToClock({ secsIn: 2400, gender: 'FEMALE', clockType: 'secsLeft' }) //
// secondsToClock({ secsIn: 1800, gender: 'FEMALE', clockType: 'secsInto' }) //
// secondsToClock({ secsIn: 1800, gender: 'FEMALE', clockType: 'secsLeft' }) //
