
import React from 'react';
import { Container } from 'react-bootstrap';

function NoMatch() {
    return (
        <Container className='shadow-container' style={{ maxWidth: 800, margin: '45px auto 25px' }}>
            <div>
                <h3 className='cbb-policy-top-header'>Error: 404</h3>
                <p><strong>The page you were trying to reach does not exist.</strong></p>
            </div>
            <div>
                <p style={{ marginTop: 15 }}>Please return back to the previous page from which you came.
                    If there is an issue with the website that led you to this page, which there may be, since you have arrived on an error page after all, you can send an email reporting the issue to info@cbbanalytics.com.</p>
            </div>
        </Container>
    );
}

export default NoMatch;
