
import * as Sentry from '@sentry/react';

// for troubleshooting what array is, due to t.filter() is not a function error
export function sentryIsArray({ arr, page, name }) {
    // array:       variable that should be an array
    if (!Array.isArray(arr)) {
        const error = new Error('Input is not an array');
        Sentry.captureException(error, {
            extra: {
                info: `${page} expected an array for ${name}`,
                receivedType: typeof arr,
                receivedValue: arr
            }
        });

        console.error('Expected an array, received:', arr);
    }
}


// for capturing errors in the codebase, with build in debounce mechanism to avoid spamming Sentry
const lastErrorTimestamps = {};
export function sentryCaptureError({ message, extra }) {
    // message:         error message to be sent to Sentry
    // extra:           additional information to be sent to Sentry

    // Throttle error reporting to once per 60 seconds for a given message
    const now = Date.now(); // Current timestamp in milliseconds
    const timeSinceLastError = now - (lastErrorTimestamps[message] || 0);
    if (timeSinceLastError < 60000) { // Check if the last error for this message was less than 60 seconds ago
        console.error(`Error reporting for "${message}" is throttled to once per 60 seconds`);
        return; // Exit without reporting the error if it's too soon
    }

    // If more than 60 seconds have passed since the last error with this message, report the error
    const error = new Error(`CBB Captured Error: ${message}`);
    Sentry.captureException(error, { extra });
    console.error('Caught an error in the codebase!');
    lastErrorTimestamps[message] = now; // Update the last error timestamp for this message
}
