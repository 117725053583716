
// 202.5 --> 206.8 (FIXING WHERE LW3 MEETS CENTER3)
// 157.5 --> 153.2 (FIXING WHERE CENTER3 MEETS RW2)
// 195.4 --> 199.3 (HEAVE ARC FIX)
// 164.6 --> 160.7 (HEAVE ARC FIX)
// 23.92 --> 24.52 (CENTER 3 PATH START/END Y)

// Links to NCAA Court Layouts
// critical measurements (e.g. lines are 2 inches) https://ballercoach.com/game-info/mens-ncaa-basketball-court-dimensions/
// mens new court https://ncaaorg.s3.amazonaws.com/championships/sports/basketball/rules/common/PRXBB_CourtDiagram.pdf
// mens old court https://web.archive.org/web/20210513115439/https://www.maplefloor.org/MFMAMainSite/media/Game-Markings-Files/NCAA-Basketball-Court-Diagram_FINAL_06212017-(003).pdf
// nba + intl court layouts https://www.recunlimited.com/blog/diagrams-basketball-courts/

const centerX = 25;
const centerY = 5.25; // center of hoop is 5.25
const rY = 20.75;
const rYN3 = 22.145833333;

// Shades (Zone + Dist) Helper Functions
let polarToCartesian = (radius, angleInDegrees) => {
    let angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
};

// eslint-disable-next-line max-params
let describeArc = (x, y, radius, startAngle, endAngle, isClockwise, isLargeArc, isNewLine) => {
    let start = polarToCartesian(radius, startAngle);
    let end = polarToCartesian(radius, endAngle);
    let largeArcFlag = isLargeArc === true ? '1' : '0';
    let firstLetter = isNewLine === true ? 'M' : 'L';

    let pathD;
    switch (isClockwise) {
        case true: pathD = [firstLetter, start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 1, end.x, end.y].join(' '); break;
        case false: pathD = [firstLetter, start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' '); break;
        default: pathD = [firstLetter, start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ');
    }

    return pathD;
};


// "Arcs" To Be Used Within Paths of the Various Zone Schema
// ==========================================================
// describeArc(x, y, radius, startAngle, endAngle, isClockwise, isLargeArc, isNewLine)
const restrictedAreaArc = describeArc(centerX, centerY, 4.5, 0.1, 359.9, true, true, false);

// Counter Clockwise (Main Direction)
// ====================================
// For Old 3PL
const lc3Arc = describeArc(centerX, centerY, rY, 270, 245.1, false, false, false); // left corner 3
const lw3Arc = describeArc(centerX, centerY, rY, 245.1, 208.8, false, false, false); // left wing 3
const c3Arc = describeArc(centerX, centerY, rY, 208.8, 151.2, false, false, false); // center 3
const rw3Arc = describeArc(centerX, centerY, rY, 151.2, 114.9, false, false, false); // right wing 3
const rc3Arc = describeArc(centerX, centerY, rY, 114.9, 90, false, false, false); // right corner 3
const threeArc = `${lc3Arc} ${lw3Arc} ${c3Arc} ${rw3Arc} ${rc3Arc}`;
const atbThreeArc = `${lw3Arc} ${c3Arc} ${rw3Arc}`;

const le2Arc = describeArc(centerX, centerY, rY, 245, 180, false, false, false); // left elbow 2
const re2Arc = describeArc(centerX, centerY, rY, 180, 115, false, false, false); // right elbow 2

const lh3Arc = describeArc(centerX, centerY, 30, 236.4, 195.4, false, false, false);
const ch3Arc = describeArc(centerX, centerY, 30, 195.4, 164.6, false, false, false);
const rh3Arc = describeArc(centerX, centerY, 30, 164.6, 123.6, false, false, false);
const heaveArc = `${lh3Arc} ${ch3Arc} ${rh3Arc}`;

// For new 3PL
const lc3ArcN3 = describeArc(centerX, centerY, rYN3, 255, 246.7, false, false, false); // left corner 3
const lw3ArcN3 = describeArc(centerX, centerY, rYN3, 246.7, 206.8, false, false, false); // left wing 3
const c3ArcN3 = describeArc(centerX, centerY, rYN3, 206.8, 153.2, false, false, false); // center 3
const rw3ArcN3 = describeArc(centerX, centerY, rYN3, 153.2, 113.3, false, false, false); // right wing 3
const rc3ArcN3 = describeArc(centerX, centerY, rYN3, 113.3, 105, false, false, false); // right corner 3
const threeArcN3 = `${lc3ArcN3} ${lw3ArcN3} ${c3ArcN3} ${rw3ArcN3} ${rc3ArcN3}`;
const atbThreeArcN3 = `${lw3ArcN3} ${c3ArcN3} ${rw3ArcN3}`;

const le2ArcN3 = describeArc(centerX, centerY, rYN3, 246.5, 180, false, false, false); // left elbow 2
const re2ArcN3 = describeArc(centerX, centerY, rYN3, 180, 113.5, false, false, false); // right elbow 2


// Clockwise (Secondary Direction)
// =================================
const lh3ArcB = describeArc(centerX, centerY, 30, 199.4, 236.4, true, false, false);
const ch3ArcB = describeArc(centerX, centerY, 30, 160.6, 199.4, true, false, false);
const rh3ArcB = describeArc(centerX, centerY, 30, 123.6, 160.6, true, false, false);
const heaveArcB = `${rh3ArcB} ${ch3ArcB} ${lh3ArcB}`;

const lh3ArcBN3 = describeArc(centerX, centerY, 30, 199.4, 236.4, true, false, false);
const ch3ArcBN3 = describeArc(centerX, centerY, 30, 160.6, 199.4, true, false, false);
const rh3ArcBN3 = describeArc(centerX, centerY, 30, 123.6, 160.6, true, false, false);
// const heaveArcBN3 = `${rh3ArcBN3} ${ch3ArcBN3} ${lh3ArcBN3}`;


// A: "zones6" Zone Schema
// ========================
// Paths for Old 3 Point Line
let atr2Path = `M25 0.75 ${restrictedAreaArc}`;
let paint2NoRimPath = `M31 19 L31 0 L25 0 ${restrictedAreaArc} L25 0 L19 0 L19 19 L31 19`;
let mid2Path = `M4.25 4.75 ${threeArc} L45.75 0 L31 0 L31 19 L19 19 L19 0 L4.25 0 L4.25 4.75`;
let c3Path = `M4.25 4.75 ${lc3Arc} L0 14 L0 0 L50 0 L50 14 L43.77 14 ${rc3Arc} L45.75 0 L4.25 0 L4.25 4.75`;
let atb3Path = `M0 14 ${atbThreeArc} L50 14 L50 21.3 ${heaveArcB} L0 14`;
let heave3Path = `M0 30 ${heaveArc} L50 38 L0 38 L0 30`;

// For New 3 Point Line
// New Mens DI 3PL: 22' 1.375"
// Corner Line Length is (9*12 + 10.375) / 12 == 9.86458333333 feet
// 3PL Distance is: (22*12 + 1.75) / 12 == 22.145833333 feet
// Distance to Sideline: (40.125 / 12)  = 3.34375 (for cleaner line, lets move this by 0.1)
let mid2PathN3 = `M3.34 9.86 ${threeArcN3} L46.66 0 L31 0 L31 19 L19 19 L19 0 L3.34 0 L3.34 9.86`;
let c3PathN3 = `M3.34 9.86 ${lc3ArcN3} L0 14 L0 0 L50 0 L50 14 L45.35 14 ${rc3ArcN3} L46.66 0 L3.34 0 L3.34 9.86`;
let atb3PathN3 = `M0 14 ${atbThreeArcN3} L50 14 L50 21.3 ${heaveArcB} L0 14`;

let zones6Array = {
    new3: [
        { acc: 'atr2', name: 'At The Rim', idx: 1, zoneValue: 2, textX: 25, textY: 7.25, textX2: 25, textY2: 2.75, fontSize: 1.6, path: atr2Path, distanceInfo: 'Within 4.5 feet of the rim' },
        { acc: 'paint2', name: 'In The Paint', idx: 2, zoneValue: 2, textX: 25, textY: 13.0, fontSize: 1.6, path: paint2NoRimPath, distanceInfo: 'Shots taken in the paint' },
        { acc: 'mid2', name: 'Midrange 2s', idx: 3, zoneValue: 2, textX: 11.5, textY: 7.5, fontSize: 1.6, path: mid2PathN3, distanceInfo: 'All 2s outside the paint' },
        { acc: 'atb3', name: 'Above Break 3s', idx: 4, zoneValue: 3, textX: 25, textY: 30.25, fontSize: 1.6, path: atb3PathN3, distanceInfo: 'Greater than 14 feet from baseline' },
        { acc: 'c3', name: 'Corner 3s', idx: 5, zoneValue: 3, textX: 48.3, textY: 7, textX2: 47.15, textY2: 13.3, fontSize: 1.5, rotation: 270, path: c3PathN3, distanceInfo: 'Within 14 feet of baseline' },
        { acc: 'heave3', name: 'Heaves', idx: 6, zoneValue: 3, textX: 3.6, textY: 30, fontSize: 1.5, path: heave3Path, distanceInfo: 'Longer than 30 feet' }
    ],
    old3: [
        { acc: 'atr2', name: 'At The Rim', idx: 1, zoneValue: 2, textX: 25, textY: 7.25, textX2: 25, textY2: 2.75, fontSize: 1.6, path: atr2Path, distanceInfo: 'Within 4.5 feet of the rim' },
        { acc: 'paint2', name: 'In The Paint', idx: 2, zoneValue: 2, textX: 25, textY: 13.0, fontSize: 1.6, path: paint2NoRimPath, distanceInfo: 'Shots taken in the paint' },
        { acc: 'mid2', name: 'Midrange 2s', idx: 3, zoneValue: 2, textX: 11.5, textY: 7.5, fontSize: 1.6, path: mid2Path, distanceInfo: 'All 2s outside the paint' },
        { acc: 'atb3', name: 'Above Break 3s', idx: 4, zoneValue: 3, textX: 25, textY: 29.25, fontSize: 1.6, path: atb3Path, distanceInfo: 'Greater than 14 feet from baseline' },
        { acc: 'c3', name: 'Corner 3s', idx: 5, zoneValue: 3, textX: 47.7, textY: 7, textX2: 47.15, textY2: 13.3, fontSize: 1.5, rotation: 270, path: c3Path, distanceInfo: 'Within 14 feet of baseline' },
        { acc: 'heave3', name: 'Heaves', idx: 6, zoneValue: 3, textX: 3.6, textY: 30, fontSize: 1.5, path: heave3Path, distanceInfo: 'Longer than 30 feet' }
    ]
};
// ======


// B: "zones13" Zone Schema
// ========================
// Old 3 Point Line
const leftCornerPath = `M4.25 0 ${lc3Arc} L0 14 L0 0 L4.25 0`;
const leftBaselinePath = `M4.25 0 ${lc3Arc} L19 14 L19 0 L4.25 0`;
const rightCornerPath = `M50 14 ${rc3Arc} L45.75 0 L50 0 L50 14`;
const rightBaselinePath = `M31 14 ${rc3Arc} L45.75 0 L31 0 L31 14`;
const leftElbowPath = `M19 14 ${le2Arc} L25 19 L19 19 L19 14`;
const rightElbowPath = `M31 14 L31 19 L25 19 ${re2Arc} L31 14`;
const leftWingThreePath = `M0 14 ${lw3Arc} ${lh3ArcB} L0 14`;
const rightWingThreePath = `M50 14 ${rh3ArcB} ${rw3Arc} L50 14`;
const centerThreePath = `M15 23.43 ${c3Arc} ${ch3ArcB} L15 23.43`;

// New 3 Point Line
const leftCornerPathN3 = `M3.34 0 ${lc3ArcN3} L0 14 L0 0 L3.34 0`;
const leftBaselinePathN3 = `M3.34 0 ${lc3ArcN3} L19 14 L19 0 L3.34 0`;
const rightCornerPathN3 = `M50 14 ${rc3ArcN3} L46.66 0 L50 0 L50 14`;
const rightBaselinePathN3 = `M31 14 ${rc3ArcN3} L46.66 0 L31 0 L31 14`;
const leftElbowPathN3 = `M19 14 ${le2ArcN3} L25 19 L19 19 L19 14`;
const rightElbowPathN3 = `M31 14 L31 19 L25 19 ${re2ArcN3} L31 14`;
const leftWingThreePathN3 = `M0 14 ${lw3ArcN3} ${lh3ArcBN3} L0 14`;
const rightWingThreePathN3 = `M50 14 ${rh3ArcBN3} ${rw3ArcN3} L50 14`;
const centerThreePathN3 = `M15 24.92 ${c3ArcN3} ${ch3ArcBN3} L15 24.92`;

let zones13Array = {
    new3: [
        { acc: 'atr2', name: 'At The Rim', idx: 1, zoneValue: 2, textX: 25, textY: 7.25, textX2: 25, textY2: 2.75, fontSize: 1.6, path: atr2Path, distanceInfo: 'Within 4.5 feet of the rim' },
        { acc: 'paint2', name: 'In The Paint', idx: 2, zoneValue: 2, textX: 25, textY: 14, fontSize: 1.6, path: paint2NoRimPath, distanceInfo: 'Shots taken in the paint' },
        { acc: 'lb2', name: 'Left Baseline 2', idx: 3, zoneValue: 2, textX: 12, textY: 4.5, fontSize: 1.6, path: leftBaselinePathN3, distanceInfo: 'Within 14 feet of baseline' },
        { acc: 'rb2', name: 'Right Baseline 2', idx: 4, zoneValue: 2, textX: 38, textY: 4.5, fontSize: 1.6, path: rightBaselinePathN3, distanceInfo: 'Within 14 feet of baseline' },
        { acc: 'le2', name: 'Left Elbow 2', idx: 5, zoneValue: 2, textX: 13.5, textY: 17.5, fontSize: 1.6, path: leftElbowPathN3, distanceInfo: 'Left side, beyond paint & baseline' },
        { acc: 're2', name: 'Right Elbow 2', idx: 6, zoneValue: 2, textX: 36.5, textY: 17.5, fontSize: 1.6, path: rightElbowPathN3, distanceInfo: 'Right side, beyond paint & baseline' },
        { acc: 'lw3', name: 'Left Wing 3', idx: 9, zoneValue: 3, textX: 8, textY: 24.25, fontSize: 1.6, path: leftWingThreePathN3, distanceInfo: 'Within 15 feet of left sideline' },
        { acc: 'rw3', name: 'Right Wing 3', idx: 10, zoneValue: 3, textX: 42, textY: 24.25, fontSize: 1.6, path: rightWingThreePathN3, distanceInfo: 'Within 15 feet of right sideline' },
        { acc: 'tok3', name: 'Top of Key 3', idx: 11, zoneValue: 3, textX: 25, textY: 30.25, fontSize: 1.6, path: centerThreePathN3, distanceInfo: 'Center 20 feet of court' },
        { acc: 'lc3', name: 'Left Corner 3', idx: 7, zoneValue: 3, textX: 1.9, textY: 7, textX2: 2.5, textY2: 13.5, fontSize: 1.5, rotation: 270, path: leftCornerPathN3, distanceInfo: 'Within 14 feet of baseline' },
        { acc: 'rc3', name: 'Right Corner 3', idx: 8, zoneValue: 3, textX: 48.3, textY: 7, textX2: 47.5, textY2: 13.5, fontSize: 1.5, rotation: 270, path: rightCornerPathN3, distanceInfo: 'Within 14 feet of baseline' },
        { acc: 'heave3', name: 'Heaves', idx: 12, zoneValue: 3, textX: 3.6, textY: 30, fontSize: 1.5, path: heave3Path, distanceInfo: 'Longer than 30 feet' }
    ],
    old3: [
        { acc: 'atr2', name: 'At The Rim', idx: 1, zoneValue: 2, textX: 25, textY: 7.25, textX2: 25, textY2: 2.75, fontSize: 1.6, path: atr2Path, distanceInfo: 'Within 4.5 feet of the rim' },
        { acc: 'paint2', name: 'In The Paint', idx: 2, zoneValue: 2, textX: 25, textY: 13, fontSize: 1.6, path: paint2NoRimPath, distanceInfo: 'Shots taken in the paint' },
        { acc: 'lb2', name: 'Left Baseline 2', idx: 3, zoneValue: 2, textX: 12, textY: 4.5, fontSize: 1.6, path: leftBaselinePath, distanceInfo: 'Within 14 feet of baseline' },
        { acc: 'rb2', name: 'Right Baseline 2', idx: 4, zoneValue: 2, textX: 38, textY: 4.5, fontSize: 1.6, path: rightBaselinePath, distanceInfo: 'Within 14 feet of baseline' },
        { acc: 'le2', name: 'Left Elbow 2', idx: 5, zoneValue: 2, textX: 13.75, textY: 17.75, fontSize: 1.6, path: leftElbowPath, distanceInfo: 'Left side, beyond paint & baseline' },
        { acc: 're2', name: 'Right Elbow 2', idx: 6, zoneValue: 2, textX: 36.25, textY: 17.75, fontSize: 1.6, path: rightElbowPath, distanceInfo: 'Right side, beyond paint & baseline' },
        { acc: 'lw3', name: 'Left Wing 3', idx: 9, zoneValue: 3, textX: 8, textY: 23, fontSize: 1.6, path: leftWingThreePath, distanceInfo: 'Within 15 feet of left sideline' },
        { acc: 'rw3', name: 'Right Wing 3', idx: 10, zoneValue: 3, textX: 42, textY: 23, fontSize: 1.6, path: rightWingThreePath, distanceInfo: 'Within 15 feet of right sideline' },
        { acc: 'tok3', name: 'Top of Key 3', idx: 11, zoneValue: 3, textX: 25, textY: 29.5, fontSize: 1.6, path: centerThreePath, distanceInfo: 'Center 20 feet of court' },
        { acc: 'lc3', name: 'Left Corner 3', idx: 7, zoneValue: 3, textX: 2.3, textY: 7, textX2: 2.85, textY2: 13.3, fontSize: 1.5, rotation: 270, path: leftCornerPath, distanceInfo: 'Within 14 feet of baseline' },
        { acc: 'rc3', name: 'Right Corner 3', idx: 8, zoneValue: 3, textX: 47.9, textY: 7, textX2: 47.15, textY2: 13.3, fontSize: 1.5, rotation: 270, path: rightCornerPath, distanceInfo: 'Within 14 feet of baseline' },
        { acc: 'heave3', name: 'Heaves', idx: 12, zoneValue: 3, textX: 3.6, textY: 30, fontSize: 1.5, path: heave3Path, distanceInfo: 'Longer than 30 feet' }
    ]
};
// ======


// C: "zones17" Zone Schema
// ========================
const shortLeftArc = describeArc(centerX, centerY, 4.5, 286.2, 180, false, false, false);
const shortRightArc = describeArc(centerX, centerY, 4.5, 180, 73.8, false, false, false);
const behindHoopArc = describeArc(centerX, centerY, 4.5, 286.2, 73.8, true, false, false);

const shortLeftPaintPath = `M20.6 3.95 ${shortLeftArc} L25 12 L19 12 L19 4 L20.65 4`;
const shortRightPaintPath = `M25 9.75 ${shortRightArc} L31 4 L31 12 L25 12 L25 9.75`;
const farLeftPaintPath = `M19 19 L25 19 L25 12 L19 12 L19 19`;
const farRightPaintPath = `M31 19 L25 19 L25 12 L31 12 L31 19`;
const behindHoopPath = `M19 0 L19 4 L20.65 4 ${behindHoopArc} L31 4 L31 0 L19 0`;

let zones17Array = {
    new3: [
        { acc: 'atr2', name: 'At The Rim', idx: 1, zoneValue: 2, textX: 25, textY: 7.25, textX2: 25, textY2: 2.75, fontSize: 1.5, path: atr2Path, distanceInfo: 'Within 4.5 feet of the rim' },
        { acc: 'behindHoop', name: 'Behind Hoop', idx: 2, zoneValue: 2, textX: 21.45, textY: 1.18, textX2: 28.95, textY2: 1.18, fontSize: 1.2, path: behindHoopPath, distanceInfo: 'Within 4.25 feet of baseline' },
        { acc: 'slp2', name: 'Short Left Paint 2', idx: 3, zoneValue: 2, textX: 22, textY: 9.8, fontSize: 1.35, path: shortLeftPaintPath, distanceInfo: 'Left side, 0 - 8 feet from backboard' },
        { acc: 'srp2', name: 'Short Right Paint 2', idx: 4, zoneValue: 2, textX: 28, textY: 9.8, fontSize: 1.35, path: shortRightPaintPath, distanceInfo: 'Right side, 0 - 8 feet from backboard' },
        { acc: 'flp2', name: 'Far Left Paint 2', idx: 5, zoneValue: 2, textX: 22, textY: 15.9, fontSize: 1.35, path: farLeftPaintPath, distanceInfo: 'Left side, 8 - 15 feet from backboard' },
        { acc: 'frp2', name: 'Far Right Paint 2', idx: 6, zoneValue: 2, textX: 28, textY: 15.9, fontSize: 1.35, path: farRightPaintPath, distanceInfo: 'Left side, 8 - 15 feet from backboard' },
        { acc: 'lb2', name: 'Left Baseline 2', idx: 7, zoneValue: 2, textX: 12, textY: 5, fontSize: 1.5, path: leftBaselinePathN3, distanceInfo: 'Within 14 feet of baseline' },
        { acc: 'rb2', name: 'Right Baseline 2', idx: 8, zoneValue: 2, textX: 38, textY: 5, fontSize: 1.5, path: rightBaselinePathN3, distanceInfo: 'Within 14 feet of baseline' },
        { acc: 'le2', name: 'Left Elbow 2', idx: 9, zoneValue: 2, textX: 13.75, textY: 18.25, fontSize: 1.5, path: leftElbowPathN3, distanceInfo: 'Left side, beyond paint & baseline' },
        { acc: 're2', name: 'Right Elbow 2', idx: 10, zoneValue: 2, textX: 36.25, textY: 18.25, fontSize: 1.5, path: rightElbowPathN3, distanceInfo: 'Right side, beyond paint & baseline' },
        { acc: 'lw3', name: 'Left Wing 3', idx: 13, zoneValue: 3, textX: 8, textY: 24.75, fontSize: 1.5, path: leftWingThreePathN3, distanceInfo: 'Within 15 feet of left sideline' },
        { acc: 'rw3', name: 'Right Wing 3', idx: 14, zoneValue: 3, textX: 42, textY: 24.75, fontSize: 1.5, path: rightWingThreePathN3, distanceInfo: 'Within 15 feet of right sideline' },
        { acc: 'tok3', name: 'Top of Key 3', idx: 15, zoneValue: 3, textX: 25, textY: 30.75, fontSize: 1.5, path: centerThreePathN3, distanceInfo: 'Center 20 feet of court' },
        { acc: 'lc3', name: 'Left Corner 3', idx: 11, zoneValue: 3, textX: 1.9, textY: 7, textX2: 2.5, textY2: 13.5, fontSize: 1.5, rotation: 270, path: leftCornerPathN3, distanceInfo: 'Within 14 feet of baseline' },
        { acc: 'rc3', name: 'Right Corner 3', idx: 12, zoneValue: 3, textX: 48.3, textY: 7, textX2: 47.5, textY2: 13.5, fontSize: 1.5, rotation: 270, path: rightCornerPathN3, distanceInfo: 'Within 14 feet of baseline' },
        { acc: 'heave3', name: 'Heaves', idx: 16, zoneValue: 3, textX: 3.6, textY: 30, fontSize: 1.5, path: heave3Path, distanceInfo: 'Longer than 30 feet' }
    ],
    old3: [
        { acc: 'atr2', name: 'At The Rim', idx: 1, zoneValue: 2, textX: 25, textY: 7.25, textX2: 25, textY2: 2.75, fontSize: 1.5, path: atr2Path, distanceInfo: 'Within 4.5 feet of the rim' },
        { acc: 'behindHoop', name: 'Behind Hoop', idx: 2, zoneValue: 2, textX: 21.45, textY: 1.18, textX2: 28.95, textY2: 1.18, fontSize: 1.2, path: behindHoopPath, distanceInfo: 'Within 4.25 feet of baseline' },
        { acc: 'slp2', name: 'Short Left Paint 2', idx: 3, zoneValue: 2, textX: 22, textY: 9.8, fontSize: 1.35, path: shortLeftPaintPath, distanceInfo: 'Left side, 0 - 8 feet from backboard' },
        { acc: 'srp2', name: 'Short Right Paint 2', idx: 4, zoneValue: 2, textX: 28, textY: 9.8, fontSize: 1.35, path: shortRightPaintPath, distanceInfo: 'Right side, 0 - 8 feet from backboard' },
        { acc: 'flp2', name: 'Far Left Paint 2', idx: 5, zoneValue: 2, textX: 22, textY: 15.9, fontSize: 1.35, path: farLeftPaintPath, distanceInfo: 'Left side, 8 - 15 feet from backboard' },
        { acc: 'frp2', name: 'Far Right Paint 2', idx: 6, zoneValue: 2, textX: 28, textY: 15.9, fontSize: 1.35, path: farRightPaintPath, distanceInfo: 'Left side, 8 - 15 feet from backboard' },
        { acc: 'lb2', name: 'Left Baseline 2', idx: 7, zoneValue: 2, textX: 12, textY: 5, fontSize: 1.5, path: leftBaselinePath, distanceInfo: 'Within 14 feet of baseline' },
        { acc: 'rb2', name: 'Right Baseline 2', idx: 8, zoneValue: 2, textX: 38, textY: 5, fontSize: 1.5, path: rightBaselinePath, distanceInfo: 'Within 14 feet of baseline' },
        { acc: 'le2', name: 'Left Elbow 2', idx: 9, zoneValue: 2, textX: 13.75, textY: 17.75, fontSize: 1.5, path: leftElbowPath, distanceInfo: 'Left side, beyond paint & baseline' },
        { acc: 're2', name: 'Right Elbow 2', idx: 10, zoneValue: 2, textX: 36.25, textY: 17.75, fontSize: 1.5, path: rightElbowPath, distanceInfo: 'Right side, beyond paint & baseline' },
        { acc: 'lw3', name: 'Left Wing 3', idx: 13, zoneValue: 3, textX: 8, textY: 23.5, fontSize: 1.5, path: leftWingThreePath, distanceInfo: 'Within 15 feet of left sideline' },
        { acc: 'rw3', name: 'Right Wing 3', idx: 14, zoneValue: 3, textX: 42, textY: 23.5, fontSize: 1.5, path: rightWingThreePath, distanceInfo: 'Within 15 feet of right sideline' },
        { acc: 'tok3', name: 'Top of Key 3', idx: 15, zoneValue: 3, textX: 25, textY: 30, fontSize: 1.5, path: centerThreePath, distanceInfo: 'Center 20 feet of court' },
        { acc: 'lc3', name: 'Left Corner 3', idx: 11, zoneValue: 3, textX: 2.3, textY: 7, textX2: 2.85, textY2: 13.3, fontSize: 1.5, rotation: 270, path: leftCornerPath, distanceInfo: 'Within 14 feet of baseline' },
        { acc: 'rc3', name: 'Right Corner 3', idx: 12, zoneValue: 3, textX: 47.9, textY: 7, textX2: 47.15, textY2: 13.3, fontSize: 1.5, rotation: 270, path: rightCornerPath, distanceInfo: 'Within 14 feet of baseline' },
        { acc: 'heave3', name: 'Heaves', idx: 16, zoneValue: 3, textX: 3.6, textY: 30, fontSize: 1.5, path: heave3Path, distanceInfo: 'Longer than 30 feet' }
    ]
};
// ======


// C: "dist7" Zone Schema
// =======================
// describeArc(x, y, radius, startAngle, endAngle, isClockwise, isLargeArc, isNewLine)
// let atr2Path = `${describeArc(centerX, centerY, 4.00, 20, 340, true, true, true)}`;
let sht2Path = `M25 0 ${describeArc(centerX, centerY, 4.5, 0.1, 359.9, true, true, false)} L25 0 ${describeArc(centerX, centerY, 10, 301.7, 58.3, false, true, false)} L25 0`;
let med2Path = `${describeArc(centerX, centerY, 10, 301.7, 58.3, false, true, true)} ${describeArc(centerX, centerY, 15, 69.5, 290.5, true, true, false)} L16.45 0`;
let lng2Path = `${describeArc(centerX, centerY, 15, 69.5, 290.5, true, true, true)} L4.25 0 ${threeArc} L45.75 0 L39.15 0`;
let sht3Path = `M4.25 0 ${threeArc} L45.75 0 L50 0 ${describeArc(centerX, centerY, 25, 90, 270, true, true, false)} L0 0 L4.25 0`;
let lng3Path = `M50 5.25 ${describeArc(centerX, centerY, 25, 90, 270, true, true, false)} ${describeArc(centerX, centerY, 30, 236.4, 123.6, false, false, false)} L50 5.25`;

// For New 3PL
let lng2PathN3 = `${describeArc(centerX, centerY, 15, 69.5, 290.5, true, true, true)} L3.34 0 ${threeArcN3} L46.66 0 L39.15 0`;
let sht3PathN3 = `M3.34 0 ${threeArcN3} L46.66 0 L50 0 ${describeArc(centerX, centerY, 25, 90, 270, true, true, false)} L0 0 L3.34 0`;

let dists7Array = {
    new3: [
        { acc: 'atr2', name: 'At The Rim', idx: 1, zoneValue: 2, textX: 25, textY: 7.25, textX2: 25, textY2: 2.75, fontSize: 1.6, path: atr2Path, distanceInfo: 'Within 4.5 feet of the rim' },
        { acc: 'sht2', name: 'Short Dist 2s', idx: 2, zoneValue: 2, textX: 25, textY: 11.3, fontSize: 1.6, path: sht2Path, distanceInfo: '4 - 10 feet from the rim' },
        { acc: 'med2', name: 'Medium Dist 2s', idx: 3, zoneValue: 2, textX: 25, textY: 16.9, fontSize: 1.6, path: med2Path, distanceInfo: '10 - 15 feet from the rim' },
        { acc: 'lng2', name: 'Long Dist 2s', idx: 4, zoneValue: 2, textX: 25, textY: 22.7, fontSize: 1.6, path: lng2PathN3, distanceInfo: '2s longer than 15 feet' },
        { acc: 'sht3', name: 'Short Dist 3s', idx: 5, zoneValue: 3, textX: 25, textY: 28.7, textX2: 31, textY2: 28.5, fontSize: 1.6, path: sht3PathN3, distanceInfo: '3s shorter than 25 feet' },
        { acc: 'lng3', name: 'Long Dist 3s', idx: 6, zoneValue: 3, textX: 25, textY: 31.9, fontSize: 1.6, path: lng3Path, distanceInfo: '25 - 30 feet from the rim' },
        { acc: 'heave3', name: 'Heaves', idx: 7, zoneValue: 3, textX: 3.6, textY: 30, fontSize: 1.5, path: heave3Path, distanceInfo: 'Longer than 30 feet' }
    ],
    old3: [
        { acc: 'atr2', name: 'At The Rim', idx: 1, zoneValue: 2, textX: 25, textY: 7.25, textX2: 25, textY2: 2.75, fontSize: 1.6, path: atr2Path, distanceInfo: 'Within 4.5 feet of the rim' },
        { acc: 'sht2', name: 'Short Dist 2s', idx: 2, zoneValue: 2, textX: 25, textY: 11.3, fontSize: 1.6, path: sht2Path, distanceInfo: '4 - 10 feet from the rim' },
        { acc: 'med2', name: 'Medium Dist 2s', idx: 3, zoneValue: 2, textX: 25, textY: 16.9, fontSize: 1.6, path: med2Path, distanceInfo: '10 - 15 feet from the rim' },
        { acc: 'lng2', name: 'Long Dist 2s', idx: 4, zoneValue: 2, textX: 25, textY: 22.4, fontSize: 1.6, path: lng2Path, distanceInfo: '2s longer than 15 feet' },
        { acc: 'sht3', name: 'Short Dist 3s', idx: 5, zoneValue: 3, textX: 25, textY: 27.3, fontSize: 1.6, path: sht3Path, distanceInfo: '3s shorter than 25 feet' },
        { acc: 'lng3', name: 'Long Dist 3s', idx: 6, zoneValue: 3, textX: 25, textY: 31.9, fontSize: 1.6, path: lng3Path, distanceInfo: '25 - 30 feet from the rim' },
        { acc: 'heave3', name: 'Heaves', idx: 7, zoneValue: 3, textX: 3.6, textY: 30, fontSize: 1.5, path: heave3Path, distanceInfo: 'Longer than 30 feet' }
    ]
};
// ======


// Covert to Objects
const zoneIdxs = {
    atr2: 1,
    paint2: 2,
    mid2: 3,
    atb3: 4,
    c3: 5,
    lb2: 6,
    rb2: 7,
    le2: 8,
    re2: 9,
    lc3: 10,
    rc3: 11,
    lw3: 12,
    rw3: 13,
    tok3: 14,
    slp2: 15,
    srp2: 16,
    flp2: 17,
    frp2: 18,
    behindHoop: 19,
    sht2: 20,
    med2: 21,
    lng2: 22,
    sht3: 23,
    lng3: 24,
    heave3: 25
};

module.exports = {
    zoneIdxs: zoneIdxs,
    zones6Array: zones6Array, // zones6
    zones13Array: zones13Array, // zones13
    zones17Array: zones17Array, // zones17
    dists7Array: dists7Array // dists7
};
