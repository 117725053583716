import React from 'react';
import { RingLoader } from 'react-spinners';

function LoadingSpinner({
    wrapperStyle = {},
    size = 'big',
    width = null,
    text = 'statistics loading',
    color = '#0066CC'
}) {
    // Create Small Loading Bar
    let smallLoadingBar =
        (<div style={{ margin: '10px 3px', display: 'flex', alignItems: 'flex-start', ...wrapperStyle }}>
            <RingLoader
                size={width || 20}
                color={color}
                loading={true}
                speedMultiplier={1}
            />
            <p style={{ zIndex: 10, margin: 0, paddingLeft: 4, fontStyle: 'italic', fontSize: '0.95em' }}>{text}</p>
        </div>);

    let bigLoadingBar =
        (<div style={{ margin: '35px 5px 5px 5px', display: 'flex', alignItems: 'flex-start', height: 1500, justifyContent: 'center', ...wrapperStyle }}>
            <RingLoader
                size={width || 34}
                color={color}
                loading={true}
                speedMultiplier={1}
            />
            <p style={{ zIndex: 10, margin: 0, paddingLeft: 8, fontStyle: 'italic', fontSize: '1.4em' }}>{text}</p>
        </div>);


    // let smallLoadingBar =
    //     (<div style={{ margin: '10px 3px', display: 'flex', alignItems: 'center', ...wrapperStyle }}>
    //         <RingLoader
    //             size={40}
    //             color={color}
    //             speedMultiplier={0.9}
    //         />
    //     </div>);

    // let bigLoadingBar =
    //     (<div style={{ margin: '25px 5px 5px 5px', display: 'flex', alignItems: 'flex-start', justifyContent: 'center', height: 1500, ...wrapperStyle }}>
    //         <RingLoader
    //             size={50}
    //             color={color}
    //             speedMultiplier={0.9}
    //         />
    //     </div>);

    return (
        <React.Fragment>
            {size === 'big' ? bigLoadingBar : smallLoadingBar}
        </React.Fragment>
    );
}

export default LoadingSpinner;
