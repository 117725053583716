import React, { useEffect, useState } from 'react';
import useScreenSize from '../../../hooks/useScreenSize';
import useContainerWidth from '../../../hooks/useContainerWidth';

const Pagination = ({ reactTable }) => {
    const {
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageSize }
    } = reactTable;

    // set constants
    const pageSizeOptions = [8, 10, 15, 20, 25, 30, 35, 50, 100, 200, 500];

    // useScreenSize, and useState track page state
    const { isXs } = useScreenSize();
    const [currentPageIndex, setPageIndex] = useState(
        reactTable.state.pageIndex + 1
    );

    // useContainerWidth to get width of pagination bar
    const [containerWidth, containerRef] = useContainerWidth();
    // console.log('containerWidth: ', containerWidth);

    // useEffect to set page index to 1 ??
    useEffect(
        () => {
            if (pageCount === 0 && currentPageIndex !== 1) {
                setPageIndex(1);
            } else if (pageCount !== 0 && currentPageIndex > pageCount) {
                setPageIndex(pageCount);
            }
        },
        [currentPageIndex, pageCount]
    );

    const handleOnPageChange = (pageIndex) => {
        setPageIndex(pageIndex + 1);
        gotoPage(pageIndex);
    };

    const handlePreviousPage = () => {
        previousPage();
        setPageIndex(currentPageIndex - 1);
    };

    const handleNextPage = () => {
        nextPage();
        setPageIndex(currentPageIndex + 1);
    };

    return (
        <div className='pagination-row' ref={containerRef}>
            <div className='pagination-row__panel'>
                {/* Button Group */}
                <div className='pagination-row__button-group'>
                    <button
                        className='pagination-row__button'
                        onClick={() => handleOnPageChange(0)}
                        disabled={currentPageIndex <= 1}
                    >
                        {'<<'}
                    </button>
                    <button
                        className='pagination-row__button'
                        onClick={() => handlePreviousPage()}
                        disabled={currentPageIndex <= 1}
                    >
                        {'<'}
                    </button>
                    <button
                        className='pagination-row__button'
                        onClick={() => handleNextPage()}
                        disabled={currentPageIndex >= pageCount}
                    >
                        {'>'}
                    </button>
                    <button
                        className='pagination-row__button'
                        onClick={() => handleOnPageChange(pageCount - 1)}
                        disabled={currentPageIndex >= pageCount}
                    >
                        {'>>'}
                    </button>
                </div>

                {/* Page Text */}
                <span className='pagination-row__span1'>
                    {isXs || containerWidth < 435 ? '' : `Page `}
                    <strong>
                        {isXs
                            ? `${currentPageIndex}/${pageCount}`
                            : `${currentPageIndex} of ${pageCount}`
                        }
                    </strong>{' '}
                </span>

                {/* Go To Page Tool */}
                <span className='pagination-row__span2'>
                    {isXs || containerWidth < 435 ? '| To ' : '| Go to page: '}
                    <input
                        type='number'
                        defaultValue='1'
                        onChange={(e) => {
                            const newPageNumber = e.target.value
                                ? Number(e.target.value)
                                : 1;
                            handleOnPageChange(newPageNumber - 1);
                        }}
                        style={{ width: 32 }}
                    />
                </span>{' '}

                {/* Page Size Dropdown */}
                <select
                    value={pageSize}
                    onChange={(e) => setPageSize(Number(e.target.value))}
                >
                    {pageSizeOptions.map((option) => (
                        <option key={option} value={option}>
                            Show {option}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default Pagination;
