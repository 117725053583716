
// "npm run start" to run cbb-streaming locally

// Remaining Streaming to dos for 2nd pass for Big Ten guys
//      memoize big data analytics to improve performance. perf test this page.
//      team & player shooting by zone tables?
//      text descriptions?
//      freeze it by half for the shot charts (cannot do for the tables / box scores)

// The problem is that the big Game Selection page AND the individual game page are BOTH HANDLED IN THIS COMPONENT...

// Import React Components
import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import useCBBQuery from '../../hooks/useCBBQuery';
import CBBSelect from '../../components/selects/CBBSelect';
import CBBPusher from '../../components/selects/CBBPusher';
import CBBLiveSelect from '../../components/selects/CBBLiveSelect';
import ShotChartsD3 from '../../components/d3Graphs/ShotChartsD3';
import Logo from '../../components/uiUxItems/Logo';
import TextHeaders from '../../components/headers/TextHeaders';
import { enhRawPbp, getGameFlowLineups, getGameFlowPlayers } from '../../utils/EnhanceRawData';

// Fetch from Streaming API!!
import useLiveStreamApi from '../../hooks/useLiveStreamApi';
import LongTable from '../../components/tables/groupings/LongTable';
import PlayerGameStatsTable from '../../components/tables/groupings/PlayerGameStatsTable';
import CBBMultiSelect from '../../components/selects/CBBMultiSelect';
import ControllerNavbar from '../../components/navbars/ControllerNavbar';
import TeamGameStatsTable from '../../components/tables/groupings/TeamGameStatsTable';
import D3LineupFlow from '../../components/d3Graphs/D3LineupFlow';
import D3LineupFlowV2 from '../../components/d3Graphs/D3LineupFlowV2';
import GameCard from '../../components/cards/GameCard';
import { applyPlayerPctiles, arrayToObject, filterAndSortArray, leftJoinPctiles, formatAMPM, pivotTesting, getPostGameTotalsRows, getPriorCompetitionId } from '../../utils/ReshapeData';
import { useContext } from 'react';
import GlobalContext from '../../context/GlobalContext';
import NotSubscribed from '../../components/logins/NotSubscribed';

function getTodaysDate() {
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1; // Add 1 because months are 0-indexed
    let day = today.getDate();

    // Pad the month and day with a leading zero if they are less than 10
    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    let formattedDate = year + '-' + month + '-' + day;
    return formattedDate;
}

function StatusBar({ statusObject }) {
    // handle no status
    if (!statusObject || Object.keys(statusObject).length === 0) {
        return null;
    }

    // util function for formatting the timestamp
    const formatTimestamp = (timestamp) => {
        if (!timestamp) { return 'Loading...'; }
        const date = new Date(timestamp);
        return date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
            timeZone: 'America/New_York'
        }) + ' ET'; // Manually append ET since it's always Eastern Time
    };

    const { homeMarket, awayMarket, clock, clockRunning, gameDate, period, periodStatus, scores, status, summary, updated } = statusObject;
    // const updatedLabel = updated ? updated.toString() : 'Loading...';
    const updatedLabel = updated ? formatTimestamp(updated) : 'Loading...';
    const scoreString = scores && scores.length === 2 ? `${scores[0].score} - ${scores[1].score}` : 'Loading...';
    const periodString = period ? `${period.current}, ${period.periodType}, ${periodStatus}` : 'Loading...';
    const foulsString = summary && Object.keys(summary).length === 2 ? `${summary['1'].fouls} - ${summary['2'].fouls}` : 'Loading...';
    const clockString = `${clock} ${clockRunning === 1 ? 'Running' : 'Not Running'}`;
    const gameInfoString = `${gameDate}: ${homeMarket} vs ${awayMarket}`;
    return (<>
        <Row style={{ margin: 0, marginTop: 15 }}>
            <TextHeaders className='print-break'>
                Streaming Game Status
            </TextHeaders>
        </Row>
        <Row style={{ margin: 0, marginTop: 5, flexDirection: 'column', lineHeight: 1.3 }}>
            <p style={{ fontWeight: 700 }}>Matchup: <span style={{ fontWeight: 300 }}>{gameInfoString}</span></p>
            <p style={{ fontWeight: 700 }}>Status: <span style={{ fontWeight: 300 }}>{status}</span></p>
            <p style={{ fontWeight: 700 }}>Period: <span style={{ fontWeight: 300 }}>{periodString}</span></p>
            <p style={{ fontWeight: 700 }}>Clock: <span style={{ fontWeight: 300 }}>{clockString}</span></p>
            <p style={{ fontWeight: 700 }}>Scores: <span style={{ fontWeight: 300 }}>{scoreString}</span></p>
            <p style={{ fontWeight: 700 }}>Fouls: <span style={{ fontWeight: 300 }}>{foulsString}</span></p>
            <p style={{ fontWeight: 700 }}>Last Updated: <span style={{ fontWeight: 300 }}>{updatedLabel}</span></p>
        </Row>
    </>);
}

// Create The Component
function StreamTesting() {
    // grab params + grab test gameId
    const params = useParams();
    const gameId = params.gameId ? params.gameId : null;
    const isTestGame = gameId === '251383';

    // if not tier 3 or higher, return
    const { userTier } = useContext(GlobalContext);
    if (userTier.value <= 2) {
        return (
            <Container className='shadow-container' style={{ padding: 0 }}>
                <NotSubscribed
                    errorType='needsTier3'
                    page='streaming'
                />
            </Container>
        );
    }

    // useState
    const [competitionInfo, setCompetitionInfo] = useState(null);
    const [division, setDivision] = useState({ value: 1, label: 'Division I' });
    const [conferences, setConferences] = useState([]);
    const conferenceValues = conferences.map(row => row.conferenceId);
    const [teamInfo, setTeamInfo] = useState(null);
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [tableType, setTableType] = useState({ value: 'basic', label: 'Traditional Box Stats', header: 'Traditional Boxscore Stats' });
    const [chartType, setChartType] = useState({ value: 'hex', label: 'Heat Map Graph' });
    const [zoneSchema, setZoneSchema] = useState({ value: 'zones6', label: 'Simple Zones' });
    const [zoneMetric, setZoneMetric] = useState({ value: 'absFgPct', label: 'Total FG%, FGA%', fgPct: 'absFgPct', fgaFreq: 'absFgaFreq' });
    const [showFgmFga, setShowFgmFga] = useState({ value: true, label: 'Show FGM/FGA' });
    const [gameTweet, setGameTweet] = useState({ value: null, label: 'Select Graphic' });

    // Fetch Streaming Data!
    let { pbpData, teamInfos, playerInfos, gameInfo, teamBoxScores, playerBoxScores, status, connectionStatus } = useLiveStreamApi({ gameId, pass: !gameId });
    const isStreamLoading = Object.keys(status).length === 0 || playerBoxScores.length === 0 || teamBoxScores.length === 0 || pbpData.length === 0;
    const gameInfoFetched = Object.keys(gameInfo).length > 0;
    // console.log('liveStream returned: ', { isStreamLoading, pbpData, teamInfos, playerInfos, gameInfo, teamBoxScores, playerBoxScores, status, connectionStatus });

    // // If paramsGameId passed, immediately fetch game info
    // const { data: gameInfo = {}, isLoading: isLoading1, isError: isError1 } = useCBBQuery({ ep: 'games', cfg: { gameId: paramsGameId, pass: !paramsGameId } });
    let { homeId, awayId, homeMarket, awayMarket, homeName, awayName, homeRecord, awayRecord, gameTimeUtc } = gameInfo || {};
    if (isTestGame) {
        homeId = 22277; homeMarket = 'Duke'; homeName = 'Blue Devils';
        awayId = 22576; awayMarket = 'Louisville'; awayName = 'Cardinals';
        gameInfo.homeMarket = 'Duke'; gameInfo.homeName = 'Blue Devils';
        gameInfo.homeOverallWins = 5; gameInfo.homeOverallLosses = 1; gameInfo.homeConfWins = 2; gameInfo.homeConfLosses = 0;
        gameInfo.awayMarket = 'Louisville'; gameInfo.awayName = 'Cardinals';
        gameInfo.awayOverallWins = 4; gameInfo.awayOverallLosses = 2; gameInfo.awayConfWins = 2; gameInfo.awayConfLosses = 1;
    }

    // Set constants depending on params & options selected
    const competitionId = competitionInfo?.value || gameInfo?.competitionId || null;
    const teamId = teamInfo && teamInfo._id ? parseInt(teamInfo._id.replace('team-', ''), 10) : null;
    const divisionId = 1; // jo mire (select team, get divisionId + other team info)
    const todaysDate = getTodaysDate();

    // use last year's competitionId if recent exhibition game (within 5 weeks of current date) for pctiles, and shot charts lg averages
    let fiveWeeksAgo = new Date(Date.now() - 35 * 24 * 60 * 60 * 1000);
    let isEarlyExhibition = new Date(gameInfo?.gameDate) >= fiveWeeksAgo && (gameInfo?.homeIsExhib === true || gameInfo?.awayIsExhib === true);
    let pctilesCompetitionId = isEarlyExhibition ? getPriorCompetitionId({ competitionId }) : competitionId;
    // console.log('pctilesCompetitionId: ', { isEarlyExhibition, pctilesCompetitionId, competitionId, gameId, priorId: getPriorCompetitionId({ competitionId }) });

    // React Query Data Fetching
    const passGameInfo = !gameId || !competitionId;
    const { data: gameInfos = [], isLoading: isLoading2, isError: isError2 } = useCBBQuery({ ep: 'games', cfg: { competitionId, teamId, pass: !teamId } });
    const { data: leagueHexData = [], isLoading: isLoading3, isError: isError3 } = useCBBQuery({ ep: 'lg-hexes', cfg: { competitionId: pctilesCompetitionId, divisionId, pass: passGameInfo } });
    const { data: leagueZoneData = [], isLoading: isLoading4, isError: isError4 } = useCBBQuery({ ep: 'lg-zones', cfg: { competitionId: pctilesCompetitionId, divisionId, pass: passGameInfo } });
    const { data: pctilesPlayerGameStats = [], isLoading: isLoading5, isError: isError5 } = useCBBQuery({ ep: 'pctiles-player-game-stats', cfg: { competitionId: pctilesCompetitionId, divisionId, pass: passGameInfo } });
    const { data: pctilesTeamGameStats = [], isLoading: isLoading6, isError: isError6 } = useCBBQuery({ ep: 'pctiles-team-game-stats', cfg: { competitionId: pctilesCompetitionId, divisionId, pass: passGameInfo } });
    const { data: heightRanks = [], isLoading: isLoading7, isError: isError7 } = useCBBQuery({ ep: 'height-rankings', cfg: { teamIds: [homeId, awayId], pass: !homeId || !awayId } });
    const { data: gameInfosToday = [], isLoading: isLoading8, isError: isError8 } = useCBBQuery({ ep: 'games', cfg: { gameDate: todaysDate } });
    // console.log('StreamTesting data fetched: ', { competitionId, gameInfo, gameInfos, leagueHexData, leagueZoneData, pctilesPlayerGameStats, pctilesTeamGameStats, gameInfosToday });

    if (isError2 || isError3 || isError4 || isError5 || isError6 || isError7) { console.log('Data Fetching Error'); }
    const isLoading = (isLoading2 || isLoading3 || isLoading4 || isLoading5 || isLoading6 || isLoading7);


    // Game Cards to Select Game from Today's Date
    const showGameCards = !gameId;
    const gameInfosSorted = gameInfosToday.sort((a, b) => a.gameTimeUtc > b.gameTimeUtc ? 1 : -1);
    const gameCards = gameInfosSorted
        .filter(row => !competitionId ? true : row.competitionId === competitionId)
        .filter(row => row.awayDivisionId === division.value || row.homeDivisionId === division.value)
        .filter(row => conferenceValues.length === 0 ? true : conferenceValues.includes(row.awayConferenceId) || conferenceValues.includes(row.homeConferenceId))
        .map(game => (
            <GameCard
                key={game.gameId}
                gameInfoCbb={game}
                navigateTo='streaming'
            />
        ));

    // formatting, reshaping data?
    const playerInfosObj = arrayToObject({ array: playerInfos, key: 'playerId' });
    const enhPbpData = enhRawPbp(pbpData, gameInfo, heightRanks);

    // Left Join Pctiles (no %iles for integer stats)
    let playerGameStats = applyPlayerPctiles({ statsArray: playerBoxScores, pctilesArray: pctilesPlayerGameStats, dataType: 'playerGameStats', pctilesType: 'byPosition' });
    let teamGameStats = leftJoinPctiles({ tableData: teamBoxScores, pctilesData: pctilesTeamGameStats, dataType: 'teamGameStats' });
    let noPctileKeys = ['fbPts', 'pitp', 'scp', 'potov', 'benchPts', 'ptsScored', 'ast', 'orb', 'drb', 'stl', 'blk', 'tov', 'pf', 'fga', 'fag2', 'fga3', 'fta', 'pfd'];
    teamGameStats = teamGameStats.map(row => {
        let newRow = row;
        noPctileKeys.forEach(key => { delete newRow[`${key}Pctile`]; });
        return newRow;
    });

    // cost homeI
    const { gameDate } = status;
    const pbpShots = enhPbpData.filter(row => ['2pt', '3pt'].includes(row.actionType));
    const homePbpShots = pbpShots.filter(row => row.teamId === homeId);
    const awayPbpShots = pbpShots.filter(row => row.teamId === awayId);

    // Lineup Flow Data
    let gameFlowLineupsGsApi = getGameFlowLineups(enhPbpData, gameInfo);
    let gameFlowPlayersGsApi = getGameFlowPlayers(gameFlowLineupsGsApi, gameInfo, enhPbpData);


    // 2. Create Detailed Paint Buttons
    // ==================================
    const navigate = useNavigate();
    const handleCompetitionInfo = (e) => {
        setTeamInfo(null); // reset these two fields
        navigate('/streaming');
        setCompetitionInfo(e);
    };

    let resetPageButton = // should really have a good <CBBButton /> component
        (<div className={`cbb-button`} onClick={() => navigate('/streaming')}>
            Reset, Select New Game
        </div>);
    let competitionSelect =
        (<CBBSelect
            selectType='competitions'
            value={competitionInfo}
            setValue={handleCompetitionInfo}
            keeperOptions={[38409, 38529]}
            isDisabled={false}
            placeholder='Select Competition...'
        />);
    const liveTeamsSelect =
        (<CBBLiveSelect
            selectType='team'
            value={teamInfo}
            setValue={(e) => { setTeamInfo(e); navigate('/streaming'); }}
            isDisabled={isLoading || competitionInfo === null}
            ids={{ competitionId }}
            placeholder='Search Team...'
        />);
    let divisionSelect =
        (<CBBSelect
            selectType='divisions'
            value={division}
            setValue={setDivision}
            isDisabled={false}
        />);
    const conferenceMultiSelect =
        (<CBBMultiSelect
            selectType='conferences'
            array={conferences}
            setArray={setConferences}
            ids={{ divisionId }}
            extraOptions={['all']}
            isDisabled={isLoading}
        />);

    let gameOptions = gameInfos ? gameInfos : [];
    gameOptions = gameOptions.sort((a, b) => a.gameDate > b.gameDate ? -1 : 1);
    gameOptions = gameOptions.map(row => { return { value: row.gameId, label: `${row.gameDate.slice(5)}: ${row.homeMarket} vs ${row.awayMarket}` }; });
    const gamePusher =
        (<CBBPusher
            selectType='games'
            value={gameId}
            optionsArray={gameOptions}
            isDisabled={isLoading || teamInfo === null || competitionInfo === null}
            placeholder='Select Game...'
        />);

    // let playerOptions = playerGameStats.map(row => { return { value: row.playerId, label: `${row.teamMarket}: ${row.fullName}` }; });
    let playerIds = playerGameStats.map(row => row.playerId);
    let playerOptions = playerInfos.filter(row => playerIds.includes(row.playerId));
    const playerMultiSelect =
        (<CBBMultiSelect
            selectType='players'
            array={selectedPlayers}
            setArray={setSelectedPlayers}
            optionsArray={playerOptions}
            placeholder='Select Players...'
            isDisabled={isLoading}
        />);
    const zoneSchemaSelect =
        (<CBBSelect
            selectType='zoneSchemas'
            value={zoneSchema}
            setValue={setZoneSchema}
            isDisabled={isLoading}
            optionGroup={1}
        />);
    let zoneMetricSelect =
        (<CBBSelect
            selectType='zoneMetrics'
            value={zoneMetric}
            setValue={setZoneMetric}
            isDisabled={isLoading}
            optionGroup={2}
        />);
    let chartTypeSelect =
        (<CBBSelect
            selectType='hexMarkerZone'
            value={chartType}
            setValue={setChartType}
            isDisabled={isLoading}
        />);
    let showFgmFgaSelect =
        (<CBBSelect
            selectType='showFgmFga'
            value={showFgmFga}
            setValue={setShowFgmFga}
            isDisabled={isLoading}
        />);
    let tableTypeSelect =
        (<CBBSelect
            selectType='tableTypes'
            value={tableType}
            setValue={setTableType}
            optionGroup='playerGameBox'
            isDisabled={isLoading}
        />);
    let tweetSelect =
        (<CBBSelect
            selectType='gameTweets'
            value={gameTweet}
            setValue={setGameTweet}
        />);
    let teamSelect =
        (<CBBSelect
            selectType='teams'
            value={selectedTeam}
            setValue={setSelectedTeam}
            optionsArray={teamInfos}
            placeholder='Select Team'
        />);

    let createTeamLogo = (thisTeamId, width = 40, margin = 0) => {
        return (
            <Logo
                className={width > 30 ? 'hide-print' : ''}
                style={{ display: 'inline-block', height: width, width, margin }}
                ptgc='team'
                teamId={thisTeamId}
            />
        );
    };
    let homeLogoBig = createTeamLogo(homeId, 35, '0px 6px 0px 0px');
    let awayLogoBig = createTeamLogo(awayId, 35, '0px 6px 0px 0px');

    const notReadyInfo =
        (<p className='green-explainer'>
            Use the dropdowns above or the cards below to select a game. For the dropdowns above, select the competition, then the team, and then a
            game from that team's schedule. For the cards below, simply click on a game card. Use the Competition, Division and Conference filters
            to assist with finding the game you are looking for.
        </p>);
    const welcomeInfo =
        (<p className='green-explainer'>
            This is our In-Game Streaming Dashboard. The following page updates live as the selected game is played. Search dropdowns may lag at
            the exact moments when the page is being updated with the latest data.
        </p>);

    // Function to create team & player shot charts
    const isShotChartsLoading = isLoading || pbpData.length === 0;
    const createShotChart = (ptgc, homeAway, thisChartType, zoneType = '', playerId = '') => {
        // set early variables
        let newShotData, tooltipName, headerText, subHeaderPrefix, subHeaderText, playerInfo, fullName;

        // handle team-game shot charts
        if (ptgc === 'team-game') {
            newShotData = homeAway === 'home' ? homePbpShots : awayPbpShots;
            tooltipName = homeAway === 'home' ? homeMarket : awayMarket;
            headerText = homeAway === 'home' ? `${homeMarket} ${homeName}` : `${awayMarket} ${awayName}`;
            subHeaderPrefix = zoneType === 'fgPct' ? `FG% By Zone, ` : (zoneType === 'fgaFreq' ? `% Shots By Zone, ` : '');
            subHeaderText = homeAway === 'home' ? `${subHeaderPrefix}${gameDate} vs. ${awayMarket}` : `${subHeaderPrefix}${gameDate} vs. ${homeMarket}`;
        }

        if (ptgc === 'player-game') {
            playerInfo = playerInfosObj[playerId];
            if (!playerInfo) { return null; } // if 'player-game' but no playerInfo, then return null;
            fullName = playerInfo && playerInfo.fullName ? playerInfo.fullName : '';
            newShotData = pbpShots.filter(row => row.playerId === playerId);
            tooltipName = fullName;
            headerText = `${fullName}`;
            subHeaderPrefix = zoneType === 'fgPct' ? `FG% By Zone, ` : (zoneType === 'fgaFreq' ? `% Shots By Zone, ` : '');
            subHeaderText = playerInfo.teamId === homeId ? `${subHeaderPrefix}${gameDate} vs. ${awayMarket}` : `${subHeaderPrefix}${gameDate} vs. ${homeMarket}`;
        }

        return (
            <ShotChartsD3
                ptgc={ptgc === 'team-game' ? 'game' : ptgc}
                chartType={thisChartType}
                competitionId={competitionId}
                newShotData={newShotData}
                leagueHexData={leagueHexData}
                leagueZoneData={leagueZoneData}

                zoneSchema={zoneSchema.value || 'zones6'}
                zoneMetric={zoneType === 'fgPct' ? zoneMetric.fgPct : zoneMetric.fgaFreq}
                showFgmFga={showFgmFga.value || false}
                isOffense={'true'}
                isLoading={isShotChartsLoading}

                tooltipName={tooltipName}
                headerText={headerText}
                subHeaderText={subHeaderText}
            />
        );
    };


    // Team Boxscore (Long) Tables
    // ============================
    let sfx = ''; // true ? '' : 'Pg'; // no suffix for now
    let tableInfos = [
        { header: 'Core Stats', arr: ['netRtg', 'ortg', 'drtg', 'pace', 'efgPct', 'orbPct', 'tovPct', 'ftaRate'] },
        { header: 'Misc Scoring', arr: [`fbpts${sfx}`, 'fbptsPctPts', `pitp${sfx}`, 'pitpPctPts', `scp${sfx}`, 'scpPctPts', `potov${sfx}`, `benchPts${sfx}`] },
        { header: 'Box Score', arr: [`ptsScored${sfx}`, `ast${sfx}`, `orb${sfx}`, `drb${sfx}`, `stl${sfx}`, `blk${sfx}`, `tov${sfx}`, `pf${sfx}`] },
        { header: 'Shooting', arr: [`fga${sfx}`, 'fgPct', `fga2${sfx}`, 'fg2Pct', `fga3${sfx}`, 'fg3Pct', `fta${sfx}`, 'ftPct'] },
        { header: 'Adv Offense', arr: ['ortg', 'tsPct', 'fga3Rate', 'ftaRate', 'astPct', 'tovPct', 'astTov', `pfd${sfx}`] },
        { header: 'Adv Defense', arr: ['drtg', 'drbPct', 'stlPct', 'blkPct', 'hkmPct', 'pfEff', 'stlPerPf', 'blkPerPf'] }
    ];

    const wideCols = ['teamId'];
    const teamGameStatsLong = pivotTesting({ array: teamGameStats.filter(row => row.isOffense === true), wideCols });
    const sideBySideColumnInfos = [{ group: 1, teamId: homeId, scope: 'game' }, { group: 2, teamId: awayId, scope: 'game' }];

    let createGamesTable = (tableInfo, header = 'yesHeader') => {
        let thisData = filterAndSortArray({ data: teamGameStatsLong, keysArray: tableInfo.arr, key: 'key' });
        return (<>
            {header === 'yesHeader' &&
                <Row style={{ margin: 0, alignItems: 'center', marginBottom: 10 }}>
                    <Col xs={5} style={{ display: 'flex', padding: 0, justifyContent: 'end', alignItems: 'center', paddingRight: 20 }}>
                        <div style={{ marginRight: 5, lineHeight: 1.3 }}>
                            <p style={{ fontSize: '0.875em', fontWeight: 700, color: '#222' }}>{homeMarket}</p>
                            <p style={{ fontSize: '0.80em', fontWeight: 700, color: '#444' }}>{homeRecord}</p>
                        </div>
                        {homeLogoBig}
                    </Col>
                    <Col xs={2} style={{ padding: 0, textAlign: 'center', lineHeight: 1.1, fontSize: '0.8em', fontWeight: 700, color: '#444' }}>
                        <p>{gameDate && gameDate.slice(5).replace('-', '/')}</p>
                        <p>{formatAMPM({ dateString: gameTimeUtc })}</p>
                    </Col>
                    <Col xs={5} style={{ display: 'flex', padding: 0, justifyContent: 'start', alignItems: 'center', paddingLeft: 20 }}>
                        {awayLogoBig}
                        <div style={{ marginLeft: 5, lineHeight: 1.3 }}>
                            <p style={{ fontSize: '0.875em', fontWeight: 700, color: '#222' }}>{awayMarket}</p>
                            <p style={{ fontSize: '0.80em', fontWeight: 700, color: '#444' }}>{awayRecord}</p>
                        </div>
                    </Col>
                </Row>
            }

            {((homeId && awayId) || isTestGame) &&
                <LongTable
                    tableData={thisData}
                    tableType='multiEntity'
                    sideBySideColumnInfos={sideBySideColumnInfos}
                    headerGroups={['teamMarket']}
                    wideCols={wideCols}
                    features={[]}
                    labelHeader={tableInfo.header}
                    scrollOrExpand='expand'
                />
            }
        </>);
    };
    // ========


    // Player Boxscore (Wide) Tables
    // =============================
    const homePlayerGameStats = playerGameStats.filter(row => row.teamId === homeId);
    let homeTableData = homePlayerGameStats.sort((a, b) => a.mins > b.mins ? -1 : 1);
    let homePinnedRows = getPostGameTotalsRows({ teamGameStats, pctilesTeamGameStats, teamId: homeId, rows: ['totals'] });
    homeTableData = [...homeTableData, ...homePinnedRows]; // order matters

    const homeBoxscoresTable =
        (<PlayerGameStatsTable
            tableData={homeTableData}
            tableType={tableType.value}
            features={['ratingsBar', 'columnSelect', 'dlButton', 'infoModal', 'hiderButton']}
            pageType='gameBoxscores'
            lhsColsGroup={2}
        />);

    const awayPlayerGameStats = playerGameStats.filter(row => row.teamId === awayId);
    let awayTableData = awayPlayerGameStats.sort((a, b) => a.mins > b.mins ? -1 : 1);
    let awayPinnedRows = getPostGameTotalsRows({ teamGameStats, pctilesTeamGameStats, teamId: awayId, rows: ['totals'] });
    awayTableData = [...awayTableData, ...awayPinnedRows]; // order matters

    const awayBoxscoresTable =
        (<PlayerGameStatsTable
            tableData={awayTableData}
            tableType={tableType.value}
            features={['ratingsBar', 'columnSelect', 'dlButton', 'infoModal', 'hiderButton']}
            pageType='gameBoxscores'
            lhsColsGroup={2}
        />);
    // ========


    // Controller Navbar
    // ==================
    // console.log('gameInfo: ', gameInfo); // get live/final score into the controllerNavbar, get "Live", "PreGame", "Final" into the bottom-left of controllerNavbar
    const labelText = isLoading ? '' : (!gameInfoFetched ? 'No Game' : `${homeMarket} vs. ${awayMarket}: `);
    const subLabelText = isLoading ? '' : (!gameInfoFetched ? '' : ` ${gameInfo.gameDate}`);

    const controllerNavbar =
        (<ControllerNavbar
            label={labelText}
            subLabel={subLabelText}
            view={''}
            ptgc='game'
            gameInfo={gameInfo}
            handleViewChange={''}
            pad={false}
            showButtons={false}
            competitionId={competitionId}
        />);

    // And Finally, The Return
    // ========================
    const isGameSelected = (competitionId && gameId ? true : false) || isTestGame;
    // const gameHasStreamingData = connectionStatus === 'CONNECTED' || false;
    const gameHasStreamingData = pbpData.length > 0 && teamBoxScores.length > 0;
    const isReady = (competitionId && gameId ? true : false) || isTestGame;
    const gameOverviewPath = `/stats/${competitionId}/games/${gameId}/overview`;

    return (<>
        <Container className='shadow-container'>
            {controllerNavbar}
            <Row className='dropdown-row'>
                {competitionSelect}
                {liveTeamsSelect}
                {gamePusher}
                {gameId && resetPageButton}
            </Row>

            <Container className='padded-inner-container' style={{ paddingTop: 10 }}>
                {/* Select Game Tooling */}
                {!isReady && notReadyInfo}
                {showGameCards && <>
                    <Row style={{ margin: 0, marginTop: 10 }}>
                        {competitionSelect}
                        {divisionSelect}
                        {conferenceMultiSelect}
                    </Row>
                    <Row style={{ margin: 0, marginTop: 25 }}>
                        {gameCards}
                    </Row>
                </>}

                {/* {connectionStatus === 'NOTCONNECTED' && <LoadingSpinner size='big' text='NOT CONNECTED, ARE YOU SURE THIS GAME IS EITHER LIVE, OR HAS BEEN COMPLETED WITHIN THE LAST 12 HOURS?' />} */}
                {/* {isReady && <> */}
                {/* No Data Available in Streaming API */}
                {gameId && !gameHasStreamingData && <>
                    <Row style={{ margin: 0 }}>
                        <p className='green-explainer'>
                            No data for this game. The In-Game Streaming page only works for live games and games completed within the last 12 hours. For statistics on games completed
                            more than 12 hours ago, you should utilize the <Link style={{ textDecoration: 'underline' }} to={gameOverviewPath}>Game Breakdown</Link> page for this game.
                        </p>
                    </Row>
                </>}

                {gameHasStreamingData && <>
                    {welcomeInfo}
                    {<Row style={{ display: 'none' }}>
                        {zoneSchemaSelect}
                        {zoneMetricSelect}
                        {showFgmFgaSelect}
                    </Row>}
                    <StatusBar
                        statusObject={status}
                    />

                    {/* Home Team Shot Charts */}
                    {/* ===================== */}
                    <Row style={{ margin: 0, marginTop: 30 }}>
                        <TextHeaders className='print-break' teamId={homeId}>
                            {homeLogoBig}{homeMarket} Team Shot Charts:{` `}
                            <span>{gameDate} Game Shooting</span>
                        </TextHeaders>
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                        <Col xs={12} sm={11} md={6} lg={3}>
                            {createShotChart('team-game', 'home', 'marker', '', '')}
                        </Col>
                        <Col xs={12} sm={11} md={6} lg={3}>
                            {createShotChart('team-game', 'home', 'hex', '', '')}
                        </Col>
                        <Col xs={12} sm={11} md={6} lg={3}>
                            {createShotChart('team-game', 'home', 'zone', 'fgPct', '')}
                        </Col>
                        <Col xs={12} sm={11} md={6} lg={3}>
                            {createShotChart('team-game', 'home', 'zone', 'fgaFreq', '')}
                        </Col>
                    </Row>

                    {/* Away Team Shot Charts */}
                    {/* ===================== */}
                    <Row style={{ margin: 0, marginTop: 30 }}>
                        <TextHeaders className='print-break' teamId={awayId}>
                            {awayLogoBig}{awayMarket} Team Shot Charts:{` `}
                            <span>{gameDate} Game Shooting</span>
                        </TextHeaders>
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                        <Col xs={12} sm={11} md={6} lg={3}>
                            {createShotChart('team-game', 'away', 'marker', '', '')}
                        </Col>
                        <Col xs={12} sm={11} md={6} lg={3}>
                            {createShotChart('team-game', 'away', 'hex', '', '')}
                        </Col>
                        <Col xs={12} sm={11} md={6} lg={3}>
                            {createShotChart('team-game', 'away', 'zone', 'fgPct', '')}
                        </Col>
                        <Col xs={12} sm={11} md={6} lg={3}>
                            {createShotChart('team-game', 'away', 'zone', 'fgaFreq', '')}
                        </Col>
                    </Row>

                    {/* Player Shot Charts */}
                    {/* ================== */}
                    <Row style={{ margin: 0, marginTop: 30 }}>
                        <TextHeaders className='print-break' teamId={awayId}>
                            Player Shot Charts:{` `}
                            <span>{gameDate} Game Shooting</span>
                        </TextHeaders>
                    </Row>
                    <Row style={{ margin: 0, marginTop: 10 }}>
                        {playerMultiSelect}
                    </Row>
                    <Row style={{ marginTop: 20 }} />
                    {selectedPlayers.map(playerInfo => (
                        <Row key={playerInfo.playerId} style={{ marginTop: 15 }}>
                            <Col xs={12} sm={11} md={6} lg={3}>
                                {createShotChart('player-game', null, 'marker', null, playerInfo.playerId)}
                            </Col>
                            <Col xs={12} sm={11} md={6} lg={3}>
                                {createShotChart('player-game', null, 'hex', null, playerInfo.playerId)}
                            </Col>
                            <Col xs={12} sm={11} md={6} lg={3}>
                                {createShotChart('player-game', null, 'zone', 'fgPct', playerInfo.playerId)}
                            </Col>
                            <Col xs={12} sm={11} md={6} lg={3}>
                                {createShotChart('player-game', null, 'zone', 'fgaFreq', playerInfo.playerId)}
                            </Col>
                        </Row>
                    ))}

                    {/* Game Lineup Flow */}
                    {/* ================ */}
                    <Row style={{ margin: 0, marginTop: 30 }}>
                        <TextHeaders className='print-break' teamId={awayId}>
                            Game Lineup Flow Graphs
                        </TextHeaders>
                    </Row>
                    <Row style={{ margin: 0 }}>
                        {teamSelect}
                    </Row>
                    {selectedTeam !== null &&
                        <D3LineupFlow
                            graphData={gameFlowPlayersGsApi.filter(row => row.teamId === selectedTeam.teamId)}
                            colorType='netRtgScale'
                        />
                    }
                    {selectedTeam !== null &&
                        <Row style={{ marginTop: 25 }}>
                            <D3LineupFlowV2
                                graphData={gameFlowPlayersGsApi.filter(row => row.teamId === selectedTeam.teamId)}
                                colorType='netRtgScale'
                            />
                        </Row>
                    }

                    {/* Main 6 Tables */}
                    {/* ============= */}
                    <Row style={{ margin: 0, marginTop: 30 }}>
                        <TextHeaders className='print-break' teamId={awayId}>
                            Team Game Stats
                        </TextHeaders>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <Col xs={12} sm={6} md={4} style={{ marginBottom: 15 }}>{createGamesTable(tableInfos[0], 'yesHeader')}</Col>
                        <Col xs={12} sm={6} md={4} style={{ marginBottom: 15 }}>{createGamesTable(tableInfos[1], 'yesHeader')}</Col>
                        <Col xs={12} sm={6} md={4} style={{ marginBottom: 15 }}>{createGamesTable(tableInfos[2], 'yesHeader')}</Col>
                        <Col xs={12} sm={6} md={4} style={{ marginBottom: 15 }}>{createGamesTable(tableInfos[3], 'yesHeader')}</Col>
                        <Col xs={12} sm={6} md={4} style={{ marginBottom: 15 }}>{createGamesTable(tableInfos[4], 'yesHeader')}</Col>
                        <Col xs={12} sm={6} md={4} style={{ marginBottom: 15 }}>{createGamesTable(tableInfos[5], 'yesHeader')}</Col>
                    </Row>

                    {/* Player BoxScore Tables */}
                    {/* ====================== */}
                    <Row style={{ margin: 0, marginTop: 50 }}>
                        {tableTypeSelect}
                    </Row>
                    <Row style={{ margin: 0, marginTop: 20 }}>
                        <TextHeaders className='print-break' teamId={homeId}>
                            {homeLogoBig}{homeMarket} Box Score
                        </TextHeaders>
                    </Row>
                    <Row style={{ margin: 0 }}>
                        {homeBoxscoresTable}
                    </Row>
                    <Row style={{ margin: 0, marginTop: 20 }}>
                        <TextHeaders className='print-break' teamId={awayId}>
                            {awayLogoBig}{awayMarket} Box Score
                        </TextHeaders>
                    </Row>
                    <Row style={{ margin: 0 }}>
                        {awayBoxscoresTable}
                    </Row>

                    {/* Game Tweets (set page to 800px) */}
                    {/* =============================== */}
                    <Row style={{ margin: 0, marginTop: 50 }}>
                        {tweetSelect}
                        {['tweet1', 'tweet2'].includes(gameTweet.value) && chartTypeSelect}
                        {chartType.value === 'zone' && ['tweet1', 'tweet2'].includes(gameTweet.value) && zoneSchemaSelect}
                        {chartType.value === 'zone' && ['tweet1', 'tweet2'].includes(gameTweet.value) && zoneMetricSelect}
                        {gameTweet.value === 'tweet3' && tableTypeSelect}
                    </Row>
                    {gameTweet.value === 'tweet2' && <Row style={{ margin: 0 }}>
                        {playerMultiSelect}
                    </Row>}
                    {gameTweet.value === 'tweet1' && <>
                        <Row style={{ marginTop: 25 }}>
                            <Col xs={6} style={{ padding: '0px 20px 0px 40px' }}>
                                {createShotChart('team-game', 'home', chartType.value, zoneSchema.value, '')}
                            </Col>
                            <Col xs={6} style={{ padding: '0px 40px 0px 20px' }}>
                                {createShotChart('team-game', 'away', chartType.value, zoneSchema.value, '')}
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 25, marginBottom: 200 }}>
                            <Col xs={6} style={{ padding: '0px 20px 0px 40px' }}>
                                {createGamesTable(tableInfos[0], 'noHeader')}
                            </Col>
                            <Col xs={6} style={{ padding: '0px 40px 0px 20px' }}>
                                {createGamesTable(tableInfos[3], 'noHeader')}
                            </Col>
                        </Row>
                    </>}

                    {gameTweet.value === 'tweet2' && selectedPlayers.length >= 2 && <>
                        <Row style={{ marginTop: 25 }}>
                            {selectedPlayers[0].playerId &&
                                <Col xs={6} style={{ padding: '0px 30px 0px 60px' }}>
                                    {createShotChart('player-game', null, chartType.value, zoneSchema.value, selectedPlayers[0].playerId)}
                                </Col>
                            }
                            {selectedPlayers[1].playerId &&
                                <Col xs={6} style={{ padding: '0px 60px 0px 30px' }}>
                                    {createShotChart('player-game', null, chartType.value, zoneSchema.value, selectedPlayers[1].playerId)}
                                </Col>
                            }
                        </Row>
                        <Row style={{ margin: '25px 20px 350px 20px' }}>
                            <PlayerGameStatsTable
                                tableData={playerGameStats.filter(row => [selectedPlayers[0].playerId, selectedPlayers[1].playerId].includes(row.playerId))}
                                tableType='singleGameTweet'
                                features={['ratingsBar', 'columnSelect', 'dlButton', 'infoModal', 'hiderButton']}
                                pageType='gameBoxscores'
                                lhsColsGroup={2}
                            />
                        </Row>
                    </>}

                    {gameTweet.value === 'tweet3' && <>
                        <Row style={{ margin: '20px 20px 0px 20px' }}>
                            <TeamGameStatsTable
                                tableData={teamGameStats.filter(row => row.isOffense === true)}
                                tableType='gameOverview'
                                features={['infoModal', 'hiderButton']}
                                lhsCols={['teamLogo', 'teamMarket']}
                                infoModal='gameOverview'
                                hideFilters={true}
                            />
                        </Row>
                        <Row style={{ margin: '12px 20px 0px 20px' }}>
                            {homeBoxscoresTable}
                        </Row>
                        <Row style={{ margin: '12px 20px 200px 20px' }}>
                            {awayBoxscoresTable}
                        </Row>
                    </>}

                    {!isLoading && <span className='done-loading' />}
                </>}
            </Container>
        </Container>
    </>);
}

export default StreamTesting;
// 363
