
import React from 'react';

function TableInfoModalTooltip() {
    return (
        <div className='info-modal global-info-modal hide-modal hide-print'> {/* style={{ ...modalStyle }}> */}
            {/* Modal Header */}
            <div className='info-modal-header'>
                <h3 className='header' />
            </div>

            {/* Color Bars */}
            <div className={`table-legend pctiles big`}>
                <span className='legend-title'>0 - 100 Percentiles</span>
            </div>
            <div className={`table-legend ranks big`}>
                <span className='legend-title'>Team Rankings</span>
            </div>

            {/* Modal Body */}
            <div className='body'>
                <div className='content content1'>
                    <p className='title' />
                    <p className='desc' />
                </div>
                <div className='content content2'>
                    <p className='title' />
                    <p className='desc' />
                </div>
                <div className='content content3'>
                    <p className='title' />
                    <p className='desc' />
                </div>
                <div className='content content4'>
                    <p className='title' />
                    <p className='desc' />
                </div>
                <div className='content content5'>
                    <p className='title' />
                    <p className='desc' />
                </div>
            </div>
        </div>
    );
}

export default TableInfoModalTooltip;
