
// better chunk names when lazy-loading - https://www.belter.io/react-suspense-lazy-chunk-filenames/ - (may not need if using @loadable/component)

// import loadable from '@loadable/component';
// const ShotChartsApp = loadable(() => import('./containers/appPages/ShotChartsApp'));

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function (componentImport) {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
        );
        // try to import the component
        componentImport().then((component) => {
            window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
            resolve(component);
        }).catch((error) => {
            if (!hasRefreshed) { // not been refreshed yet
                window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
                return window.location.reload(); // refresh the page
            }
            reject(error); // Default error behaviour as already tried refresh
        });
    });
};

import 'bootstrap/dist/css/bootstrap.min.css';

// Import React Components
import React, { lazy, useState, useEffect, Suspense } from 'react';

import { lazyWithRetry } from './utils/lazyWithRetry';
import ReactGA from 'react-ga'; // React + Google Analytics
import Axios from 'axios';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import { arrayToObject } from './utils/ReshapeData';
import { RingLoader } from 'react-spinners';
import { allCompetitionIds } from './harddata/NcaaStructures';
import config from './config';
import { getTokenFromLocalStorage, checkTokenValidity } from './utils/jwtTokens';
// import { MathJaxContext } from 'better-react-mathjax';


// Import Data Fetching
import useCBBQuery from './hooks/useCBBQuery';
import usePageTracking from './hooks/usePageTracking';
import GlobalContext from './context/GlobalContext';
import RouteListenerLayout from './containers/landingPages/RouteListenerLayout';

// Import Container Apps
import HomePage from './containers/landingPages/homePage/HomePage';
import Consulting from './containers/landingPages/Consulting';
import SignupPage from './containers/landingPages/SignupPage';
import AboutUs from './containers/landingPages/AboutUs';
import MyAccount from './containers/landingPages/MyAccount';
import ProductSummary from './containers/landingPages/ProductSummary';
import TipsPage from './containers/landingPages/HelpVideos';
import ResetPassword from './containers/landingPages/ResetPassword';
import AdminPage from './containers/landingPages/AdminPage';
import ForgotPassword from './containers/landingPages/ForgotPassword';
import Unsubscribe from './containers/landingPages/Unsubscribe';
import SendVerificationEmail from './containers/landingPages/SendVerificationEmail';
import VerifyEmail from './containers/landingPages/VerifyEmail';
import LoginWithLink from './containers/landingPages/LoginWithLink';
import HelpPage from './containers/landingPages/HelpPage';
import StreamTesting from './containers/landingPages/StreamTesting';
import StatsLanding from './containers/landingPages/StatsLanding';
import ToolsLanding from './containers/landingPages/ToolsLanding';

const ShotChartsApp = lazy(() => lazyRetry(() => import(/* webpackChunkName: "ShotChartsApp" */ './containers/appPages/ShotChartsApp')));
const TeamScatterApp = lazy(() => lazyRetry(() => import(/* webpackChunkName: "TeamScatterApp" */ './containers/appPages/TeamScatterApp')));
const PlayerScatterApp = lazy(() => lazyRetry(() => import(/* webpackChunkName: "PlayerScatterApp" */ './containers/appPages/PlayerScatterApp')));
const BarChartsApp = lazy(() => lazyRetry(() => import(/* webpackChunkName: "BarChartsApp" */ './containers/appPages/BarChartsApp')));
const RegressionApp = lazy(() => lazyRetry(() => import(/* webpackChunkName: "RegressionApp" */ './containers/appPages/RegressionApp')));
const MarchMadnessApp = lazy(() => lazyRetry(() => import(/* webpackChunkName: "MarchMadnessApp" */ './containers/appPages/MarchMadnessApp')));
const ScoreboardApp = lazy(() => lazyRetry(() => import(/* webpackChunkName: "ScoreboardApp" */ './containers/appPages/ScoreboardApp')));
const PlayerRatingsApp = lazy(() => lazyRetry(() => import(/* webpackChunkName: "PlayerRatingsApp" */ './containers/appPages/PlayerRatingsApp')));
const ComparisonsApp = lazy(() => lazyRetry(() => import(/* webpackChunkName: "ComparisonsApp" */ './containers/appPages/ComparisonsApp')));
const GameStatsLeaderboard = lazy(() => lazyRetry(() => import(/* webpackChunkName: "GameStatsLeaderboard" */ './containers/appPages/GameStatsLeaderboard')));
const StreaksApp = lazy(() => lazyRetry(() => import(/* webpackChunkName: "StreaksApp" */ './containers/appPages/StreaksApp')));
const DIYShotChart = lazy(() => lazyRetry(() => import(/* webpackChunkName: "DIYShotChart" */ './containers/appPages/DIYShotChart')));
const GameDecidingShotsApp = lazy(() => lazyRetry(() => import(/* webpackChunkName: "GameDecidingShotsApp" */ './containers/appPages/GameDecidingShotsApp')));
const BlogController = lazy(() => lazyRetry(() => import(/* webpackChunkName: "BlogController" */ './containers/blog/BlogController')));
const DevSandbox = lazy(() => lazyRetry(() => import(/* webpackChunkName: "DevSandbox" */ './containers/appPages/DevSandbox')));
const DefaultAppStarter = lazy(() => lazyRetry(() => import(/* webpackChunkName: "DefaultAppStarter" */ './containers/appPages/DefaultAppStarter')));
// Statistics Pages
const TourneyController = lazy(() => lazyRetry(() => import(/* webpackChunkName: "TourneyController" */ './containers/statsPages/tourney/TourneyController')));
const DraftController = lazy(() => lazyRetry(() => import(/* webpackChunkName: "DraftController" */ './containers/statsPages/draft/DraftController')));
const TransferPortalController = lazy(() => lazyRetry(() => import(/* webpackChunkName: "TransferPortalController" */ './containers/statsPages/transferPortal/TransferPortalController')));
const TransferHistoryController = lazy(() => lazyRetry(() => import(/* webpackChunkName: "TransferHistoryController" */ './containers/statsPages/transferHistory/TransferHistoryController')));
const DivisionController = lazy(() => lazyRetry(() => import(/* webpackChunkName: "DivisionController" */ './containers/statsPages/division/DivisionController')));
const TeamController = lazy(() => lazyRetry(() => import(/* webpackChunkName: "TeamController" */ './containers/statsPages/team/TeamController')));
const PlayerController = lazy(() => lazyRetry(() => import(/* webpackChunkName: "PlayerController" */ './containers/statsPages/player/PlayerController')));
const GameController = lazy(() => lazyRetry(() => import(/* webpackChunkName: "GameController" */ './containers/statsPages/game/GameController')));
const ConfLanding = lazy(() => lazyRetry(() => import(/* webpackChunkName: "ConfLanding" */ './containers/statsPages/conference/ConfLanding')));
const ConfController = lazy(() => lazyRetry(() => import(/* webpackChunkName: "ConfController" */ './containers/statsPages/conference/ConfController')));


// Navbar + Signup Components
import AppNavbar from './components/navbars/AppNavbar';
import LoginModal from './components/logins/LoginModal';
import AppFooter from './components/headers/AppFooter';
import TableHeaderTooltip from './components/TableHeaderTooltip';
import TableInfoModalTooltip from './components/TableInfoModalTooltip';
// Components Linked To In Footer
import TermsOfUse from './containers/legalPages/TermsOfUse';
import PrivacyPolicy from './containers/legalPages/PrivacyPolicy';
import Disclaimer from './containers/legalPages/Disclaimer';
// 404 Component
import NoMatch from './containers/landingPages/NoMatch';
import ErrorBoundaryFallback from './containers/landingPages/ErrorBoundaryFallback';

import HiddenDemoPage from './containers/landingPages/HiddenDemoPage';
import SentryNoDev from './components/uiUxItems/SentryNoDev';
import CheckoutFormWrapper from './containers/landingPages/stripe/CheckoutFormWrapper';
import FooterAlert from './components/uiUxItems/FooterAlert';


// And Create The App
function App() {
    // useState
    const [showLogin, setShowLogin] = useState(false);
    const [isUserLoading, setIsUserLoading] = useState(true);
    const [userData, setUserData] = useState({ token: null, user: null });

    // grab key values, fallback values from userData
    const adminTier = userData?.user?.admin || { value: 0, label: 'No Admin' };
    const userTier = userData?.user?.tier || { value: 0, label: 'No Access: Not Verified' };
    const userType = userData?.user?.userType || { value: 'fan' };
    const userTeamId = userData?.user?.team?.teamId || null;
    const userCompetitionId = userData?.user?.team?.gender === 'MALE' ? 38409 : 38529;

    // activate google analytics
    let reactGAInitialized = usePageTracking();

    // react query data fetching
    let { data: allCompetitions = [], isLoading: isLoading1, isError: isError1 } = useCBBQuery({ ep: 'competitions' });
    let { data: teamInfos = [], isLoading: isLoading2, isError: isError2 } = useCBBQuery({ ep: 'teams' });
    let { data: conferenceInfos = [], isLoading: isLoading3, isError: isError3 } = useCBBQuery({ ep: 'conferences' });
    let { data: tournamentInfos = [], isLoading: isLoading4, isError: isError4 } = useCBBQuery({ ep: 'tournaments' });
    let { data: teamPlayerMinutes = [], isError: isError5 } = useCBBQuery({ ep: 'player-agg-stats', cfg: { competitionId: userCompetitionId, teamId: userTeamId, scope: 'season', fields: ['mins', 'fullName'], pass: !(userCompetitionId && userTeamId) } });

    // console.log('App.js Data Fetched: ', { allCompetitions, teamInfos, conferenceInfos, tournamentInfos });
    if (isError1 || isError2 || isError3 || isError4 || isError5) { console.log('Data Fetching Error'); }
    let isLoading = (isLoading1 || isLoading2 || isLoading3 || isLoading4);

    // filter competition infos
    let competitionInfos = allCompetitions.length > 0 ? allCompetitions.filter(row => allCompetitionIds.all.includes(row.competitionId)) : [];

    // convert arrays to objects for context
    let competitionInfosObj = arrayToObject({ array: competitionInfos, key: 'competitionId' }) || {};
    let teamInfosObj = arrayToObject({ array: teamInfos, key: 'teamId' }) || {};
    let conferenceInfosObj = arrayToObject({ array: conferenceInfos, key: 'conferenceId' }) || {};
    let tournamentInfosObj = arrayToObject({ array: tournamentInfos, key: 'tournamentId' }) || {};

    // grab defaultCompetition from userData
    const defaultGender = userData?.user?.team?.gender || 'MALE';
    const defaultCompetition = defaultGender === 'MALE'
        ? { value: 38409, label: `2024-25 Men's Basketball` }
        : { value: 38529, label: `2024-25 Women's Basketball` };

    // get trialPlayerIds (3 players free for freeTier)
    const freePlayers = [
        { playerId: 1611558, competitionId: 36045, fullName: 'Caitlin Clark', for: 'all' },
        { playerId: 1603882, competitionId: 36045, fullName: 'Angel Reese', for: 'all' },
        { playerId: 1321388, competitionId: 33535, fullName: 'Aliyah Boston', for: 'all' },
        { playerId: 1603842, competitionId: 36046, fullName: 'Hunter Dickinson', for: 'all' },
        { playerId: 1600744, competitionId: 36046, fullName: 'RJ Davis ', for: 'all' },
        { playerId: 1606325, competitionId: 36046, fullName: 'Zach Edey', for: 'all' },
        { playerId: 1310118, competitionId: 33533, fullName: 'Oscar Tshiebwe', for: 'all' }
    ];

    const sortedMinutes = teamPlayerMinutes.map(row => row.mins).sort((a, b) => b - a);
    const minsCutoff = sortedMinutes?.[2] || 0;
    const trialPlayers = [
        ...freePlayers,
        ...(teamPlayerMinutes
            .filter(row => row.mins >= minsCutoff)
            .map(row => ({ playerId: row.playerId, competitionId: row.competitionId, fullName: row.fullName, for: 'user' })) || [])
    ];

    // Check User Is Logged In
    useEffect(() => {
        const checkLoggedIn = async () => {
            // Wrap Valid Token Check in a Try Catch (big Network Error problem coming from here...)
            try {
                // grab auth token from local storage - if no token, no user to load, so we are done here, can return
                let token = getTokenFromLocalStorage();
                if (!token) { setIsUserLoading(false); return; }

                // check if token is valid - if invalid token, not loading user, so we are done here, can return
                const tokenValidity = await checkTokenValidity(token);
                token = tokenValidity.token || token; // if token returned, then it was updated in checkTokenValidity()
                if (!tokenValidity.isValid) { setIsUserLoading(false); return; }

                // if valid token, we fetch the user
                const apiBaseUrl = config.url.API_URL;
                const userRes = await Axios.get(`${apiBaseUrl}/users/`, { headers: { 'x-auth-token': token } });
                const user = userRes.data;

                // if google analytics activated, call ReactGA.set on the userId
                if (reactGAInitialized) { ReactGA.set({ userId: user._id }); }

                // add to event-logging that user token fetched from local storage successfully
                let newEventLog = { userId: user._id, email: user.email, type: 'localstoragefetch' };
                await Axios.post(`${apiBaseUrl}/users/add-event-log`, newEventLog);

                // update users last-logged-in datetime
                await Axios.post(`${apiBaseUrl}/users/update-last-login`, { userId: user._id });

                // set user data, giving user logged-in access
                setUserData({ token, user });
                setIsUserLoading(false);

                // not great to get error in this useEffect.
            } catch (err) {
                setIsUserLoading(false);
                console.log('err: ', err);
                console.log('err.response: ', err.response);
            }
        };

        checkLoggedIn();
    }, []);


    // Full PageLoading Spinner
    let loadingSpinner =
        (<div style={{ margin: '200px auto 0 auto' }}>
            <RingLoader
                size={60}
                color={'#0066cc'}
                loading={true}
                speedMultiplier={1}
            />
        </div>);


    // Set a few constants before return
    const endDate = new Date('2023-03-17');
    const currentDate = new Date();
    const showMarchTourney = (currentDate < endDate) && userTier && userTier.value === 1 && userType && userType.value !== 'team';
    const showSurveyAlert = userTier && userTier.value === 1 && userType && userType.value !== 'team';
    const showFreeTierAlert = userTier && userTier.value === 1 && userType && userType.value !== 'team';

    // const showFooterAlert = true;
    return (
        // <MathJaxContext>
        <GlobalContext.Provider value={{ // Pass Global State Through To Entire App
            teamInfosObj, conferenceInfosObj, tournamentInfosObj, competitionInfosObj, // key info objects
            adminTier, userTier, userData, setUserData, defaultCompetition, // check if needed in global state
            trialPlayers,
            setShowLogin // check if needed in global state
        }}>
            <AppNavbar />
            <LoginModal
                showLogin={showLogin}
                setShowLogin={setShowLogin}
            />

            {isUserLoading && loadingSpinner}
            {!isUserLoading && <>
                <SentryNoDev fallback={<ErrorBoundaryFallback />}>
                    {/* <Suspense fallback={loadingSpinner}> */}
                    <Suspense fallback={<div />}>
                        <Routes>
                            <Route element={<RouteListenerLayout />}>
                                <Route path='/' element={<HomePage />} />

                                {/* LANDING PAGES */}
                                {/* ============= */}
                                <Route path='/about' element={<AboutUs />} />
                                <Route path='/product-summary' element={<ProductSummary />} />
                                <Route path='/consulting' element={<Consulting />} />
                                <Route path='/my-account/:view' element={<MyAccount />} />
                                <Route path='/my-account' element={<Navigate replace to='/my-account/subscribe' />} />
                                <Route path='/unsubscribe' element={<Unsubscribe />} />
                                <Route path='/reset-password' element={<ForgotPassword />} />
                                <Route path='/send-verification-email' element={<SendVerificationEmail />} />
                                <Route path='/reset-password/:token' element={<ResetPassword />} />
                                <Route path='/admin' element={<AdminPage />} />
                                <Route path='/verify-email-page/:verifyStatus' element={<VerifyEmail />} />
                                <Route path='/login-with-link/:loginToken' element={<LoginWithLink />} />
                                <Route path='/site-guide' element={<HelpPage />} />
                                <Route path='/getting-started' element={<TipsPage />} />
                                <Route path='/signup' element={
                                    <CheckoutFormWrapper>
                                        <SignupPage />
                                    </CheckoutFormWrapper>
                                } />

                                {/* TOOL PAGES */}
                                {/* ========== */}
                                <Route path='/tools/home' element={<ToolsLanding />} />
                                <Route path='/tools/shot-charts' element={<ShotChartsApp />} />
                                <Route path='/tools/team-scatter' element={<TeamScatterApp />} />
                                <Route path='/tools/player-scatter' element={<PlayerScatterApp />} />
                                <Route path='/tools/bar-charts' element={<BarChartsApp />} />
                                <Route path='/tools/model-fitting' element={<RegressionApp />} />
                                <Route path='/tools/march-madness' element={<MarchMadnessApp />} />
                                <Route path='/tools/comparisons' element={<ComparisonsApp />} />
                                <Route path='/tools/game-calculator' element={<PlayerRatingsApp />} />
                                <Route path='/tools/diy-shot-charts' element={<DIYShotChart />} />

                                <Route path='/tools/game-deciding-shots/:competitionId/:divisionLabel' element={<GameDecidingShotsApp />} />
                                <Route path='/tools/single-game-leaders/:competitionId/:divisionLabel' element={<GameStatsLeaderboard />} />
                                <Route path='/tools/scoring-streaks/:competitionId/:divisionLabel' element={<StreaksApp />} />

                                <Route path='/tools/new-app' element={<DefaultAppStarter />} />
                                <Route path='/development-sandbox' element={<DevSandbox />} />
                                <Route path='/streaming' element={<StreamTesting />} />
                                <Route path='/streaming/:gameId' element={<StreamTesting />} />
                                <Route path='/insights/:view' element={<BlogController />} />

                                {/* WEBSITE PTGC+ STATS */}
                                {/* ============================================== */}
                                <Route path='/stats/home' element={<StatsLanding />} />
                                {/* Main Stats Pages */}
                                <Route path='/stats/:competitionId/tournaments/:tournamentId/:view' element={<TourneyController />} />
                                <Route path='/stats/:competitionId/portal/:view' element={<TransferPortalController />} />
                                <Route path='/stats/:competitionId/transfers/:divisionId/:view' element={<TransferHistoryController />} />
                                <Route path='/stats/:competitionId/draft/:view' element={<DraftController />} />
                                <Route path='/stats/:competitionId/division/:divisionLabel/:view' element={<DivisionController />} />
                                <Route path='/stats/:competitionId/players/:playerId/:view' element={<PlayerController />} />
                                <Route path='/stats/:competitionId/teams/:teamId/:view' element={<TeamController />} />
                                <Route path='/stats/:competitionId/games/:gameId/:view' element={<GameController />} />
                                <Route path='/stats/:competitionId/conferences/:conferenceId/:view' element={<ConfController />} />
                                {/* Section Landing Pages (down to 1) */}
                                <Route path='/stats/conference-home/:competitionId' element={<ConfLanding />} />
                                <Route path='/tools/scoreboard/:competitionId/:divisionLabel/:gameDate' element={<ScoreboardApp />} />
                                {/* ============ */}

                                <Route path='/terms-of-use' element={<TermsOfUse />} />
                                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                                <Route path='/disclaimer' element={<Disclaimer />} />

                                <Route path='/hidden-demo-page' element={<HiddenDemoPage />} />
                                <Route path='/error-fallback' element={<ErrorBoundaryFallback />} />

                                <Route path='*' element={<NoMatch />} />
                            </Route>
                        </Routes>
                    </Suspense>
                </SentryNoDev>

                {/* don't market towards teams initially */}
                {/* {showFreeTierAlert && <FooterAlert secs={20} color='#0066CC' alertType='freeTier' />} */}
                {showSurveyAlert && <FooterAlert secs={27} color='darkgreen' alertType='submitSurveyForFreeWeek' />}
                {showFreeTierAlert && <FooterAlert secs={18} color='#0066CC' alertType='freeTier' />}
                {/* {showMarchTourney && <FooterAlert secs={12} color='darkorange' alertType='signupForMarchMadnessTourney' />} */}
                <AppFooter />
                <TableHeaderTooltip />
                <TableInfoModalTooltip />
            </>}
        </GlobalContext.Provider>
        // </MathJaxContext>
    );
}

export default App;


// NC: saving for future reference on redirect logic
// ===-===
// // For redirecting from old portal route (no competitionId) to new portal route
// const RedirectPortal = () => {
//     const { view } = useParams();
//     return <Navigate replace to={`/stats/36046/portal/${view}`} />;
// };

// <Route path='/stats/portal/:view' element={<RedirectPortal />} />
