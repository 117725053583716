
import React, { useContext, useState } from 'react';
import GlobalContext from '../../context/GlobalContext';
import { Row, Col } from 'react-bootstrap';
import Logo from '../uiUxItems/Logo';
import PlayerImage from '../uiUxItems/PlayerImage';

import { Navbar } from 'react-bootstrap';
import useScreenSize from '../../hooks/useScreenSize';

function ControllerNavbar({
    className = '',
    label = 'Conference',
    subLabel = '',
    recordLabel = '',
    view = null,
    ptgc = null,
    handleViewChange = null,
    // info on the selected [team,player,game,conference,division]
    conferenceId = null, // optional, for showing conference logo
    competitionId = null, // optional, for logo links to work
    teamId = null, // optional, for showing team logo
    gameInfo = null,
    playerInfo = null,
    tournamentInfo = null,
    divId = null,
    // other settings
    pad = false,
    showButtons = true // hide for tweet images
}) {
    // console.log('ControllerNavbar Props: ', { className, label, subLabel, recordLabel, view, ptgc, conferenceId, teamId, homeInfo, awayInfo, gameInfo, playerInfo, tournamentInfo, pad, handleViewChange });

    // grab constants from parameters passed
    const { homeId, homeMarket, homeName, homeConferenceId, awayId, awayMarket, awayName, awayConferenceId } = gameInfo || {};
    const hasGameInfo = gameInfo && Object.keys(gameInfo).length > 0;
    const gameId = hasGameInfo ? gameInfo.gameId : null;
    const gameDate = hasGameInfo ? gameInfo.gameDate : null;
    const homeRecord = hasGameInfo ? `${gameInfo?.homeOverallWins || 0}-${gameInfo?.homeOverallLosses || 0} (${gameInfo?.homeConfWins || 0}-${gameInfo?.homeConfLosses || 0})` : null;
    const awayRecord = hasGameInfo ? `${gameInfo?.awayOverallWins || 0}-${gameInfo?.awayOverallLosses || 0} (${gameInfo?.awayConfWins || 0}-${gameInfo?.awayConfLosses || 0})` : null;
    const homeScore = hasGameInfo ? gameInfo.homeScore : null;
    const awayScore = hasGameInfo ? gameInfo.awayScore : null;
    const hasPlayerInfo = playerInfo !== null;
    const playerId = playerInfo ? playerInfo.playerId : null;
    const hasPlayerImage = playerInfo ? playerInfo.hasImage : false;

    // grab screensize
    const { isMd } = useScreenSize();

    // If no view or ptgc, return label only
    if (view === null || ptgc === null) {
        return (
            <div className='controller-navbar-container'>
                <h3 className={`header${pad === true ? ' pad' : ''}`}>
                    {label}
                    <span className='show-big' style={{ fontSize: '0.7em' }}>{subLabel}</span>
                    {/* <span className='show-small' style={{ fontSize: '0.7em' }}>{subLabel.replace(`Women's Basketball`, 'WBB').replace(`Men's Basketball`, 'MBB')}</span> */}
                </h3>
            </div>
        );
    }

    // Grab Colors and Set Background Color
    let { teamInfosObj, conferenceInfosObj, trialPlayers } = useContext(GlobalContext);
    let hasConferenceInfo = conferenceId !== null && conferenceInfosObj && conferenceInfosObj[conferenceId] ? true : false;
    let conferenceColor = hasConferenceInfo ? (conferenceInfosObj[conferenceId].hexColor1 || '#666666') : '#666666';
    let hasTeamInfo = teamId !== null && teamInfosObj && teamInfosObj[teamId] ? true : false;
    let teamColor = hasTeamInfo ? (teamInfosObj[teamId].hexColor1 || '#666666') : '#666666';
    let hasHomeInfo = homeId && teamInfosObj && teamInfosObj[homeId] ? true : false;
    let homeColor = hasHomeInfo ? (teamInfosObj[homeId].hexColor1 || '#AAAAAA') : '#AAAAAA';
    let hasAwayInfo = awayId && teamInfosObj && teamInfosObj[awayId] ? true : false;
    let awayColor = hasAwayInfo ? (teamInfosObj[awayId].hexColor1 || '#AAAAAA') : '#AAAAAA';
    let backgroundColor;
    switch (ptgc) {
        case 'division': backgroundColor = '#0066CC'; break;
        case 'player': backgroundColor = teamColor; break;
        case 'team': backgroundColor = teamColor; break;
        case 'game': backgroundColor = `linear-gradient(to right, ${homeColor} 0%, ${homeColor} 50%, ${awayColor} 50%, ${awayColor} 100%)`; break;
        case 'conference': backgroundColor = conferenceColor; break;
        case 'tournament': backgroundColor = '#0066CC'; break;
        case 'portal': backgroundColor = '#0066CC'; break;
        case 'transfer': backgroundColor = '#0066CC'; break;
        case 'comparisons': backgroundColor = '#0066CC'; break;
        case 'tyingGoAhead': backgroundColor = '#0066CC'; break;
        case 'draft': backgroundColor = '#0066CC'; break;
        case 'scoreboard': backgroundColor = '#0066CC'; break;
        case 'tools': backgroundColor = '#0066CC'; break;
        default: console.log('bad ptgc value'); backgroundColor = '#444444';
    }

    // Grab divisionId
    let hasTourneyInfo = tournamentInfo !== null;
    let divisionId = divId !== null ? divId : // first check for divId param!
        (hasTeamInfo ? teamInfosObj[teamId].divisionId
            : (hasPlayerInfo ? playerInfo.divisionId
                : (hasConferenceInfo ? conferenceInfosObj[conferenceId].divisionId
                    : (hasHomeInfo && hasAwayInfo ? Math.min(teamInfosObj[homeId].divisionId, teamInfosObj[awayId].divisionId)
                        : (hasTourneyInfo ? (tournamentInfo.isAllD1 ? 1 : 2) : null)))));

    // grab tournamentId
    let tournamentId = tournamentInfo?.tournamentId || null;

    // Page-Access Defaults for Button Disabling
    // ==========================================
    const { userData, userTier } = useContext(GlobalContext);
    const userConferenceId = userData?.user?.conference?.value || null;
    const utv = userTier.value;
    const t1TrialT2 = false; // Gives Tier-1 (free tier) access to Tier-2 (fan tier) Content

    // Team Pages (Basic Pages need Tier2, Premium Pages need Tier2 (D2, D3) or Tier3 (D1), 1 Default Team gets All Pages)
    const userTeamId = userData && userData.token ? userData.user.team.teamId : null;
    const teamBasicAccess = utv >= 2 || (utv === 1 && teamId === userTeamId) || (utv === 1 && t1TrialT2);
    const teamPremiumAccess = utv >= 3 || (utv === 2 && divisionId !== 1) || ([1, 2].includes(utv) && teamId === userTeamId);

    // Player Pages (Basic Pages need Tier2, Premium Pages need Tier2 (D2, D3) or Tier3 (D1), 2 Default Players get All Pages)
    const trialPlayerIds = trialPlayers.map(row => row.playerId);
    const playerBasicAccess = utv >= 2 || (utv === 1 && trialPlayerIds.includes(playerId)) || (utv === 1 && t1TrialT2);
    const playerPremiumAccess = utv >= 3 || (utv === 2 && divisionId !== 1) || ([1, 2].includes(utv) && trialPlayerIds.includes(playerId));

    // Conference Pages (Basic Pages need Tier2, Premium Pages need Tier2 (D2, D3) or Tier3 (D1), 1 Default Conference gets All Pages)
    const isDefaultConf = conferenceId === 100; // Great American Conference (lucky for them)
    const confsBasicAccess = utv >= 2 || (utv === 1 && isDefaultConf) || (utv === 1 && t1TrialT2);
    const confsPremiumAccess = utv >= 3 || (utv === 2 && divisionId !== 1) || ([1, 2].includes(utv) && isDefaultConf);

    // Game Pages (Basic Pages need Tier2, Premium Pages need Tier2 (D2, D3) or Tier3 (D1), 2 Default Games get All Pages)
    const defaultGameIds = [1864019, 1863338]; // 2020-2021 national title games
    const gameBasicAccess = utv >= 2 || (utv === 1 && defaultGameIds.includes(gameId)) || (utv === 1 && t1TrialT2);
    const gamePremiumAccess = utv >= 3 || (utv === 2 && divisionId !== 1) || ([1, 2].includes(utv) && defaultGameIds.includes(gameId));

    // Division Pages (Basic Pages need Tier2, Premium Pages need Tier2 (D2, D3) or Tier3 (D1))
    const divisionDemoAccess = utv >= 1;
    const divisionBasicAccess = utv >= 2 || (utv === 1 && t1TrialT2);
    const divisionPremiumAccess = utv >= 3 || (utv === 2 && divisionId !== 1);

    // Access to tournament, portal, draft pages
    const portalAccess = utv >= 3;
    const draftAccess = utv >= 1; // Free Tier gets full access to Draft Stats
    // const tourneyAccess = needsTier2 = utv <= 1 && isAllD1 === true && !isDemo;
    // const tourneyAccess = utv >= 2 || [2225, 2227].includes(tournamentId) ||  || ; // User Tier gets full access to Tourney Stats
    const tourneyDemo = utv <= 1 && [2225, 2227].includes(tournamentId);
    const d23Tourney = utv <= 1 && tournamentInfo?.isAllD1 === false;
    const tourneyAccess = utv >= 2 || tourneyDemo || d23Tourney;
    // ========

    // Create Objects to Navigate Over
    let controllerOptions = {
        player: [
            ...(playerBasicAccess ? [{ value: 'overview', label: 'Overview' }] : []),
            ...(playerPremiumAccess ? [{ value: 'shooting', label: 'Shooting' }] : []),
            ...(playerBasicAccess ? [{ value: 'game-recaps', label: 'Game Recaps' }] : []),
            ...(playerPremiumAccess ? [{ value: 'on-off', label: 'On-Off' }] : []),
            ...(playerBasicAccess ? [{ value: 'comps', label: 'Comps' }] : []),
            ...(playerPremiumAccess ? [{ value: 'career', label: 'Career' }] : []),
            ...(!playerBasicAccess ? [{ value: 'user-tier', label: 'User Tier Access', disabled: true }] : []),
            ...(!playerPremiumAccess ? [{ value: 'pro-tier', label: 'Pro Tier Access', disabled: true }] : [])
        ],
        team: [
            ...(teamBasicAccess ? [{ value: 'overview', label: 'Overview' }] : []),
            ...(teamBasicAccess ? [{ value: 'shooting', label: 'Shooting' }] : []),
            ...(teamPremiumAccess ? [{ value: 'pbp-stats', label: 'PBP Stats' }] : []),
            ...(teamPremiumAccess ? [{ value: 'by-start-and-length', label: 'By Start & Length' }] : []),
            ...(teamBasicAccess ? [{ value: 'player-box', label: 'Player Box' }] : []),
            ...(teamBasicAccess ? [{ value: 'player-pbp', label: 'Player PBP' }] : []),
            ...(teamPremiumAccess ? [{ value: 'player-profiles', label: 'Player Profiles' }] : []),
            ...(teamBasicAccess ? [{ value: 'game-recaps', label: 'Game Recaps' }] : []),
            ...(teamBasicAccess ? [{ value: 'lineups', label: 'Lineups' }] : []),
            ...(teamPremiumAccess ? [{ value: 'combos', label: 'Combos' }] : []),
            ...(teamPremiumAccess ? [{ value: 'on-off', label: 'On-Off' }] : []),
            ...(teamPremiumAccess ? [{ value: 'teammates', label: 'Teammates' }] : []),
            ...(!teamBasicAccess ? [{ value: 'user-tier', label: 'User Tier Access', disabled: true }] : []),
            ...(!teamPremiumAccess ? [{ value: 'pro-tier', label: 'Pro Tier Access', disabled: true }] : [])
        ],
        game: [
            ...(gameBasicAccess ? [{ value: 'overview', label: 'Overview' }] : []),
            ...(gamePremiumAccess ? [{ value: 'team-pbp', label: 'Team PBP' }] : []),
            ...(gameBasicAccess ? [{ value: 'player-box', label: 'Player Box' }] : []),
            ...(gamePremiumAccess ? [{ value: 'player-pbp', label: 'Player PBP' }] : []),
            ...(gamePremiumAccess ? [{ value: 'lineup-flow', label: 'Lineup Flow' }] : []),
            ...(gamePremiumAccess ? [{ value: 'all-game-pages', label: 'All Five' }] : []),
            ...(!gameBasicAccess ? [{ value: 'user-tier', label: 'User Tier Access', disabled: true }] : []),
            ...(!gamePremiumAccess ? [{ value: 'pro-tier', label: 'Pro Tier Access', disabled: true }] : []),
            // Admin Pages Only for now
            ...(utv >= 8 ? [{ value: 'trackers', label: 'Trackers' }] : []),
            ...(utv >= 8 ? [{ value: 'shot-chart', label: 'Shot Chart, GS Raw' }] : []),
            ...(utv >= 8 ? [{ value: 'match-actions', label: 'Shot Log' }] : []),
            ...(utv >= 8 ? [{ value: 'testing', label: 'Testing' }] : []),
            ...(utv >= 8 ? [{ value: 'post-game', label: 'Post' }] : [])
        ],
        conference: [
            ...(confsBasicAccess || (utv === 1 && conferenceId === userConferenceId) ? [{ value: 'team-box', label: 'Team Box' }] : []),
            ...(confsPremiumAccess ? [{ value: 'team-pbp', label: 'Team PBP' }] : []),
            ...(confsPremiumAccess ? [{ value: 'team-shooting', label: 'Team Shooting' }] : []),
            ...(confsPremiumAccess ? [{ value: 'by-start-and-length', label: 'By Start & Length' }] : []),
            ...(confsBasicAccess ? [{ value: 'player-box', label: 'Player Box' }] : []),
            ...(confsPremiumAccess ? [{ value: 'player-pbp', label: 'Player PBP' }] : []),
            ...(confsPremiumAccess ? [{ value: 'player-shooting', label: 'Player Shooting' }] : []),
            ...(confsBasicAccess ? [{ value: 'game-recaps', label: 'Game Recaps' }] : []),
            ...(confsPremiumAccess ? [{ value: 'lineups', label: 'Lineups' }] : []),
            ...(!confsBasicAccess ? [{ value: 'user-tier', label: 'User Tier Access', disabled: true }] : []),
            ...(!confsPremiumAccess ? [{ value: 'pro-tier', label: 'Pro Tier Access', disabled: true }] : [])
        ],
        division: [
            ...(divisionDemoAccess ? [{ value: 'team-box', label: 'Team Box' }] : []),
            ...(divisionPremiumAccess ? [{ value: 'team-pbp', label: 'Team PBP' }] : []),
            ...(divisionPremiumAccess ? [{ value: 'team-shooting', label: 'Team Shooting' }] : []),
            ...(divisionBasicAccess ? [{ value: 'player-box', label: 'Player Box' }] : []),
            ...(divisionPremiumAccess ? [{ value: 'player-pbp', label: 'Player PBP' }] : []),
            ...(divisionPremiumAccess ? [{ value: 'player-shooting', label: 'Player Shooting' }] : []),
            ...(divisionPremiumAccess ? [{ value: 'player-profiles', label: 'Player Profiles' }] : []),
            ...(divisionBasicAccess ? [{ value: 'game-recaps', label: 'Game Recaps' }] : []),
            ...(divisionPremiumAccess ? [{ value: 'lineups', label: 'Lineups' }] : []),
            ...(divisionPremiumAccess ? [{ value: 'on-off', label: 'On-Off' }] : []),
            ...(!divisionBasicAccess ? [{ value: 'user-tier', label: 'User Tier Access', disabled: true }] : []),
            ...(!divisionPremiumAccess ? [{ value: 'pro-tier', label: 'Pro Tier Access', disabled: true }] : [])
        ],
        tournament: [
            ...(tourneyAccess ? [{ value: 'team-box', label: 'Team Box', disabled: t1TrialT2 ? false : !tourneyAccess }] : []),
            ...(tourneyAccess ? [{ value: 'team-pbp', label: 'Team PBP', disabled: t1TrialT2 ? false : !tourneyAccess }] : []),
            ...(tourneyAccess ? [{ value: 'team-shooting', label: 'Team Shooting', disabled: t1TrialT2 ? false : !tourneyAccess }] : []),
            ...(tourneyAccess ? [{ value: 'player-box', label: 'Player Box', disabled: t1TrialT2 ? false : !tourneyAccess }] : []),
            ...(tourneyAccess ? [{ value: 'player-pbp', label: 'Player PBP', disabled: t1TrialT2 ? false : !tourneyAccess }] : []),
            ...(tourneyAccess ? [{ value: 'player-shooting', label: 'Player Shooting', disabled: t1TrialT2 ? false : !tourneyAccess }] : []),
            ...(tourneyAccess ? [{ value: 'game-recaps', label: 'Game Recaps', disabled: t1TrialT2 ? false : !tourneyAccess }] : []),
            ...(tourneyAccess ? [{ value: 'lineups', label: 'Lineups', disabled: t1TrialT2 ? false : !tourneyAccess }] : []),
            ...(!tourneyAccess ? [{ value: 'user-tier', label: 'User Tier Access', disabled: true }] : []),
            ...(!tourneyAccess ? [{ value: 'pro-tier', label: 'Pro Tier Access', disabled: true }] : [])
        ],
        portal: [
            ...(portalAccess ? [{ value: 'overview', label: 'Overview' }] : []),
            ...(portalAccess ? [{ value: 'player-box', label: 'Player Box' }] : []),
            ...(portalAccess ? [{ value: 'player-pbp', label: 'Player PBP' }] : []),
            ...(portalAccess ? [{ value: 'player-shooting', label: 'Player Shooting' }] : []),
            ...(portalAccess ? [{ value: 'player-compare', label: 'Player Compare' }] : []),
            ...(portalAccess ? [{ value: 'player-profiles', label: 'Player Profiles' }] : []),
            ...(!portalAccess ? [{ value: 'pro-tier', label: 'Pro Tier Access', disabled: true }] : [])
        ],
        draft: [
            { value: 'player-box', label: 'Player Box', disabled: !draftAccess },
            { value: 'player-pbp', label: 'Player PBP', disabled: !draftAccess },
            { value: 'player-shooting', label: 'Player Shooting', disabled: !draftAccess },
            { value: 'player-compare', label: 'Player Compare', disabled: !draftAccess },
            { value: 'player-profiles', label: 'Player Profiles', disabled: !draftAccess }
        ],
        scoreboard: [],
        tools: [],
        transfer: [],
        comparisons: [],
        tyingGoAhead: []

        // cant wait for the compare features
        // compare: [
        //     { value: 'teams', label: 'Teams', disabled: false },
        //     { value: 'players', label: 'Teams', disabled: false },
        //     { value: 'games', label: 'Teams', disabled: false },
        //     { value: 'lineups', label: 'Teams', disabled: false }
        // ]
    };


    // Create Navigation Buttons
    // ==========================
    // const [expanded, setExpanded] = useState(false);
    // onClick={() => setExpanded(expanded ? false : 'expanded')}
    const [expanded, setExpanded] = useState(false);

    let handlerExists = (handleViewChange !== null);
    let pageButtons = controllerOptions[ptgc].map((thisView, idx) => {
        return (
            <span
                key={thisView.value + '-' + idx}
                className={`button${view === thisView.value ? ' selected' : ''} ${thisView.disabled ? 'disabled' : ''}`}
                // onClick={handlerExists ? handleViewChange.bind(this, thisView.value) : null}
                onClick={() => {
                    if (handlerExists) { handleViewChange(thisView.value); }
                    setExpanded(expanded && false);
                }}
            >
                {thisView.label}
            </span>
        );
    });

    // {/* alignSelf: 'flex-start',  */}
    let pageButtonNavbar =
        (<Navbar expanded={expanded} expand='lg' className='hide-print page-tabs-navbar' style={{ padding: 0 }}>
            <Navbar.Toggle className='cbb-hamburger' aria-controls='button-group-nav' onClick={() => setExpanded(expanded ? false : 'expanded')} />
            <Navbar.Collapse id='button-group-nav' className='buttons game-buttons'>
                {pageButtons}
            </Navbar.Collapse>
        </Navbar>);
    // ======


    // Create Left + Right Logos
    // ==========================
    let homeTeamLogo =
        (<Logo
            className='logo-sticker'
            style={{ marginRight: 10, borderRadius: 10 }} // height/width in app.scss
            ptgc='team'
            teamId={homeId}
            competitionId={competitionId}
        />);
    let homeConfLogo =
        (<Logo
            className='logo-sticker'
            id='hide-mobile'
            style={{ borderRadius: 10 }}
            ptgc='conference'
            conferenceId={homeConferenceId}
            competitionId={competitionId}
        />);
    let awayTeamLogo =
        (<Logo
            className='logo-sticker'
            style={{ marginRight: 10, borderRadius: 10 }}
            ptgc='team'
            teamId={awayId}
            competitionId={competitionId}
        />);
    let awayConfLogo =
        (<Logo
            className='logo-sticker'
            id='hide-mobile'
            style={{ borderRadius: 10 }}
            ptgc='conference'
            conferenceId={awayConferenceId}
            competitionId={competitionId}
        />);
    let teamLogo =
        (<Logo
            className='logo-sticker'
            style={{ marginRight: 10, borderRadius: 10 }}
            ptgc='team'
            teamId={teamId}
            competitionId={competitionId}
        />);
    let confLogo =
        (<Logo
            className='logo-sticker'
            style={{ borderRadius: 10 }}
            ptgc='conference'
            conferenceId={conferenceId}
            competitionId={competitionId}
        />);
    let naiaLogo =
        (<Logo
            className='logo-sticker'
            style={{ borderRadius: 10 }}
            ptgc='conference'
            conferenceId={300} // NC chose 300 for NAIA logo saved in GCS
            competitionId={competitionId}
        />);
    let njcaaLogo =
        (<Logo
            className='logo-sticker'
            style={{ borderRadius: 10 }}
            ptgc='conference'
            conferenceId={301} // NC chose 301 for NJCAA logo saved in GCS
            competitionId={competitionId}
        />);
    let playerImage =
        (<PlayerImage
            className='logo-sticker'
            style={{ marginRight: 12, borderRadius: 10, width: 'inherit' }}
            imgStyle={{ maxHeight: 101, width: '100%' }} // imgStyle={{ height: 'calc(27px + 6vw)', maxHeight: 101, width: 'auto' }}
            playerId={playerId}
            hasImage={hasPlayerImage}
            teamId={teamId}
        />);

    let leftLogos = <></>;
    switch (ptgc) {
        case 'game': leftLogos = (!homeId && !homeConferenceId) ? <></> :
            (<div className='logos-wrapper'>
                {homeId && homeTeamLogo}
                {homeConferenceId && homeConfLogo}
            </div>); break;
        default: leftLogos = <></>;
    }

    let rightLogos = ['portal', 'transfer', 'draft', 'division', 'tools', 'comparisons', 'tyingGoAhead'].includes(ptgc) ? null : (ptgc !== 'game'
        ? (<div className='logos-wrapper'>
            {ptgc === 'player' && playerId && playerImage}
            {['team', 'player'].includes(ptgc) && teamId && conferenceId !== 0 && teamLogo}
            {conferenceId === 0 ? naiaLogo : null}
            {conferenceId === 0 ? njcaaLogo : null}
            {conferenceId && conferenceId > 0 ? confLogo : null}
        </div>)
        : ((!homeId && !homeConferenceId) ? <></> :
            <div className='logos-wrapper'>
                {awayTeamLogo}
                {awayConfLogo}
            </div>
        ));

    // no logos for tournament, scoreboard
    rightLogos = ['tournament', 'scoreboard'].includes(ptgc) ? <></> : rightLogos;
    // ======

    // Hide some text on small screens
    const { isXs } = useScreenSize();

    // And Return
    return (
        <div className={`controller-navbar-container ${className}`} style={{ background: backgroundColor }}>
            {leftLogos}
            {ptgc !== 'game' &&
                <div className='header-wrapper'>
                    {showButtons && isMd && pageButtonNavbar}
                    <h3 className={`header ${pad === true ? 'pad' : ''}`}>
                        {label}
                        <span className='show-big' style={{ fontSize: '0.8em' }}>{subLabel}</span>
                        <span className='show-small' style={{ fontSize: '0.8em' }}>{subLabel.replace(`Women's Basketball`, 'WBB').replace(`Men's Basketball`, 'MBB')}</span>
                    </h3>
                    {!isXs &&
                        <h3 className='win-loss-label'>
                            {recordLabel}
                        </h3>
                    }
                    {showButtons && !isMd && pageButtonNavbar}
                </div>
            }
            {ptgc === 'game' && <>
                <Row className='header-wrapper' style={{ width: '100%', flexDirection: 'row', alignSelf: 'stretch' }}>
                    <Col className='game-lhs-col' xs={6}>
                        <div className='game-score'>
                            {homeScore}
                        </div>
                        <div>
                            <h3 className='game-header'>{homeMarket} {homeName}</h3>
                            <h3 className='game-record'>{homeRecord}</h3>
                        </div>
                    </Col>
                    <Col className='game-rhs-col' xs={6}>
                        <div>
                            <h3 className='game-header'>{awayMarket} {awayName}</h3>
                            <h3 className='game-record'>{awayRecord}</h3>
                        </div>
                        <div className='game-score'>
                            {awayScore}
                        </div>
                    </Col>
                    {/* <Col xs={12} className='buttons game-buttons hide-print'>
                        {navbarOptions}
                    </Col> */}
                    <Col xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', padding: '0px 18px' }}>
                        {showButtons && pageButtonNavbar}
                        {gameDate &&
                            <div className='game-date'>
                                Game on {gameDate}
                            </div>
                        }
                    </Col>
                </Row>
            </>}

            {rightLogos}
        </div >
    );
}

export default ControllerNavbar;


// <Row style={{ position: 'relative', justifyContent: 'center', width: '100%' }}>
//     <Col xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingRight: 35 }}>
//         <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
//             <h3 style={{ fontSize: '1.2em', color: 'white', margin: 5, fontWeight: 700 }}>{homeRecord}</h3>
//             <h3 className='game-header'>{homeMarket}</h3>
//         </div>
//         <h3 className='game-header' style={{ float: 'right' }}>{homeName}</h3>
//     </Col>
//     <Col xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: 35 }}>
//         <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
//             <h3 className='game-header'>{awayMarket}</h3>
//             <h3 style={{ fontSize: '1.2em', color: 'white', margin: 5, fontWeight: 700 }}>{awayRecord}</h3>
//         </div>
//         <h3 className='game-header'>{awayName}</h3>
//     </Col>
//     <Col xs={12} className='buttons' style={{ display: 'flex', justifyContent: 'center' }}>
//         {navbarOptions}
//     </Col>
//     <h3 className='game-date-label'>
//         {gameDateLabel}
//     </h3>
//     <div className='vs-circle'>
//         vs
//     </div>
// </Row>


// <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 5 }}>
// <div>
//     <div style={{ width: '50%', paddingRight: 20, textAlign: 'right' }}>
//         <h3 className='game-header'>{homeMarket} {homeName}</h3>
//         <h3 className='game-score'>{homeRecord}</h3>
//     </div>
//     <div style={{ width: '50%', paddingLeft: 20 }}>
//         <h3 className='game-header'>{awayMarket} {awayName}</h3>
//         <h3 className='game-score'>{awayRecord}</h3>
//     </div>
// </div>
// <div>
//     {navbarOptions}
// </div>
// </div>
