
// Import React Components
import React, { useState } from 'react';
import { Container, Row, FormControl, Alert } from 'react-bootstrap';
import axios from 'axios';
import config from '../../config';

// Create The Component
function SendVerificationEmail() {
    // useState: set component state
    const [email, setEmail] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [error, setError] = useState(null);

    // Reset Password handler function
    const handleButtonSubmit = async (e) => {
        setIsUpdating(true);
        e.preventDefault();
        try {
            let apiBaseUrl = config.url.API_URL;
            let postResponse = await axios.post(`${apiBaseUrl}/email/send-verification-email`, { email: email });

            // Set States Following Post Request which Sends Email
            setIsUpdating(false);
            setShowAlert(true);
            setTimeout(() => { setShowAlert(false); setEmail(''); }, 6000);
        } catch (err) {
            setIsUpdating(false);
            if (!err.response) {
                setError('Error When Sending Email');
            } else {
                // eslint-disable-next-line no-unused-expressions
                err.response.data && err.response.data.msg && setError(err.response.data.msg);
            }
            setTimeout(() => { setError(null); }, 4000);
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e);
    };

    // Create Submit Button
    const submitButton =
        (<div
            className={`cbb-submit-button ${showAlert ? 'faded' : ''}`}
            style={{ width: 295, textAlign: 'center' }}
            onClick={handleButtonSubmit}
            onChange={handleEmailChange}
        >
            {`${isUpdating ? 'Sending Email...' : 'Resend Verification Email'}`}
        </div>);

    // Create Email Form Input
    const emailFormControl =
        (<FormControl
            className='modal-input'
            style={{ margin: 0 }}
            type='text'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            name='email'
            placeholder='Email Address'
        />);


    return (
        <Container style={{ maxWidth: '100%' }}>
            <Row style={{ justifyContent: 'center', margin: '10px', marginTop: '40px' }}>
                {emailFormControl}
            </Row>
            <Row style={{ justifyContent: 'center', margin: '10px' }}>
                {submitButton}
            </Row>
            {(error && error.length > 0) &&
                <Row style={{ justifyContent: 'center', margin: '10px' }}>
                    <Alert style={{ minWidth: '275px' }} variant='danger' className='animate-fade-away'>
                        {error}
                    </Alert>
                </Row>
            }
            {showAlert &&
                <Row style={{ justifyContent: 'center', margin: '10px' }}>
                    <Alert style={{ minWidth: '275px' }} variant='success' className='animate-fade-away'>
                        Email sent to {email}
                    </Alert>
                </Row>
            }
        </Container>
    );
}

export default SendVerificationEmail;
