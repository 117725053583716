
import React from 'react';
import { Alert } from 'react-bootstrap';

const PaymentAlert = ({
    variant, // success, danger
    showAlert, // to show or not to show
    setShowAlert, // handler
    alertMessage = 'Payment Successful!'
}) => {
    // Successful Payment Alert
    const successAlertStyles = showAlert ? {} : { opacity: 0, visibility: 'hidden', pointerEvents: 'none', display: 'none' };
    const alert =
        (<Alert
            className={`modal-alert ${showAlert ? 'animate-fade-away' : ''}`}
            style={{ ...successAlertStyles }}
            variant={variant}
            onAnimationEnd={() => setShowAlert(false)}
        >
            {alertMessage}
        </Alert>);

    // And Return
    return (alert);
};

export default PaymentAlert;
