
// Using Global Search to Set Page State...

// Import React Stuff
import React, { useContext, useState } from 'react';
import Select, { components } from 'react-select';

// Import Data Fetching Hook
import useLiveSearchApi from '../../hooks/useLiveSearchApi.js';

// Import Utis
import { liveSearchStyles } from '../../utils/ReactSelectStyles.js';
import { MdSearch } from 'react-icons/md';
import { isNil } from '../../utils/ReshapeData.js';
import { allCompetitionIds } from '../../harddata/NcaaStructures.js';
import GlobalContext from '../../context/GlobalContext.js';


function CBBLiveSelect({
    selectType,
    stylesType = 'small', // this isnt proper?
    value,
    setValue,
    placeholder = '',
    ids = { competitionId: null, divisionId: null },
    gender = null,
    isDisabled = false,
    overrideStyle = null
}) {
    // Set Page State
    const [searchText, setSearchText] = useState('');
    const { userTier } = useContext(GlobalContext);

    // Fetch App Select Options
    const pass = userTier.value === 0;
    let searchConfig = {
        ptgc: selectType,
        searchText: searchText,
        // ...(!isNil({ d: ids.competitionIds }) && { competitionIds: ids.competitionIds }),
        ...(!isNil({ d: ids.competitionId }) && { competitionId: ids.competitionId }),
        ...(!isNil({ d: ids.divisionId }) && { divisionId: ids.divisionId }),
        pass
    };

    const [ptgcTopResults, isLoading, isError] = useLiveSearchApi({ initialValue: [], cfg: searchConfig });
    if (isError) { console.log('CBBLiveSelect Data Fetching Error!'); }
    // console.log('ptgcTopResults: ', ptgcTopResults);

    // Magnifying Glass Select
    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <MdSearch size='1.75em' color='#333333' />
                </components.DropdownIndicator>
            )
        );
    };

    // Filter for Gender
    const filteredResults = ptgcTopResults
        .filter(row => gender === 'MALE' ? row.competitionIds.some(id => allCompetitionIds.male.includes(id)) : true)
        .filter(row => gender === 'FEMALE' ? row.competitionIds.some(id => allCompetitionIds.female.includes(id)) : true);

    // Create The Select
    const teamsSelect =
        (<Select
            className='cbb-select'
            styles={liveSearchStyles}
            value={value}
            options={filteredResults}
            onChange={setValue}
            onInputChange={setSearchText}
            getOptionValue={(option) => option._id}
            noOptionsMessage={() => null}
            placeholder={placeholder}
            isDisabled={isDisabled} // live search is never disabled
            components={{
                DropdownIndicator: DropdownIndicator,
                IndicatorSeparator: () => null,
                onMouseDown: (e) => e.stopPropagation()
            }}
        />);

    return (
        <div style={{ width: 275, marginRight: 3, marginTop: 3, ...(overrideStyle && overrideStyle) }}>
            {stylesType === 'large' &&
                <p className={`large-select-text`}>
                    Search Teams:
                </p>
            }
            {teamsSelect}
        </div>
    );
}

export default CBBLiveSelect;
