
import React from 'react';
import { Link } from 'react-router-dom';
import { teamLogoUrl } from '../../utils/GetLogos';
import { Row } from 'react-bootstrap';
import questionMark from '../../images/question-mark.png';
import { formatAMPM } from '../../utils/ReshapeData';
import { allCompetitionIds } from '../../harddata/NcaaStructures';

function GameCard({
    gameInfoCbb = null,
    gameInfoRaw = null,
    gameStats = null,
    navigateTo = 'gameOverview', // 'gameOverview', 'streaming'
    style = {}
}) {
    // console.log('GameCard params: ', { gameInfoCbb, gameInfoRaw, gameStats, gameTime: gameInfoCbb?.gameTime });

    // handles passing EITHER gameInfo or gameStats. not great for now.
    // extract props [gameInfoRaw, gameStats, gameInfoCbb]
    let gameId = gameInfoRaw?.gameId || gameStats?.gameId || gameInfoCbb?.gameId || null;
    let competitionId = gameInfoRaw?.competitionId || gameStats?.competitionId || gameInfoCbb?.competitionId || null;
    let gameDate = gameInfoRaw?.gameDate || gameStats?.gameDate || gameInfoCbb?.gameDate || null;
    let gameStatus = gameInfoRaw?.gameStatus || gameStats?.gameStatus || gameInfoCbb?.gameStatus || null;
    let teamId1 = gameInfoRaw?.homeId || gameStats?.teamId || gameInfoCbb?.homeId || null;
    let teamId2 = gameInfoRaw?.awayId || gameStats?.teamIdAgst || gameInfoCbb?.awayId || null;
    let teamMarket1 = gameInfoRaw?.homeMarket || gameStats?.teamMarket || gameInfoCbb?.homeMarket || null;
    let teamMarket2 = gameInfoRaw?.awayMarket || gameStats?.teamMarketAway || gameInfoCbb?.awayMarket || null;
    let ptsScored1 = gameInfoRaw?.liveHomeScore || gameInfoRaw?.homeScore || gameStats?.ptsScored || gameInfoCbb?.homeScore || null;
    let ptsScored2 = gameInfoRaw?.liveAwayScore || gameInfoRaw?.awayScore || gameStats?.ptsAgst || gameInfoCbb?.awayScore || null;
    let isMale = allCompetitionIds.male.includes(competitionId);

    // live props
    let liveClock = gameInfoRaw && gameInfoRaw.liveClock ? gameInfoRaw.liveClock.slice(0, 5) : '';
    let livePeriod = gameInfoRaw ? gameInfoRaw.livePeriod : '';
    let livePeriodType = gameInfoRaw ? gameInfoRaw.livePeriod : '';
    let livePeriodString = livePeriodType === 'OVERTIME'
        ? 'OT'
        : (livePeriod === 1 ? '1ST' : (livePeriod === 2 ? '2ND' : (livePeriod === 3 ? '3RD' : '4TH')));
    livePeriodString = isMale ? `${livePeriodString} HALF` : livePeriodString;

    // team record props
    let gameTimeUtc = gameInfoCbb ? gameInfoCbb.gameTimeUtc : (gameStats ? gameStats.gameTimeUtc : gameInfoRaw.gameTimeUtc);
    gameTimeUtc = formatAMPM({ dateString: gameTimeUtc });
    let teamOverallRecord1 = gameInfoCbb ? `${gameInfoCbb?.homeOverallWins || 0} - ${gameInfoCbb?.homeOverallLosses || 0}` : '';
    let teamOverallRecord2 = gameInfoCbb ? `${gameInfoCbb?.awayOverallWins || 0} - ${gameInfoCbb?.awayOverallLosses || 0}` : '';
    let teamConfRecord1 = gameInfoCbb ? `${gameInfoCbb?.homeConfWins || 0} - ${gameInfoCbb?.homeConfLosses || 0}` : '';
    let teamConfRecord2 = gameInfoCbb ? `${gameInfoCbb?.awayConfWins || 0} - ${gameInfoCbb?.awayConfLosses || 0}` : '';
    let teamRecord1 = gameInfoCbb ? `(${teamOverallRecord1} Overall, ${teamConfRecord1} Conf)` : '';
    let teamRecord2 = gameInfoCbb ? `(${teamOverallRecord2} Overall, ${teamConfRecord2} Conf)` : '';

    // Initialize The Game Card, grab gameInfo
    let gameCard1, gameCard2, newPath;
    switch (navigateTo) {
        case 'gameOverview': newPath = `/stats/${competitionId}/games/${gameId}/overview`; break;
        case 'streaming': newPath = `/streaming/${gameId}`; break;
        default: console.log(`Error bad navigateTo value: ${navigateTo}`);
    }

    // Team1 Logo
    let team1LogoSrc = teamLogoUrl(teamId1);
    let team2LogoSrc = teamLogoUrl(teamId2);
    let createLogoDiv = (width, src) => {
        return (<div style={{ maxWidth: width, maxHeight: width }}>
            <img style={{ width: '100%' }}
                alt='sports logo'
                onError={ev => { ev.target.src = questionMark; }}
                src={src}
            />
        </div>);
    };

    // Set Variables Based on gameStatus
    let borderColor, headerText, cardClass;
    switch (gameStatus) {
        case 'SCHEDULED': cardClass = 'darkgrey'; borderColor = '#666'; headerText = `${gameDate}: at ${gameTimeUtc}`; break;
        case 'COMPLETE': cardClass = 'darkgreen'; borderColor = 'darkgreen'; headerText = `${gameDate}`; break;
        case 'IN_PROGRESS': cardClass = 'darkorange'; borderColor = '#e67e00'; headerText = `${liveClock} ${livePeriodString}`; break;
        default: cardClass = 'darkred'; borderColor = 'darkred'; headerText = `${gameDate}`;
    }

    // Game Card Renderings (Currently for D-I Game Recaps Page, may want to remove this eventually)
    let team1win = ptsScored1 > ptsScored2;
    gameCard1 = // Card 1 the original from Division Game Recaps. Very Meh
        (<Link className='conference-card' style={{ padding: 0 }} to={newPath}>
            <h3 style={{ margin: 7, fontWeight: 700, fontSize: '0.90em' }}>Final - {gameDate}</h3>
            <Row style={{ margin: 0, position: 'relative', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ paddingLeft: 5, display: 'flex', alignItems: 'center' }}>
                    {createLogoDiv(26, team1LogoSrc)}
                    <h3 style={{ margin: 0, marginLeft: 5, fontSize: '0.85em' }}>{teamMarket1}</h3>
                </div>
                <div style={{ paddingRight: 10, display: 'flex', alignItems: 'center' }}>
                    <h3 style={{ margin: 0, fontSize: '0.85em' }}>{ptsScored1}</h3>
                    <div className={`triangle-left${team1win ? '' : ' hidden'}`} />
                </div>
            </Row>
            <Row style={{ margin: '0 0 5px 0', position: 'relative', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ paddingLeft: 5, display: 'flex', alignItems: 'center' }}>
                    {createLogoDiv(26, team2LogoSrc)}
                    <h3 style={{ margin: 0, marginLeft: 5, fontSize: '0.85em' }}>{teamMarket2}</h3>
                </div>
                <div style={{ paddingRight: 10, display: 'flex', alignItems: 'center' }}>
                    <h3 style={{ margin: 0, fontSize: '0.85em', float: 'right' }}>{ptsScored2}</h3>
                    <div className={`triangle-left${team1win ? ' hidden' : ''}`} />
                </div>
            </Row>
        </Link>);


    // Card Type 2, for Scoreboard App
    gameCard2 =
        (<Link className='scoreboard-card' style={{ borderColor, ...style }} to={newPath}>
            <h3 className='card-title'>
                <span className={`header-span ${cardClass}`}>{gameStatus}: </span>
                {headerText}
            </h3>
            <Row style={{ margin: 0, position: 'relative', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ paddingLeft: 5, display: 'flex', alignItems: 'center' }}>
                    {createLogoDiv(29, team1LogoSrc)}
                    <h3 className='team-title'>
                        {teamMarket1}
                        <p>{teamRecord1}</p>
                    </h3>
                </div>
                {['COMPLETE', 'IN_PROGRESS'].includes(gameStatus) &&
                    <div style={{ paddingRight: 10, display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ margin: 0, fontSize: '1.15em', float: 'right' }}>{ptsScored1}</h3>
                        {gameStatus === 'COMPLETE' && <div className={`triangle-left${team1win ? ' ' : 'hidden'}`} />}
                    </div>
                }
            </Row>
            <Row style={{ margin: '8px 0 5px 0', position: 'relative', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ paddingLeft: 5, display: 'flex', alignItems: 'center' }}>
                    {createLogoDiv(29, team2LogoSrc)}
                    <h3 className='team-title'>
                        {teamMarket2}
                        <p>{teamRecord2}</p>
                    </h3>
                </div>
                {['COMPLETE', 'IN_PROGRESS'].includes(gameStatus) &&
                    <div style={{ paddingRight: 10, display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ margin: 0, fontSize: '1.15em', float: 'right' }}>{ptsScored2}</h3>
                        {gameStatus === 'COMPLETE' && <div className={`triangle-left${team1win ? ' hidden' : ''}`} />}
                    </div>
                }
            </Row>
        </Link>);

    // Return the Game Card
    return (
        <React.Fragment>
            {gameStats ? gameCard1 : gameCard2}
        </React.Fragment>
    );
}

export default GameCard;
