
// Import React Components
import React, { useState, useEffect } from 'react';
import { Container, Row, FormControl, Alert } from 'react-bootstrap';
import axios from 'axios';
import config from '../../config';

// Create The Component
function ForgotPassword() {
    // useState: set component state
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    // Reset Password handler function
    const handleEmailSubmit = async (e) => {
        setIsUpdating(true);
        e.preventDefault();
        try {
            let apiBaseUrl = config.url.API_URL;
            await axios.post(`${apiBaseUrl}/email/forgot-password`, { email: email });

            // Set States Following Post Request which Sends Email
            setIsUpdating(false);
            setShowAlert(true);
            setTimeout(() => { setShowAlert(false); setEmail(''); }, 20000);
        } catch (err) {
            setIsUpdating(false);
        }
    };

    // Check for valid email
    useEffect(() => {
        let isCurrentEmailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? true : false;
        setIsEmailValid(isCurrentEmailValid);
    }, [email]);

    // Create Submit Button
    const submitButton =
        (<div>
            <div
                className={`cbb-submit-button ${showAlert ? 'faded' : ''} ${isEmailValid ? '' : 'disabled'}`}
                style={{ maxWidth: 375, minWidth: 375, textAlign: 'center' }}
                onClick={handleEmailSubmit}
                onChange={setEmail}
            >
                {`${isUpdating ? 'Sending Email...' : 'Send Email to Reset Password'}`}
            </div>
            {!isEmailValid &&
                <p style={{ margin: '0 0 5px 0', fontSize: '0.95em', fontStyle: 'italic', lineHeight: 1.3 }}>
                    please enter a valid email address
                </p>
            }
        </div>);

    // Create Email Form Input
    const emailFormControl =
        (<FormControl
            className='modal-input'
            style={{ margin: 0 }}
            type='text'
            value={email}
            onChange={(e) => setEmail(e.target.value.toLowerCase().trim())}
            name='email'
            placeholder='Email Address'
        />);


    return (
        <Container style={{ maxWidth: '100%' }}>
            <Row style={{ justifyContent: 'center', margin: 10, marginTop: 40 }}>
                {emailFormControl}
            </Row>
            <Row style={{ justifyContent: 'center', margin: 10 }}>
                {submitButton}
            </Row>
            {showAlert &&
                <Row style={{ justifyContent: 'center', margin: 10 }}>
                    <Alert style={{ minWidth: 375, maxWidth: 375 }} variant='success' className='animate-fade-away'>
                        If an account for {email} exists in our system, we will send a reset-password email.
                        Reach out to <span style={{ fontWeight: 700, color: '#0066CC' }}>nick@cbbanalytics.com</span> if you do not receive a reset-password email as expected.
                        {/* Email sent to {email} */}
                    </Alert>
                </Row>
            }
        </Container>
    );
}

export default ForgotPassword;
