
// Import React Components
import React from 'react';
import { Container, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player/youtube';

// Create The Component
function ProductSummary() {
    // new page lets go!
    let url1 = 'https://youtu.be/yVTaL9xiuh8';

    // let playVideo
    // The Return
    return (
        <Container className='shadow-container' style={{ maxWidth: 1100 }}>
            <Row style={{ padding: 40 }}>
                <ReactPlayer url={url1} playing />
            </Row>


        </Container>
    );
}


export default ProductSummary;
