import React from 'react';
import Helmet from 'react-helmet';
import imageUrls from '../images/gcsImages';

const HELMET_MAP = {
    barChartsApp: () => ({
        title: 'Bar Charts Application | The CBB Analytics Platform - Sports Data Visualizations and Analysis',
        link: [{ rel: 'canonical', href: 'https://node-api-image-deploy-q62r35344q-uc.a.run.app/tools/team-scatter' }],
        meta: [
            { name: 'description', content: 'bar charts app of ncaa college basketball' },
            { name: 'keywords', content: 'college basketball, team stats, college basketball data, analysis, analytics, cbb analytics, sports data, data analytics, team scatter' },
            { name: 'robots', content: 'index,follow' }
        ]
    }),
    teamController: (config) => ({
        // { url, teamMarket, teamName, pView, cName }
        title: `${config.teamMarket} ${config.teamName} ${config.cName} Stats, Team ${config.pView} Page || CBB Analytics`,
        meta: [
            { name: 'description', content: `${config.teamMarket} ${config.teamName} ${config.cName}, Team ${config.pView} - explore detailed statistics, shot charts, player performance, game analysis, and lineup and on/off stats with CBB Analytics.` },
            { name: 'keywords', content: `college basketball, ${config.teamMarket}, ${config.teamName}, ${config.year} season, ${config.pView}, team stats, player statistics, shot charts, game analysis, lineup stats, on/off stats, cbb analytics, sports data, data analytics` },
            { name: 'robots', content: 'index,follow' },
            { name: 'author', content: 'CBB Analytics' },
            { name: 'copyright', content: 'CBB Analytics, 2024' },
            { property: 'og:title', content: `${config.teamMarket} ${config.teamName} ${config.cName} Stats, Team ${config.pView}` },
            { property: 'og:description', content: `${config.teamMarket} ${config.teamName} ${config.cName}, Team ${config.pView} - explore detailed statistics, shot charts, player performance, game analysis, and lineup and on/off stats with CBB Analytics.` },
            { property: 'og:image', content: imageUrls.cbbLogoBlack },
            { property: 'og:url', content: config.url },
            { property: 'og:type', content: 'website' },
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:site', content: '@CBBAnalytics' },
            { name: 'twitter:title', content: `${config.teamMarket} ${config.cName} Stats, Team ${config.pView} Page || CBB Analytics` },
            { name: 'twitter:description', content: `${config.teamMarket} ${config.teamName} ${config.cName}, Team ${config.pView} - explore detailed statistics, shot charts, player performance, game analysis, and lineup and on/off stats with CBB Analytics.` },
            { name: 'twitter:image', content: imageUrls.cbbLogoBlack },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' },
            { httpEquiv: 'X-UA-Compatible', content: 'IE=edge' }
        ],
        link: [
            { rel: 'canonical', href: config.url }
        ]
    }),
    playerController: (config) => ({
        // { url, playerName, teamName, pView, cName }
        title: `${config.playerName} ${config.cName} Stats, Player ${config.pView} Page || CBB Analytics`,
        meta: [
            { name: 'description', content: `${config.playerName} ${config.cName} Stats, Player ${config.pView} - explore ${config.playerName}'s statistics, shot chart, player performance, game boxscores, and on/off stats with CBB Analytics.` },
            { name: 'keywords', content: 'college basketball, player stats, college basketball statistics, analysis, analytics, stats, statistics, cbb analytics, sports data, data analytics' },
            { name: 'robots', content: 'index,follow' },
            { name: 'author', content: 'CBB Analytics' },
            { name: 'copyright', content: 'CBB Analytics, 2024' },
            { property: 'og:title', content: `${config.playerName} ${config.cName} Stats, Player ${config.pView} Page || CBB Analytics` },
            { property: 'og:description', content: `${config.playerName} ${config.cName} Stats, Player ${config.pView} - explore ${config.playerName}'s statistics, shot chart, player performance, game boxscores, and on/off stats with CBB Analytics.` },
            { property: 'og:image', content: imageUrls.cbbLogoBlack },
            { property: 'og:url', content: config.url },
            { property: 'og:type', content: 'website' },
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:site', content: '@CBBAnalytics' },
            { name: 'twitter:title', content: `${config.playerName} ${config.cName} Stats, Player ${config.pView} Page || CBB Analytics` },
            { name: 'twitter:description', content: `${config.playerName} ${config.cName} Stats, Player ${config.pView} - explore ${config.playerName}'s statistics, shot chart, player performance, game boxscores, and on/off stats with CBB Analytics.` },
            { name: 'twitter:image', content: imageUrls.cbbLogoBlack },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' },
            { httpEquiv: 'X-UA-Compatible', content: 'IE=edge' }
        ],
        link: [
            { rel: 'canonical', href: config.url }
        ]
    }),

    blogController: (config) => ({
        title: config.helmetTitle,
        meta: [
            { name: 'description', content: `college basketball hoops stats analytics articles insights content blog ${config.extraHelmetTags}` },
            { name: 'keywords', content: `college basketball hoops stats analytics articles insights content blog ${config.extraHelmetTags}` },
            { name: 'robots', content: 'index,follow' }
        ]
    }),
    homePage: () => ({
        title: 'CBB Analytics - College Basketball Analytics, Stats & Metrics, & Research',
        meta: [
            { name: 'description', content: 'home page for cbb analytics, college basketball statistics' },
            { name: 'keywords', content: 'college basketball, stats, college basketball statistics, analysis, analytics, stats, statistics, cbb analytics, sports data, data analytics' },
            { name: 'robots', content: 'index,follow' }
        ]
    }),
    marchMadnessApp: () => ({
        title: 'March Madness App | The CBB Analytics Platform - Sports Data Visualizations and Analysis',
        link: [{ rel: 'canonical', href: 'https://node-api-image-deploy-q62r35344q-uc.a.run.app/march-madness' }],
        meta: [
            { name: 'description', content: 'scatter plotting application of college team logos' },
            { name: 'keywords', content: 'college basketball, team stats, college basketball data, analysis, analytics, cbb analytics, sports data, data analytics, team scatter' },
            { name: 'robots', content: 'index,follow' }
        ]
    }),
    playerScatterApp: () => ({
        title: 'Player Scatter Application | The CBB Analytics Platform - Sports Data Visualizations and Analysis',
        link: [{ rel: 'canonical', href: 'https://node-api-image-deploy-q62r35344q-uc.a.run.app/player-universe' }],
        meta: [
            { name: 'description', content: 'scatter plotting application of individual players' },
            { name: 'keywords', content: 'college basketball, team stats, college basketball data, analysis, analytics, cbb analytics, sports data, data analytics, player scatter' },
            { name: 'robots', content: 'index,follow' }
        ]
    }),
    scoreboardApp: () => ({
        title: 'NCAA College Basketball Scoreboard',
        meta: [
            { name: 'description', content: 'daily scoreboard for ncaa college basketball, mens and womens' },
            { name: 'keywords', content: 'ncaa, college, basketball, scoreboard, daily, stats, analytics' },
            { name: 'robots', content: 'index,follow' }
        ]
    }),
    shotChartsApp: () => ({
        title: 'College Basketball Shot Charts - CBB Analytics'
    }),
    teamScatterApp: () => ({
        title: 'Team Scatter Application | The CBB Analytics Platform - Sports Data Visualizations and Analysis',
        link: [{ rel: 'canonical', href: 'https://node-api-image-deploy-q62r35344q-uc.a.run.app/tools/team-scatter' }],
        meta: [
            { name: 'description', content: 'scatter plotting application of college team logos' },
            { name: 'keywords', content: 'college basketball, team stats, college basketball data, analysis, analytics, cbb analytics, sports data, data analytics, team scatter' },
            { name: 'robots', content: 'index,follow' }
        ]
    }),
    gameDecidingShotsApp: () => ({
        title: 'Game Deciding Shots | The CBB Analytics Platform - Sports Data Visualizations and Analysis',
        link: [{ rel: 'canonical', href: 'https://node-api-image-deploy-q62r35344q-uc.a.run.app/tools/game-deciding-shots/38409/d1' }],
        meta: [
            { name: 'description', content: 'game winning, game tying, game deciding shots, go-ahead shots in college basketball' },
            { name: 'keywords', content: 'college basketball, game winning shots, game tying shots, go ahead shots, college basketball, analysis, analytics, cbb analytics, sports data, data analytics' },
            { name: 'robots', content: 'index,follow' }
        ]
    }),
    streaksApp: () => ({
        title: 'Scoring Streaks & Droughts | The CBB Analytics Platform - Sports Data Visualizations and Analysis',
        link: [{ rel: 'canonical', href: 'https://node-api-image-deploy-q62r35344q-uc.a.run.app/tools/scoring-streaks/38409/d1' }],
        meta: [
            { name: 'description', content: 'scoring streaks, scoring runs, scoring droughts, longest streaks in college basketball' },
            { name: 'keywords', content: 'college basketball, scoring streaks, scoring runs, scoring droughts, longest streaks in college basketball, college basketball, analysis, analytics, cbb analytics, sports data, data analytics' },
            { name: 'robots', content: 'index,follow' }
        ]
    }),
    aboutUs: () => ({
        title: 'About Us Page | The CBB Analytics Platform - Sports Analysis and Data Viz',
        meta: [
            { name: 'description', content: 'View College Basketball' },
            { name: 'keywords', content: 'college basketball, conference stats, college basketball data, analysis, analytics, cbb analytics, sports data, data analytics' },
            { name: 'robots', content: 'index,follow' }
        ],
        link: [{ rel: 'canonical', href: 'https://cbbanalytics.com/about' }]
    }),
    helpPage: () => ({
        title: 'CBB Analytics FAQ Page | The CBB Analytics Platform - Sports Data Visualizations and Analysis',
        link: [{ rel: 'canonical', href: 'https://node-api-image-deploy-q62r35344q-uc.a.run.app/stats' }],
        meta: [
            { name: 'description', content: 'landing page for cbb analytics stats' },
            { name: 'keywords', content: 'college basketball, stats, college basketball data, analysis, analytics, cbb analytics, sports data, data analytics' },
            { name: 'robots', content: 'index,follow' }
        ]
    }),
    compareController: (config) => ({
        title: `Division-${config.divisionId} Compare Stats`,
        meta: [
            { name: 'description', content: 'all college players teams landing page compare' },
            { name: 'keywords', content: 'college basketball, team stats, college basketball statistics, analysis, analytics, statistics, stats, cbb analytics, sports data, data analytics' },
            { name: 'robots', content: 'index,follow' }
        ]
    }),
    confController: (config) => ({
        title: `${config.shortName} - ${config.longName} Statistics - CBB Analytics`,
        meta: [
            { name: 'description', content: 'all ncaa conferences stats landing page' },
            { name: 'keywords', content: 'college basketball, conferences, college basketball data, analysis, analytics, cbb analytics, sports data, data analytics' },
            { name: 'robots', content: 'index,follow' }
        ]
    }),
    divisionController: (config) => ({
        title: `${config.longName} Stats - CBB Analytics`,
        meta: [
            { name: 'description', content: 'all ncaa conferences stats landing page' },
            { name: 'keywords', content: 'college basketball, conferences, college basketball data, analysis, analytics, cbb analytics, sports data, data analytics' },
            { name: 'robots', content: 'index,follow' }
        ]
    }),
    draftController: () => ({
        title: 'Draft Pages, College Basketball Statistics | CBB Analytics',
        meta: [
            { name: 'description', content: 'nba draft wnba draft portal all college players teams landing page' },
            { name: 'keywords', content: 'nba draft wnba draft, college basketball, team stats, college basketball data, analysis, analytics, cbb analytics, sports data, data analytics' },
            { name: 'robots', content: 'index,follow' }
        ]
    }),
    gameController: (config) => ({
        title: `${config.matchupString} - Games Controller Page | The CBB Analytics Platform - Sports Data Visualizations and Analysis`,
        meta: [
            { name: 'description', content: 'all college games statistics landing page' },
            { name: 'keywords', content: 'college basketball, game stats, college basketball data, analysis, analytics, statistics, stats, cbb analytics, sports data, data analytics' },
            { name: 'robots', content: 'index,follow' }
        ],
        link: [
            { rel: 'canonical', href: config.url }
        ]
    }),
    tourneyController: (config) => ({
        title: `${config.tournamentName} Tournament Statistic | CBB Analytics`,
        meta: [
            { name: 'description', content: 'all college players teams landing page' },
            { name: 'keywords', content: 'college basketball, team stats, college basketball data, analysis, analytics, cbb analytics, sports data, data analytics' },
            { name: 'robots', content: 'index,follow' }
        ]
    }),
    transferPortalController: () => ({
        title: 'Transfer Portal, College Basketball Statistic | CBB Analytics',
        meta: [
            { name: 'description', content: 'transfer portal all college players teams landing page' },
            { name: 'keywords', content: 'transfer portal, college basketball, team stats, college basketball data, analysis, analytics, cbb analytics, sports data, data analytics' },
            { name: 'robots', content: 'index,follow' }
        ]
    }),
    playerRatingsApp: () => ({
        title: 'Player Ratings App | CBB Analytics - Sports Data Visualizations and Analysis',
        link: [{ rel: 'canonical', href: 'https://node-api-image-deploy-q62r35344q-uc.a.run.app/player-ratings' }],
        meta: [
            { name: 'description', content: 'player ratings app of ncaa college basketball' },
            { name: 'keywords', content: 'college basketball, player stats, college basketball statistics, analysis, analytics, cbb analytics, sports data, data analytics' },
            { name: 'robots', content: 'index,follow' }
        ]
    }),
    adminPage: () => ({
        title: 'Admin | CBB Analytics',
        meta: [
            { name: 'description', content: 'Admin page for CBB Analytics' },
            { name: 'robots', content: 'noindex,nofollow' }
        ]
    }),
    signupPage: () => ({
        title: 'Sign Up | CBB Analytics',
        meta: [
            { name: 'description', content: 'Create your CBB Analytics account for college basketball statistics and analysis' },
            { name: 'keywords', content: 'sign up, register, college basketball, statistics, analytics, cbb analytics' },
            { name: 'robots', content: 'noindex,nofollow' }
        ]
    })
};

const getHelmetProps = ({ page, config }) => {
    if (!HELMET_MAP[page]) {
        console.error(`page ${page} was passed to HelmetComponent without being in HELMET_MAP`);
        return {};
    }
    const pageHelmetFunction = HELMET_MAP[page];
    const props = pageHelmetFunction(config);
    return props;
};

const HelmetComponent = ({ page, config }) => {
    const props = getHelmetProps({ page, config });
    return <Helmet {...props} />;
};

export default HelmetComponent;
