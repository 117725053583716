
// Import React Components
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaTachometerAlt, FaListAlt, FaEye, FaRegEnvelope } from 'react-icons/fa'; // FaRegListAlt, FaRegEye
import imageUrls from '../../images/gcsImages';


// define styles at the top (is this ultimately better?)
const cardStyles = {
    // main: { background: '#E9E9E9', padding: 25, textAlign: 'center', borderRadius: 10, height: '100%' },
    main: { background: '#0066CC', padding: 25, paddingBottom: 35, textAlign: 'center', borderRadius: 10, height: '100%', fontFamily: 'Roboto Slab' },
    image: { position: 'absolute', top: -75, left: 'calc(50% - 50px)', width: 90, borderRadius: '50%' },
    header: { fontSize: 34, marginBottom: 20, fontWeight: 700, marginTop: 5, color: 'white' },
    body: { fontSize: 18, lineHeight: 1.5, marginTop: 5, color: 'white' }
};

function FeatureCard({ type }) {
    const cardObject = {
        // mission: { header: 'Our Mission', image: <FaTachometerAlt size={70} color='#CC6600' />, body: 'In collaboration with CBB Analytics, raYnge remains committed to effecting positive change in sports analytics by providing comprehensive analytical reporting presented in a way that is simple, readable and actionable.' },
        mission: { header: 'Our Mission', image: <FaTachometerAlt size={70} color='#CC6600' />, body: 'In collaboration with CBB Analytics, raYnge remains committed to effecting positive change in sports by providing comprehensive insights and reporting to coaches, presented in a manner that is simple, readable and actionable.' },
        service: { header: 'Our Services', image: <FaListAlt size={70} color='#CC6600' />, body: `raYnge is currently available in all D-I, II and III men's and women's basketball conferences. The reporting, insights and quality services we deliver help build trust and confidence in our partners, giving them competitive advantages through team strategy, scouting operations and player development.` },
        vision: { header: 'Our Vision', image: <FaEye size={70} color='#CC6600' />, body: 'We play a crucial role in challenging the traditional technologies that use numbers in sports and seek opportunities to innovate.' }
    };

    // Return Card
    return (
        <div style={cardStyles.main}>
            <div style={cardStyles.image}>
                {cardObject[type].image}
            </div>
            <h2 style={cardStyles.header}>{cardObject[type].header}</h2>
            <p style={cardStyles.body}>{cardObject[type].body}</p>
        </div>
    );
}

// Create The Component
function Consulting() {
    // Good Looks

    // For Easier Commenting In & Out of Sections, Save Sections to elements
    const heroSection =
        (<Container className='hero-container hide-print' style={{ background: 'black' }}>
            <div
                className='hero-image-container'
                style={{ width: '100vw', height: 'calc(100vh - 48px)', opacity: 0.35, backgroundImage: `url(${imageUrls.rayngeHero})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: '50% 10%' }}
            />

            <p style={{ fontWeight: 700, letterSpacing: 2, fontFamily: '"Montserrat", sans-serif', position: 'absolute', color: '#EEE', top: 85, left: '50%', transform: 'translateX(-50%)', fontSize: 82, borderLeft: '5px solid #0066CC', paddingLeft: 8, lineHeight: 1.1 }}>raYnge</p>
            <p style={{ fontWeight: 700, maxWidth: 500, fontFamily: '"Montserrat", sans-serif', position: 'absolute', color: '#EEE', top: 190, left: '50%', transform: 'translateX(-50%)', fontSize: 30, lineHeight: 1.2 }}>A Hands-On Consulting Service Leveraging CBB Analytics</p>
        </Container>);

    const featuresSection =
        (<Container className='home-page-container' style={{ background: 'white' }}>
            <Row style={{ maxWidth: 1325, margin: '0 auto', alignItems: 'center', padding: '0 15px', justifyContent: 'center' }}>
                <h3 className='home-page-header'>Get Ahead, Stay Ahead</h3>
            </Row>
            <Row style={{ maxWidth: 1325, margin: '-30px auto 105px', justifyContent: 'center' }}>
                <p style={{ fontSize: 21 }}>Led by Alex Beene, at raYnge we believe we can all grow together towards our highest purpose and mission. Alex's experience in Pro and college hoops spans over 15 years, including volunteering to keep the clock and helping with numbers on Coach Hardaway's staff at the University of Memphis, Assistant Coach Analytics on Coach Mutombo's staff with Raptors 905, doing the numbers on Coach Toppert's staff at TBT, and roles with the Oklahoma City Thunder, Indiana Pacers and Sacramento Kings. Prior to getting involved in coaching, Alex was a team member on two national tournament teams in college and was a head high school basketball coach. With raYnge, here's what you can expect:</p>
            </Row>
            <Row style={{ maxWidth: 1200, margin: '0 auto 30px' }}>
                <Col xs={12} md={4} style={{ padding: '0px 30px', marginTop: 20 }}>
                    <FeatureCard type='mission' />
                </Col>
                <Col xs={12} md={4} style={{ padding: '0px 30px', marginTop: 20 }}>
                    <FeatureCard type='service' />
                </Col>
                <Col xs={12} md={4} style={{ padding: '0px 30px', marginTop: 20 }}>
                    <FeatureCard type='vision' />
                </Col>
            </Row>
        </Container>);

    const contactSection =
        (<Container className='home-page-container bg-grey'>
            <Row style={{ maxWidth: 1325, margin: '0 auto', alignItems: 'center', padding: '0 15px', justifyContent: 'center' }}>
                <h3 className='home-page-header'>Contact Us</h3>
            </Row>
            <Row style={{ margin: '0 auto', justifyContent: 'center' }}>
                <FaRegEnvelope size={45} color='#0066CC' />
            </Row>
            <Row style={{ maxWidth: 1325, margin: '10px auto 20px', flexDirection: 'column', alignItems: 'center' }}>
                {/* <span style={{ fontSize: '1.2em' }}>Email</span> */}
                <a style={{ fontSize: '1.3em', marginTop: -5, color: '#0066CC' }} href='mailto:abeene@raynge.com'>abeene@raynge.com</a>
            </Row>
        </Container>);


    // The Return
    return (
        <React.Fragment>
            {/* <HelmetComponent page='consulting' /> */}
            {heroSection}
            {featuresSection}
            {contactSection}
        </React.Fragment>
    );
}

export default Consulting;
