
import React, { useState } from 'react';
import Select, { components } from 'react-select';

import { inTableMultiSelect } from '../../utils/ReactSelectStyles';
import { dDict } from '../../harddata/DataDictionary';

const ColumnMultiSelect = ({
    setHiddenColumns,
    optionsArray = []
}) => {
    // console.log('optionsArray: ', optionsArray);

    // selectedCols: those selected (to keep)
    const [selectedCols, setSelectedCols] = useState([]);

    // all columns (including blank columns, non-searching columns)
    const optionsArrayy = optionsArray
        .map(row => ({
            ...row,
            value: row.id, //                                   value: for select dropdown
            label: dDict?.[row?.id]?.label2 || row.Header //    label: for select dropdown
        }));

    // options to choose from in dropdown
    const dropdownOptions = optionsArrayy
        .filter(col => !col.disableColumnSelect)
        .filter(col => col.Header !== '');

    // columns built via blankCols() (going to want to hide these too)
    const blankColumnValues = optionsArrayy
        .filter(col => typeof col.className === 'string' && col.className?.includes('blank-col') || false)
        .map(row => row.value);

    const handleColumnChange = (latestCols, { action }) => {
        let optionsArrayValues = dropdownOptions.map(i => i.value);
        let latestColsValues = latestCols ? latestCols.map(i => i.value) : [];
        let skipHiding = !latestCols || latestCols.length === 0;
        let columnsToHide = [...optionsArrayValues.filter(col => !latestColsValues.includes(col)), ...blankColumnValues];

        // console.log('colsToHide: ', { latestCols, optionsArrayy, dropdownOptions, columnsToHide });
        // we are not hiding blankCol() columns...

        // hide nothing if ALL is selected as first value
        if (action === 'select-option' && latestCols[latestCols.length - 1].value === 'ALL') {
            setHiddenColumns([]);
            return;
        }

        // run the switch statement to determine what is selected, and what to hide
        switch (action) {
            case 'select-option':
                setSelectedCols(latestCols);
                setHiddenColumns(skipHiding ? [] : columnsToHide);
                break;
            case 'remove-value':
                setSelectedCols(latestCols);
                setHiddenColumns(skipHiding ? [] : columnsToHide);
                break;
            case 'clear':
                setSelectedCols([]);
                setHiddenColumns([]); break;
            default: return;
        }
    };

    // Custom Option that handles <sup> superscripts
    const CustomOption = (props) => {
        // console.log('props: ', { props });

        // regex pattern matching on **
        const pattern = /\*\*([A-Za-z]+)/;
        if (typeof props.data.label !== 'string') {
            return (
                <components.Option {...props}>
                    {props.data.label}
                </components.Option>
            );
        }

        // Variables to hold the processed label parts
        const match = props.data.label.match(pattern);
        let mainLabel = props.data.label;
        let superScriptContent = null;

        // If match, split label to exclude the matched pattern, and create JSX <sup> for the superscript
        if (match) {
            mainLabel = mainLabel.replace(pattern, '');
            superScriptContent = <sup>{match[1]}</sup>;
        }

        // and return
        return (
            <components.Option {...props}>
                {mainLabel}{superScriptContent}
            </components.Option>
        );
    };

    // and return
    return (
        <Select
            className='cbb-select cbb-column-multi-select'
            classNames={{
                option: d => `cbb-option-${d.data.value}`,
                multiValue: d => `cbb-multivalue-${d.data.value}`
            }}
            styles={inTableMultiSelect}
            value={selectedCols}
            options={dropdownOptions}
            onChange={handleColumnChange}
            placeholder='Select Columns'
            components={{
                Option: CustomOption
            }}
            isMulti
        />
    );
};

export default ColumnMultiSelect;
