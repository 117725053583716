
export function getScopeInfos({ tourneyId = '', tourneyTitle = '', conferenceAbb = '', conferenceLongName = '', divisionId = '' }) {
    return {
        game: { header: 'Game', tipTitle: 'Game Stats', tipText: 'Stats in the selected game. 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their last 5 games Stats.' },
        h1Game: { header: 'Gm H1', tipTitle: 'Game 1st Half', tipText: 'Stats in the first half of the selected game.' },
        h2Game: { header: 'Gm H2', tipTitle: 'Game 2nd Half', tipText: 'Stats in the second half of the selected game.' },
        q1Game: { header: 'Gm Q1', tipTitle: 'Game 1st Quarter', tipText: 'Stats in the first quarter of the selected game.' },
        q2Game: { header: 'Gm Q2', tipTitle: 'Game 2nd Quarter', tipText: 'Stats in the second quarter of the selected game.' },
        q3Game: { header: 'Gm Q3', tipTitle: 'Game 3rd Quarte®', tipText: 'Stats in the third quarter of the selected game.' },
        q4Game: { header: 'Gm Q4', tipTitle: 'Game 4th Quarter', tipText: 'Stats in the fourth quarter of the selected game.' },
        otGame: { header: 'Gm OT', tipTitle: 'Game Overtime', tipText: 'Stats in overtime of the selected game.' },
        l5g: { header: 'Last 5', tipTitle: 'Stats Over Last 5 Team Games', tipText: `Average stats over the team's last 5 games, where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their last 5 games Stats.` },
        l10g: { header: 'Last 10', tipTitle: 'Stats Over Last 10 Team Games', tipText: `Average stats over the team's last 10 games, where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their last 10 games Stats.` },
        home: { header: 'Home', tipTitle: 'Stats In Home Games', tipText: `Average stats over the team's home games, where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their Home Stats.` },
        q1: { header: 'Q1', tipTitle: 'Stats In 1st Quarter', tipText: `Average stats in the 1st quarter, where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their 1st quarter stats.` },
        q2: { header: 'Q2', tipTitle: 'Stats In 2nd Quarter', tipText: `Average stats in the 2nd quarter, where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their 2nd quarter stats.` },
        q3: { header: 'Q3', tipTitle: 'Stats In 3rd Quarter', tipText: `Average stats in the 3rd quarter, where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their 3rd quarter stats.` },
        q4: { header: 'Q4', tipTitle: 'Stats In 4th Quarter', tipText: `Average stats in the 4th quarter, where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their 4th quarter stats.` },
        h1: { header: 'H1', tipTitle: 'Stats In 1st Half', tipText: `Average stats in the 1st half, where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their 1st half stats.` },
        h2: { header: 'H2', tipTitle: 'Stats In 2nd Half', tipText: `Average stats in the 2nd half, where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their 2nd half stats.` },
        ot: { header: 'OT', tipTitle: 'Stats In Overtime', tipText: `Average stats in overtime, where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their overtime stats.` },
        away: { header: 'Away', tipTitle: 'Stats In Away Games', tipText: `Average stats over the team's away games, where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their Away Stats.` },
        neutral: { header: 'Neutral', tipTitle: 'Stats In Neutral-Court Games', tipText: `Average stats over the team's neutral-court games, where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their neutral-court Stats.` },
        wins: { header: 'Wins', tipTitle: 'Stats In Games Won', tipText: `Average stats over games that the team won, where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their stats in wins.` },
        losses: { header: 'Losses', tipTitle: 'Stats In Games Lost', tipText: `Average stats over games that the team lost, where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their stats in losses.` },
        clutch: { header: 'Clutch', tipTitle: 'Stats In Clutch Moments', tipText: `Average stats in clutch moments, when the score is within 6 points with under 5 minutes to play in regulation or OT, where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their clutch stats.` },
        quad1: { header: 'Quad 1', tipTitle: 'Stats In Quad-1 Games', tipText: `Average stats over quad 1 games, where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their stats in quad 1 games.` },
        quad2: { header: 'Quad 2', tipTitle: 'Stats In Quad-2 Games', tipText: `Average stats over quad 2 games, where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their stats in quad 2 games.` },
        quad3: { header: 'Quad 3', tipTitle: 'Stats In Quad-3 Games', tipText: `Average stats over quad 3 games, where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their stats in quad 3 games.` },
        quad4: { header: 'Quad 4', tipTitle: 'Stats In Quad-4 Games', tipText: `Average stats over quad 4 games, where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their stats in quad 4 games.` },
        confReg: { header: 'Conf Reg', tipTitle: 'Stats Over In-Conference Games (excl tourneys)', tipText: `Average stats over the team's regular-season in-conference games (matchups against conference opponents in tournaments are excluded), where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their in-conference Stats.` },
        confAll: { header: 'Conf All', tipTitle: 'Stats Over In-Conference Games (inc tourneys)', tipText: `Average stats over any games against teams from the same conference (including matchups in tournaments), where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their in-conference Stats.` },
        nonconfReg: { header: 'NC Reg ', tipTitle: 'Stats Over Non-Conference Games (excl tourneys)', tipText: `Average stats over the team's regular-season non-conference games (excluding postseason tournaments), where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their non-conference Stats.` },
        nonconfAll: { header: 'NC All', tipTitle: 'Stats Over Non-Conference Games (inc tourneys)', tipText: `Average stats over the team's non-conference games (including postseason tournaments), where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their non-conference Stats.` },
        post: { header: 'Post', tipTitle: 'Stats In Post Season Games', tipText: `Average stats over the team's post season (conference tournaments + march madness) games, where game data is available. Note that 0-100 percentiles for stats are computed relative to the Full Season Stats of all other teams or players, not their post season Stats.` },
        season: { header: 'Season', tipTitle: 'Full Season Stats', tipText: `Average stats over the team's full regular season, where game data is available. Stat percentiles are relative to other teams or players full-season stats.` },
        custom: { header: 'Custom', tipTitle: 'Custom Selected Games', tipText: `Average stats over the specific games selected, where game data is available. Stat percentiles are relative to other teams or players full-season stats.` },
        daterange: { header: 'Range', tipTitle: 'Custom Date Range', tipText: `Average stats over the games in the selected date range, where game data is available. Games on the selected end and start dates are included. Stat percentiles are relative to other teams or players full-season stats.` },
        tourney: { header: `T${tourneyId}`, tipTitle: tourneyTitle, tipText: `Average stats over the team's ${tourneyTitle} games. Stat percentiles are relative to other teams / players full-season stats.` },
        conferenceAvg: { header: `${conferenceAbb} Avg`, tipTitle: `${conferenceLongName} Averages`, tipText: `Average stats for all teams in the ${conferenceLongName}, over the full regular season, where game data is available. Note that percentiles in this column reflect the percentiles that a hypothetical team with these statistics would receive, rated against all qualifying Division-${divisionId} teams.` },
        divisionAvg: { header: `D-${divisionId} Avg`, tipTitle: `Division-${divisionId} Averages`, tipText: `Average stats for all Division-${divisionId} teams, over the full regular season, where game data is available. Note that percentiles in this column reflect the percentiles that a hypothetical team with these statistics would receive, rated against all qualifying Division-${divisionId} teams. Since the D-${divisionId} average IS NOT equal to the D-${divisionId} median, D-${divisionId} averages will not always be in the 50th percentile.` }
    };
}
