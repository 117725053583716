
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import GlobalContext from '../../context/GlobalContext';

// The Modal Itself
const getHexColor1 = (obj, id) => {
    const hexColor = obj && obj[id] ? obj[id].hexColor1 : '#444444';
    return hexColor;
};

function TableHideButtons({ teamId, conferenceId = null, hideStuff, setHideStuff }) {
    // Get Params
    const params = useParams();
    const paramConferenceId = Number(params.conferenceId);
    const paramTeamId = Number(params.teamId);

    // Set State
    // const [showModal, setShowModal] = useState(false);
    const { teamInfosObj, conferenceInfosObj } = useContext(GlobalContext);

    // Set Modal Color
    let modalColor = getHexColor1(teamInfosObj, teamId);
    if (paramTeamId) { modalColor = getHexColor1(teamInfosObj, paramTeamId); }
    if (conferenceId) { modalColor = getHexColor1(conferenceInfosObj, conferenceId); }
    if (paramConferenceId) { modalColor = getHexColor1(conferenceInfosObj, paramConferenceId); }

    // And Return
    return (
        <button
            className='info-button hide-print'
            style={{ marginRight: 3, background: modalColor }}
            onClick={() => {
                setHideStuff(!hideStuff);
            }}
        >
            <span style={{ marginBottom: 2 }}>x</span>
        </button>
    );
};

export default TableHideButtons;
