
// From https://stackoverflow.com/questions/34836500/how-to-set-up-google-analytics-for-react-router
// Still not working... need to refresh the page for pageView to count...

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import config from '../config';

const usePageTracking = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!window.location.href.includes('localhost')) {
            ReactGA.initialize(config.gaTrackingId);
            setInitialized(true);
        }
    }, []);

    useEffect(() => {
        if (initialized) {
            ReactGA.pageview(location.pathname + location.search);
            // ReactGA.set({ userId: userId }); // don't have userId in usePageTracking
        }
    }, [initialized, location]);

    return initialized;
};

export default usePageTracking;
