
// Use to handle styling based on the width of a specific div
// See usage of useContainerWidth in other components:
//      // simply need to set ref{} on <div> in other component to containerRef, and use width as needed.


import { useState, useEffect, useRef } from 'react';

const useContainerWidth = () => {
    const [width, setWidth] = useState(0);
    const containerRef = useRef(null);

    useEffect(() => {
        const updateWidth = () => {
            if (containerRef.current) {
                setWidth(containerRef.current.offsetWidth);
            }
        };

        updateWidth();
        window.addEventListener('resize', updateWidth);

        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, []);

    return [width, containerRef];
};

export default useContainerWidth;
