// 4154179448017283

// React & Stripe Libraries
import React, { useState } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { Row } from 'react-bootstrap';
import Axios from 'axios';
import { FaLock } from 'react-icons/fa';

import CBBCardElement from './CBBCardElement';
import PaymentAlert from './PaymentAlert';

import config from '../../../../config';

const UpdatePaymentCardButton = ({
    style = {},
    currentPaymentMethod, // only show update form if currentPaymentMethod exists
    onPaymentMethodUpdated
}) => {
    // console.log('UpdatePaymentCardButton props: ', { style, currentPaymentMethod, onPaymentMethodUpdated });

    // useState: Set Button State
    const [isProcessing, setIsProcessing] = useState(false);
    const [checkoutError, setCheckoutError] = useState();
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [isCardComplete, setIsCardComplete] = useState(false);

    // Grab User Data, State of Free Trial

    // Handle Stripe Stuf
    const stripe = useStripe();
    const elements = useElements();
    const handleSubmit = async () => {
        setIsProcessing(true);
        const cardElement = elements.getElement('card');
        try {
            // Grab Stripe Elements + Variables needed to post payment subscription
            cardElement.update({ disabled: true });
            const apiBaseUrl = config.url.API_URL;

            const paymentMethodReq = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement
            });

            // Handle Bad Payment Method
            if (paymentMethodReq.error) {
                setCheckoutError(paymentMethodReq.error.message);
                setShowErrorAlert(true);
                setIsProcessing(false);
                cardElement.update({ disabled: false });
                return;
            }

            // Handle Creating Payment Subscription
            const authHeader = { headers: { 'x-auth-token': localStorage.getItem('auth-token') } };
            const paymentObject = { paymentMethodId: paymentMethodReq.paymentMethod.id }; // address
            const response = await Axios.post(`${apiBaseUrl}/stripe/update-payment-method`, paymentObject, authHeader);
            // console.log('update-payment-method response: ', response);

            // Clear & Undisable Card
            cardElement.clear();
            cardElement.update({ disabled: false });

            // Handle Alerts
            setShowSuccessAlert(true);
            setIsProcessing(false);
            onPaymentMethodUpdated(response.data.paymentMethod);
        } catch (error) {
            // Handle Errors
            console.log('Err: payment error');
            cardElement.update({ disabled: false });
            setCheckoutError(error.message);
            setShowErrorAlert(true);
            setIsProcessing(false);
        }
    };

    // Buttons
    // =========
    return (<div style={{ ...style }}>

        {/* Text Above Payment Box */}
        {currentPaymentMethod &&
            <Row style={{ margin: 0, justifyContent: 'space-between', alignItems: 'center', fontSize: '0.9em', color: '#444' }}>
                <span>New Payment Information</span>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: 3 }}>
                    <span style={{ marginRight: 5 }}>Secure</span>
                    <FaLock size={12} stroke='#444' />
                </div>
            </Row>
        }

        {/* Fill Out Credit Card Information */}
        {currentPaymentMethod &&
            <Row style={{ margin: 0, marginBottom: 5 }}>
                <CBBCardElement onChange={e => setIsCardComplete(e.complete)} />
            </Row>
        }

        {/* Main Submit-Payment Button */}
        {currentPaymentMethod &&
            <Row style={{ margin: 0, marginBottom: 5 }}>
                <button
                    className={`stripe-button ${!isCardComplete && 'grey'}`}
                    onClick={handleSubmit}
                    disabled={isProcessing || !stripe || !isCardComplete}
                >
                    {!isCardComplete ? 'Enter New Card' : (isProcessing ? 'Card Updating...' : `Update Card`)}
                </button>
            </Row>
        }

        {/* Alerts (Success & Fail) */}
        {currentPaymentMethod &&
            <Row style={{ margin: 0, marginBottom: 5 }}>
                <PaymentAlert
                    variant='success'
                    showAlert={showSuccessAlert}
                    setShowAlert={setShowSuccessAlert}
                    alertMessage={'Payment Successfully Updated!'}
                />
                <PaymentAlert
                    variant='danger'
                    showAlert={showErrorAlert}
                    setShowAlert={setShowErrorAlert}
                    alertMessage={checkoutError}
                />
            </Row>
        }
    </div>);
};

export default UpdatePaymentCardButton;
