// Import React Components
import React, { useState, useEffect } from 'react';
import { Container, Row, FormControl, Alert, Button } from 'react-bootstrap';
import axios from 'axios';
import config from '../../config';
import { FaCopy, FaCheck } from 'react-icons/fa';

// Create The Component
function SendReferral() {
    // useState: set state
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [referralLink, setReferralLink] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('success');
    const [copySuccess, setCopySuccess] = useState(false);

    // handle email submit
    const handleEmailSubmit = async (e) => {
        setIsUpdating(true);
        e.preventDefault();
        try {
            const apiBaseUrl = config.url.API_URL;
            const token = localStorage ? localStorage.getItem('auth-token') : window.localStorage.getItem('auth-token');
            const authHeader = { headers: { 'x-auth-token': token } };
            const response = await axios.post(`${apiBaseUrl}/email/send-referral`, { email: email }, authHeader);
            setAlertMessage(response.data.msg || 'Referral sent successfully');
            setAlertVariant(response.data.variant || 'success');
            setIsUpdating(false);
            setShowAlert(true);
            setTimeout(() => { setShowAlert(false); setEmail(''); }, 8000);
        } catch (err) {
            setAlertMessage(err.response?.data?.msg || 'Failed to send referral');
            setAlertVariant('danger');
            setIsUpdating(false);
            setShowAlert(true);
        }
    };

    // handle loading referral link
    const loadReferralLink = async () => {
        const apiBaseUrl = config.url.API_URL;
        const token = localStorage ? localStorage.getItem('auth-token') : window.localStorage.getItem('auth-token');
        const authHeader = { headers: { 'x-auth-token': token } };
        const response = await axios.post(`${apiBaseUrl}/email/create-referral-link`, {}, authHeader);

        setReferralLink(response.data.referralLink);
    };

    // load referral links
    useEffect(() => {
        loadReferralLink();
    }, []);

    // Check for valid email
    useEffect(() => {
        let isCurrentEmailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? true : false;
        setIsEmailValid(isCurrentEmailValid);
    }, [email]);

    // Create Submit Button
    const submitButton = (
        <div>
            <button
                className={`cbb-submit-button ${showAlert ? 'faded' : ''} ${isEmailValid ? '' : 'disabled'}`}
                style={{ maxWidth: 375, minWidth: 375 }}
                onClick={handleEmailSubmit}
                disabled={!isEmailValid || isUpdating || showAlert}
                aria-label={!isEmailValid ? 'Enter a Valid Email Address' : isUpdating ? 'Sending Email...' : showAlert ? 'Email Sent' : 'Send Referral'}
            >
                {!isEmailValid ? 'Enter a Valid Email Address' : isUpdating ? 'Sending Email...' : showAlert ? 'Email Sent' : 'Send Referral'}
            </button>
            {!isEmailValid && (
                <p style={{ margin: '0 0 5px 0', fontSize: '0.95em', fontStyle: 'italic', lineHeight: 1.3 }}>
                    enter a valid email address
                </p>
            )}
        </div>
    );

    // Create Email Form Input
    const emailFormControl =
        (<FormControl
            className='modal-input'
            style={{ margin: 0 }}
            type='text'
            value={email}
            onChange={(e) => setEmail(e.target.value.toLowerCase().trim())}
            name='email'
            placeholder='Email Address'
            disabled={showAlert}
        />);

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(referralLink);
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    return (
        <Container style={{ maxWidth: '100%', padding: 20, borderRadius: 8 }}>
            <Row style={{ justifyContent: 'left', margin: '20px auto 4px', maxWidth: 450 }}>
                <h5 style={{ fontSize: '1.2rem' }}>Your referral link</h5>
            </Row>
            <Row style={{ justifyContent: 'center', margin: '4px 0' }}>
                <div style={{ position: 'relative', width: '100%', maxWidth: 450 }}>
                    <input
                        readOnly={true}
                        value={referralLink}
                        style={{
                            width: '100%',
                            padding: '12px 50px 12px 12px',
                            border: '1px solid #ced4da',
                            borderRadius: 4,
                            backgroundColor: '#fff',
                            fontSize: 16
                        }}
                    />
                    <Button
                        onClick={copyToClipboard}
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            background: 'none',
                            border: 'none',
                            padding: 5,
                            cursor: 'pointer'
                        }}
                    >
                        {copySuccess ? (
                            <FaCheck size={20} color='#6c757d' />
                        ) : (
                            <FaCopy size={20} color='#6c757d' />
                        )}
                    </Button>
                </div>
            </Row>
            <Row style={{ justifyContent: 'left', margin: '30px auto 0px', maxWidth: 450 }}>
                <h5 style={{ fontSize: '1.15rem' }}>
                    Or we can send them an email for you
                </h5>
            </Row>
            <Row style={{ justifyContent: 'left', margin: '4px auto 5px', maxWidth: 450 }}>
                {emailFormControl}
            </Row>
            <Row style={{ justifyContent: 'left', margin: '6px auto 5px', maxWidth: 450 }}>
                {submitButton}
            </Row>

            <Row style={{ justifyContent: 'left', margin: '20px auto 5px', maxWidth: 450 }}>
                <p>Users you refer receive a $15 discount on their first month and also receive a 2nd free trial week. You get a $15 credit when they pay (after their 2-week trial ends).</p>
            </Row>

            {/* Updated Alert at the end */}
            {showAlert &&
                <Row style={{ justifyContent: 'center', margin: '20px 0' }}>
                    <Alert style={{ minWidth: 450, maxWidth: 450 }} variant={alertVariant} className='animate-fade-away'>
                        {alertMessage}
                    </Alert>
                </Row>
            }
        </Container>
    );
}

export default SendReferral;
