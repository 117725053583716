// https://stripe.com/docs/card-brand-choice - card brand options

import React from 'react';
import { Row } from 'react-bootstrap';

import { FaCcVisa } from 'react-icons/fa';
import { FaCcDiscover } from 'react-icons/fa';
import { FaCcAmex } from 'react-icons/fa';
import { FaCcMastercard } from 'react-icons/fa';
import { FaLock } from 'react-icons/fa';

function PaymentMethodDisplay({ paymentMethod, userTier = { value: 1 } }) {
    // console.log('PaymentMethodDisplay Props: ', { paymentMethod, userTier });

    // Handle No Payment Method Passed
    if (!paymentMethod) { return <p>No payment method on file</p>; }

    // Struggling to get better card image...
    // let urlSuffix = imageUrls.visa // load imageUrls to get presaved SVGs of card images (design, color scheme on these isn't great. makes for fake-looking payment field)
    // let cardImage = <svg style={{ width: 30 }} src={`${urlSuffix}/visa.svg`} />;
    // let cardImage =
    //     (<svg style={{ padding: 6 }} width='54' viewBox='0 0 54 36' xmlns='http://www.w3.org/2000/svg'>
    //         <image href={`${urlSuffix}/discover.svg`} width='54' />
    //     </svg>);

    // Handle Yes Payment
    let card = paymentMethod.card;
    return (<>
        {/* {userTier.value <= 1 &&
            <p>You are not a paid user</p>
        } */}
        {/* {userTier.value === 2 && */}
        <div style={{ width: 320 }}>
            <Row style={{ margin: 0, justifyContent: 'space-between', alignItems: 'center', fontSize: '0.9em', color: '#444' }}>
                <span>Card on File</span>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: 3 }}>
                    <span style={{ marginRight: 5 }}>Secure</span>
                    <FaLock size={12} stroke='#444' />
                </div>
            </Row>
            <div style={{ display: 'flex', alignItems: 'center', background: 'rgba(0, 102, 204, 0.04)', borderRadius: 5, border: '1px solid #bbb', padding: '4px 10px' }}>
                <div style={{ marginRight: 12 }}>
                    {/* {true && cardImage} */}
                    {card.brand === 'visa' && <FaCcVisa size={31} />}
                    {card.brand === 'discover' && <FaCcDiscover size={31} />}
                    {card.brand === 'amex' && <FaCcAmex size={31} />}
                    {card.brand === 'mastercard' && <FaCcMastercard size={31} />}
                    {!['visa', 'discover', 'amex', 'mastercard'].includes(card.brand) && <div />}
                </div>
                <p style={{ marginRight: 6, color: '#333', lineHeight: 1.1 }}>
                    • • • •
                </p>
                <p style={{ marginRight: 10, color: '#333', lineHeight: 1.1 }}>
                    {card.last4}
                </p>
                <p style={{ color: '#888', fontSize: '0.8em', lineHeight: 0.85, padding: '5px 0px 4px 0px' }}>
                    {card.exp_month.toString().padStart(2, '0') + '/' + card.exp_year.toString().slice(2, 4)}
                </p>
            </div>
        </div>
        {/* } */}
        {/* {userTier.value >= 3 &&
            <p>Pro Tier Users pay in another way</p>
        } */}
    </>);
}

export default PaymentMethodDisplay;
