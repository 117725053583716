// Import React Stuff
import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { Container } from 'react-bootstrap';
import { ClimbingBoxLoader } from 'react-spinners';

// Import CBB Stuff
import GlobalContext from '../../context/GlobalContext';
import config from '../../config';
// import ClimbingBox from '../../components/uiUxItems/Cli'

function ErrorBoundaryFallback() {
    // Event Logging the Error

    // No Hooks in this component? Cannot log these issues for the time being...
    // can test locally with /hidden-demo-page and with turned off...

    const { userData } = useContext(GlobalContext);
    let userId = userData && userData.user && userData.user._id;
    let userEmail = userData && userData.user && userData.user.email;

    const sendEventLog = async () => {
        let newEventLog = { userId, email: userEmail, type: 'error' };
        try {
            let apiBaseUrl = config.url.API_URL;
            await Axios.post(`${apiBaseUrl}/users/add-event-log`, newEventLog);
        } catch (err) {
            console.log('err: ', err);
        }
    };

    useEffect(() => {
        if (userId) {
            sendEventLog();
        }
    }, [userId]);

    return (
        <Container className='padded-inner-container' style={{ position: 'relative', maxWidth: '900px', margin: '55px auto 25px' }}>
            <div style={{ float: 'left' }}>
                <ClimbingBoxLoader
                    size={35}
                    color={'#0066cc'}
                    css={{ opacity: 0.8 }}
                // css='opacity: 0.8;'
                />
            </div>
            <div>
                <h3 className='cbb-policy-top-header'>You ran into an error on our website...</h3>
            </div>
            <div>
                <p style={{ marginBottom: 15 }}>
                    Since CBB Analytics is being actively worked on, unfortunately bugs can occasionally slip by
                    and find their way into the website. Whether you clicked a broken button, toggled a busted switch, or data couldn't be fetched correctly,
                    there was an issue that just caused a breaking error on the website. Rather than displaying a white screen of death,
                    our error handling sent you here instead, and it also notified us of the problem (which we're working hard to fix)!
                </p>
                <p style={{ marginBottom: 15 }}>
                    Hitting this error may have disabled the buttons on the top of the website. To return to the home page, you will need to
                    type cbbanalytics.com into your browser's address bar and hit enter. If you'd like to help resolve the issue, feel free to
                    <span style={{ fontSize: '1.05em', paddingLeft: 5, fontWeight: 700, color: 'darkgreen' }}>
                        email me at nick@cbbanalytics.com with a short description of what actions led to to the error.
                    </span>
                </p>
            </div>
        </Container>
    );
}

export default ErrorBoundaryFallback;
