
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { allCompetitionIds } from '../../harddata/NcaaStructures';
import imageUrls from '../../images/gcsImages';
import { isNil } from '../../utils/ReshapeData';

function PlayerImage({
    className = '',
    teamId,
    playerId,
    competitionId = null,
    hasImage = false, // switched default from "true" to "false". now need proof of player image via "hasImage: true" to show image.
    style = {},
    imgStyle = {}
}) {
    // grab competitionId, set path link
    const params = useParams();
    const imageCompetitionId = competitionId !== null ? competitionId : Number(params.competitionId);
    const isLinkDisabled = !imageCompetitionId || isNaN(imageCompetitionId) || teamId === null;
    const newPath = `/stats/${imageCompetitionId}/players/${playerId}/overview`;

    // our official check that image has loaded
    // // the presence of this renders hasImage completely useless, which is a problem. have invested stock into hasImage. removing hasImage entirely would be nice.
    // const [imageLoaded, setImageLoaded] = useState(false);
    // useEffect(() => {
    //     if (hasImage) {
    //         const img = new Image();
    //         img.src = `${imageUrls.headshotPrefix}/${teamId}-${playerId}.png`;
    //         img.onload = () => setImageLoaded(true);
    //         img.onerror = () => setImageLoaded(false);
    //     } else {
    //         setImageLoaded(false);
    //     }
    // }, [teamId, playerId, hasImage]);

    // get correct image url path
    const noGender = isNil({ d: imageCompetitionId }) ? true : false;
    const isMale = allCompetitionIds.male.includes(imageCompetitionId);
    const fallbackImage = noGender ? imageUrls.missingPerson : (isMale ? imageUrls.missingMale : imageUrls.missingFemale);
    const imgSrc = hasImage ? `${imageUrls.headshotPrefix}/${teamId}-${playerId}.png` : fallbackImage;
    // const effectiveImgSrc = imageLoaded ? `${imageUrls.headshotPrefix}/${teamId}-${playerId}.png` : fallbackImage;

    // console.log('image info: ', { noGender, fallbackImage, hasImage, imgSrc });

    // image version 1 - this is how this component used to work 100%
    const mainImage =
        (<div className={className} style={{ ...style, pointerEvents: isLinkDisabled ? 'none' : 'auto' }}>
            <Link className='logo' to={newPath}>
                <img
                    src={imgSrc}
                    alt='player-face'
                    style={{ ...imgStyle }}
                    onError={ev => { ev.target.src = fallbackImage; }} />
            </Link>
        </div>);

    // image version 2 - for in-table images (no more onError to fallback. rather we render both images)
    const linkStyles = { height: '100%', width: '100%', display: 'block', backgroundRepeat: 'no-repeat', backgroundImage: `url(${imgSrc})`, backgroundSize: 'cover', backgroundPosition: '50% 10%' };
    const inTableImage =
        (<div className={className} style={{ ...style, position: 'relative', pointerEvents: isLinkDisabled ? 'none' : 'auto' }}>
            <Link
                className='logo'
                style={{ ...linkStyles, ...imgStyle, position: 'absolute', zIndex: 2 }}
                to={newPath}
            />
        </div>);

    // and return correct rendering
    return (className === 'intable-player-image' ? inTableImage : mainImage);
}

export default PlayerImage;
