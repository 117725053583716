import React from 'react';
import { useNavigate, Link } from 'react-router-dom';

// Create The Component
function LandingPageCard({
    header,
    subHeader,
    subHeader2,
    src,
    path,
    disabled,
    tierRequired
}) {
    // console.log('LandingPageCard props: ', { header, subHeader, src, path, disabled, tierRequired });
    const navigate = useNavigate();

    // and return
    return (
        <div className={`tools-card ${disabled ? 'disabled' : ''}`} onClick={() => navigate(path)}>

            <div className='tools-card-image-wrapper' style={{ position: 'relative' }}>
                <img alt='' src={src} style={{ opacity: disabled ? 0.06 : 1 }} />
                {disabled && (
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        borderRadius: 12,
                        backgroundColor: 'rgba(50, 50, 50, 0.3)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#222',
                        textAlign: 'center'
                    }}>
                        <div style={{ fontFamily: 'Roboto Slab', fontWeight: 700, fontSize: '1.5em', lineHeight: 1.1 }}>
                            {tierRequired === 3 && 'PRO TIER'}
                            {tierRequired === 2 && 'USER TIER'}
                            {tierRequired === 1 && 'FREE TIER'}
                        </div>
                        <div style={{ fontFamily: 'Roboto Slab', fontWeight: 700, fontSize: '1.5em', marginBottom: 10 }}>
                            FEATURE
                        </div>
                        {[1, 2].includes(tierRequired) &&
                            <Link
                                to={tierRequired === 1 ? '/signup' : '/my-account/subscribe'}
                                style={{ color: '#0066CC', textDecoration: 'underline', fontSize: '1.05em', cursor: 'pointer', pointerEvents: 'auto' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(tierRequired === 1 ? '/signup' : '/my-account/upgrade');
                                }}
                            >
                                {tierRequired === 1 ? 'Sign Up' : 'Upgrade'}
                            </Link>
                        }
                    </div>
                )}
            </div>

            {/* Show the header and subheader text */}
            <div style={{ marginTop: 15 }}>
                <span className='tools-card-header no-border'>{header}</span>
            </div>

            <div style={{ height: 115, lineHeight: 1.3, marginTop: 10 }}>
                {/* {disabled && <span className='tools-card-sub-header'>Pro Tier subscriptions are required to access the {header} section on our platform.</span>} */}
                {/* {!disabled && <span className='tools-card-sub-header'>{subHeader}</span>} */}
                {<span className='tools-card-sub-header'>{subHeader}</span>}
                {<span className='tools-card-sub-header' style={{ paddingLeft: 4, fontWeight: 700 }}>{subHeader2}</span>}
            </div>
        </div>
    );
}

export default LandingPageCard;



// Unused, handling logo grids (teams, conferences, tournaments)

// const { teamInfosObj, conferenceInfosObj } = useContext(GlobalContext);
// const teamIds = Object.values(teamInfosObj).flat().filter(row => [89, 74, 53, 60, 73, 29, 17, 4, 87].includes(row.conferenceId) && row.gender === 'MALE').map(row => row.teamId).slice(0, 104);

// // team logos
// const teamLogos = teamIds.map((id, idx) => {
//     const extraLeft = (idx % 13 === 0 && idx % 26 !== 0) ? '4%' : '0.2%';
//     return (
//         <Logo
//             key={`${id}-${idx}`}
//             ptgc={header === 'Division Stats' ? 'team' : 'conference'}
//             teamId={id}
//             style={{ width: '6.8%', display: 'inline-flex', margin: '0.25%', marginLeft: extraLeft }}
//             imgStyle={{ borderRadius: 0 }}
//         />
//     );
// });

// // conference logos
// const conferenceIds = Object.values(conferenceInfosObj)
//     .flat()
//     .filter(row => !['DI Independent', 'DII Independent', 'DIII Independent'].includes(row.conferenceShortName))
//     .filter(row => [1, 2].includes(row.divisionId))
//     .map(row => row.conferenceId);
// const confLogos = conferenceIds.map((id, idx) => {
//     const extraLeft = (idx % 8 === 0 && idx % 16 !== 0) ? '4.5%' : '0.75%';
//     return (
//         <Logo
//             key={`${id}-${idx}`}
//             ptgc='conference'
//             conferenceId={id}
//             style={{ width: '9.75%', display: 'inline-flex', margin: '0.75% 0.75%', marginLeft: extraLeft }}
//             imgStyle={{ borderRadius: 0 }}
//         />
//     );
// });

// tournament logos
// const tourneyLinks = ['statsTourneyLegendsClassic', 'statsTourneyVegasShowdown', 'statsTourneyEmeraldCoast', 'statsTourneyContinentalTire', 'statsTourneyBattle4Atl', 'statsTourneyMauiInv', 'statsTourneyJimmyV', 'statsTourneyArmedForces', 'statsTourneyPuertoRico', 'statsTourneyEspnInvtl', 'statsTourneyBahaMar2'];
// const tourneyLogos = tourneyLinks.map((logoName, idx) => {
//     const extraLeft = idx === 4 ? '13.5%' : '3%';
//     return (
//         <img
//             alt=''
//             key={`${logoName}-${idx}`}
//             src={imageUrls[logoName]}
//             style={{ width: '18.5%', display: 'inline-flex', margin: `0.5% 3% 0.5% ${extraLeft}` }}
//         />
//     );
// });

// {/* {header === 'Conference Stats' &&
//     <div className='tools-card-image-wrapper' style={{ padding: 8, paddingLeft: 12 }}>
//         {confLogos}
//     </div>
// } */}
// {/* {header === 'Division Stats' &&
//     <div className='tools-card-image-wrapper' style={{ padding: 8, paddingLeft: 12 }}>
//         {teamLogos}
//     </div>
// } */}
// {/* {header === 'Tournament Stats' &&
//     <div className='tools-card-image-wrapper' style={{ padding: 8, paddingLeft: 12 }}>
//         {tourneyLogos}
//     </div>
// } */}
