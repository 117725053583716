
import cols from '../utils/ColumnsDict';

// Shot Context: shooting by half court, transition, putbacks
const getShotContextCols = ({ ptgc = 'teamGame', isOffense = true, ratingsType = 'pctiles' }) => {
    // ptgc:    one of ['teamGame', 'teamAgg']

    // configs for layout
    const configPtgc = ['teamGame', 'teamAgg'].includes(ptgc) ? 'team' : 'player';
    const cfgMain = { ptgc: configPtgc, isOffense, ratingsType };
    const cfgL10 = { ...cfgMain, className: 'border-left pad-left-10' };

    // and return the columns
    return [
        { Header: 'Half Court', className: 'border-left pad-left-10', columns: [
            cols.valueAndRating('fgaFreqHc', { ...cfgL10, className: 'border-left pad-left-10' }),
            cols.valueOnly('fga2Hc', { ...cfgMain, width: 42, className: 'pad-left-10' }),
            cols.valueAndRating('fg2PctHc', cfgMain),
            cols.valueOnly('fga3Hc', { ...cfgMain, width: 42, className: 'pad-left-10' }),
            cols.valueAndRating('fg3PctHc', cfgMain)
        ] },
        { Header: 'Transition', className: 'border-left pad-left-10', columns: [
            cols.valueAndRating('fgaFreqTr', { ...cfgL10, className: 'border-left pad-left-10' }),
            cols.valueOnly('fga2Tr', { ...cfgMain, width: 42, className: 'pad-left-10' }),
            cols.valueAndRating('fg2PctTr', cfgMain),
            cols.valueOnly('fga3Tr', { ...cfgMain, width: 42, className: 'pad-left-10' }),
            cols.valueAndRating('fg3PctTr', cfgMain)
        ] },
        { Header: 'Putbacks', className: 'border-left pad-left-10', columns: [
            cols.valueAndRating('fgaFreqPb', { ...cfgL10, className: 'border-left pad-left-10' }),
            cols.valueOnly('fgaPb', { ...cfgMain, width: 42, className: 'pad-left-10' }),
            cols.valueAndRating('fgPctPb', cfgMain)
        ] }
    ];
};

// Chance Context: efficiency/frequency by half court, transition, putbacks
const getChncContextCols = ({ ptgc = 'team', isOffense = true, ratingsType = 'pctiles' }) => {
    // configs for layout
    const cfgMain = { ptgc, isOffense, ratingsType };
    const cfgL10 = { ...cfgMain, className: 'border-left pad-left-10' };

    // and return the columns
    return [
        { Header: 'Half Court', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('chncHc', { ...cfgL10, width: 35, className: 'border-left pad-left-10' }),
            cols.valueOnly('ptsChncHc', { ...cfgMain, width: 35, className: 'pad-left-10' }),
            cols.valueAndRating('ppcHc', cfgMain),
            cols.valueAndRating('freqChncHc', cfgMain)
        ] },
        { Header: 'Transition', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('chncTr', { ...cfgL10, width: 35, className: 'border-left pad-left-10' }),
            cols.valueOnly('ptsChncTr', { ...cfgMain, width: 35, className: 'pad-left-10' }),
            cols.valueAndRating('ppcTr', cfgMain),
            cols.valueAndRating('freqChncTr', cfgMain)
        ] },
        { Header: 'Putbacks', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('chncPb', { ...cfgL10, width: 35, className: 'border-left pad-left-10' }),
            cols.valueOnly('ptsChncPb', { ...cfgMain, width: 35, className: 'pad-left-10' }),
            cols.valueAndRating('ppcPb', cfgMain),
            cols.valueAndRating('freqChncPb', cfgMain)
        ] }
    ];
};

// Misc Shooting: assisted vs unassisted by all, 2s, 3s, rim, dunks
const getAstdUnastdCols = ({ isTotals = false, perWhat = 'perGame', ratingsType = 'pctiles', isMale = true }) => {
    // configs for layout
    const cfgMain = { perWhat, ratingsType };
    const cfgL10 = { ...cfgMain, className: 'border-left pad-left-10' };
    const cfgW45 = { ...cfgMain, width: 45 };
    const cfgL10W45 = { ...cfgL10, width: 45, className: 'border-left pad-left-10' };

    // and return the columns
    return [
        { Header: 'All Shots', className: 'border-left pad-left-10', columns: [
            ...(isTotals ? [cols.valueOnly('fgmA', cfgL10W45)] : [cols.valueAndRating(`fgmA${perWhat}`, cfgL10)]),
            ...(isTotals ? [cols.valueOnly('fgmU', cfgW45)] : [cols.valueAndRating(`fgmU${perWhat}`, cfgMain)]),
            cols.valueAndRating('fgmAstdPct', cfgMain)
        ] },
        { Header: '2-Pointers', className: 'border-left pad-left-10', columns: [
            ...(isTotals ? [cols.valueOnly('fgm2A', cfgL10W45)] : [cols.valueAndRating(`fgm2A${perWhat}`, cfgL10)]),
            ...(isTotals ? [cols.valueOnly('fgm2U', cfgW45)] : [cols.valueAndRating(`fgm2U${perWhat}`, cfgMain)]),
            cols.valueAndRating('fgm2AstdPct', cfgMain)
        ] },
        { Header: '3-Pointers', className: 'border-left pad-left-10', columns: [
            ...(isTotals ? [cols.valueOnly('fgm3A', cfgL10W45)] : [cols.valueAndRating(`fgm3A${perWhat}`, cfgL10)]),
            ...(isTotals ? [cols.valueOnly('fgm3U', cfgW45)] : [cols.valueAndRating(`fgm3U${perWhat}`, cfgMain)]),
            cols.valueAndRating('fgm3AstdPct', cfgMain)
        ] },
        { Header: 'At Rim', className: 'border-left pad-left-10', columns: [
            ...(isTotals ? [cols.valueOnly('atr2FgmA', cfgL10W45)] : [cols.valueAndRating(`atr2FgmA${perWhat}`, cfgL10)]),
            ...(isTotals ? [cols.valueOnly('atr2FgmU', cfgW45)] : [cols.valueAndRating(`atr2FgmU${perWhat}`, cfgMain)]),
            cols.valueAndRating('atr2AstdPct', cfgMain)
        ] },
        ...(isMale ? [{
            Header: 'Dunks', className: 'border-left pad-left-10', columns: [
                ...(isTotals ? [cols.valueOnly('dunkFgmA', cfgL10W45)] : [cols.valueAndRating(`dunkFgmA${perWhat}`, cfgL10)]),
                ...(isTotals ? [cols.valueOnly('dunkFgmU', cfgW45)] : [cols.valueAndRating(`dunkFgmU${perWhat}`, cfgMain)]),
                cols.valueAndRating('dunkAstdPct', cfgMain)
            ]
        }] : [])
    ];
};

// foul drawing metrics (charges, SFLs)
const getPbpFoulsCols = ({ isTotals = false, perWhat = 'perGame', ratingsType = 'pctiles' }) => {
    const cfgMain = { perWhat, ratingsType };
    const cfgW45 = { ...cfgMain, width: 45 };
    const cfgL10 = { ...cfgMain, className: 'border-left pad-left-10' };
    const cfgL10W45 = { ...cfgL10, width: 45, className: 'border-left pad-left-10' };

    return [
        { Header: 'Shooting Fouls', className: 'border-left pad-left-10', columns: [
            ...(isTotals ? [cols.valueOnly('sflDrawn', cfgL10W45)] : [cols.valueAndRating(`sflDrawn${perWhat}`, cfgL10)]),
            ...(isTotals ? [cols.valueOnly('shotAtt', cfgW45)] : []),
            cols.valueAndRating(`sflDrawnPct`, cfgMain),
            ...(isTotals ? [cols.valueOnly('sfl2Drawn', cfgL10W45)] : [cols.valueAndRating(`sfl2Drawn${perWhat}`, cfgL10)]),
            ...(isTotals ? [cols.valueOnly('shotAtt2P', cfgW45)] : []),
            cols.valueAndRating(`sfl2DrawnPct`, cfgMain),
            ...(isTotals ? [cols.valueOnly('sfl3Drawn', cfgL10W45)] : [cols.valueAndRating(`sfl3Drawn${perWhat}`, cfgL10)]),
            ...(isTotals ? [cols.valueOnly('shotAtt3P', cfgW45)] : []),
            cols.valueAndRating(`sfl3DrawnPct`, cfgMain)
        ] },
        { Header: 'And 1s', className: 'border-left pad-left-10', columns: [
            ...(isTotals ? [cols.valueOnly('and1', cfgL10W45)] : [cols.valueAndRating(`and1${perWhat}`, cfgL10)]),
            cols.valueAndRating(`and1Pct`, cfgMain)
        ] },
        { Header: 'Floor Fouls', className: 'border-left pad-left-10', columns: [
            ...(isTotals ? [cols.valueOnly('fflDrawn', cfgL10W45)] : [cols.valueAndRating(`fflDrawn${perWhat}`, cfgL10)]),
            cols.valueAndRating(`fflDrawnPct`, cfgMain)
        ] },
        { Header: 'Charges Drawn', className: 'border-left pad-left-10', columns: [
            ...(isTotals ? [cols.valueOnly('opfd', cfgL10W45)] : [cols.valueAndRating(`opfd${perWhat}`, cfgL10)]),
            cols.valueAndRating(`opfdPct`, cfgMain)
        ] }
    ];
};


// and export
export {
    getShotContextCols,
    getChncContextCols,
    getAstdUnastdCols,
    getPbpFoulsCols
};
