
import React, { useContext, useState } from 'react';
import Select from 'react-select';
import { FormControl } from 'react-bootstrap';

// Data Fetching
import GlobalContext from '../../context/GlobalContext';

// For Saving Titles
import axios from 'axios';
import config from '../../config';
import { smallSelectStyles } from '../../utils/ReactSelectStyles';
import useCBBQuery from '../../hooks/useCBBQuery';

function SelectsSaver({
    selectedOptions = [],
    setSelectedOptions,
    isDisabled = false,
    listType = 'teams', // ['teams', 'players', 'conferences', 'player-seasons', 'livesearch-players', 'games']
    buttonStyle = {} // mainly for button positioning
}) {
    // UseContext
    const { userTier, userData } = useContext(GlobalContext);
    const userId = userTier.value > 0 ? userData.user._id : '';

    // handle saving new list options
    const [grabbedOption, setGrabbedOption] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [showTools, setShowTools] = useState(false);
    const [title, setTitle] = useState('');

    // useCBBQuery for a /users/ route!
    const pass = userTier.value === 0;
    const [refetchKey, setRefetchKey] = useState(0); // refetch if options added, deleted - refetchKey not used otherwise, only to trigger refetch
    const { data: dropdownOptions = [], isLoading } = useCBBQuery({ ep: 'get-dropdown-options', routeKey: 'users', cfg: { userId, listType, pass }, refetchKey });

    // Button Handlers
    const saveOption = async (e) => {
        e.preventDefault();
        setIsProcessing(true);
        try {
            const token = localStorage ? localStorage.getItem('auth-token') : window.localStorage.getItem('auth-token');
            const saveRoute = `${config.url.API_URL}/users/save-new-dropdown`;
            const saveBody = { userId, listType, savedList: selectedOptions, listName: title };
            await axios.post(saveRoute, saveBody, { headers: { 'x-auth-token': token } });
            setTitle('');
            setIsProcessing(false);
            setRefetchKey(prevKey => prevKey + 1); // increment refetchKey to trigger refetch
        } catch (err) {
            setIsProcessing(false);
        }
    };

    const loadOption = () => {
        setSelectedOptions(grabbedOption.savedList);
        setGrabbedOption(null);
    };

    const deleteOption = async (e) => {
        e.preventDefault();
        setIsProcessing(true);
        try {
            const token = localStorage ? localStorage.getItem('auth-token') : window.localStorage.getItem('auth-token');
            const deleteRoute = `${config.url.API_URL}/users/delete-dropdown?userId=${userId}&listName=${grabbedOption.listName}`;
            await axios.delete(deleteRoute, { headers: { 'x-auth-token': token } });
            setGrabbedOption(null);
            setIsProcessing(false);
            setRefetchKey(prevKey => prevKey + 1); // increment refetchKey to trigger refetch
        } catch (err) {
            setIsProcessing(false);
        }
    };

    // load all the list options
    const loadSelect =
        (<div style={{ width: 160, marginTop: 3, marginRight: 3 }}>
            <Select
                className='cbb-select cbb-selects-saver'
                classNames={{ option: d => `cbb-option-${d.data._id}` }}
                styles={smallSelectStyles}
                value={grabbedOption}
                options={dropdownOptions}
                onChange={e => setGrabbedOption(e)}
                placeholder='Choose Option'
                isDisabled={isLoading || isDisabled}
                getOptionValue={option => `${option._id}`}
                getOptionLabel={option => option.listName}
            />
        </div>);

    const titleFormControl =
        (<FormControl
            className='modal-input'
            style={{ fontSize: '0.9em', paddingLeft: 6, margin: 0, width: 120, height: 26.6, marginTop: 3, marginRight: 3, marginLeft: 6, borderColor: '#222' }}
            type='text'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            name='title'
            placeholder='Save Group As'
        />);

    // Are Buttons Disabled
    const loadIsDisabled = isProcessing || grabbedOption === null;
    const deleteIsDisabled = isProcessing || grabbedOption === null;
    const saveIsDisabled = isProcessing || title.length === 0;

    // And Return
    let displayStyle = userTier.value >= 1 ? 'flex' : 'none'; // Individual User Tier + D-I Teams Tier (inc D-I Teams Trial)
    return (
        <div className={`select-saver hide-print display-${displayStyle}`}>
            <div className='toggle-button' style={{ ...buttonStyle }} onClick={() => setShowTools(!showTools)} />
            <div style={{ display: showTools ? 'inherit' : 'none' }}>
                {/* Load and Delete a List */}
                {loadSelect}
                <span
                    className={`save-button ${loadIsDisabled && 'disabled'}`}
                    style={{ color: 'darkblue' }}
                    onClick={e => loadOption(e)}>Load</span>
                <span
                    className={`save-button ${deleteIsDisabled && 'disabled'}`}
                    style={{ color: 'darkred' }}
                    onClick={e => deleteOption(e)}>Dele</span>

                {/* Save a New List and Refresh */}
                {titleFormControl}
                <span
                    className={`save-button ${saveIsDisabled && 'disabled'}`}
                    style={{ color: 'darkgreen' }}
                    onClick={e => saveOption(e)}>Save</span>

            </div>
        </div>
    );
}

export default SelectsSaver;
