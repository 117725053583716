
import React, { useContext } from 'react';
import GlobalContext from '../../context/GlobalContext';

function TextHeaders({
    className = '',
    children,
    teamId,
    conferenceId,
    headerStyles
}) {
    // console.log('TextHeaders Props: ', { children, teamId, headerStyles });
    const { teamInfosObj, conferenceInfosObj } = useContext(GlobalContext);
    // console.log('TextHeaders teamInfosObj: ', { teamId, teamInfosObj, thisTeam: teamInfosObj[teamId] });

    // Set correct color
    let teamColor = null;
    if (teamId && teamInfosObj) {
        teamColor = teamInfosObj[teamId] ? teamInfosObj[teamId].hexColor1 : null;
    }
    if (conferenceId && conferenceInfosObj) {
        teamColor = conferenceInfosObj[conferenceId] ? conferenceInfosObj[conferenceId].hexColor1 : null;
    }

    // return the link + image
    return (
        <h3
            style={{ textDecorationColor: teamColor, ...headerStyles }}
            className={`section-header-1 ${className}`}
        >
            {children}
        </h3>
    );
}

export default TextHeaders;
