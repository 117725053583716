import React, { useState, useEffect } from 'react';
import CBBTable from '../renders/CBBTable';

const ResponsiveTable = ({
    columns,
    tableData,
    features,
    defaultSortObj,
    initialPageSize,
    infoModal,
    wrapperStyle,
    nthRow = 1,
    ratingsType = 'pctiles',
    hideFilters = false,
    scrollOrExpand = null
}) => {
    // console.log('ResponsiveTable Props: ', { scrollOrExpand, columns, tableData, defaultSortObj, initialPageSize, infoModal });

    // Note: the resizing logic is based on a single table's width as compared to the width of the <div> .padded-inner-container
    // for "long" tables (not meant to take up the entire with of the screen) where there can be multiple horizontally, a value
    // should be set for "scrollOrExpand" for now...

    // compute the table width based on width of columns in the table
    let tableWidth = 0;
    columns.forEach(i => {
        if (i.columns) {
            i.columns.forEach(j => { tableWidth += j.width ? j.width : j.minWidth; }); // add width, otherwise add minMidth?
        } else {
            tableWidth += i.width ? i.width : i.minWidth;
        }
    });

    // get left + right padding of container wrapping around the table
    const getPadding = (element) => {
        const paddingLeft = window.getComputedStyle(element).getPropertyValue('padding-left');
        const paddingRight = window.getComputedStyle(element).getPropertyValue('padding-right');
        return parseInt(paddingLeft.split('px')[0], 10) + parseInt(paddingRight.split('px')[0], 10);
    };

    // NOTE: this whole resizing depends on the wirth of the .padded-inner-container <div>
    // this is not necessarily the table container (NOT GREAT)...
    const tableContainer = document.querySelector('.padded-inner-container');
    const padding = tableContainer && getPadding(tableContainer);
    const [containerWidth, setContainerWidth] = useState(tableContainer ? tableContainer.offsetWidth - padding : 0);
    const [useStickyTable, setUseStickyTable] = useState(tableWidth > containerWidth);

    // if using expanding table, expand as much as 20%
    const useTableMaxWidth = !!containerWidth && (tableWidth / containerWidth < (6 / 7));
    const tableMaxWidth = useTableMaxWidth ? tableWidth * (7 / 6) + 'px' : '100%';

    // sticky table if table is bigger than identified .padded-inner-container
    useEffect(() => {
        if (tableContainer) { setContainerWidth(tableContainer.offsetWidth - padding); }
        setUseStickyTable(tableWidth > containerWidth);
    }, [tableContainer, padding]);

    // sticky table if table is bigger than identified .padded-inner-container
    useEffect(() => {
        setUseStickyTable(tableWidth > containerWidth);
    }, [tableWidth, containerWidth]);

    // I believe this runs on resize?
    useEffect(() => {
        const handleResize = () => {
            if (tableContainer) {
                setContainerWidth((tableContainer.offsetWidth - padding));
                setUseStickyTable(tableWidth > containerWidth);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    let showColumnSelect = features.includes('columnSelect');

    // Create table to return
    // NC: this is a bit hacky/whack. why does showColumnSelect impact sticky vs scroll table? why expand on the fallback?
    const finalScrollOrExpand = scrollOrExpand || (useStickyTable || showColumnSelect ? 'scroll' : 'expand');
    const responsiveTable =
        (<CBBTable
            scrollOrExpand={finalScrollOrExpand}
            data={tableData}
            columns={columns}
            features={features}
            defaultSortObj={defaultSortObj}
            initialPageSize={initialPageSize}
            infoModal={infoModal}
            nthRow={nthRow}
            wrapperStyle={wrapperStyle}
            ratingsType={ratingsType}
            hideFilters={hideFilters}
            tableMaxWidth={finalScrollOrExpand === 'expand' && tableMaxWidth}
            className={finalScrollOrExpand === 'expand' && 'full-table'}
        />);

    // // Logging
    // console.log('scrollOrExpand fields: ', {
    //     finalScrollOrExpand,
    //     useStickyTable,
    //     containerWidth,
    //     tableWidth,
    //     tableMaxWidth,
    //     useTableMaxWidth
    // });

    // and return
    return responsiveTable;
};

export default ResponsiveTable;
