
import React from 'react';
import { select } from 'd3-selection';

const showLegend = (e, ratingsType = 'pctiles') => {
    let tooltipDiv = select('.global-info-modal');

    const isFlipped = window.innerWidth - e.pageX < 400 ? true : false;

    // Reveal & Position Tooltip
    tooltipDiv.classed('show-modal', true);
    tooltipDiv.classed('hide-modal', false);
    tooltipDiv.style('left', (isFlipped ? e.pageX - 425 : e.pageX - 10) + 'px').style('top', (e.pageY + 20) + 'px'); // position

    // Rating Text
    let ratingHeader = ratingsType === 'pctiles' ? 'Percentiles' : 'Rankings';
    let ratingDesc = ratingsType === 'pctiles'
        ? `For percentiles, we use a red (0 is the worst) to white (50 is average) to green (100 is the best) color scale.`
        : `For team rankings, we use a red (highest rank, the worst) to yellow (the middle) to green (lowest rank, #1 the best) color scale.`;

    // Pre-Hide Titles & All Legends
    tooltipDiv.select('.pctiles').style('display', 'none');
    tooltipDiv.select('.ranks').style('display', 'none');
    tooltipDiv.select('.info-modal-header').style('display', 'none');
    tooltipDiv.select(`.${ratingsType}`).style('display', 'block');

    // Bulleted Info
    // const bodyDiv = tooltipDiv.select(`.body`);
    // const content1Div = bodyDiv.append('div').attr('class', 'content content1').style('display', 'block');
    // content1Div.append('p').attr('class', 'title').text(`${ratingHeader} Scale:`);
    // content1Div.append('p').attr('class', 'desc').text(ratingDesc);
    tooltipDiv.select(`.content1`).style('display', 'block');
    tooltipDiv.select(`.content1 .title`).text(`${ratingHeader} Scale:`);
    tooltipDiv.select(`.content1 .desc`).text(ratingDesc);
};

const hideLegend = () => {
    // hide the whole tooltip
    let tooltipDiv = select('.global-info-modal');
    tooltipDiv.style('top', 0).style('left', 0);
    tooltipDiv.classed('show-modal', false);
    tooltipDiv.classed('hide-modal', true);

    // hide contents & remove textx
    tooltipDiv.select('.content1 .title').text('');
    tooltipDiv.select('.content1 .desc').text('');
    tooltipDiv.select(`.content1`).style('display', 'none');
};

// The Modal Itself
function TableColorsLegend({ ratingsType = 'pctiles' }) {
    return (
        <div
            className={`table-legend ${ratingsType}`}
            onMouseOver={(e) => showLegend(e, ratingsType)}
            onMouseOut={() => hideLegend()}
        />
    );
}

export default TableColorsLegend;
