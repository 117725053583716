
import React, { useState, useEffect } from 'react';
import imageUrls from '../../../images/gcsImages';

function CBBBranding({
    svgRef,
    svgWidth,
    svgHeight,
    width,
    type = 'black',
    show = false
}) {
    // console.log('CBBBranding props: ', { x, y, width, type, defaultShow });

    // Dragging State
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [startDragPos, setStartDragPos] = useState({ x: 0, y: 0 });

    // Set Scale for smoother drag
    const svgRect = svgRef?.current?.getBoundingClientRect() || {};
    const xScaleFactor = svgRect.width / svgWidth;
    const yScaleFactor = svgRect.height / svgHeight;

    // Handle Mouse Down
    const handleMouseDown = (event) => {
        setIsDragging(true);
        const adjustedX = (event.clientX - svgRect.left) / xScaleFactor - position.x;
        const adjustedY = (event.clientY - svgRect.top) / yScaleFactor - position.y;
        setStartDragPos({ x: adjustedX, y: adjustedY });
        event.preventDefault();
    };

    // Handle Mouse Move
    const handleMouseMove = (event) => {
        if (!isDragging) { return; }
        const adjustedX = (event.clientX - svgRect.left) / xScaleFactor - startDragPos.x;
        const adjustedY = (event.clientY - svgRect.top) / yScaleFactor - startDragPos.y;
        setPosition({ x: adjustedX, y: adjustedY });
    };

    // Handle Mouse Up
    const handleMouseUp = () => {
        setIsDragging(false);
    };

    // The Way This Works is via global addEventListeneners
    // it was either this, or handleMouseMove, handleMouseUp listeners on the parent SVG
    // with this approach, solution is encapsulated solely within this component
    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging]);


    // PREVIOUS EXISTING CODE
    let ratio = 4.546; // width / height
    let hrefLink = '';
    switch (type) {
        case 'black': hrefLink = imageUrls.cbbLogoBlack; break;
        case 'white': hrefLink = imageUrls.cbbLogoWhite; break;
        case 'logo': hrefLink = imageUrls.cbbLogoIconTransparent; break;
        default: console.log('Error bad type');
    }

    let poweredBy =
        (<text
            style={{ opacity: show ? 1 : 0, fontStyle: 'italic' }}
            x={-(width / 25)}
            y={(width / ratio) / 1.6} // center?
            textAnchor='end'
            dominantBaseline='middle'
            fill={type === 'black' ? 'black' : 'white'}
            fontSize={width / 7.5}
        >
            Powered by
        </text>);

    let cbbLogo = (
        <image
            style={{ opacity: show ? 1 : 0 }}
            x={0}
            y={0}
            width={width}
            dominantBaseline='middle'
            xlinkHref={hrefLink}
        />
    );

    const translateG = `translate(${position.x},${position.y})`;
    return (
        <g className='cbb-logo' transform={translateG} onMouseDown={handleMouseDown} cursor={isDragging ? 'grabbing' : 'grab'}>
            {type !== 'logo' && poweredBy}
            {cbbLogo}
        </g>
    );
}

export default CBBBranding;
