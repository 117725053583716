
import React from 'react';
import cols from '../utils/ColumnsDict';
import ResponsiveTable from '../utils/ResponsiveTable';

const PlayerGameStatsTable = ({
    tableData,
    tableType,
    tableTypeConfig = { subType: null, shotZone: null, clockInterval: null },
    features = ['ratingsBar', 'pagination', 'columnSelect', 'dlButton', 'infoModal', 'hiderButton'],
    pageType,
    lhsColsGroup = 1
}) => {
    // Default Left-Most Columns
    let isMinsPbp = ['zones6', 'dists7', 'assistregion', 'rebByFgaFta', 'tradshotPbp', 'freeThrows', 'pbpFouls'].includes(tableType);
    const defaultCols1 = { // For 1 Player / Many Games
        Header: ' ', sticky: 'left', columns: [
            cols.gameDate,
            cols.winLossRecord(''),
            cols.gameBox,
            cols.winOrLoss('ptsScoredTm', 'ptsAgst'),
            cols.isHome(),
            cols.teamLogo({ idKey: 'teamIdAgst' }),
            cols.blankCol({ width: 15 }),
            cols.valueOnly('ptsScoredTm', { ptgc: 'player', header: 'Tm', hideFilter: true, unit: 'int', width: 30 }),

            cols.valueOnly('ptsAgst', { ptgc: 'player', header: 'Op', hideFilter: true, unit: 'int', width: 30 }),
            cols.blankCol({ width: 15 }),
            cols.valueOnly(isMinsPbp ? 'minsPbp' : 'mins', { ptgc: 'player', className: 'sticky-left left-last' })
        ]
    };
    const defaultCols2 = { // For 1 Game / Many Players
        Header: ' ', sticky: 'left', className: 'sticky-left left-last', columns: [
            cols.teamLogo({ idKey: 'teamId', className: 'sticky-left' }),
            cols.position(),
            // cols.height(), // feel like height is better than jerseyNum for any table? maybe for game table it is better?
            cols.jerseyNum(),
            cols.classYr(),
            cols.playerFullName({ idAcc: 'playerId', nameAcc: 'fullName', header: 'Player', width: 135, className: 'sticky-left player-full-name-width' }),
            cols.valueOnly(isMinsPbp ? 'minsPbp' : 'mins', { ptgc: 'player', className: 'sticky-left left-last' })
        ]
    };
    const defaultCols3 = {
        Header: ' ', sticky: 'left', columns: [
            cols.gameDate,
            cols.winOrLoss('ptsScoredTm', 'ptsAgst'),
            cols.teamLogo({ idKey: 'teamIdAgst' }),
            cols.valueOnly(isMinsPbp ? 'minsPbp' : 'mins', { ptgc: 'player', className: 'sticky-left left-last' })
        ]
    };
    let defaultCols = [];
    switch (lhsColsGroup) {
        case 1: defaultCols = defaultCols1; break;
        case 2: defaultCols = defaultCols2; break;
        case 3: defaultCols = defaultCols3; break;
        default: console.log('Error bad lhsCols value');
    }

    const mainConfig = { ptgc: 'player' };
    const cfgL10 = { ptgc: 'player', className: 'border-left pad-left-10' };
    const cfgW40 = { ...mainConfig, width: 40 };
    const cfgL10W40 = { ...mainConfig, width: 40, className: 'border-left pad-left-10' };

    // Create Columns for various table types
    const basicCols = [
        defaultCols,
        {
            Header: 'Traditional Boxscore', columns: [
                cols.valueOnly('ptsScored', { ptgc: 'player' }),
                cols.valueOnly('ast', { ptgc: 'player', width: 36 }),
                cols.valueOnly('orb', { ptgc: 'player', width: 36 }),
                cols.valueOnly('drb', { ptgc: 'player', width: 36 }),
                cols.valueOnly('reb', { ptgc: 'player', width: 36 }),
                cols.valueOnly('stl', { ptgc: 'player', width: 36 }),
                cols.valueOnly('blk', { ptgc: 'player', width: 36 }),
                cols.valueOnly('tov', { ptgc: 'player', width: 36 }),
                cols.valueOnly('pf', { ptgc: 'player', width: 36 }),
                cols.valueOnly('plusMinus', { ptgc: 'player', width: 36 })
                // cols.per, // add to spreadsheet
                // cols.eff, // add to spreadsheet
            ]
        }, {
            Header: 'Traditional Shooting', className: 'border-left pad-left-10', columns: [
                cols.valueOnly('fgm', { ptgc: 'player', className: 'border-left pad-left-10' }),
                cols.valueOnly('fga', { ptgc: 'player' }),
                cols.valueAndRating('fgPct', { ptgc: 'player', width: 80 }),
                cols.valueOnly('fgm2', { ptgc: 'player', className: 'border-left pad-left-10' }),
                cols.valueOnly('fga2', { ptgc: 'player' }),
                cols.valueAndRating('fg2Pct', { ptgc: 'player', width: 80 }),
                cols.valueOnly('fgm3', { ptgc: 'player', className: 'border-left pad-left-10' }),
                cols.valueOnly('fga3', { ptgc: 'player' }),
                cols.valueAndRating('fg3Pct', { ptgc: 'player', width: 80 }),
                cols.valueOnly('ftm', { ptgc: 'player', className: 'border-left pad-left-10' }),
                cols.valueOnly('fta', { ptgc: 'player' }),
                cols.valueAndRating('ftPct', { ptgc: 'player', width: 80 })
            ]
        }
    ];

    const boxSkinnyCols = [
        defaultCols,
        {
            Header: 'Traditional Boxscore', columns: [
                cols.valueOnly('ptsScored', { ptgc: 'player' }),
                cols.valueOnly('ast', { ptgc: 'player' }),
                cols.valueOnly('orb', { ptgc: 'player' }),
                cols.valueOnly('drb', { ptgc: 'player' }),
                cols.valueOnly('reb', { ptgc: 'player' }),
                cols.valueOnly('stl', { ptgc: 'player' }),
                cols.valueOnly('blk', { ptgc: 'player' }),
                cols.valueOnly('tov', { ptgc: 'player' }),
                cols.valueOnly('pf', { ptgc: 'player' })
            ]
        }, {
            Header: 'Traditional Shooting', className: 'border-left', columns: [
                // cols.valueOnly('fga', { ptgc: 'player' }),
                // cols.valueOnly('fga2', { ptgc: 'player' }),
                cols.valueAndRating('fg2Pct', { ptgc: 'player', className: 'border-left' }),
                // cols.valueOnly('fga3', { ptgc: 'player' }),
                cols.valueAndRating('fg3Pct', { ptgc: 'player' }),
                // cols.valueOnly('fta', { ptgc: 'player' }),
                cols.valueAndRating('ftPct', { ptgc: 'player' }),
                cols.valueAndRating('efgPct', { ptgc: 'player' }),
                cols.valueAndRating('tsPct', { ptgc: 'player' })
            ]
        }
    ];

    const tradshotCols = [
        defaultCols,
        { Header: 'Advanced', columns: [
            cols.valueAndRating('efgPct', mainConfig),
            cols.valueAndRating('tsPct', mainConfig),
            cols.valueAndRating('fga3Rate', mainConfig)
        ] },
        { Header: 'Field Goals', className: 'border-left', columns: [
            cols.valueOnly(`fgm`, { ...cfgL10, showFilter: true }),
            cols.valueOnly(`fga`, { ...mainConfig, showFilter: true }),
            cols.valueAndRating('fgPct', mainConfig)
        ] },
        { Header: '2-Point FGs', className: 'border-left', columns: [
            cols.valueOnly(`fgm2`, { ...cfgL10, showFilter: true }),
            cols.valueOnly(`fga2`, { ...mainConfig, showFilter: true }),
            cols.valueAndRating('fg2Pct', mainConfig)
        ] },
        { Header: '3-Point FGs', className: 'border-left', columns: [
            cols.valueOnly(`fgm3`, { ...cfgL10, showFilter: true }),
            cols.valueOnly(`fga3`, { ...mainConfig, showFilter: true }),
            cols.valueAndRating('fg3Pct', mainConfig)
        ] },
        { Header: 'Free Throws', className: 'border-left', columns: [
            cols.valueOnly(`ftm`, { ...cfgL10, showFilter: true }),
            cols.valueOnly(`fta`, { ...mainConfig, showFilter: true }),
            cols.valueAndRating('ftPct', mainConfig)
        ] }
    ];

    const tradshotPbpCols = [
        defaultCols,
        { Header: 'Advanced', className: 'border-left pad-left-10', columns: [
            cols.valueAndRating('efgPct', { ...cfgL10 }),
            cols.valueAndRating('fga3Rate', mainConfig)
        ] },
        { Header: 'Field Goals', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`fgm`, { ...cfgL10 }),
            cols.valueOnly(`fga`, { ...mainConfig }),
            cols.valueAndRating('fgPct', mainConfig)
        ] },
        { Header: '2-Point FGs', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`fgm2`, { ...cfgL10 }),
            cols.valueOnly(`fga2`, { ...mainConfig }),
            cols.valueAndRating('fg2Pct', mainConfig)
        ] },
        { Header: '3-Point FGs', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`fgm3`, { ...cfgL10 }),
            cols.valueOnly(`fga3`, { ...mainConfig }),
            cols.valueAndRating('fg3Pct', mainConfig)
        ] }
    ];

    const offensiveCols = [
        defaultCols,
        {
            Header: 'Ball Control & Usage', columns: [
                cols.valueAndRating('astPct', { ptgc: 'player' }),
                cols.valueAndRating('astRatio', { ptgc: 'player' }),
                cols.valueAndRating('tovPct', { ptgc: 'player' }),
                cols.valueAndRating('astTov', { ptgc: 'player' }),
                cols.valueAndRating('usagePct', { ptgc: 'player' }),
                cols.valueAndRating('astUsage', { ptgc: 'player' })
            ]
        },
        {
            Header: 'Shooting', className: 'border-left pad-left-10', columns: [
                cols.valueAndRating('tsPct', { ptgc: 'player', className: 'border-left pad-left-10' }),
                cols.valueAndRating('fg2Pct', { ptgc: 'player' }),
                cols.valueAndRating('fg3Pct', { ptgc: 'player' }),
                cols.valueAndRating('ftPct', { ptgc: 'player' })
            ]
        },
        {
            Header: 'Play Style', className: 'border-left pad-left-10', columns: [
                cols.valueAndRating('orbPct', { ptgc: 'player', className: 'border-left pad-left-10' }),
                cols.valueAndRating('ftaRate', { ptgc: 'player' }),
                cols.valueAndRating('fga3Rate', { ptgc: 'player' })
            ]
        }
    ];

    const defensiveCols = [
        defaultCols,
        {
            Header: 'Defense Overall', className: 'border-left pad-left-10', columns: [
                cols.valueOnly('drb', { ...cfgL10 }),
                cols.valueAndRating('drbPct', { ptgc: 'player' }),
                cols.valueOnly('stl', { ...cfgL10 }),
                cols.valueAndRating('stlPct', { ptgc: 'player' }),
                cols.valueOnly('blk', { ...cfgL10 }),
                cols.valueAndRating('blkPct', { ptgc: 'player' }),
                cols.valueAndRating('hkmPct', { ptgc: 'player' })
            ]
        },
        {
            Header: 'Foul Tendencies', className: 'border-left pad-left-10', columns: [
                cols.valueOnly('pf', { ...cfgL10 }),
                cols.valueAndRating('pfEff', { ptgc: 'player' }),
                cols.valueAndRating('stlPerPf', { ptgc: 'player' }),
                cols.valueAndRating('blkPerPf', { ptgc: 'player' })
            ]
        }
    ];

    const zones6Cols = [
        defaultCols,
        { Header: 'At The Rim (u4.5 ft)', columns: [
            cols.valueOnly('atr2Fgm', { ...cfgL10 }),
            cols.valueOnly('atr2Fga', { ...mainConfig }),
            cols.valueAndRating('atr2FgPct', { ...mainConfig })
        ] },
        { Header: 'In The Paint', className: 'border-left', columns: [
            cols.valueOnly('paint2Fgm', { ...cfgL10 }),
            cols.valueOnly('paint2Fga', { ...mainConfig }),
            cols.valueAndRating('paint2FgPct', { ...mainConfig })
        ] },
        { Header: 'Mid Range 2s', className: 'border-left', columns: [
            cols.valueOnly('mid2Fgm', { ...cfgL10 }),
            cols.valueOnly('mid2Fga', { ...mainConfig }),
            cols.valueAndRating('mid2FgPct', { ...mainConfig })
        ] },
        { Header: 'Above Break 3s', className: 'border-left', columns: [
            cols.valueOnly('atb3Fgm', { ...cfgL10 }),
            cols.valueOnly('atb3Fga', { ...mainConfig }),
            cols.valueAndRating('atb3FgPct', { ...mainConfig })
        ] },
        { Header: 'Corner 3s', className: 'border-left', columns: [
            cols.valueOnly('c3Fgm', { ...cfgL10 }),
            cols.valueOnly('c3Fga', { ...mainConfig }),
            cols.valueAndRating('c3FgPct', { ...mainConfig })
        ] }
    ];

    const dists7Cols = [
        defaultCols,
        { Header: 'At The Rim (u4.5 ft)', columns: [
            cols.valueOnly('atr2Fgm', { ...cfgL10 }),
            cols.valueOnly('atr2Fga', { ...mainConfig }),
            cols.valueAndRating('atr2FgPct', { ...mainConfig })
        ] },
        { Header: 'Short 2s (4.5 - 10 ft)', className: 'border-left', columns: [
            cols.valueOnly('sht2Fgm', { ...cfgL10 }),
            cols.valueOnly('sht2Fga', { ...mainConfig }),
            cols.valueAndRating('sht2FgPct', { ...mainConfig })
        ] },
        { Header: 'Medium 2s (10 - 15 ft)', className: 'border-left', columns: [
            cols.valueOnly('med2Fgm', { ...cfgL10 }),
            cols.valueOnly('med2Fga', { ...mainConfig }),
            cols.valueAndRating('med2FgPct', { ...mainConfig })
        ] },
        { Header: 'Long 2s (15+ ft)', className: 'border-left', columns: [
            cols.valueOnly('lng2Fgm', { ...cfgL10 }),
            cols.valueOnly('lng2Fga', { ...mainConfig }),
            cols.valueAndRating('lng2FgPct', { ...mainConfig })
        ] },
        { Header: 'Short 3s (u25 ft)', className: 'border-left', columns: [
            cols.valueOnly('sht3Fgm', { ...cfgL10 }),
            cols.valueOnly('sht3Fga', { ...mainConfig }),
            cols.valueAndRating('sht3FgPct', { ...mainConfig })
        ] },
        { Header: 'Long 3s (25+ ft)', className: 'border-left', columns: [
            cols.valueOnly('lng3Fgm', { ...cfgL10 }),
            cols.valueOnly('lng3Fga', { ...mainConfig }),
            cols.valueAndRating('lng3FgPct', { ...mainConfig })
        ] }
    ];

    const assistZoneCols = [
        defaultCols,
        { Header: 'At The Rim (under 4.5 ft)', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`atr2Ast`, cfgL10),
            cols.valueAndRating(`pctAstAtr2`, mainConfig),
            cols.valueAndRating(`atr2AstP40`, mainConfig)
            // cols.valueAndRating(`atr2AstdPct`, mainConfig)
        ] },
        { Header: 'In The Paint 2s', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`paint2Ast`, cfgL10),
            cols.valueAndRating(`pctAstPaint2`, mainConfig),
            cols.valueAndRating(`paint2AstP40`, mainConfig)
            // cols.valueAndRating(`paint2AstdPct`, mainConfig)
        ] },
        { Header: 'Midrange 2s', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`mid2Ast`, cfgL10),
            cols.valueAndRating(`pctAstMid2`, mainConfig),
            cols.valueAndRating(`mid2AstP40`, mainConfig)
            // cols.valueAndRating(`mid2AstdPct`, mainConfig)
        ] },
        { Header: 'Above Break 3s', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`atb3Ast`, cfgL10),
            cols.valueAndRating(`pctAstAtb3`, mainConfig),
            cols.valueAndRating(`atb3AstP40`, mainConfig)
            // cols.valueAndRating(`atb3AstdPct`, mainConfig)
        ] },
        { Header: 'Corner 3s', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`c3Ast`, cfgL10),
            cols.valueAndRating(`pctAstC3`, mainConfig),
            cols.valueAndRating(`c3AstP40`, mainConfig)
            // cols.valueAndRating(`c3AstdPct`, mainConfig)
        ] }
    ];

    const rebByFgaFtaCols = [
        defaultCols,
        { Header: 'ORB off FGs', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`orbFg`, { ptgc: 'player', className: 'border-left pad-left-10' }),
            cols.valueAndRating(`orbFgPct`, { ptgc: 'player' })
        ] },
        { Header: 'ORB off FTs', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`orbFt`, { ptgc: 'player', className: 'border-left pad-left-10' }),
            cols.valueAndRating(`orbFtPct`, { ptgc: 'player' })
        ] },
        { Header: 'DRB off FGs', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`drbFg`, { ptgc: 'player', className: 'border-left pad-left-10' }),
            cols.valueAndRating(`drbFgPct`, { ptgc: 'player' })
        ] },
        { Header: 'DRB off FTs', className: 'border-left pad-left-10', columns: [
            cols.valueOnly(`drbFt`, { ptgc: 'player', className: 'border-left pad-left-10' }),
            cols.valueAndRating(`drbFtPct`, { ptgc: 'player' })
        ] }
    ];

    const freeThrowCols = [
        defaultCols,
        { Header: '1st Free Throw', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('ftm1', { ptgc: 'player', className: 'border-left pad-left-10', width: 40 }),
            cols.valueOnly('fta1', { ptgc: 'player', width: 40 }),
            cols.valueAndRating('ft1Pct', { ptgc: 'player' })
        ] },
        { Header: '2nd Free Throw', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('ftm2', { ptgc: 'player', className: 'border-left pad-left-10', width: 40 }),
            cols.valueOnly('fta2', { ptgc: 'player', width: 40 }),
            cols.valueAndRating('ft2Pct', { ptgc: 'player' })
        ] },
        { Header: '3rd Free Throw', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('ftm3', { ptgc: 'player', className: 'border-left pad-left-10', width: 40 }),
            cols.valueOnly('fta3', { ptgc: 'player', width: 40 }),
            cols.valueAndRating('ft3Pct', { ptgc: 'player' })
        ] },
        { Header: 'All Free Throws', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('ftm', { ptgc: 'player', className: 'border-left pad-left-10', width: 40 }),
            cols.valueOnly('fta', { ptgc: 'player', width: 40 }),
            cols.valueAndRating('ftPct', { ptgc: 'player' })
        ] }
    ];

    const pbpFoulsCols = tableType !== 'pbpFouls' ? [] : [
        defaultCols,
        { Header: 'Shooting Fouls', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('sflDrawn', { ptgc: 'player', className: 'border-left pad-left-10', width: 45 }),
            cols.valueOnly('shotAtt', { ptgc: 'player', width: 45 }),
            cols.valueAndRating(`sflDrawnPct`, { ptgc: 'player' }),
            cols.valueOnly('sfl2Drawn', { ptgc: 'player', className: 'border-left pad-left-10', width: 45 }),
            cols.valueOnly('shotAtt2P', { ptgc: 'player', width: 45 }),
            cols.valueAndRating(`sfl2DrawnPct`, { ptgc: 'player' }),
            cols.valueOnly('sfl3Drawn', { ptgc: 'player', className: 'border-left pad-left-10', width: 45 }),
            cols.valueOnly('shotAtt3P', { ptgc: 'player', width: 45 }),
            cols.valueAndRating(`sfl3DrawnPct`, { ptgc: 'player' })
        ] },
        { Header: 'And 1s', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('and1', { ptgc: 'player', className: 'border-left pad-left-10', width: 45 }),
            cols.valueAndRating(`and1Pct`, { ptgc: 'player' })
        ] },
        { Header: 'Floor Fouls', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('fflDrawn', { ptgc: 'player', className: 'border-left pad-left-10', width: 45 }),
            cols.valueAndRating(`fflDrawnPct`, { ptgc: 'player' })
        ] },
        { Header: 'Charges Drawn', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('opfd', { ptgc: 'player', className: 'border-left pad-left-10', width: 45 }),
            cols.valueAndRating(`opfdPct`, { ptgc: 'player' })
        ] }
    ];

    // Shooting By Shot Clock
    const subType = tableTypeConfig?.subType || 'oneZone';
    const shotZone = tableTypeConfig?.shotZone || 'atr2';
    const shotZoneName = shotZone === 'atr2' ? 'At The Rim (under 4.5 feet)' : shotZone === 'paint2' ? 'In The Paint 2s' : shotZone === 'mid2' ? 'Mid Range 2s' : shotZone === 'atb3' ? 'Above Break 3s' : shotZone === 'c3' ? 'Corner 3s' : '';
    const clockInterval = tableTypeConfig?.clockInterval || '01'; // S01 == 0 - 10 secs, S12: 10 - 20 secs, S23: 20 - 30 secs
    const clockIntervalName = clockInterval === 'S01' ? '0 - 10 Seconds' : clockInterval === 'S12' ? '10 - 20 Seconds' : clockInterval === 'S23' ? '20 - 30 Seconds' : '';
    const byShotClockCols = (tableType !== 'byShotClock') ? [] :
        subType === 'oneZone' ?
            [
                defaultCols,
                { Header: `${shotZoneName} Shooting By Shot Clock`, className: 'border-left pad-left-10', columns: [
                    { Header: '0 - 10 Secs on Shot Clock', className: 'border-left pad-left-10', columns: [
                        cols.valueOnly(`${shotZone}FgmS01`, cfgL10W40),
                        cols.valueOnly(`${shotZone}FgaS01`, cfgW40),
                        cols.valueAndRating(`${shotZone}FgPctS01`, mainConfig),
                        cols.valueAndRating(`${shotZone}FgaFreqZoneS01`, mainConfig)
                    ] },
                    { Header: '10 - 20 Secs on Shot Clock', className: 'border-left pad-left-10', columns: [
                        cols.valueOnly(`${shotZone}FgmS12`, cfgL10W40),
                        cols.valueOnly(`${shotZone}FgaS12`, cfgW40),
                        cols.valueAndRating(`${shotZone}FgPctS12`, mainConfig),
                        cols.valueAndRating(`${shotZone}FgaFreqZoneS12`, mainConfig)
                    ] },
                    { Header: '20 - 30 Secs on Shot Clock', className: 'border-left pad-left-10', columns: [
                        cols.valueOnly(`${shotZone}FgmS23`, cfgL10W40),
                        cols.valueOnly(`${shotZone}FgaS23`, cfgW40),
                        cols.valueAndRating(`${shotZone}FgPctS23`, mainConfig),
                        cols.valueAndRating(`${shotZone}FgaFreqZoneS23`, mainConfig)
                    ] }
                ] }
            ]
            : subType === 'oneClock' ?
                [
                    defaultCols,
                    { Header: `${clockIntervalName} On Shot Clock Shooting By Zone`, className: 'border-left pad-left-10', columns: [
                        { Header: 'At The Rim (u4.5 ft)', className: 'border-left pad-left-10', columns: [
                            cols.valueOnly(`atr2Fgm${clockInterval}`, cfgL10W40),
                            cols.valueOnly(`atr2Fga${clockInterval}`, cfgW40),
                            cols.valueAndRating(`atr2FgPct${clockInterval}`, mainConfig),
                            cols.valueAndRating(`atr2FgaFreqClock${clockInterval}`, mainConfig)
                        ] },
                        { Header: 'In The Paint 2s', className: 'border-left pad-left-10', columns: [
                            cols.valueOnly(`paint2Fgm${clockInterval}`, cfgL10W40),
                            cols.valueOnly(`paint2Fga${clockInterval}`, cfgW40),
                            cols.valueAndRating(`paint2FgPct${clockInterval}`, mainConfig),
                            cols.valueAndRating(`paint2FgaFreqClock${clockInterval}`, mainConfig)
                        ] },
                        { Header: 'Mid Range 2s', className: 'border-left pad-left-10', columns: [
                            cols.valueOnly(`mid2Fgm${clockInterval}`, cfgL10W40),
                            cols.valueOnly(`mid2Fga${clockInterval}`, cfgW40),
                            cols.valueAndRating(`mid2FgPct${clockInterval}`, mainConfig),
                            cols.valueAndRating(`mid2FgaFreqClock${clockInterval}`, mainConfig)
                        ] },
                        { Header: 'Above Break 3s', className: 'border-left pad-left-10', columns: [
                            cols.valueOnly(`atb3Fgm${clockInterval}`, cfgL10W40),
                            cols.valueOnly(`atb3Fga${clockInterval}`, cfgW40),
                            cols.valueAndRating(`atb3FgPct${clockInterval}`, mainConfig),
                            cols.valueAndRating(`atb3FgaFreqClock${clockInterval}`, mainConfig)
                        ] },
                        { Header: 'Corner 3s', className: 'border-left pad-left-10', columns: [
                            cols.valueOnly(`c3Fgm${clockInterval}`, cfgL10W40),
                            cols.valueOnly(`c3Fga${clockInterval}`, cfgW40),
                            cols.valueAndRating(`c3FgPct${clockInterval}`, mainConfig),
                            cols.valueAndRating(`c3FgaFreqClock${clockInterval}`, mainConfig)
                        ] }
                    ] }
                ] : [];

    const singleGameTweetCols = [
        defaultCols,
        { Header: 'Basic Box', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('ptsScored', { ptgc: 'player', className: 'border-left pad-left-10' }),
            cols.valueOnly('reb', { ptgc: 'player' }),
            cols.valueOnly('ast', { ptgc: 'player' }),
            cols.valueOnly('stl', { ptgc: 'player' }),
            cols.valueOnly('blk', { ptgc: 'player' }),
            cols.valueOnly('tov', { ptgc: 'player' })
        ] },
        { Header: 'Usage vs TS%', className: 'border-left pad-left-10', columns: [
            cols.valueAndRating('usagePct', { ptgc: 'player' }),
            cols.valueAndRating('tsPct', { ptgc: 'player', className: 'border-left pad-left-10' })
        ] },
        { Header: 'Shooting', className: 'border-left pad-left-10', columns: [
            cols.valueAndRating('fg2Pct', { ptgc: 'player', className: 'border-left pad-left-10' }),
            cols.valueAndRating('fg3Pct', { ptgc: 'player' }),
            cols.valueAndRating('ftPct', { ptgc: 'player' })
        ] }
    ];

    // post-game report template for consultin service
    const memphisPostGameCols = tableType !== 'memphisPostGame' ? [] : [
        defaultCols,
        { Header: 'Shooting', className: 'border-left pad-left-10', columns: [
            cols.fgmFga({ fgmKey: `fgm`, fgaKey: `fga`, header: 'FGM/A', width: 54, showPct: true, className: 'pad-left-10' }),
            cols.fgmFga({ fgmKey: `fgm3`, fgaKey: `fga3`, header: '3PM/A', width: 54, showPct: true }),
            cols.fgmFga({ fgmKey: `ftm`, fgaKey: `fta`, header: 'FTM/A', width: 54, showPct: true }),
            cols.valueOnly('layupDunks', { ptgc: 'player', header: 'LayDnk', width: 54, hideFilter: true })
        ] },
        { Header: 'Box Score', className: 'border-left pad-left-10', columns: [
            cols.valueOnly('orb', { ptgc: 'player', width: 39, hideFilter: true, className: 'border-left pad-left-10' }),
            cols.valueOnly('drb', { ptgc: 'player', width: 39, hideFilter: true }),
            cols.valueOnly('reb', { ptgc: 'player', width: 39, hideFilter: true }),
            cols.valueOnly('ast', { ptgc: 'player', width: 39, hideFilter: true }),
            // LU/DUNKS
            cols.valueOnly('tov', { ptgc: 'player', width: 39, hideFilter: true }),
            cols.valueOnly('stl', { ptgc: 'player', width: 39, hideFilter: true }),
            cols.valueOnly('blk', { ptgc: 'player', width: 39, hideFilter: true }),
            cols.valueOnly('pf', { ptgc: 'player', width: 39, hideFilter: true }),
            cols.valueOnly('ptsScored', { ptgc: 'player', width: 43, hideFilter: true })
        ] },
        { Header: 'Advanced', className: 'border-left pad-left-10', columns: [
            cols.valueAndRating('efgPct', { ptgc: 'player', width: 78, hideFilter: true, className: 'border-left pad-left-10' }),
            cols.valueAndRating('orbPct', { ptgc: 'player', width: 78, hideFilter: true }),
            cols.valueAndRating('drbPct', { ptgc: 'player', width: 78, hideFilter: true }),
            cols.valueAndRating('usagePct', { ptgc: 'player', width: 78, hideFilter: true }),
            cols.valueAndRating('goodTakeRate', { ptgc: 'player', width: 78, hideFilter: true }),
            cols.valueOnly('plusMinus', { ptgc: 'player', width: 58, hideFilter: true })
            // FGM-FGA
            // 3PM-3PA
            // FTM-FTA
            // ORB, DRB, TRB, AST, LU/DUNKS, TOV, STL, BLK, PF
            // GAP, PTS, GAP
            // eFG%, ORB%, DRB%, USG%, gTK%, +/-
        ] }
    ];


    // Switch Between Columns
    const columns = React.useMemo(() => {
        switch (tableType) {
            case 'basic': return basicCols;
            case 'boxSkinny': return boxSkinnyCols;
            case 'tradshot': return tradshotCols;
            case 'tradshotPbp': return tradshotPbpCols;
            case 'offensive': return offensiveCols;
            case 'defensive': return defensiveCols;
            case 'zones6': return zones6Cols;
            case 'dists7': return dists7Cols;
            case 'assistregion': return assistZoneCols;
            case 'byShotClock': return byShotClockCols;
            case 'rebByFgaFta': return rebByFgaFtaCols;
            case 'freeThrows': return freeThrowCols;
            case 'pbpFouls': return pbpFoulsCols;
            case 'singleGameTweet': return singleGameTweetCols;
            case 'memphisPostGame': return memphisPostGameCols;
            default: return [];
        }
    }, [tableData, tableType]);

    const defaultSortObj = [1, 3].includes(lhsColsGroup)
        ? { id: 'gameDate', desc: true }
        : (isMinsPbp === true
            ? { id: 'minsPbp', desc: true }
            : { id: 'mins', desc: true }
        );

    let thisInfoModal = pageType; // [playerGameRecaps, gameOverview]
    return (
        <ResponsiveTable
            columns={columns}
            tableData={tableData}
            defaultSortObj={defaultSortObj}
            features={features}
            infoModal={thisInfoModal}
        />
    );
};

export default PlayerGameStatsTable;
