
// Imports
import React from 'react';
import { showTooltip, hideTooltip, showRowTooltip, hideRowTooltip } from '../../../utils/TableHelpers';

// HTML table headers and table rows: createRow, createHeader for 2 different (scroll, expand) tables

const extractColumnIds = ({ column }) => {
    // Base case: if there's no column or columns, return null.
    if (!column || !column.columns) {
        return [];
    }

    // Use a recursive function to handle nested columns.
    const recursiveExtract = (columns) => {
        return columns.reduce((acc, curr) => {
            // If the current item has its own columns array, dive deeper.
            if (curr.columns && Array.isArray(curr.columns)) {
                acc.push(...recursiveExtract(curr.columns));
            } else if (curr.id) {
                // Otherwise, if it has an id, add it to the accumulator.
                if (!curr.id.includes('randomId_')) {
                    acc.push(curr.id);
                }
            }

            return acc;
        }, []);
    };

    // use the recursive function to extract the column ids
    let columnsArray = recursiveExtract(column.columns);

    // filter away the "random_8294" columns
    columnsArray = columnsArray.filter(row => !row.includes('randomId_'));

    // and return
    return columnsArray;
};


// Headers for scrollOrExpand === 'expand', expand to 100% width table
export const createExpandingHeader = (headerGroups, allColumns, visibleColumns, hideFilters = false) => {
    // console.log('createExpandingHeader props: ', { headerGroups, allColumns, visibleColumns, hideFilters });

    // set variables to utilize with showing/hiding filter boxes and column headers
    const visibleColumnIds = visibleColumns.map(row => row.id); // columnIds to show given what is kept via ColumnMultiSelect
    const hideFiltersClass = hideFilters ? 'hide-filters' : ''; // className to use to hide mix/max filter boxes
    const emptyHeader = hideFilters ? '' : '-'; // the placeholder column name to use

    // and return
    return (
        <thead className='table-headers'>
            {headerGroups.map((headerGroup, headerIdx) => (
                <tr
                    key={`headerGroup-${headerIdx}`}
                    {...headerGroup.getHeaderGroupProps()}
                    className='tr'
                >
                    {headerGroup.headers.map((column, columnIdx) => {
                        // normal stuff
                        if (typeof column.Header === 'undefined') { console.log('Problematic Column (missing Header): ', column.id); }
                        let isSortable = (headerGroups.length === headerIdx + 1); // sortable only for last headerRow
                        let sortProps = isSortable ? column.getSortByToggleProps() : [];

                        // hide header if (1) using ColumnMutliSelect and (2) not all columns selected in a group
                        // let innerColumnIds = column?.columns?.map(row => row.id) || null; // the columnIds within a group. null === individual column
                        let innerColumnIds = extractColumnIds({ column });
                        let showHeaders = innerColumnIds.length === 0 // empty array === individual column
                            ? true : // show individual column names
                            innerColumnIds.every(element => visibleColumnIds.includes(element)); // show group name if all columns present in group

                        // no <th> needed at all when showHeaders is false
                        if (showHeaders === false) { return null; }

                        // otherwise return
                        return (
                            <th
                                key={`headerGroup-column-${columnIdx}`}
                                {...column.getHeaderProps()}
                                className={`th ${column.headerClass || ''} ${column.className || ''} ${hideFiltersClass}`.trim()}
                                onMouseOver={(e) => showTooltip({ column, e })}
                                onMouseOut={() => hideTooltip()}
                                style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                            >
                                <div
                                    {...sortProps}
                                    className={`${column.isSorted ? column.isSortedDesc ? 'col-sort-desc' : 'col-sort-asc' : '' }`}
                                >
                                    {/* Handle Header Text (hide when using column select) */}
                                    {showHeaders ? column.render('Header') : emptyHeader}
                                </div>
                                {column.canFilter ? column.render('Filter') : null}
                            </th>
                        );})}
                </tr>
            ))}
        </thead>
    );
};

// Rows for scrollOrExpand === 'expand', expand to 100% width table
export const createExpandingRow = ({ row, nthRow, printRowHeight }) => {
    // console.log('createExpandingRow props: ', { row, nthRow, printRowHeight });

    // modify cell props with `td`, `row-height` classNames
    const computeCellProps = (cell) => {
        return {
            ...cell.getCellProps(),
            className: `td row-height-${printRowHeight} ${cell.column.className || ''}`
        };
    };

    // assess if row should be pinned to top or bottom of table (e.g. for Totals rows)
    // pin locations: 1 (top), 2 (top, 2nd row), -1 (bottom), -2 (bottom, 2nd-last row)
    let isPinnedRow = (row?.original?.isPinned || false) === true;
    let pinClassName = isPinnedRow ? (row.original.pinLocation > 0 ? 'top' : 'bottom') : 'top';

    // set classname for <div.tr> selement
    let trClassName = `tr nthRow${nthRow}`;
    if (isPinnedRow) { trClassName = `${trClassName} ${`pinned-row ${isPinnedRow ? `pin-${pinClassName}` : ''}`}`; }

    // and return
    return (
        <tr
            {...row.getRowProps()}
            className={trClassName}
        >
            {row.cells.map((cell, cellIdx) => {
                // if (cell.column.Header === 'Position') { console.log('cell: ', cell); }
                // console.log('what are the cell props: ', computeCellProps(cell));
                return (
                    <td
                        key={`row-cell-${cellIdx}`}
                        {...computeCellProps(cell)} // key, role, className
                        style={{ minWidth: cell.column.minWidth, maxWidth: cell.column.maxWidth }}
                        onMouseOver={(e) => showRowTooltip({ cell, e })}
                        onMouseOut={() => hideRowTooltip()}
                    >
                        {cell.render('Cell', { original: row.original })}
                    </td>
                );
            })}
        </tr>
    );
};

// Headers for scrollOrExpand === 'scroll', scroll table
export const createScrollHeader = (headerGroups, allColumns, visibleColumns, hideFilters = false) => {
    // console.log('createScrollHeader props: ', { headerGroups, allColumns, visibleColumns, hideFilters });

    // set variables to utilize with showing/hiding filter boxes and column headers
    const visibleColumnIds = visibleColumns.map(row => row.id); // columnIds to show given what is kept via ColumnMultiSelect
    const hideFiltersClass = hideFilters ? 'hide-filters' : ''; // className to use to hide mix/max filter boxes
    const emptyHeader = hideFilters ? '' : '-'; // the placeholder column name to use

    // and return
    return (
        <div className='table-headers'>
            {headerGroups.map((headerGroup, headerIdx) => (
                <div
                    key={`headerGroup-${headerIdx}`}
                    {...headerGroup.getHeaderGroupProps()}
                    className='tr'
                >
                    {headerGroup.headers.map((column, columnIdx) => {
                        // normal stuff
                        if (typeof column.Header === 'undefined') { console.log('Problematic Column (missing Header): ', column.id); }
                        let isSortable = (headerGroups.length === headerIdx + 1); // sortable only for last headerRow
                        let sortProps = isSortable ? column.getSortByToggleProps() : [];

                        // hide header if (1) using ColumnMutliSelect and (2) not all columns selected in a group
                        // let innerColumnIds = column?.columns?.map(row => row.id) || null; // the columnIds within a group. null === individual column
                        let innerColumnIds = extractColumnIds({ column });
                        let showHeaders = innerColumnIds.length === 0
                            ? true // empty array === individual column
                            : innerColumnIds.every(element => visibleColumnIds.includes(element)); // show group name if all columns present in group

                        // console.log('column: ', { column, header: column.Header, emptyHeader, visibleColumnIds, innerColumnIds, showHeaders });

                        // no <th> needed at all when showHeaders is false
                        if (showHeaders === false) { return null; }

                        // and return
                        return (
                            <div
                                key={`headerGroup-column-${columnIdx}`}
                                {...column.getHeaderProps()}
                                className={`th ${column.headerClass || ''} ${column.className || ''} ${hideFiltersClass}`.trim()}
                                onMouseOver={(e) => showTooltip({ column, e })}
                                onMouseOut={() => hideTooltip()}
                            >
                                <div
                                    {...sortProps}
                                    className={`sorter ${column.isSorted ? column.isSortedDesc ? 'col-sort-desc' : 'col-sort-asc' : '' }`}
                                >
                                    {/* Handle Header Text (hide when using column select) */}
                                    {showHeaders ? column.render('Header') : emptyHeader}
                                </div>

                                {/* display the filter */}
                                {column.canFilter ? column.render('Filter') : null}
                            </div>
                        );
                    })}
                </div>
            ))}
        </div>
    );
};

// Rows for scrollOrExpand === 'scroll', scroll table
export const createScrollRow = ({ row, nthRow, printRowHeight }) => {
    // assess if row should be pinned to top or bottom of table (e.g. for Totals rows)
    // pin locations: 1 (top), 2 (top, 2nd row), -1 (bottom), -2 (bottom, 2nd-last row)
    let isPinnedRow = (row?.original?.isPinned || false) === true;
    let pinClassName = isPinnedRow ? (row.original.pinLocation < 0 ? 'bottom' : 'top') : 'top';

    // set classname for <div.tr> selement
    let rowClassName = `tr nthRow${nthRow} row-height-${printRowHeight}`;
    if (isPinnedRow) { rowClassName = `${rowClassName} ${`pinned-row ${isPinnedRow ? `pin-${pinClassName}` : ''}`}`; }

    // and return the row
    return (
        <div
            {...row.getRowProps()}
            className={rowClassName}
        >
            {row.cells.map((cell, cellIdx) => {
                let cellProps = cell.getCellProps();
                return (
                    <div
                        key={`row-cell-${cellIdx}`}
                        {...cellProps}
                        className={`td ${cell.column.className || ''}`}
                        onMouseOver={(e) => showRowTooltip({ cell, e })}
                        onMouseOut={() => hideRowTooltip()}
                    >
                        {cell.render('Cell')}
                    </div>
                );
            })}
        </div>
    );
};


// Table util to replace 0.73 with null for pctiles when invalid.
// This is part of effort to (a) join all percentiles to tables regardless of isQualified, and (b) use isQualified inside final table render, to replace values with DNQs
// This would allow us to have a showDnqs button/toggle in the table render, which would be nice

// Not using this at the moment
// export const handlePercentiles = (row) => {
//     if (row.isQualified !== false) { return row; }
//     Object.keys(row).forEach(key => {
//         const isRatingKey = key.endsWith('Pctile') || key.endsWith('Rank');
//         if (isRatingKey) { row[key] = null; }
//     });
//     return row;
// };

// // handle removing percentiles for non-qualifiers // this is what would be added to CBBTable
// const newData = useMemo(() => {
//     return data.map(row => showDnqs ? handlePercentiles(row) : row);
// }, [showDnqs, data]);

