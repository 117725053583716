
import React from 'react';
import * as Sentry from '@sentry/react';
import config from '../../config';

// hmm, in addition to process.env.NODE_ENV, i like this way too to get isDev()
// function isDev() {
//     return window.location.href.startsWith('http://localhost');
// }

function SentryNoDev({ fallback, userData, children }) {
    // console.log('isDev: ', isDev());

    // Do Not Error Hanlde on Dev
    return config.isDev
        ? children
        : <Sentry.ErrorBoundary fallback={fallback} userData={userData}>
            {children}
        </Sentry.ErrorBoundary>;

    // // Yes Error Handle on Dev
    // return (
    //     <Sentry.ErrorBoundary fallback={fallback} userData={userData}>
    //         {children}
    //     </Sentry.ErrorBoundary>
    // );
}

export default SentryNoDev;
