
import React, { useContext } from 'react';
import CBBTable from '../renders/CBBTable';
import cols from '../utils/ColumnsDict';

import GlobalContext from '../../../context/GlobalContext.js';
import { getScopeInfos } from '../../../harddata/ScopeTooltips';
import { hyperlinkTeamLogo, hyperlinkPlayerImage } from '../../../utils/TableHelpers';
import { competitionIdMap } from '../../../harddata/NcaaStructures';


const dec2hex = (dec) => { return dec < 10 ? '0' + String(dec) : dec.toString(16); };
function generateId(len) {
    let arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return `randomId_${Array.from(arr, dec2hex).join('')}`;
}

function getScopeColumns(scope, tournamentInfosObj, config = {}) {
    // extract from config and scope
    let { ptgcId = null, divisionId = null, conferenceAbb = '', conferenceLongName = '', ratingsType = 'pctiles', showLogos = false } = config;
    let tourneyId = scope.substr(0, 7) === 'tourney' ? scope.substr(7) : 'not used but not empty';
    let tourneyTitle = scope.substr(0, 7) === 'tourney' ? (tournamentInfosObj[tourneyId] ? tournamentInfosObj[tourneyId].tournamentName : '') : '';

    // get header, tipTipe, and tipText for each scope
    let scopeInfos = getScopeInfos({ tourneyId, tourneyTitle, conferenceAbb, conferenceLongName, divisionId });

    // get accessor for value & rating fields
    let scopeUp = scope.substr(0, 1).toUpperCase() + scope.substr(1);
    let scopeKey = scope.substr(0, 7) === 'tourney' ? 'tourney' : scope;
    let scopeExists = scopeInfos[scopeKey];
    let valueKey = ptgcId === null ? `value${scopeUp}` : `value${scopeUp}_${ptgcId}`;
    let ratingKey = ptgcId === null
        ? (ratingsType === 'pctiles' ? `pctile${scopeUp}` : `rank${scopeUp}`)
        : (ratingsType === 'pctiles' ? `pctile${scopeUp}_${ptgcId}` : `rank${scopeUp}_${ptgcId}`);

    // create column
    let scopeColumn = {
        accessor: generateId(30),
        Header: showLogos ? hyperlinkTeamLogo({ teamId: ptgcId, size: 32 }) : (scopeExists ? scopeInfos[scopeKey].header : 'Nada'),
        tipTitle: scopeExists ? scopeInfos[scopeKey].tipTitle : 'Nada',
        tipDesc: scopeExists ? scopeInfos[scopeKey].tipText : 'Nada',
        columns: [
            cols.statValue(valueKey, ''),
            ratingsType === 'pctiles'
                ? cols.statRating(ratingKey, '')
                : cols.statRank({ acc: ratingKey, header: '' })
        ]
    };

    // and return
    return scopeColumn;
}
function getScopeColumns2({ columnInfo, wideCols = ['scope', 'teamId'], ratingsType = 'pctiles', showLogos = false }) {
    // console.log('in table getScopeColumns2:', { columnInfo, wideCols });

    // extract from config and scope
    // let tourneyId = scope.substr(0, 7) === 'tourney' ? scope.substr(7) : 'not used but not empty';
    // let tourneyTitle = scope.substr(0, 7) === 'tourney' ? (tournamentInfosObj[tourneyId] ? tournamentInfosObj[tourneyId].tournamentName : '') : '';

    // get header, tipTipe, and tipText for each scope
    let scopeInfos = getScopeInfos({});

    // get accessor for value & rating fields
    let valueKey = `value`;
    let ratingKey = ratingsType === 'pctiles' ? 'pctile' : 'rank';
    wideCols.forEach(col => {
        valueKey = `${valueKey}-${columnInfo[col]}`;
        ratingKey = `${ratingKey}-${columnInfo[col]}`;
    });


    // create column
    // console.log('value, rating keys: ', { columnInfo, wideCols, valueKey, ratingKey });
    let ptgcId = columnInfo.id;
    let scopeKey = columnInfo.scope;
    let scopeColumn = {
        accessor: generateId(30),
        Header: showLogos ? hyperlinkTeamLogo({ teamId: ptgcId, size: 32 }) : (scopeInfos?.[scopeKey]?.header || 'Nada'),
        tipTitle: scopeInfos?.[scopeKey]?.tipTitle || 'Nada',
        tipDesc: scopeInfos?.[scopeKey]?.tipText || 'Nada',
        columns: [
            cols.statValue(valueKey, ''),
            ratingsType === 'pctiles'
                ? cols.statRating(ratingKey, '')
                : cols.statRank({ acc: ratingKey, header: '' })
        ]
    };

    // and return
    return scopeColumn;
}

function LongTable({
    className = '',
    wrapperStyle,
    features = ['ratingsBar', 'columnSelect', 'dlButton', 'infoModal', 'hiderButton'],
    tableData, // array of data
    tableType, // for switching between columns
    tableTypeConfig,
    scopes = [],
    sbzCols = [], // [fga, fgm, fgaPg, fgaP40, fgaP100, fgaFreq, fgPct] (choose 2 - 3)
    sideBySideColumnInfos = [],
    headerGroups = [],
    wideCols = [],
    nthRow = 1, //                  number of rows between alternating colors
    labelType = 2,
    hideLabels = false,
    labelHeader = ' ',
    initialPageSize = 10, //        default for pagination, when 'pagination' is included in features []
    scrollOrExpand = 'scroll', //   'Table' === 'scroll', 'Table2' === 'expand'
    infoModal = 0,
    perWhat = '', //                one of ['', 'Pg', 'P40', 'P100']
    ratingsType = 'pctiles', //     one of ['ratings', 'pctiles', 'ranks'] ?? ranks
    printRowHeight = 20 //          height of row <td> for print
}) {
    // console.log('Table Props: ', { tableData, tableType, scopes, nthRow, hideLabels, labelHeader, labelType });

    // handle no data, return no table
    if (tableData.length === 0) { return <div />; }

    // grab from context, grab screen size
    const { tournamentInfosObj, teamInfosObj, conferenceInfosObj } = useContext(GlobalContext);
    const rowTipType = tableData && tableData[0] && tableData[0].playerId ? 'player-row-tip' : 'team-row-tip';


    // Wide By Scope
    // ================
    let wideByScopeCols = [];
    if (hideLabels === false) {
        wideByScopeCols.push({
            Header: labelHeader, sticky: 'left', className: `left ${infoModal && 'child-margin-left-18'}`,
            columns: [
                ...(labelType === 1 ? [cols.statLabel1] : []),
                ...(labelType === 2 ? [cols.statLabel2({ width: 110, className: rowTipType })] : [])
            ]
        });
    }

    let wbsCfg = {}; // wide by scopes config
    wbsCfg.ratingsType = ratingsType;
    wbsCfg.divisionId = tableData && tableData[0] ? tableData[0].divisionId : null;
    wbsCfg.conferenceId = tableData && tableData[0] ? tableData[0].conferenceId : null;
    wbsCfg.conferenceAbb = conferenceInfosObj && conferenceInfosObj[wbsCfg.conferenceId] ? conferenceInfosObj[wbsCfg.conferenceId].conferenceAbb : null;
    wbsCfg.conferenceLongName = conferenceInfosObj && conferenceInfosObj[wbsCfg.conferenceId] ? conferenceInfosObj[wbsCfg.conferenceId].conferenceLongName : null;
    scopes.forEach((scope, index, array) => {
        if (!array.slice(0, index).includes(scope)) { // do not push duplicate scopes
            wideByScopeCols.push(getScopeColumns(scope, tournamentInfosObj, wbsCfg));
        }
    });
    // ========

    // Shooting By Zone (single split, wide by metric)
    let shotByZoneCols = [{
        Header: ' ', sticky: 'left', className: 'sticky-left', columns: [
            cols.zoneName({ header: 'Shot Zone', width: 125, sticky: true, className: `row-tooltip ${rowTipType}` }),
            cols.valueOnly('gpPbp', { hideFilter: true, width: 32 })
        ]
    }, {
        Header: 'Zone Metrics', className: 'border-left pad-left-10', columns: []
    }];
    sbzCols.forEach((metric, idx) => {
        return ['fgm', 'fga'].includes(metric)
            ? shotByZoneCols[1].columns.push(cols.valueOnly(metric, { width: 45, hideFilter: true, className: idx === 0 && 'border-left pad-left-10' }))
            : shotByZoneCols[1].columns.push(cols.valueAndRating(metric, { width: 82, ratingsType, hideFilter: true, className: idx === 0 && 'border-left pad-left-10' }));
    });

    // here we go
    const teamGameSbzAbzCols = [
        { Header: ' ', sticky: 'left', columns: [
            cols.zoneName({ header: 'Shot Zone', sticky: true })
        ] },
        { Header: 'Zone Metrics', columns: [
            cols.valueOnly('fgm', { hideFilter: true }),
            cols.valueOnly('fga', { hideFilter: true }),
            cols.valueAndRating('fgPct', { hideFilter: true, ratingsType, width: 85 }),
            cols.valueAndRating('fgaFreq', { hideFilter: true, ratingsType, width: 85 })
        ] },
        { Header: 'Assist Breakdown', className: 'pad-left-10 border-left', columns: [
            // cols.valueAndRating(`ast${perWhat}`, { ptgc: 'team', isOffense: true, hideFilter: true }),
            // cols.valueOnly('ast', { width: 35, hideFilter: true, className: 'pad-left-10' }),
            cols.valueOnly('fgmA', { width: 35, hideFilter: true, className: 'pad-left-10' }),
            cols.valueAndRating('pctAst', { ptgc: 'team', ratingsType, isOffense: true, hideFilter: true, width: 85 }),
            cols.valueAndRating('astdPct', { ptgc: 'team', ratingsType, isOffense: true, hideFilter: true, width: 85 })
        ] }
    ];

    // Rebound By Zone (wide by scope + metric)
    // ==========================================
    let reboundMetrics = tableTypeConfig?.reboundMetrics || ['orb', 'drb'];
    let teamAggRbzCols = [
        { Header: ' ', sticky: 'left', columns: [
            cols.zoneName({ width: 115, header: 'Shot Zone', sticky: true }),
            cols.valueOnly('gpPbp', { width: 30, hideFilter: true })
        ] },
        ...(reboundMetrics.includes('drb') ?
            [{ Header: 'Def Rebounding', className: 'pad-left-10 border-left', columns: [
                cols.valueOnly(`drb${perWhat}`, { hideFilter: true, width: 44, className: 'pad-left-10 border-left' }),
                cols.valueOnly(`drbChnc${perWhat}`, { hideFilter: true, width: 44 }),
                cols.valueAndRating(`drbPct`, { ratingsType, width: 70, hideFilter: true })
            ] }] : []),
        ...(reboundMetrics.includes('orb') ?
            [{ Header: 'Off Rebounding', className: 'pad-left-10 border-left', columns: [
                cols.valueOnly(`orb${perWhat}`, { hideFilter: true, width: 44, className: 'pad-left-10 border-left' }),
                cols.valueOnly(`orbChnc${perWhat}`, { hideFilter: true, width: 44 }),
                cols.valueAndRating(`orbPct`, { ratingsType, width: 70, hideFilter: true })
            ] }] : []),
        ...(reboundMetrics.includes('reb') ?
            [{ Header: 'Overall Rebounding', className: 'pad-left-10 border-left', columns: [
                cols.valueOnly(`reb${perWhat}`, { hideFilter: true, width: 44, className: 'pad-left-10 border-left' }),
                cols.valueOnly(`rebChnc${perWhat}`, { hideFilter: true, width: 44 }),
                cols.valueAndRating(`rebPct`, { ratingsType, width: 70, hideFilter: true })
            ] }] : [])
    ];
    let teamGameRbzCols = [ // can handle rebType == ['orb', 'drb', 'reb']
        { Header: ' ', sticky: 'left', columns: [
            cols.zoneName({ width: 115, header: 'Shot Zone', sticky: true })
        ] },
        { Header: 'Def Rebounding', columns: [
            cols.valueOnly('drb', { hideFilter: true, width: 40 }),
            cols.valueOnly('drbChnc', { hideFilter: true, width: 40 }),
            cols.valueAndRating('drbPct', { ratingsType, hideFilter: true, width: 85 })
        ] },
        { Header: 'Off Rebounding', columns: [
            cols.valueOnly('orb', { hideFilter: true, width: 40 }),
            cols.valueOnly('orbChnc', { hideFilter: true, width: 40 }),
            cols.valueAndRating('orbPct', { ratingsType, hideFilter: true, width: 85 })
        ] }
    ];
    // ========

    // Shooting By Shot Clock
    let shotByShotClockCols = [ // can handle rebType == ['orb', 'drb', 'reb']
        { Header: ' ', sticky: 'left', columns: [
            cols.zoneName({ width: 115, header: 'Shot Zone', sticky: true }),
            cols.valueOnly('gpPbp', { width: 30, hideFilter: true })
        ] },
        { Header: '0-10 on Shot Clock', className: 'pad-left-10 border-left', columns: [
            cols.valueOnly('fgaS01', { hideFilter: true, width: 35, className: 'pad-left-10 border-left' }),
            cols.valueAndRating('fgPctS01', { ratingsType, width: 78, hideFilter: true }),
            cols.valueAndRating('fgaFreqAllS01', { ratingsType, width: 78, hideFilter: true })
        ] },
        { Header: '10-20 on Shot Clock', className: 'pad-left-10 border-left', columns: [
            cols.valueOnly('fgaS12', { hideFilter: true, width: 35, className: 'pad-left-10 border-left' }),
            cols.valueAndRating('fgPctS12', { ratingsType, width: 78, hideFilter: true }),
            cols.valueAndRating('fgaFreqAllS12', { ratingsType, width: 78, hideFilter: true })
        ] },
        { Header: '20-30 on Shot Clock', className: 'pad-left-10 border-left', columns: [
            cols.valueOnly('fgaS23', { hideFilter: true, width: 35, className: 'pad-left-10 border-left' }),
            cols.valueAndRating('fgPctS23', { ratingsType, width: 78, hideFilter: true }),
            cols.valueAndRating('fgaFreqAllS23', { ratingsType, width: 78, hideFilter: true })
        ] }
    ];

    let teamAggAbzCols = [
        { Header: ' ', sticky: 'left', className: 'sticky-left', columns: [
            cols.zoneName({ width: 115, header: 'Shot Zone', sticky: true }),
            cols.valueOnly('gpPbp', { width: 30, hideFilter: true })
        ] },
        { Header: 'Assist Breakdown', className: 'pad-left-10 border-left', columns: [
            cols.valueOnly(`fgmA`, { ptgc: 'team', isOffense: true, ratingsType, hideFilter: true, className: 'pad-left-10 border-left' }),
            // cols.valueOnly(`ast`, { ptgc: 'team', isOffense: true, ratingsType, hideFilter: true, className: 'pad-left-10 border-left' }),
            // cols.valueAndRating(`ast${perWhat}`, { ptgc: 'team', isOffense: true, ratingsType, hideFilter: true }),
            // cols.valueAndRating('pctAst', { ptgc: 'team', isOffense: true, hideFilter: true }), // a bit too wide in Team PBP Stats page with pctAst, also pctAst is not helpful, a bit confusing tbh
            cols.valueAndRating('astdPct', { ptgc: 'team', ratingsType, isOffense: true, hideFilter: true })
        ] }
    ];


    // MultiEntity, MultiScope Tables
    let multiEntityCols = [{ Header: labelHeader, className: 'left', columns: [cols.statLabel({ header: '' })] }];
    if (tableType === 'multiEntity') {
        // really need table: long by metric, wide by all of the wideCols

        // loop over the pre-defined group IDs
        const groupIds = [... new Set(sideBySideColumnInfos.map(row => row.group))];
        groupIds.forEach((groupId, idx) => {
            // grab constants
            let groupColumnInfos = sideBySideColumnInfos.filter(row => row.group === groupId);
            let { teamId, playerId, competitionId, playerInfo } = groupColumnInfos[0];

            // creating scope columns
            let scopesGroup = [];
            let dezeScopes = sideBySideColumnInfos.filter(row => row.group === groupId).map(row => row.scope);
            dezeScopes.forEach(scope => {
                let columnInfo = groupColumnInfos.filter(row => row.scope === scope)[0];
                let thisScopeCol = getScopeColumns2({ columnInfo, wideCols });
                scopesGroup.push(thisScopeCol);
            });

            // create the various headerGroups in order
            let priorColumnGroup = scopesGroup;
            headerGroups.forEach((headerGroup, groupIndex) => {
                let headerText = '';
                switch (headerGroup) {
                    case 'competitionId': headerText = competitionIdMap[competitionId]?.abb3 || 'HMMM'; break;
                    case 'teamMarket': headerText = teamInfosObj?.[teamId]?.teamMarket || `t${idx + 1}`; break;
                    case 'fullName': headerText = playerInfo?.fullName || `t${idx + 1}`; break;
                    case 'teamLogo': headerText = hyperlinkTeamLogo({ teamId, size: 30 }); break;
                    case 'playerImage': headerText = hyperlinkPlayerImage({ playerId, teamId: playerInfo?.teamId, hasImage: true }); break;
                    default: console.log('Error bad headerGroup');
                }

                const isLastIter = groupIndex !== headerGroups.length - 1;
                if (isLastIter) {
                    let newColumnGroup = [];
                    newColumnGroup.push({ Header: headerText, accessor: generateId(30), columns: priorColumnGroup });
                    priorColumnGroup = newColumnGroup;
                } else {
                    multiEntityCols.push(cols.blankCol({ width: 10 }));
                    multiEntityCols.push({ Header: headerText, accessor: generateId(30), columns: priorColumnGroup });
                    multiEntityCols.push(cols.blankCol({ width: 10 }));
                }
            });
        });

        // console.log('multiEntityCols: ', multiEntityCols);
    }


    // Lineup Flow Table
    const lineupFlowCols = [
        { Header: 'Players In Lineup', columns: [
            cols.textLabel({ acc: 'flowStint', header: 'S', width: 22 }),
            cols.shortName({ idKey: 'pId0', nameKey: 'pName0', width: 58 }),
            cols.shortName({ idKey: 'pId1', nameKey: 'pName1', width: 58 }),
            cols.shortName({ idKey: 'pId2', nameKey: 'pName2', width: 58 }),
            cols.shortName({ idKey: 'pId3', nameKey: 'pName3', width: 58 }),
            cols.shortName({ idKey: 'pId4', nameKey: 'pName4', width: 58 })
        ] },
        { Header: 'Game Time', className: 'pad-left-10 border-left', columns: [
            cols.textLabel({ acc: 'periodNumber', header: 'P', width: 28, className: 'pad-left-10 border-left' }),
            cols.textLabel({ acc: 'cStart', header: 'Start', width: 45 }),
            cols.textLabel({ acc: 'cEnd', header: 'End', width: 45 }),
            cols.textLabel({ acc: 'cDiff', header: 'Time', width: 45 })
        ] },
        { Header: 'Net PPP', className: 'pad-left-10 border-left', columns: [
            cols.textLabel({ acc: 'netPts', header: 'PTS', width: 42, className: 'pad-left-10 border-left' }),
            cols.valueAndRating('netppp', { ptgc: 'team', ratingsType, hideFilter: true, width: 71 })
        ] },
        { Header: 'Offense: Team Stats', className: 'pad-left-10 border-left', columns: [
            cols.textLabel({ acc: 'ptsScored', header: 'PTS', width: 42, className: 'pad-left-10 border-left' }),
            cols.valueAndRating('oppp', { ptgc: 'team', ratingsType, header: 'O PPP', hideFilter: true, width: 71 }),
            cols.fgmFga({ fgmKey: 'fgm2', fgaKey: 'fga2', header: '2s', width: 40 }),
            cols.fgmFga({ fgmKey: 'fgm3', fgaKey: 'fga3', header: '3s', width: 30 }),
            cols.valueOnly('ast', { hideFilter: true, width: 32 }),
            cols.valueOnly('reb', { hideFilter: true, width: 32 }),
            cols.valueOnly('tov', { hideFilter: true, width: 32 })
        ] },
        { Header: 'Defense: Opponent Stats', className: 'pad-left-10 border-left', columns: [
            cols.textLabel({ acc: 'ptsAgst', header: 'PTS', width: 42, className: 'pad-left-10 border-left' }),
            cols.valueAndRating('dppp', { ptgc: 'team', ratingsType, header: 'D PPP', hideFilter: true, width: 71 }),
            cols.fgmFga({ fgmKey: 'fgm2Agst', fgaKey: 'fga2Agst', header: '2s', width: 40 }),
            cols.fgmFga({ fgmKey: 'fgm3Agst', fgaKey: 'fga3Agst', header: '3s', width: 30 }),
            cols.valueOnly('astAgst', { header: 'AST', hideFilter: true, width: 32 }),
            cols.valueOnly('rebAgst', { header: 'REB', hideFilter: true, width: 32 }),
            cols.valueOnly('tovAgst', { header: 'TOV', hideFilter: true, width: 32 })
        ] }
    ];
    // ========

    // Linear Regression Table Columns
    // ============
    const regCfg = { hideFilter: true, width: 80 };
    const regressionSummaryCols = [
        cols.statLabel2({ width: 145, className: rowTipType }),
        cols.valueOnly('coefficient', { ...regCfg }),
        cols.valueOnly('stdError', { ...regCfg }),
        cols.valueOnly('tValue', { ...regCfg }),
        cols.pValue({ width: 80 })
    ];


    // New Game Matchups Table (Bettors Layout)
    // =========================================
    const teamIds = tableData?.[0]?.teamIds || [];
    const teamId1 = teamIds[0], teamId2 = teamIds[1];
    const teamMarket1 = tableData?.[0]?.teamMarket1 || 'Team 1';
    const teamMarket2 = tableData?.[0]?.teamMarket2 || 'Team 2';
    const gameMatchupCols = teamIds.length !== 2 ? [] : [
        { Header: teamMarket1, columns: [
            cols.statValue(`value${teamId1}`, ''),
            cols.statRating(`pctile${teamId1}`, '')
        ] },
        cols.statLabel2({ header: 'Metric', width: 130, className: `left-grey ${rowTipType}`, spanClass: '' }),
        { Header: teamMarket2, columns: [
            cols.statRating(`pctile${teamId2}`, ''),
            cols.statValue(`value${teamId2}`, '')
        ] }
    ];


    // Switch Between Column Groupings
    // ================================
    const columns = React.useMemo(
        () => {
            switch (tableType) {
                case 'wideByScope': return wideByScopeCols;
                case 'shotByZone': return shotByZoneCols;
                case 'shotByShotClock': return shotByShotClockCols;
                case 'teamAggRbz': return teamAggRbzCols;
                case 'teamAggAbz': return teamAggAbzCols;
                case 'teamGameRbz': return teamGameRbzCols;
                case 'multiEntity': return multiEntityCols;
                case 'teamGameSbzAbz': return teamGameSbzAbzCols;
                case 'lineupFlow': return lineupFlowCols;
                case 'regression': return regressionSummaryCols;
                case 'gameMatchup': return gameMatchupCols;
                default: console.log('Error: Wrong TableType: ', tableType); return [];
            }
        },
        [tableType, scopes]
    );

    // Set Sort (Not Too Many Long Tables)
    let defaultSortObj = {};
    switch (tableType) {
        case 'assistNets': defaultSortObj = { id: 'hookup', desc: true }; break;
        case 'lineupFlow': defaultSortObj = { id: 'flowStint', desc: false }; break;
        case 'teamGameSbzAbz': defaultSortObj = { id: 'zoneName', desc: false }; break;
        case 'teamGameRbz': defaultSortObj = { id: 'zoneName', desc: false }; break;
        case 'teamAggRbz': defaultSortObj = { id: 'zoneName', desc: false }; break;
        case 'teamAggAbz': defaultSortObj = { id: 'zoneName', desc: false }; break;
        case 'shotByZone': defaultSortObj = { id: 'zoneName', desc: false }; break;
        case 'shotByShotClock': defaultSortObj = { id: 'zoneName', desc: false }; break;
        case 'regression': defaultSortObj = { id: 'idx', desc: false }; break;
        default: defaultSortObj = {};
    }

    const longTable =
        (<CBBTable
            scrollOrExpand={scrollOrExpand} // one of 'scroll', 'expand'
            className={className}
            wrapperStyle={wrapperStyle}
            data={tableData}
            columns={columns}
            features={features}
            defaultSortObj={defaultSortObj}
            initialPageSize={initialPageSize}
            nthRow={nthRow}
            tableMaxWidth={null}
            infoModal={infoModal}
            ratingsType={ratingsType}
            printRowHeight={printRowHeight}
        />);

    return longTable;
}

export default LongTable;
