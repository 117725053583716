// Command K + Command 0 to code fold all !!

// links to color scheme articles that may be helpful

// https://gka.github.io/palettes/#colors=#EEEEBB,#FAD976,#FC9144,#E31A1C,#800026|steps=5|bez=0|coL=1
// https://html-color-codes.info/colors-from-image/?imageLoader=#
// https://www.kennethmoreland.com/color-advice/
// https://coolors.co/444444-97ead2-8cc7a1-816e94-00a508

// Import React + D3 Stuff
import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { select } from 'd3-selection';

// Import CBB Components
import d3 from '../assets/d3/index.js';
import Logo from '../components/uiUxItems/Logo';
import PlayerImage from '../components/uiUxItems/PlayerImage';

import { dDict } from '../harddata/DataDictionary';
import { pppcQualifiersMap } from '../harddata/PppcQualifiers';
import { getPlayerNameAbb } from './ReshapeData.js';


// Column Formatters - Hyperlinks
// ===============================
export function hyperlinkPlayerName({ playerId, playerName, competitionId = null, style = {} }) {
    if (!playerId || !playerName) { return <div />; }
    const params = useParams();

    // handle missing competitionId
    const linkCompetitionId = (competitionId !== null ? competitionId : params.competitionId);
    if (!linkCompetitionId) {
        return (<span className='text-span left'>{playerName}</span>);
    }

    // handle link to new path
    const newPath = `/stats/${linkCompetitionId}/players/${playerId}/overview`;
    return (
        <span className='text-span left' style={{ ...style }}>
            <Link className='no-hover-line' to={newPath}>
                {`${playerName}`}
            </Link>
        </span>
    );
}
export function hyperlinkPlayerImage({ playerId, teamId, competitionId, hasImage = false }) {
    if (!playerId || !teamId) { return <div />; }
    return (
        <PlayerImage
            className='intable-player-image'
            imgStyle={{ width: '100%' }}
            playerId={playerId}
            teamId={teamId}
            competitionId={competitionId}
            hasImage={hasImage}
        />
    );
}
export function hyperlinkShortPlayerName({ playerId, playerName }) {
    // , competitionId = null unused parameter
    // const linkCompetitionId = (competitionId !== null ? competitionId : params.competitionId);
    if (!playerId || !playerName) { return <div />; }
    const params = useParams();
    const linkCompetitionId = params.competitionId;
    const shortName = getPlayerNameAbb({ fullName: playerName });


    // Create Return Element, and Return
    const newPath = `/stats/${linkCompetitionId}/players/${playerId}/overview`;
    const returnElement = shortName === 'Total' ?
        <span><strong>{shortName}</strong> </span> :
        <Link className='no-hover-line' to={newPath}>{shortName}</Link>;

    return (
        <span key={playerId} className='text-span short-name' style={{ float: 'left' }}>
            {returnElement}
        </span>
    );
}
export function hyperlinkGameBox({ gameId }) {
    if (!gameId) { return <div />; }
    const params = useParams();
    const { competitionId } = params;
    return (
        <span className='text-span'>
            <Link to={`/stats/${competitionId}/games/${gameId}/overview`}>Box</Link>
        </span>
    );
}
export function hyperlinkTeamLogo({ teamId, competitionId = null, size = null }) {
    if (!teamId) { return <div />; }
    let style = !size ? {} : { width: size, maxHeight: size }; // override 24px size
    return (
        <Logo
            className='intable-logo'
            ptgc='team'
            style={style}
            teamId={teamId}
            competitionId={competitionId}
        />
    );
}
export function hyperlinkTeamMarket({ teamId, teamMarket, competitionId = null }) {
    if (!teamId || !teamMarket) { return <div />; }
    const params = useParams();
    const linkCompetitionId = (competitionId !== null ? competitionId : params.competitionId);

    // handle missing competitionId
    if (!linkCompetitionId) {
        return (<span className='text-span left'>{teamMarket}</span>);
    }

    // handle link to new path
    const newPath = `/stats/${linkCompetitionId}/teams/${teamId}/overview`;
    return (
        <span className='text-span left'>
            <Link to={newPath}>{teamMarket}</Link>
        </span>
    );
}
export function hyperlinkConferenceLongName({ conferenceId, conferenceLongName, competitionId = null }) {
    if (!conferenceId || !conferenceLongName) { return <div />; }
    const params = useParams();
    const linkCompetitionId = (competitionId !== null ? competitionId : params.competitionId);
    const newPath = `/stats/${linkCompetitionId}/conferences/${conferenceId}/team-box`;
    return (
        <span className='text-span left'>
            <Link to={newPath}>{conferenceLongName}</Link>
        </span>
    );
}
export function hyperlinkConferenceLogo({ conferenceId, competitionId = null }) {
    if (!conferenceId) { return <div />; }
    return (
        <Logo
            className='intable-logo'
            ptgc='conference'
            conferenceId={conferenceId}
            competitionId={competitionId}
        />
    );
}

// ======

// Val+Rating Formatters + Sorters
// ================================
export function sortText({ valueA, valueB }) {
    // this is required when sorting text columns where row.value isn't the sort key
    // pass sort values as valueA / valueB in column object
    if (valueA < valueB) { return -1; } else { return 1; }
}
export function sortRatedColumn({ rowA, rowB, colId, desc }) {
    // console.log('sortRatedColumn props: ', { rowA, rowB, colId, desc });

    // A. Grab Stat Value + Rating Columns From Parameters
    // pin locations: 1 (top), 2 (top, 2nd row), -1 (bottom), -2 (bottom, 2nd-last row)
    const isPinnedA = rowA.original.isPinned ? true : false;
    const pinLocationA = rowA.original.pinLocation;
    const isPinnedB = rowB.original.isPinned ? true : false;
    const pinLocationB = rowB.original.pinLocation;
    // if (isPinnedA || isPinnedB) { console.log('sorting pins: ', { isPinnedA, pinLocationA, isPinnedB, pinLocationB }); }
    if (isPinnedA && !isPinnedB) { return desc ? pinLocationA : -pinLocationA; }
    if (!isPinnedA && isPinnedB) { return desc ? -pinLocationB : pinLocationB; }
    if (isPinnedA && isPinnedB) { return desc ? pinLocationA > pinLocationB : pinLocationA < pinLocationB; }

    // C. Send Empty Rows To The Bottom
    if (typeof rowA === 'undefined') { return desc ? -Infinity : Infinity; }
    if (typeof rowB === 'undefined') { return desc ? Infinity : -Infinity; }

    // D. Send Top Rows To The Top
    if (rowA.isTopRow) { return desc ? Infinity : -Infinity; }
    if (rowB.isTopRow) { return desc ? -Infinity : Infinity; }

    // E. If 1 Stat Has Rating + Other Stat Doesn't, Stat With Rating Sorts First
    const aRating = rowA.original[`${colId}Pctile`];
    const bRating = rowB.original[`${colId}Pctile`];
    if (typeof aRating === 'undefined' && typeof bRating !== 'undefined') { return desc ? -1 : 1; }
    if (typeof aRating !== 'undefined' && typeof bRating === 'undefined') { return desc ? 1 : -1; }

    // F. If Neither Stat Has Rating, Assess Stat Values
    const aValue = rowA.original[colId];
    const bValue = rowB.original[colId];
    if (typeof aRating === 'undefined' && typeof bRating === 'undefined') {
        // If 1 Stat Has Value + Other Stat Doesn't, Stat With Value Sorts First
        if (typeof aValue === 'undefined' && typeof bValue !== 'undefined') { return desc ? -1 : 1; }
        if (typeof aValue !== 'undefined' && typeof bValue === 'undefined') { return desc ? 1 : -1; }
        // If Both Stats Have Value, Compare Them
        if (aValue < bValue) { return -1; } else { return 1; }
    }

    // G. Finally, use the sorting Key to sort
    if (Number(aValue) < Number(bValue)) { return -1; } else { return 1; }
}
export function computePctileColor({ pctile }) {
    // console.log('computePctileColor  pctile: ', pctile);
    if (typeof pctile === 'undefined') { return null; }

    // Much Easier Now With Percentiles Only
    let greenGreyRedScale = ['#57BB8A', '#AADDC4', '#FFFFFF', '#F3BEB9', '#E77C73'];

    let colorScale = greenGreyRedScale;
    let colorDomain = [1, 0.75, 0.50, 0.25, 0];
    let playerRatingScale = d3.scaleLinear()
        .domain(colorDomain)
        .range(colorScale);

    return playerRatingScale(pctile);
}
export function computeRankColor({ rank, maxRank }) {
    if (typeof rank === 'undefined') { return null; }

    // 3-Color Scheme for Ranks
    let redYellowGreenScale = ['#57BB8A', '#fff8d7', '#E77C73'];
    let colorDomain = [1, 0.5 * (maxRank + 1), maxRank];

    // let colorDomain = [0 * maxRank, 0.5 * maxRank, 1 * maxRank];
    let playerRatingScale = d3.scaleLinear()
        .domain(colorDomain)
        .range(redYellowGreenScale);

    return playerRatingScale(rank);
}
export function formatValue({ value, unit }) {
    let outputText = '';
    switch (unit) {
        case 'pct': outputText = `${(100 * value).toFixed(1)}%`; break;
        case 'int': outputText = Math.round(value, 0).toLocaleString('en-US'); break;
        case 'num': outputText = `${value.toFixed(1)}`; break;
        case 'num2': outputText = `${value.toFixed(2)}`; break;
        case 'num3': outputText = `${value.toFixed(3)}`; break;
        case 'ratio': outputText = `${value.toFixed(2)}x`; break;
        case 'txt': outputText = value; break;
        default: outputText = `${(100 * value).toFixed(1)}%`;
    }
    return outputText;
}
export function numberSuffix({ number }) {
    // console.log('number: ', number);
    if (!number || number > 20) { return ''; }
    if (number === 1) { return `st`; }
    if (number === 2) { return `nd`; }
    if (number === 3) { return `rd`; }
    return `th`;
}

// This is the main function for formatting a single column with both stat value + rating
export function formatValueRatingColumn({ statObj, unit, showPlus }) {
    // console.log('formatValueRatingColumn params:', { statObj, unit, showPlus });

    let isBadValue = !statObj.value && statObj.value !== 0;
    let isBadRating = !statObj.rating && statObj.rating !== 0;

    if ((isBadValue && isBadRating) || statObj.value === null || typeof statObj.value === 'undefined') { return <span />; }
    // if ((isBadValue && isBadRating) || statObj.value === null) { return <span />; }

    // Grab Value + Rating From Pre-Created Object
    let statValue = statObj.value;
    let statRating = statObj.rating;
    let ratingsType = statObj.ratingsType;
    let maxRank = statObj.maxRank;

    let ratingDisplay = isBadRating ? '--' : (ratingsType === 'pctiles' ? (100 * statObj.rating).toFixed(0) : statObj.rating);
    let rankSuffix = numberSuffix({ number: ratingDisplay });

    // Create text variables to display the statValue and statRating correctly
    let statValueDisplay = statValue;
    switch (unit) {
        case 'txt': statValueDisplay = statValue; break;
        case 'pct': statValueDisplay = `${(100 * statValue).toFixed(1)}%`; break;
        case 'int': statValueDisplay = Math.round(statValue, 0).toLocaleString('en-US'); break;
        case 'num': statValueDisplay = `${statValue.toFixed(1)}`; break;
        case 'num2': statValueDisplay = `${statValue.toFixed(2)}`; break;
        case 'num3': statValueDisplay = `${statValue.toFixed(3)}`; break;
        case 'ratio': statValueDisplay = `${statValue.toFixed(2)}x`; break;
        default: statValueDisplay = `${(100 * statValue).toFixed(1)}%`;
    }

    // Add + Sign If Needed
    statValueDisplay = showPlus === true ?
        (statValue > 0 ? '+' + statValueDisplay : statValueDisplay) :
        statValueDisplay;

    // Replace NaNs with ____ blank
    statValueDisplay = isNaN(statValue) ? '' : statValueDisplay;

    // Compute/Set The Rating Color
    let ratingColor = ratingsType === 'pctiles'
        ? computePctileColor({ pctile: statRating })
        : computeRankColor({ rank: statRating, maxRank });

    // handle no rating?
    if (statObj.rating === null) {
        ratingColor = null;
        ratingDisplay = 'DNQ';
    }

    // And Return
    let showDnq = isBadRating;
    // console.log('formatValueRatingColumn params:', { statObj, unit, showPlus, statRating, maxRank, ratingColor, showDnq });
    return (
        <div className='value-rating-div'>
            <div className={`rating-div ${showDnq ? 'dnq' : ''}`} style={{ background: ratingColor, width: '35%' }}>
                {ratingDisplay}
                {ratingsType === 'ranks' && <span style={{ fontSize: '0.85em', lineHeight: 1 }}>{rankSuffix}</span>}
            </div>
            <div className='value-div' style={{ width: '65%' }}>
                {statValueDisplay}
            </div>
        </div>
    );
}
// ======


// Column Formatters - Non-Hyperlink/Non-Percentile-Rank Columns
// ==============================================================
export function formatTextSpan({ value, className = '' }) {
    // handle creating subscript "Adj" for adjusted ratings and four factors
    let adj = <></>;
    let finalValue = value;

    // add superscript if needed
    if (value && typeof value === 'string' && value.includes('**')) {
        const parts = value.split('**');
        finalValue = <>{parts[0]}<sup style={{ top: '1em' }}>{parts[1]}</sup></>;
    }

    // show boolean true/false
    if (typeof finalValue === 'boolean') { finalValue = finalValue.toString(); }

    // and return
    return (<span className={`text-span ${className}`}>
        {finalValue}
        {adj}
    </span>);
}

export function formatTextSpanLeft({ value }) {
    // handle creating subscript "Adj" for adjusted ratings and four factors
    let adj = <></>;
    let finalValue = value;

    if (value && typeof value === 'string' && value.includes('**')) {
        const parts = value.split('**');
        finalValue = <>{parts[0]}<sup style={{ top: '1em' }}>{parts[1]}</sup></>;
    }

    // show boolean true/false
    if (typeof finalValue === 'boolean') { finalValue = finalValue.toString(); }

    // and return
    return (<span className='text-span left'>
        {finalValue}
        {adj}
    </span>);
}
export function formatOrdinaryColumn({ value }) {
    return <span className='text-span'>{value}</span>;
}

export function formatPctileColumn({ pctile }) {
    let dnq = pctile === null;
    let noPctile = typeof pctile === 'undefined';
    let ratingColor = (dnq || noPctile) ? null : computePctileColor({ pctile });
    let ratingDisplay = dnq ? 'DNQ' : (noPctile ? '--' : (100 * pctile).toFixed(0));
    let divClassName = `rating-div ${dnq ? 'dnq' : ''}`;
    // console.log('stuff: ', { pctile, dnq, noPctile, ratingColor, ratingDisplay, divClassName });

    // and return
    return (
        <div className={divClassName} style={{ backgroundColor: ratingColor }}>
            {ratingDisplay}
        </div>
    );
}
export function formatRankColumn({ rank, maxRank, rankType }) {
    const dnq = rank === null;
    const isRankMissing = typeof rank === 'undefined';
    const rankColor = (dnq || isRankMissing) ? null : computeRankColor({ rank, maxRank });
    const rankDisplay = dnq ? 'DNQ' : (isRankMissing ? '--' : rank);
    const rankSuffix = numberSuffix({ number: rank });
    const showRankSuffix = rankDisplay < 21 && rankType === 'division';
    // console.log('rank, maxRank: ', { rank, maxRank, rankColor, dnq, isRankMissing, rankSuffix, showRankSuffix });

    let divClassName = `rating-div ${dnq ? 'dnq' : ''}`;
    return (
        <div className={divClassName} style={{ backgroundColor: rankColor }}>
            {rankDisplay}
            {showRankSuffix && <span style={{ fontSize: '0.85em', lineHeight: 1 }}>{rankSuffix}</span>}
        </div>
    );
}
export function formatStatColumn({ stat, unit, showPlus = false }) {
    // console.log('stat, unit: ', { stat, unit });
    if (stat === null || typeof stat === 'undefined') { return <span />; }
    if (stat === '') { return <span />; }

    let displayText = '';
    if (typeof (stat) === 'boolean' || typeof (stat) === 'string') {
        displayText = stat;
    } else {
        switch (unit) {
            case 'pct': displayText = `${(100 * stat).toFixed(1)}%`; break;
            case 'pct0': displayText = `${(100 * stat).toFixed(0)}%`; break;
            case 'ratio': displayText = `${stat.toFixed(2)}x`; break;
            case 'num': displayText = `${stat.toFixed(1)}`; break;
            case 'num2': displayText = `${stat.toFixed(2)}`; break;
            case 'num3': displayText = `${stat.toFixed(3)}`; break;
            case 'int': displayText = Math.round(stat, 0).toLocaleString('en-US'); break;
            case 'txt': displayText = stat; break;
            default: displayText = `${stat}`;
        }
    }

    // if (unit === 'txt') { console.log('displayText1: ', displayText); }
    displayText = showPlus === true ? (stat > 0 ? '+' + displayText : displayText) : displayText;
    // if (unit === 'txt') { console.log('displayText2: ', displayText); }

    // And Return
    return (
        <span className='text-span'>
            {displayText}
        </span>
    );
}
export function formatWorLColumn({ teamPoints, oppPoints }) {
    let wOrL = teamPoints > oppPoints ? 'W' : 'L';
    return <span className='text-span'>{wOrL}</span>;
}
export function formatSeason({ seasonString, style }) {
    // assumes format '17-18', '18-19'

    // and return
    return (
        <span className='text-span' style={style}>
            {seasonString}
        </span>
    );
}


// Table Tooltip & Info Modal Functions
export function showTooltip({ column, e }) {
    // console.log('showTooltip params: ', { column, e });

    // select & display (unhide) tooltip
    let tooltipDiv = select('.table-tip');
    tooltipDiv.transition()
        .delay(50)
        .duration(0)
        .style('display', 'block')
        .style('opacity', 1);

    // set constants related to tooltip location
    let isFlipped = window.innerWidth - e.pageX < 275 ? true : false;
    let padLeft = e.pageX < 250 ? 75 : 0;

    // use constants to set tooltip location
    tooltipDiv
        .style('left', (isFlipped ? e.pageX - 325 : e.pageX + padLeft - 60) + 'px')
        .style('top', (e.pageY + 40) + 'px');

    // add title to tooltip (if it exists)
    if (!column.tipTitle || column.tipTitle.length === 0) {
        tooltipDiv.select('.table-tip__title').style('display', 'none');
        tooltipDiv.select('.table-tip__title-text').text('');
    } else {
        tooltipDiv.select('.table-tip__title').style('display', 'block');
        tooltipDiv.select('.table-tip__title-text').text(column.tipTitle);
    }

    // add description to tooltip (if it exists)
    if (!column.tipDesc || column.tipDesc.length === 0) {
        tooltipDiv.select('.table-tip__desc').style('display', 'none');
        tooltipDiv.select('.table-tip__desc-text').text('');
    } else {
        tooltipDiv.select('.table-tip__desc').style('display', 'block');
        tooltipDiv.select('.table-tip__desc-text').text(column.tipDesc);
    }

    // add equation to tooltip (if it exists (unused))
    if (!column.tipEq || column.tipEq.length === 0) {
        tooltipDiv.select('.table-tip__eq').style('display', 'none');
        tooltipDiv.select('.table-tip__eq-text').text('');
    } else {
        tooltipDiv.select('.table-tip__eq').style('display', 'block');
        tooltipDiv.select('.table-tip__eq-text').text(column.tipEq);
    }
}
export function hideTooltip() {
    let div = select('.table-tip');
    div.style('top', 0);
    div.style('left', 0);
    div.transition()
        .duration(1)
        .style('display', 'none')
        .style('opacity', 0);
}
export function showRowTooltip({ cell, e }) {
    // console.log('showRowTooltip!!: ', { cell, location, e });

    let thisClass = cell.column.className;
    if (!thisClass) { return; } // need this row to avoid "TypeError: Cannot read property 'includes' of undefined" for no class
    if (!thisClass.includes('row-tooltip')) { return; }

    let isTeam = thisClass.includes('team-row-tip');
    let isPlayer = thisClass.includes('player-row-tip');
    let isOffense = cell && cell.row && cell.row.original && cell.row.original.isOffense === false ? false : true;
    let isPossQualifiers = thisClass.includes('ppp-qualifiers');
    let isChncQualifiers = thisClass.includes('ppc-qualifiers');
    let isPppcQualifiers = isPossQualifiers || isChncQualifiers;

    let statKey = cell.value;
    let statDictObj = dDict[statKey];
    let hasTips = (statDictObj && statDictObj.tips ? true : false) || isPppcQualifiers;
    // console.log({ cell, location, e, isTeam, isPlayer, isOffense, statKey, statDictObj, hasTips, isPppcQualifiers });

    if (!hasTips) { return; }
    let tipTitle = '', tipText = '';

    // Handle PPPC Qualifier Tooptips
    if (isPppcQualifiers) {
        const possOrChnc = isPossQualifiers ? 'poss' : (isChncQualifiers ? 'chnc' : 'error');
        if (possOrChnc === 'error') { console.log('Error: bad possOrChnc value!'); }
        const pppcQualifiersDict = pppcQualifiersMap(possOrChnc);
        tipTitle = pppcQualifiersDict[statKey].tipTitle;
        tipText = pppcQualifiersDict[statKey].tipDesc;
    }

    // Handle All Other Tooltips
    if (statDictObj && hasTips) {
        tipTitle = statDictObj.tips ? statDictObj.tips.tipTitle : 'Test Title'; // tipTitle, teamTip, playerTip
        if (isTeam) {
            tipText = statDictObj.tips.teamTip ? statDictObj.tips.teamTip : statDictObj.tips.mainTip;
            tipText = isOffense ? tipText : tipText.replace('opponent', 'zed').replace('team', 'opponent').replace('zed', 'team');
            tipTitle = isOffense ? tipTitle : `Opponent's ${tipTitle}`;
        }
        if (isPlayer) {
            tipText = statDictObj.tips.playerTip ? statDictObj.tips.playerTip : statDictObj.tips.mainTip;
        }
    }

    // // check some stuff
    // console.log({ cell, statKey, tipTitle, dictObj: dDict[statKey] });

    // Handle position & flip orientation
    let isFlipped = window.innerWidth - e.pageX < 350 ? true : false;
    let tooltipDiv = select('.table-tip');
    tooltipDiv.transition().delay(50).duration(0).style('display', 'block').style('opacity', 1);
    tooltipDiv
        .style('left', (isFlipped ? e.pageX - 330 : e.pageX + 20) + 'px')
        .style('top', (e.pageY + 40) + 'px');

    // Handle adding title & body
    tooltipDiv.select('.table-tip__title').style('display', 'block');
    tooltipDiv.select('.table-tip__title-text').text(tipTitle);

    tooltipDiv.select('.table-tip__desc').style('display', 'block');
    tooltipDiv.select('.table-tip__desc-text').text(tipText);

    tooltipDiv.select('.table-tip__eq').style('display', 'none');
}
export function hideRowTooltip() {
    let div = select('.table-tip');
    div.style('top', '0px');
    div.transition()
        .duration(1)
        .style('display', 'none')
        .style('opacity', 0);
}
export function showInfoModal({ modalObject = {}, e }) {
    // console.log('showInfoModal params: ', { modalObj, e, x: e.pageX, y: e.pageY });
    let tooltipDiv = select('.global-info-modal');

    const isFlipped = window.innerWidth - e.pageX < 400 ? true : false;
    // console.log('isFlipped: ', { isFlipped, winw: window.innerWidth, xx: e.pageX });
    // const right = window.innerWidth - left;

    // Reveal & Position Tooltip
    tooltipDiv.classed('show-modal', true);
    tooltipDiv.classed('hide-modal', false);
    tooltipDiv
        .style('left', (isFlipped ? e.pageX - 425 : e.pageX - 10) + 'px')
        .style('top', (e.pageY + 20) + 'px');

    // Hide Color Legends, Show Title
    tooltipDiv.select('.pctiles').style('display', 'none');
    tooltipDiv.select('.ranks').style('display', 'none');
    tooltipDiv.select('.info-modal-header').style('display', 'block');

    // Fill Content (Title, Text1, Text2, Text3
    tooltipDiv.select('.info-modal-header h3.header').style('display', 'block');
    tooltipDiv.select('.info-modal-header h3.header').text(modalObject.title);
    tooltipDiv.select(`.body .content`).style('display', 'block');

    // // Create/Add Each Bullet
    // const bodyDiv = tooltipDiv.select(`.body`);
    // const bullets = modalObject.bullets || []; // to handle sentry error "Cannot read properties of undefined (reading 'forEach')""
    // bullets.forEach((bullet, idx) => {
    //     let contentDiv = bodyDiv.append('div').attr('class', `content content${idx + 1}`).style('display', 'block');
    //     contentDiv.append('p').attr('class', 'title').text(bullet.title);
    //     contentDiv.append('p').attr('class', 'desc').text(bullet.desc);
    //     if (bullet.title === '') { // not every bullet has a title, omit 4px padding on missing titles
    //         contentDiv.select(`..content${idx + 1} .title`).style('padding-right', '0px');
    //     }
    // });

    // Show Each Bullet
    const bullets = modalObject.bullets || [];
    bullets.forEach((bullet, idx) => {
        tooltipDiv.select(`.global-info-modal .body .content${idx + 1}`).style('display', 'block');
        tooltipDiv.select(`.global-info-modal .body .content${idx + 1} .desc`).text(bullet.desc);
        tooltipDiv.select(`.global-info-modal .body .content${idx + 1} .title`).text(bullet.title);
        if (bullet.title === '') { // not every bullet has a title, omit 4px padding on missing titles
            tooltipDiv.select(`.global-info-modal .body .content${idx + 1} .title`).style('padding-right', '0px');
        }
    });
}
export function hideInfoModal() {
    let div = select('.global-info-modal');

    // hide the whole tooltip
    div.style('top', 0).style('left', 0);
    div.classed('show-modal', false);
    div.classed('hide-modal', true);

    // hide contents
    div.select('.title').text('');
    div.select('.desc').text('');
    [1, 2, 3, 4, 5].forEach(i => {
        div.select(`.content${i}`).style('display', 'none');
        div.select(`.content${i} .title`).text('');
        div.select(`.content${i} .title`).style('padding-right', '4px');
        div.select(`.content${i} .desc`).text('');
    });
}


// For Download Data Button, get accessors from 'columns' object
export function getColumnAccessors({ columns }) {
    // digging through nested 'columns' array for 'accessors', as these are the names of the columns in the data

    // console.log('columns: ', columns);
    let outputColumnAccessors = [];

    // loop the columns
    columns.forEach(row => {
        // if row has accessor, add to outputColumnAccessors
        if (row.accessor && row.accessor.indexOf('randomId') !== 0) {
            outputColumnAccessors.push(row.accessor);
        }

        // check for nested 'columns' array (Recursion)
        let hasColumnsArray = Object.keys(row).includes('columns');
        if (hasColumnsArray) {
            outputColumnAccessors.push(...getColumnAccessors({ columns: row.columns }));
        }
    });

    // add Pctile accessors and return
    return outputColumnAccessors;
}


// Old Color Scales
// let blueRedScale = ['#00F', '#99F', '#999', '#F99', '#F00']; // blue to red here
// let redGreenTextScale = ['#aaaaaa', '#91aa88', '#75a967', '#4ea83f', '#00a508']; // going green to red here...
// let yellowOrangeRedTextScale = ['#800026', '#c91220', '#f1612f', '#fda954', '#eeeebb']; // yellow to orange to red
// let yellowRedTextScale = ['#f6e78f', '#d3ca9d','#afada9','#da785f', '#f30000']; // going green to red here...
// let redGreenBackScale = ['#aaaaaa', '#91aa88', '#75a967', '#4ea83f', '#00a508']; // going green to red here...
// let cbbYellowOrangeRed = ['#753C2C', '#9D4429', '#C74E29', '#E35B2C', '#E77235', '#EB8A40', '#F0A04B', '#F5B857']; // , '#F9D063']; // turn this from 8 to 5
