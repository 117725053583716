// Green == Get
// Red == Deletes
// Orange == Updates
// Grey == Disabled

// Import React Components
import React, { useState, useContext } from 'react';
import GlobalContext from '../../context/GlobalContext';
import { Container, Row, Col, Alert, FormControl, Table } from 'react-bootstrap';
import axios from 'axios';
import config from '../../config';

// Data Fetching Components
import useCBBQuery from '../../hooks/useCBBQuery';

// Selects, Other CBB Components
import D3LineGraph from '../../components/d3Graphs/D3LineGraph';
import CBBSelect from '../../components/selects/CBBSelect';
import CBBMultiSelect from '../../components/selects/CBBMultiSelect';
import HelmetComponent from '../../utils/HelmetUtils';
import InputNumber from 'rc-input-number';
import { tidy, groupBy, summarize, filter, n } from '@tidyjs/tidy';

// New component for the unverified users table
const UnverifiedUsersTable = ({ users }) => {
    if (!users || users.length === 0) { return null; }

    const sortedUsers = users.sort((a, b) =>
        new Date(a.trialExpiresAt) - new Date(b.trialExpiresAt)
    );

    const tableStyle = {
        fontSize: '0.85rem'
    };

    const cellStyle = {
        padding: '0.25rem 0.5rem',
        lineHeight: '1.2',
    };

    return (
        <Table striped bordered hover size='sm' style={tableStyle}>
            <thead>
                <tr>
                    <th style={cellStyle}>Email</th>
                    <th style={cellStyle}>Trial Expires At</th>
                </tr>
            </thead>
            <tbody>
                {sortedUsers.map(user => (
                    <tr key={user.email}>
                        <td style={cellStyle}>{user.email}</td>
                        <td style={cellStyle}>
                            {user.trialExpiresAt ? new Date(user.trialExpiresAt).toLocaleString() : ''}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

// Create The Component
function AdminPage() {
    // useState: set component state
    const [email, setEmail] = useState('');
    const [userAbility, setUserAbility] = useState({ type: '', value: '' });
    const [isUpdating, setIsUpdating] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [error, setError] = useState(null);
    const [tier, setTier] = useState({ value: 0, label: 'No Access: Not Verified' });
    const [userType, setUserType] = useState({ value: 'team', label: 'Work for a college basketball team' });
    const [userInfo, setUserInfo] = useState(null);
    const [apiKey, setApiKey] = useState('');
    const [apiKeys, setApiKeys] = useState(null);
    const [apiKeyExpiredAt, setApiKeyExpiredAt] = useState('');
    const [unverifiedUsers, setUnverifiedUsers] = useState(null);
    const [trialDays, setTrialDays] = useState(7);
    const [eventLogs, setEventLogs] = useState([]);
    const [password, setPassword] = useState('');
    const [eventLogDateRange, setEventLogDateRange] = useState({ start: '', end: '' });
    const [eventLogDate, setEventLogDate] = useState('');
    const [eventLogTypes, setEventLogTypes] = useState([
        { value: 'login', label: 'Login w/ Email, PW', shortLabel: 'login' },
        { value: 'error', label: 'Page Error', shortLabel: 'err' },
        { value: 'pagechange', label: 'Page Change', shortLabel: 'chng' },
        { value: 'localstoragefetch', label: 'LocalStorage Fetch', shortLabel: 'fetch' }
    ]);
    const eventLogTypesValues = eventLogTypes.map(row => row.value);

    // Permissions via adminTier object
    const { adminTier } = useContext(GlobalContext);
    let isAdmin = adminTier.value === 2;
    let isLowerAdmin = adminTier.value >= 1;
    let noAdminChange = !isAdmin && email === 'nickcanova@gmail.com';

    // Fetch Event Analytics, Trial Token Usage
    const { data: eventLogAnalytics = [] } = useCBBQuery({ ep: 'event-log-analytics', cfg: { logsType: ['overall', 'daily', 'rolling7', 'rolling30'] } });
    const { data: userTierTrialTokens = [] } = useCBBQuery({ ep: 'user-tier-trial-tokens', cfg: {} });

    const z1 = userTierTrialTokens.filter(row => row.hasRedeemed === true);
    const z2 = userTierTrialTokens.filter(row => row.hasRedeemed === false);
    // console.log('Admin Data Fetched: ', { eventLogAnalytics, userTierTrialTokens, z1, z2 });

    // Reset Password handler function
    const handleUpdate = async (e, routeSuffix = 'update-user-tier') => {
        setIsUpdating(true);
        e.preventDefault();
        try {
            let apiBaseUrl = config.url.API_URL;
            const token = localStorage ? localStorage.getItem('auth-token') : window.localStorage.getItem('auth-token');
            const authHeader = { headers: { 'x-auth-token': token } };
            let res;
            switch (routeSuffix) {
                // post requests
                case 'update-user-tier': res = await axios.post(`${apiBaseUrl}/users/${routeSuffix}`, { email, tier }, authHeader); break;
                case 'update-user-type': res = await axios.post(`${apiBaseUrl}/users/${routeSuffix}`, { email, userType }, authHeader); break;
                case 'delete-user': res = await axios.post(`${apiBaseUrl}/users/${routeSuffix}`, { email }, authHeader); break;
                case 'find-user': res = await axios.post(`${apiBaseUrl}/users/${routeSuffix}`, { email }, authHeader); break;
                case 'reset-trial': res = await axios.post(`${apiBaseUrl}/users/${routeSuffix}`, { email }, authHeader); break;
                case 'start-new-trial': res = await axios.post(`${apiBaseUrl}/users/${routeSuffix}`, { email, tier, trialDays }, authHeader); break;
                case 'add-user-ability': res = await axios.post(`${apiBaseUrl}/users/${routeSuffix}`, { email, userAbility }, authHeader); break;
                case 'remove-user-ability': res = await axios.post(`${apiBaseUrl}/users/${routeSuffix}`, { email, userAbility }, authHeader); break;
                case 'add-api-keys': res = await axios.post(`${apiBaseUrl}/users/${routeSuffix}/${email}`, { expiredAt: apiKeyExpiredAt + 'Z' }, authHeader); break;
                case 'delete-api-keys': res = await axios.post(`${apiBaseUrl}/users/${routeSuffix}`, { userId: email, apiKey }, authHeader); break;
                case 'update-api-key': res = await axios.post(`${apiBaseUrl}/users/${routeSuffix}`, { userId: email, apiKey, expiredAt: apiKeyExpiredAt + 'Z' }, authHeader); break;
                case 'set-new-password': res = await axios.post(`${apiBaseUrl}/users/${routeSuffix}`, { email, password }, authHeader); break;
                // get requests
                case 'get-all-api-keys': res = await axios.get(`${apiBaseUrl}/users/${routeSuffix}`, authHeader); break;
                case 'get-api-keys': res = await axios.get(`${apiBaseUrl}/users/${routeSuffix}/${email}`, authHeader); break;
                case 'find-unverified-users': res = await axios.get(`${apiBaseUrl}/users/${routeSuffix}/${tier.value}`, authHeader); break;
                case 'event-log': res = await axios.get(`${apiBaseUrl}/users/${routeSuffix}`, { ...authHeader, params: { email, eventLogTypesValues, eventLogDate } }); break;
                // delete requests
                default: console.log('Error Bad routeSuffix');
            }
            // console.log('response: ', res);

            // Set States Following Post Request which Sends Email
            setIsUpdating(false);
            setUserInfo(res && res.data && res.data.user ? res.data.user : null);
            setUnverifiedUsers(routeSuffix !== 'find-unverified-users' ? null : res.data);
            setEventLogs(routeSuffix === 'event-log' && res && res.data ? res.data : []);
            setApiKeys(['add-api-keys', 'delete-api-keys', 'get-api-keys', 'get-all-api-keys', 'update-api-key'].includes(routeSuffix) && res && res.data ? res.data : null);
            setShowAlert(true);
            setTimeout(() => { setShowAlert(false); }, 1000);
        } catch (err) {
            console.log('Error in Admin Page: ', err);
            setIsUpdating(false);
            setError(err?.response?.data?.msg || 'Error Handling button click on Admin Page');
            setTimeout(() => { setError(null); }, 8000);
        }
    };
    console.log('error: ', error);

    const setTierSelect =
        (<CBBSelect
            selectType='setUserTier'
            value={tier}
            setValue={e => setTier(e)}
            optionGroup={isAdmin ? 1 : (isLowerAdmin ? 3 : 0)}
            isDisabled={false}
            width='100%'
        />);

    const setUserTypeSelect =
        (<CBBSelect
            selectType='setUserType'
            value={userType}
            setValue={e => setUserType(e)}
            optionGroup={1}
            isDisabled={false}
            width='100%'
        />);

    const emailFormControl =
        (<FormControl
            className='modal-input'
            style={{ margin: 0 }}
            type='text'
            value={email}
            onChange={(e) => setEmail(e.target.value.toLowerCase().trim())}
            name='email'
            placeholder='Email Address'
        />);
    const apiKeyFormControl =
        (<FormControl
            className='modal-input'
            style={{ margin: 0 }}
            type='text'
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value.trim())}
            name='apikey'
            placeholder='API Key to Remove'
        />);
    const apiKeyExpiredAtFormControl =
        (<FormControl
            className='modal-input'
            style={{ margin: 0 }}
            type='datetime-local'
            value={apiKeyExpiredAt}
            onChange={(e) => setApiKeyExpiredAt(e.target.value.trim())}
            name='apikeyExpiredAt'
        />);
    const eventLogDateFormControl =
        (<FormControl
            className='modal-input'
            style={{ margin: 0 }}
            type='text'
            value={eventLogDate}
            onChange={(e) => setEventLogDate(e.target.value.toLowerCase().trim())}
            name='email'
            placeholder='>= YYYY/MM/DD'
        />);

    const eventLogAnalyticsStartDateForm =
        (<FormControl
            className='modal-input'
            style={{ margin: 0, maxWidth: 225, marginRight: 3 }}
            type='date'
            value={eventLogDateRange.start}
            onChange={(e) => setEventLogDateRange({ ...eventLogDateRange, start: e.target.value.trim() })}
            name='startDate'
        />);
    const eventLogAnalyticsEndDateForm =
        (<FormControl
            className='modal-input'
            style={{ margin: 0, maxWidth: 225 }}
            type='date'
            value={eventLogDateRange.end}
            onChange={(e) => setEventLogDateRange({ ...eventLogDateRange, end: e.target.value.trim() })}
            name='endDate'
        />);

    // Create Email Form Input
    const userAbilityTypeFormControl =
        (<FormControl
            className='modal-input'
            style={{ margin: 0, width: '100%' }}
            type='text'
            value={userAbility.type}
            onChange={(e) => setUserAbility({ ...userAbility, type: e.target.value })}
            name='user-ability-type'
            placeholder='ability type'
        />);
    const userAbilityValueFormControl =
        (<FormControl
            className='modal-input'
            style={{ margin: 0, width: '100%' }}
            type='text'
            value={userAbility.value}
            onChange={(e) => setUserAbility({ ...userAbility, value: e.target.value })}
            name='user-ability-value'
            placeholder='ability value'
        />);

    // Create Buttons
    const passwordFormControl =
        (<FormControl
            className='modal-input'
            style={{ margin: 0 }}
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            name='password'
            placeholder='Enter New Password'
        />);

    const updateTierButton =
        (<div
            className={`cbb-submit-button ${showAlert ? 'faded' : ''}`}
            style={{ width: '100%', textAlign: 'center', background: '#eb4000', pointerEvents: noAdminChange ? 'none' : 'auto' }}
            onClick={e => handleUpdate(e, 'update-user-tier')}
        >
            {`${isUpdating ? 'Updating Tier...' : 'Update Tier'}`}
        </div>);
    const updateUserTypeButton =
        (<div
            className={`cbb-submit-button ${showAlert ? 'faded' : ''}`}
            style={{ width: '100%', textAlign: 'center', background: '#eb4000' }}
            onClick={e => handleUpdate(e, 'update-user-type')}
        >
            {`${isUpdating ? 'Updating Type...' : 'Update User Type'}`}
        </div>);
    const deleteUserButton =
        (<div
            className={`cbb-submit-button ${showAlert ? 'faded' : ''}`}
            style={{ width: '100%', textAlign: 'center', backgroundColor: 'darkred' }}
            // eslint-disable-next-line no-alert
            onClick={e => { if (window.confirm('Are you sure?')) { handleUpdate(e, 'delete-user'); } }}
        >
            {`${isUpdating ? 'Deleting User...' : 'Delete User'}`}
        </div>);
    const findUserButton =
        (<div
            className={`cbb-submit-button ${showAlert ? 'faded' : ''}`}
            style={{ width: '100%', textAlign: 'center', backgroundColor: 'darkgreen', pointerEvents: noAdminChange ? 'none' : 'auto' }}
            onClick={e => handleUpdate(e, 'find-user')}
        >
            {`${isUpdating ? 'Finding User...' : 'Find User'}`}
        </div>);
    const getEventLogs =
        (<div
            className={`cbb-submit-button ${showAlert ? 'faded' : ''}`}
            style={{ width: '100%', textAlign: 'center', backgroundColor: 'darkgreen', pointerEvents: noAdminChange ? 'none' : 'auto' }}
            onClick={e => handleUpdate(e, 'event-log')}
        >
            {`${isUpdating ? 'Getting Logs...' : 'Get User Logs'}`}
        </div>);
    const resetTrialButton =
        (<div
            className={`cbb-submit-button ${showAlert ? 'faded' : ''}`}
            style={{ width: '100%', textAlign: 'center', background: '#eb4000', pointerEvents: noAdminChange ? 'none' : 'auto' }}
            onClick={e => handleUpdate(e, 'reset-trial')}
        >
            {`${isUpdating ? 'Resetting Trial...' : 'Reset Trial'}`}
        </div>);

    const disableNewTrial = trialDays === null || noAdminChange;
    const newTrialStyles = disableNewTrial ? { pointerEvents: 'none', backgroundColor: 'darkred' } : { background: '#eb4000' };
    const startNewTrialButton =
        (<div
            className={`cbb-submit-button ${showAlert ? 'faded' : ''}`}
            style={{ textAlign: 'center', ...newTrialStyles }}
            onClick={e => handleUpdate(e, 'start-new-trial')}
        >
            {`${isUpdating ? 'Starting Trial...' : `Start ${trialDays}-Day ${tier.label} Trial`}`}
        </div>);
    const findUnverifiedsButton =
        (<div
            className={`cbb-submit-button ${showAlert ? 'faded' : ''}`}
            style={{ width: '100%', textAlign: 'center', backgroundColor: 'darkgreen' }}
            onClick={e => handleUpdate(e, 'find-unverified-users')}
        >
            {`${isUpdating ? 'Finding Users...' : 'Find User Emails By Tier'}`}
        </div>);
    const getAllApiKeysButton =
        (<div
            className={`cbb-submit-button ${showAlert ? 'faded' : ''}`}
            style={{ maxWidth: 300, textAlign: 'center', backgroundColor: 'darkgreen' }}
            onClick={e => handleUpdate(e, 'get-all-api-keys')}
        >
            {`${isUpdating ? 'Getting API Keys...' : 'Get All API Keys'}`}
        </div>);
    const getApiKeyButton =
        (<div
            className={`cbb-submit-button ${showAlert ? 'faded' : ''}`}
            style={{ maxWidth: 300, textAlign: 'center', backgroundColor: 'darkgreen' }}
            onClick={e => handleUpdate(e, 'get-api-keys')}
        >
            {`${isUpdating ? 'Getting API Key...' : 'Get User API Keys'}`}
        </div>);
    const addApiKeyButton =
        (<div
            className={`cbb-submit-button ${showAlert ? 'faded' : ''}`}
            style={{ maxWidth: 300, textAlign: 'center' }}
            onClick={e => handleUpdate(e, 'add-api-keys')}
        >
            {`${isUpdating ? 'Adding API Key...' : 'Add User API Key'}`}
        </div>);
    const rmApiKeyButton =
        (<div
            className={`cbb-submit-button ${showAlert ? 'faded' : ''}`}
            style={{ maxWidth: 300, textAlign: 'center', backgroundColor: 'darkred' }}
            onClick={e => handleUpdate(e, 'delete-api-keys')}
        >
            {`${isUpdating ? 'Removing API Key...' : 'Remove User API Key'}`}
        </div>);
    const updateApiKeyButton =
        (<div
            className={`cbb-submit-button ${showAlert ? 'faded' : ''}`}
            style={{ maxWidth: 300, textAlign: 'center', background: '#eb4000' }}
            onClick={e => handleUpdate(e, 'update-api-key')}
        >
            {`${isUpdating ? 'Updating API Key...' : 'Update User API Key'}`}
        </div>);
    const addUserAbilityButton =
        (<div
            className={`cbb-submit-button ${showAlert ? 'faded' : ''}`}
            style={{ width: '100%', textAlign: 'center' }}
            onClick={e => handleUpdate(e, 'add-user-ability')}
        >
            {`${isUpdating ? 'Adding User Ability...' : 'Add User Ability'}`}
        </div>);
    const updatePasswordButton =
        (<div
            className={`cbb-submit-button ${showAlert ? 'faded' : ''}`}
            style={{ width: '100%', textAlign: 'center', backgroundColor: '#eb4000' }}
            onClick={e => handleUpdate(e, 'set-new-password')}
        >
            {`${isUpdating ? 'Updating Password...' : 'Update Password'}`}
        </div>);
    const removeUserAbilityButton =
        (<div
            className={`cbb-submit-button ${showAlert ? 'faded' : ''}`}
            style={{ width: '100%', textAlign: 'center', backgroundColor: 'darkred' }}
            onClick={e => handleUpdate(e, 'remove-user-ability')}
        >
            {`${isUpdating ? 'Removing User Ability...' : 'Remove User Ability'}`}
        </div>);

    // Trial, etc.
    let trialDaysInput =
        (<InputNumber
            className='cbb-input-small'
            style={{ marginRight: 2, fontSize: 26, width: '100%' }}
            value={trialDays}
            placeholder='N'
            onChange={e => setTrialDays(e === '' ? null : e)}
        />);
    let eventLogTypesSelect =
        (<CBBMultiSelect
            selectType='eventLogTypes'
            array={eventLogTypes}
            setArray={setEventLogTypes}
            wrapperStyle={{ width: '100%' }}
        />);

    // Event Logging Stuff
    // ====================
    const formatEventLogString = (row, idx) => {
        const date = new Date(row.eventAt);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        const dateString = `${year}-${month}-${day} ${hours}:${minutes}`;
        const combinedText = `${dateString} - ${row.type}, ${row.email}`;
        const entireString = <p key={`${idx}: ${combinedText}`} style={{ fontSize: '0.8em' }}>{combinedText}</p>;
        return entireString;
    };
    const eventLogTypeValues = eventLogTypes.map(row => row.value);
    const cleanedLogs = eventLogs
        .filter(row => eventLogTypeValues.includes(row.type))
        .sort((a, b) => a.eventAt < b.eventAt ? 1 : -1)
        .map((row, idx) => formatEventLogString(row, idx));

    let analyzedLogs = tidy(eventLogs,
        filter(row => eventLogTypeValues.includes(row.type)),
        groupBy(['email', 'type'], [
            summarize({ count: n() })
        ]));

    analyzedLogs = analyzedLogs
        .sort((a, b) => a.count < b.count ? 1 : -1)
        .map(row => {
            return (
                <p key={`${row.type}-${row.email}`} style={{ fontSize: '0.8em' }}>
                    <strong>{row.count} - </strong> {row.email}, {row.type}
                </p>
            );
        });
    // ========


    // Event Log Analytics -> Line Graphs
    // ===================================
    const sortedLogAnalytics = eventLogAnalytics
        .filter(row => eventLogDateRange.start === '' ? true : row.eventDate >= eventLogDateRange.start)
        .filter(row => eventLogDateRange.end === '' ? true : row.eventDate <= eventLogDateRange.end)
        .sort((a, b) => a.eventDate < b.eventDate ? 1 : -1);
    // ========

    // User Tier Trial Tokens By Month
    // ================================
    const tokensByMonth = userTierTrialTokens.reduce((acc, row) => {
        // Format date as "YYYY-MM"
        const month = new Date(row.createdAt).toISOString().slice(0, 7);
        if (!acc[month]) {
            acc[month] = [];
        }

        acc[month].push(row);
        return acc;
    }, {});

    const results = Object.keys(tokensByMonth).map(month => {
        const redeemed = tokensByMonth[month].filter(row => row.hasRedeemed === true).length;
        const notRedeemed = tokensByMonth[month].filter(row => row.hasRedeemed === false).length;
        const pctRedeemed = Math.round(1000 * redeemed / (redeemed + notRedeemed)) / 1000;
        return { month, redeemed, notRedeemed, pctRedeemed };
    });

    // console.log('tokensByMonth: ', { tokensByMonth, results });

    // =======

    return (
        <Container style={{ marginTop: 40, maxWidth: 1600, marginRight: 20, marginLeft: 20 }}>
            <HelmetComponent page='adminPage' />
            {isLowerAdmin && <>
                <Row style={{ marginBottom: 100 }}>
                    <Col xs={11} md={6} lg={3}>

                        {/* Find & Delete Users */}
                        <Row style={{ fontWeight: 700, margin: '0px auto' }}>Find & Delete Users</Row>
                        <Row style={{ justifyContent: 'center', margin: '4px 0px' }}>{emailFormControl}</Row>
                        <Row style={{ justifyContent: 'center', margin: '4px -3px' }}>
                            <Col xs={6} style={{ padding: '2px 3px' }}>{findUserButton}</Col>
                            {isAdmin &&
                                <Col xs={6} style={{ padding: '2px 3px' }}>{deleteUserButton}</Col>
                            }
                        </Row>

                        {/* Find & Analyze Logs */}
                        <Row style={{ fontWeight: 700, margin: '20px auto 0px auto' }}>Find & Analyze Logs</Row>
                        <Row style={{ margin: '2px -3px' }}>
                            <Col xs={6} style={{ padding: '2px 3px' }}>{getEventLogs}</Col>
                            <Col xs={6} style={{ padding: '2px 3px' }}>{eventLogDateFormControl}</Col>
                            <Col xs={12} style={{ padding: '2px 3px' }}>{eventLogTypesSelect}</Col>
                        </Row>

                        {/* Handling Tier & Trials */}
                        <Row style={{ fontWeight: 700, margin: '20px auto 0px auto' }}>Handling Tier & Trials</Row>
                        <Row style={{ justifyContent: 'center', margin: '4px -3px 4px 0px' }}>{setTierSelect}</Row>
                        <Row style={{ justifyContent: 'center', margin: '4px -3px' }}>
                            <Col xs={6} style={{ padding: '0px 3px' }}>{updateTierButton}</Col>
                            {isAdmin && <Col xs={6} style={{ padding: '0px 3px' }}>{findUnverifiedsButton}</Col>}
                        </Row>
                        <Row style={{ justifyContent: 'center', margin: '4px -3px' }}>
                            <Col xs={4} style={{ padding: '0px 3px' }}>{resetTrialButton}</Col>
                            <Col xs={6} style={{ padding: '0px 3px' }}>{startNewTrialButton}</Col>
                            <Col xs={2} style={{ padding: '0px 3px' }}>{trialDaysInput}</Col>
                        </Row>

                        {/* <Row style={{ justifyContent: 'center', margin: '4px 0px' }}>{resetTrialButton}</Row>
                        <Row style={{ justifyContent: 'center', margin: 4 }}>
                            {trialDaysInput}
                            {startNewTrialButton}
                        </Row> */}

                        {isAdmin && <>
                            <Row style={{ fontWeight: 700, width: '100%', margin: '20px auto 0px auto' }}>Set User Type</Row>
                            <Row style={{ justifyContent: 'center', margin: '4px -3px 4px 0px' }}>{setUserTypeSelect}</Row>
                            <Row style={{ justifyContent: 'center', margin: '4px 0px' }}>{updateUserTypeButton}</Row>
                        </>}


                        {/* API Key Control */}
                        {isAdmin && <>
                            <Row style={{ fontWeight: 700, width: '100%', margin: '20px auto 0px auto' }}>Get, Add & Remove API Keys</Row>
                            <Row style={{ justifyContent: 'center', margin: '4px 0px' }}>{emailFormControl}</Row>
                            <Row style={{ justifyContent: 'center', margin: '4px 0px' }}>{apiKeyFormControl}</Row>
                            <Row style={{ justifyContent: 'center', alignItems: 'center', margin: '4px 0px' }}>
                                <Col xs={10} style={{ padding: '2px 3px' }}>{apiKeyExpiredAtFormControl}</Col>
                                <Col xs={2} style={{ padding: '2px 3px' }}>UTC</Col>
                            </Row>
                            <Row style={{ margin: '0px -3px' }}>
                                <Col xs={6} style={{ padding: '2px 3px' }}>{getAllApiKeysButton}</Col>
                                <Col xs={6} style={{ padding: '2px 3px' }}>{getApiKeyButton}</Col>
                                <Col xs={6} style={{ padding: '2px 3px' }}>{addApiKeyButton}</Col>
                                <Col xs={6} style={{ padding: '2px 3px' }}>{rmApiKeyButton}</Col>
                                <Col xs={6} style={{ padding: '2px 3px' }}>{updateApiKeyButton}</Col>
                            </Row>
                        </>}

                        {/* Add & Remove User Abilities */}
                        {isAdmin && <>
                            <Row style={{ fontWeight: 700, width: '100%', margin: '20px auto 0px auto' }}>Add & Remove User Abilities</Row>
                            <Row style={{ justifyContent: 'center', margin: '4px 0px' }}>{emailFormControl}</Row>
                            <Row style={{ justifyContent: 'center', margin: '4px -3px' }}>
                                <Col xs={6} style={{ padding: '2px 3px' }}>{userAbilityTypeFormControl}</Col>
                                <Col xs={6} style={{ padding: '2px 3px' }}>{userAbilityValueFormControl}</Col>
                            </Row>
                            <Row style={{ justifyContent: 'center', margin: '4px -3px' }}>
                                <Col xs={6} style={{ padding: '2px 3px' }}>{addUserAbilityButton}</Col>
                                <Col xs={6} style={{ padding: '2px 3px' }}>{removeUserAbilityButton}</Col>
                            </Row>
                        </>}

                        {/* Update Password */}
                        {isAdmin && <>
                            <Row style={{ fontWeight: 700, width: '100%', margin: '20px auto 0px auto' }}>Update Password</Row>
                            <Row style={{ justifyContent: 'center', margin: '4px 0px' }}>{emailFormControl}</Row>
                            <Row style={{ justifyContent: 'center', margin: '4px 0px' }}>{passwordFormControl}</Row>
                            <Row style={{ justifyContent: 'center', margin: '4px 0px' }}>{updatePasswordButton}</Row>
                        </>}

                        {/* Alerts, Error & Success */}
                        {(error && error.length > 0) &&
                            <Row style={{ justifyContent: 'center', margin: 1 }}>
                                <Alert style={{ width: 300 }} variant='danger' className='animate-fade-away'>
                                    {error}
                                </Alert>
                            </Row>
                        }
                        {showAlert &&
                            <Row style={{ justifyContent: 'center', margin: 1 }}>
                                <Alert style={{ width: 300 }} variant='success' className='animate-fade-away'>
                                    {email} updated
                                </Alert>
                            </Row>
                        }
                    </Col>

                    {/* Event Log Analytics */}
                    {eventLogs.length > 0 &&
                        <Col lg={4}>
                            {analyzedLogs}
                        </Col>
                    }

                    {/* Event Logs, User Object, Listed Users API Keys */}
                    <Col md={6} lg={4}>
                        {eventLogs.length > 0 && cleanedLogs}
                        {userInfo && <pre style={{ fontSize: 10 }}>{JSON.stringify(userInfo, null, 2)}</pre>}
                        <UnverifiedUsersTable users={unverifiedUsers} />
                        {apiKeys && <pre style={{ fontSize: 10 }}>{JSON.stringify(apiKeys, null, 2)}</pre>}
                    </Col>


                </Row>

                {/* Event Logging Analytics */}
                <Row style={{ margin: 0 }}>
                    <h2 style={{ textDecoration: 'underline #0066CC', fontWeight: 700 }}>Event Log Analytics</h2>
                </Row>
                <Row style={{ margin: 0 }}>
                    {eventLogAnalyticsStartDateForm}
                    {eventLogAnalyticsEndDateForm}
                </Row>
                <Row style={{ marginBottom: 25 }}>
                    {['daily', 'rolling7', 'rolling30'].map(logsType => {
                        return (
                            <Col xs={4} key={`${logsType}-logs-column`}>
                                {['uVisitors', 'pagechanges', 'logins', 'errors'].map(yMetric => {
                                    const graphData = sortedLogAnalytics.filter(row => row.logsType === logsType);
                                    return (
                                        <React.Fragment key={`${logsType}-${yMetric}`}>
                                            <div style={{ marginTop: 20 }} />
                                            <p style={{ fontWeight: 700 }}>{logsType} {yMetric}</p>
                                            <D3LineGraph
                                                key={`${yMetric}-daily`}
                                                graphData={graphData}
                                                yMetric={yMetric}
                                            />
                                        </React.Fragment>
                                    );
                                })}
                            </Col>
                        );
                    })}
                </Row>

                {/* User Tier Trial Token Usage */}
                <Row style={{ margin: 0, marginBottom: 50, flexDirection: 'column' }}>
                    <h2 style={{ textDecoration: 'underline #0066CC', fontWeight: 700 }}>User Tier Trial Token Usage</h2>
                    <p>Overall Trials Redeemed: {userTierTrialTokens.filter(row => row.hasRedeemed === true).length}</p>
                    <p>Overall Trials Not Redeemed: {userTierTrialTokens.filter(row => row.hasRedeemed === false).length}</p>
                    <p><strong>month:{` `}</strong>Redeemed / Total (Percentage)</p>
                    {results.map(({ month, redeemed, notRedeemed, pctRedeemed }) => (
                        <React.Fragment key={month}>
                            <p>
                                <strong>{month}:{` `}</strong>
                                {redeemed} / {redeemed + notRedeemed} ({pctRedeemed})
                            </p>
                        </React.Fragment>
                    ))}

                </Row>
                {/* <Row style={{ margin: 0, marginBottom: 25 }}></Row> */}
            </>}
        </Container >
    );
}

export default AdminPage;
