
import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import styled from '@emotion/styled';

const CardElementContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #222;
  border-radius: 4px;
  background-color: #EEE;
  width: 475px;
  & .StripeElement {
    width: 100%;
    padding: 15px;
  }
`;

// CardElement Styles
const iframeStyles = {
    base: {
        'color': '#444444',
        'fontSize': '16px',
        'iconColor': '#888888',
        '::placeholder': {
            color: 'rgba(0, 102, 204, 0.50)'
        }
    },
    invalid: {
        iconColor: 'darkred',
        color: 'darkred'
    },
    complete: {
        iconColor: '#0066CC'
    }
};

const cardElementOpts = {
    iconStyle: 'solid',
    style: iframeStyles
    // hidePostalCode: true
};

const CBBCardElement = ({ onChange }) => {
    // const [checkoutError, setCheckoutError] = useState();

    // const isDisabled = (isProcessing || !stripe);
    // const handleCardDetailsChange = ev => {
    //     ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
    // };

    // Can Add ClassNames if needed
    return (
        <CardElementContainer>
            <CardElement
                options={cardElementOpts}
                onChange={e => onChange(e)}
            />
        </CardElementContainer>
    );
};

export default CBBCardElement;
