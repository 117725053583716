
import React, { useEffect } from 'react';
import { FormControl } from 'react-bootstrap';
import Axios from 'axios';
import config from '../../../../config';

const PromoCodeInputForm = ({
    referral, //        default state: { promoCode: null, code: '', discount: 0, color: 'black', message: '' }
    setReferral, //     updates referral state in parent components
    isDisabled, //       whether or not FormControl is disabled (disable when signing up, payment processing)
    wrapperStyle
}) => {
    // console.log('PromoCodeInputForm props:', { referral, isDisabled, wrapperStyle });

    // check if valid promo code
    const isValidPromoCode = referral.code !== null && typeof referral.code !== 'undefined' && referral.code.trim() !== '';

    // handler function to assess if promo code is valid
    const validateReferralCode = async () => {
        // handle empty string (breaks the route call)
        if (!referral.code || referral.code.trim() === '') { return; }

        // fetch all coupons, and filter for coupon matching "referralCode"
        const promoCodeUrl = `${config.url.API_URL}/stripe/promotion-codes/${referral.code}`;
        const promoCodeResp = await Axios.get(promoCodeUrl);
        const promoCode = promoCodeResp.data || null;

        // if no coupon, return error message
        if (!promoCode) {
            setReferral({
                ...referral,
                promoCode: null,
                discount: 0,
                color: 'darkred',
                message: 'The discount or referral code you entered is not valid.'
            });
        }

        // else, grab coupon object and return important info
        if (promoCode) {
            setReferral({
                ...referral,
                promoCode: promoCode,
                discount: promoCode.coupon.amount_off,
                color: 'darkgreen',
                message: `Valid code! A $${promoCode.coupon.amount_off / 100} discount ` + (promoCode.extendFreeTrial ? 'and 2nd week free trial week' : '') + ' will be applied to your subscription.'
            });
        }
    };

    // validate on first render
    useEffect(() => {
        if (referral.code && referral.code.trim() !== '') {
            validateReferralCode();
        }
    }, []);

    const referralCodeFormControl =
        (<div style={{ ...(wrapperStyle && wrapperStyle) }}>

            {/* Input Form & Apply Button */}
            <div style={{ display: 'flex' }}>

                {/* Input Form */}
                <FormControl
                    style={{ marginTop: 3 }}
                    disabled={isDisabled}
                    className='modal-input'
                    type='text'
                    value={referral.code || ''}
                    onChange={(e) => setReferral({ ...referral, code: e.target.value })}
                    name='referral'
                    placeholder='Add a Discount or Referral Code'
                />

                {/* Apply Button */}
                <div
                    className={`cbb-button ${isDisabled || !isValidPromoCode ? 'disabled' : ''}`}
                    style={{ marginRight: 0, width: 80, marginLeft: 5, border: '1px solid #777777', height: 34, color: '#222222' }}
                    onClick={() => validateReferralCode()}
                >
                    <span style={{ margin: '0 auto' }}>Apply</span>
                </div>
            </div>

            {/* Display Message */}
            <p style={{ fontWeight: 700, fontSize: '0.85em', fontStyle: 'italic', lineHeight: 1.3, color: referral.color }}>
                {referral.message}
            </p>
        </div>);


    // And Return
    return (referralCodeFormControl);
};

export default PromoCodeInputForm;
