
// imports
import React, { useRef } from 'react';
import d3 from '../../assets/d3';
import AxisLeft from './axes/AxisLeft';
import AxisBottom from './axes/AxisBottom';

// Main component
const D3LineGraph = ({ graphData, type = 'single', yMetric = 'pagechanges', xMetric = 'eventDate' }) => {
    // console.log('D3LineGraph props: ', { graphData, type, yMetric, xMetric });

    // set constants
    const svgRef = useRef(null);
    const svgWidth = 700;
    const svgHeight = 400;
    const margin = { left: 60, top: 25, bottom: 55, right: 20 };
    const width = svgWidth - margin.right - margin.left;
    const height = svgHeight - margin.top - margin.bottom;

    const parsedDates = graphData.map(date => d3.timeParse('%Y-%m-%d')(date.eventDate));

    const xScale = d3.scaleTime()
        .domain(d3.extent(parsedDates))
        // .domain(d3.extent(graphData, d => new Date(d[xMetric])))
        .range([0, width]);

    const yScale = d3.scaleLinear()
        .domain([0, d3.max(graphData, d => +d[yMetric])])
        .range([height, 0]);

    // Line generator
    const line = d3.line()
        .x(d => xScale(new Date(d[xMetric])))
        .y(d => yScale(d[yMetric]))
        .curve(d3.curveMonotoneX);

    // Draw Lines
    const mainGraphic = (
        <path d={line(graphData)} fill='none' stroke='#0066CC' strokeWidth='2' />
    );

    // Y-Axis
    const yAxis =
        (<AxisLeft
            yScale={yScale}
            height={height}
            width={width}
            margin={margin}
            count={10}
            label={yMetric}
            unit='int'
            showGrid={true}
            rotateLabel={true}
        />);

    // const xAxis = d3.axisBottom(xScale).ticks(10).tickFormat(d3.timeFormat('%Y-%m-%d'));


    // X-Axis
    const xAxis =
        (<AxisBottom
            xScale={xScale}
            height={height}
            width={width}
            label={xMetric}
            count={8}
            unit='monthYear'
            linearScale={true}
            // labels={labels}
        />);

    // And Return
    return (
        <div style={{ width: '100%' }}>
            <svg ref={svgRef} width='100%' viewBox={`0 0 ${svgWidth} ${svgHeight}`} className='cbb-box-shadowed print-border' style={{ background: '#F2F2F2' }}>
                <g transform={`translate(${margin.left},${margin.top})`}>
                    {yAxis}
                    {xAxis}
                    {mainGraphic}
                </g>
            </svg>
        </div>
    );
};


export default D3LineGraph;
