
// Live Search:     https://codeytek.com/live-search-search-react-live-search-in-react-axios-autocomplete-pagination/
// Note:            mongodb atlas search returns top 20-30 results by search score, however ReactSelect still filters
//                  those 20 - 30 results based on exact text search matching.


// Import React Stuff
import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { useNavigate } from 'react-router-dom';

// Import Utis
import { appSelectStyles } from '../../utils/ReactSelectStyles.js';

// Import Data Fetching Hook
import useLiveSearchApi from '../../hooks/useLiveSearchApi';
import { MdSearch } from 'react-icons/md';

function AppSelect() {
    // Set Page State
    const [searchText, setSearchText] = useState('');

    // Fetch App Select Options
    // eslint-disable-next-line no-unused-vars
    const [ptgcTop20, isLoading, isError] = useLiveSearchApi({ initialValue: [], cfg: { ptgc: 'all', searchText: searchText } });
    // console.log('ptgcTop20: ', { searchText, ptgcTop20 });

    // Grab History In Order To Push To Selected Pages
    const navigate = useNavigate();

    // Handler Functions
    const handleSelect = (event) => {
        if (!event) {
            // console.log('Emptied');
        } else {
            // Set Navigation
            let newPath;
            let ptgcId = event._id
                .replace('game-', '')
                .replace('team-', '')
                .replace('player-', '')
                .replace('conference-m-', '')
                .replace('conference-w-', '');

            // grab playerId from playerId-teamId combo
            if (event.ptgc === 'player') {
                ptgcId = ptgcId.split('-')[0];
            }
            // console.log('ptgcId: ', ptgcId);

            // No longer using global state "competition". Handling within ptgc_selects
            let maxCompetitionId = Math.max.apply(null, event.competitionIds);
            switch (event.ptgc) {
                case 'conference': newPath = `/stats/${maxCompetitionId}/conferences/${ptgcId}/team-box`; break;
                case 'game': newPath = `/stats/${maxCompetitionId}/games/${ptgcId}/overview`; break;
                case 'player': newPath = `/stats/${maxCompetitionId}/players/${ptgcId}/overview`; break;
                case 'team': newPath = `/stats/${maxCompetitionId}/teams/${ptgcId}/overview`; break;
                default: newPath = `/error-wrong-path`;
            }

            // Push to New Path
            navigate(newPath);
            // console.log('newPath: ', newPath);
        }
    };

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <MdSearch size='2.1em' color='#FFFFFF' />
                </components.DropdownIndicator>
            )
        );
    };

    // Handle Key Stroke
    const handleKeyStroke = (text) => {
        setSearchText(text);
    };

    // Create The Select
    const appSelect =
        (<Select
            className='cbb-select cbb-app-select'
            styles={appSelectStyles}
            value={searchText}
            options={ptgcTop20}
            onChange={(e) => handleSelect(e)}
            onInputChange={(e) => handleKeyStroke(e)}
            getOptionValue={option => option._id}
            getOptionLabel={option => option.label}
            noOptionsMessage={() => null}
            placeholder={'Search across the website'}
            components={{
                DropdownIndicator: DropdownIndicator,
                IndicatorSeparator: () => null,
                onMouseDown: (e) => e.stopPropagation()
            }}
        />);

    return (
        <div className='app-select-wrapper'>
            {appSelect}
        </div>
    );
}

export default AppSelect;
