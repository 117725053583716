
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import GlobalContext from '../../context/GlobalContext';
import { FaDownload } from 'react-icons/fa';
import { CSVLink } from 'react-csv';
import { tidy, select, filter, rename, leftJoin } from '@tidyjs/tidy';
import { dDict, dDictArray } from '../../harddata/DataDictionary.js';

// The Modal Itself
const getHexColor1 = (obj, id) => {
    const hexColor = obj && obj[id] ? obj[id].hexColor1 : '#444444';
    return hexColor;
};

const mergeArray = (array1, array2) => {
    let outputArray = [];
    let i, l = Math.min(array1.length, array2.length);

    for (i = 0; i < l; i++) {
        outputArray.push(array1[i], array2[i]);
    }

    outputArray.push(...array1.slice(l), ...array2.slice(l));
    return outputArray;
};

const dropUndefinedFields = (array) => {
    let numRows = array.length;
    let undefinedCounts = {};

    // Add field to 'undefinedCounts' if not in yet, and increment for 'undefined' values
    array.forEach(row => {
        Object.keys(row).forEach(field => {
            if (!undefinedCounts[field]) { undefinedCounts[field] = 0; }
            if (typeof row[field] === 'undefined') { undefinedCounts[field] += 1; }
        });
    });

    // Identify Fields to Drop
    let fieldsToDrop = [];
    Object.keys(undefinedCounts).forEach(field => {
        if (undefinedCounts[field] === numRows) { fieldsToDrop.push(field); }
    });

    // Drop Fields
    array.forEach(row => {
        fieldsToDrop.forEach(field => {
            delete row[field];
        });
    });

    // And Return
    return array;
};

const grabScopeFields = (scopes, ratingsType, formatType) => {
    // console.log('grabScopeFields: ', { scopes, ratingsType, formatType });
    // ['season', 'l5g'].map(row => row.slice(0,1).toUpperCase() + row.slice(1))
    let keeperFields = [];

    // For Most Long-Stats Tables (valueSeason, pctileSeason)
    if (formatType !== 'onOffLong') {
        let ratingsPrefix = ratingsType.value === 'pctiles' ? 'pctile' : 'rank';
        scopes.forEach(row => {
            keeperFields.push(`value${row.slice(0, 1).toUpperCase()}${row.slice(1)}`);
            keeperFields.push(`${ratingsPrefix}${row.slice(0, 1).toUpperCase()}${row.slice(1)}`);
        });
    }

    // For On-Off Stats Long (via Player On/Off Page)
    if (formatType === 'onOffLong') {
        keeperFields.push('isOffense');
        scopes.forEach(row => {
            keeperFields.push(`value${row.slice(0, 1).toUpperCase()}${row.slice(1)}On`);
            keeperFields.push(`rating${row.slice(0, 1).toUpperCase()}${row.slice(1)}On`);
            keeperFields.push(`value${row.slice(0, 1).toUpperCase()}${row.slice(1)}Off`);
            keeperFields.push(`rating${row.slice(0, 1).toUpperCase()}${row.slice(1)}Off`);
            keeperFields.push(`value${row.slice(0, 1).toUpperCase()}${row.slice(1)}Diff`);
            keeperFields.push(`rating${row.slice(0, 1).toUpperCase()}${row.slice(1)}Diff`);
        });
    }

    // and return (e.g. ['valueL5g', 'rankL5g', 'valueSeason', 'rankSeason'])
    return keeperFields;
};

const renameFields = (array, namesObject) => {
    // console.log('ddicts: ', { namesObject });

    let renameDict = {};
    Object.keys(namesObject).forEach(field => {
        renameDict[field] = namesObject[field].label2;
        renameDict[`${field}Pctile`] = `${namesObject[field].label2} %ile`;
    });

    // // v1, left join rename (works fine if renaming only)
    // let outputData = tidy(array, rename(renameDict));

    // v2, add 2nd row of names
    let keeperFields = Object.keys(Object.assign({}, ...array));
    let newObj = {};
    keeperFields.forEach(field => {
        newObj[field] = renameDict[field] || field;
    });

    let outputData = [newObj, ...array];
    return outputData;
};

function DownloadDataButton({
    className = null,
    style = {},
    data,
    teamId,
    size = 16,
    conferenceId = null,
    // for wide tables
    columnAccessors = [], // for wide data, we keep these fields
    // for long tables
    rowAccessors = [], // for long data, we keep these fields
    ratingsType = null, // for long data, keep pctiles or ranks, not both
    scopes = [], // for long data, we keep selected scopes only
    formatType = 'teamOverview' // one of ['teamOverview', 'onOffLong']
}) {
    // console.log('DownloadDataButton params: ', { className, style, data, teamId, size, conferenceId, columnAccessors, rowAccessors, ratingsType, scopes });
    // Get Params & Content
    const { teamInfosObj, conferenceInfosObj } = useContext(GlobalContext);
    const params = useParams();
    const paramConferenceId = Number(params.conferenceId);
    const paramTeamId = Number(params.teamId);

    // Set Modal Color
    let modalColor = getHexColor1(teamInfosObj, teamId);
    if (paramTeamId) { modalColor = getHexColor1(teamInfosObj, paramTeamId); }
    if (conferenceId) { modalColor = getHexColor1(conferenceInfosObj, conferenceId); }
    if (paramConferenceId) { modalColor = getHexColor1(conferenceInfosObj, paramConferenceId); }


    // Remove isPinned Row
    let outputData = tidy(data, filter(row => row.isPinned !== true));

    // If Wide Table
    if (columnAccessors.length > 0) {
        // Keep Columns in Website Table Only
        let pctileAccessors = columnAccessors.map(row => `${row}Pctile`);
        let allAccessors = mergeArray(columnAccessors, pctileAccessors);
        if (columnAccessors.length > 0) {
            outputData = tidy(outputData, select(allAccessors));
        }

        // Drop Undefined Fields (e.g. no fullNamePctile)
        outputData = dropUndefinedFields(outputData);

        // Rename to Human-Readable Fields
        outputData = renameFields(outputData, dDict);
    }

    // If Long Table
    if (rowAccessors.length > 0) {
        outputData = tidy(outputData,
            filter(d => rowAccessors.includes(d.key)), // keep relevant rows only
            leftJoin(tidy(dDictArray, select(['key', 'label2'])), { by: 'key' }), // join label 2
            select(['key', 'label2', ...grabScopeFields(scopes, ratingsType, formatType)]) // keep relevant columns only
        );

        // Organize Rows (all offense, then all defense. not alternating rows)
        if (formatType === 'onOffLong') {
            outputData = [...outputData.filter(row => row.isOffense === true), ...outputData.filter(row => row.isOffense === false)];
        }
    }

    // Set Filename using Datetime
    const filename = `cbb-data-${Date.now()}.csv`;

    // And Return // , display: 'none'
    return (
        <div className={`${className || ''} hide-print`} style={{ ...style }}>
            <CSVLink data={outputData} filename={filename}>
                <FaDownload size={size} color={modalColor} stroke={'#222222'} strokeWidth={30} />
            </CSVLink>
        </div>
    );
}

export default DownloadDataButton;
