
import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import GlobalContext from '../../context/GlobalContext';
import questionMark from '../../images/question-mark.png';
import { teamLogoUrl, conferenceLogoUrl } from '../../utils/GetLogos';

function Logo({
    ptgc,
    conferenceId,
    teamId,
    competitionId = null,
    showBackground = false,
    className = '',
    id = '',
    style = {},
    imgStyle = {}
}) {
    // console.log('Logo Props: ', { ptgc, conferenceId, teamId, competitionId, className });
    // grab Params (competitionId) and Context
    const params = useParams();
    const logoCompetitionId = competitionId !== null ? competitionId : params.competitionId;
    const { teamInfosObj } = useContext(GlobalContext);

    // Disable clickability from logo if no competitionId to route to.
    let extraStyle = {};
    if (competitionId === null && !params.competitionId) { extraStyle.pointerEvents = 'none'; }

    // Set background color for teamId
    let bgColor = teamInfosObj && teamId && teamInfosObj[teamId] ? teamInfosObj[teamId].hexColor1 : '';
    // console.log('bgColor: ', { teamInfosObj, teamId, bgColor });
    if (showBackground === true) {
        extraStyle.padding = '13%';
        extraStyle.borderRadius = '50%';
        if (bgColor !== '') {
            extraStyle.backgroundColor = `#E9E9E9`;
            // extraStyle.backgroundColor = `${bgColor}44`;
            extraStyle.border = `2px solid ${bgColor}`;
        } else {
            extraStyle.backgroundColor = `#88888844`;
            extraStyle.border = `2px solid #888888`;
        }
    }

    // Set new path
    let newPath = ptgc === 'conference'
        ? `/stats/${logoCompetitionId}/conferences/${conferenceId}/team-box`
        : `/stats/${logoCompetitionId}/teams/${teamId}/overview`;

    // Set image for logo
    let newImgSrc = ptgc === 'conference'
        ? conferenceLogoUrl(conferenceId)
        : teamLogoUrl(teamId);

    // return the link + image
    return (
        <div className={className} id={id} style={{ ...style, ...extraStyle }}>
            <Link className='logo' to={newPath}>
                <img
                    style={{ ...imgStyle }}
                    alt={`${ptgc === 'conference' ? 'conf' : 'team'} logo`}
                    onError={ev => { ev.target.src = questionMark; }}
                    src={newImgSrc}
                    loading='lazy'
                    // width={imgStyle.width || 24} // fixed width may speed up image loads, but these images resize for different page widths
                    // height={imgStyle.height || 24} // need to commento out for now...
                />
            </Link>
        </div>
    );
}

export default Logo;
