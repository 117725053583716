
import React, { useState, useRef, useMemo, useEffect } from 'react';
import ReactPlayer from 'react-player/youtube';
import useScreenSize from '../../hooks/useScreenSize';
import imageUrls from '../../images/gcsImages';

const strings = {
    title: 'Make Our Tools Work for You',
    websiteNavigation: 'Navigating the website',
    zero100Pctiles: '0 - 100 Percentiles',
    ratings4Factors: 'Ratings & 4 Factors',
    opponentStats: 'Stats Allowed to Opponents',
    statScopes: 'Statistical Splits',
    shotCharts: 'Shot Charts',
    printSaveToPdf: 'Save to PDF & Print',
    postGameReport: 'Creating Post Game Reports',
    dataPoweringCBB: 'The Data Powering CBB'
};

const videoMap = {
    websiteNavigation: 'https://youtu.be/XzGHmPwLJGw',
    zero100Pctiles: 'https://youtu.be/9W3cC6bFHQU',
    ratings4Factors: 'https://youtu.be/Ee6LlaEdJn4',
    opponentStats: 'https://youtu.be/JPTGDU1rmv8',
    statScopes: 'https://youtu.be/ebskA-00Teo',
    shotCharts: 'https://youtu.be/_s-oiRt9qLw',
    printSaveToPdf: 'https://youtu.be/2dQmUDZKbzQ',
    postGameReport: 'https://youtu.be/77Fg8ninjc8',
    dataPoweringCBB: 'https://youtu.be/l1OnyvKjiaI'
};

function ClickableVideoIcon({ string, onClick }) {
    const { isXs } = useScreenSize();
    return (
        <button onClick={onClick} style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '24px',
            backgroundColor: '#2C2C2C',
            border: 'none',
            color: 'white',
            width: '264px',
            height: isXs ? '200px' : '264px',
            maxWidth: 'calc(50% - 25px)',
            borderTop: '12px solid black',
            borderBottom: '12px solid black'
        }}>
            <img alt='company logo' style={{ width: 40 }} src={imageUrls.cbbLogoIconWhite} />
            <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{string}</span>
        </button>
    );
}

function HelpVideos() {
    const [videoUrl, setVideoUrl] = useState(videoMap.websiteNavigation);
    const clickTriggered = useRef(false);
    const videoContainerRef = useRef(null);
    const isFirstVideo = useMemo(() => videoUrl === videoMap.websiteNavigation && !clickTriggered.current, [videoUrl]);
    const [containerWidth, setContainerWidth] = useState(0);
    const { isXs, isMd, isLg } = useScreenSize();

    // get container width to make video take up full size of container with 16:9 aspect ratio
    useEffect(() => {
        if (videoContainerRef.current?.offsetWidth) {
            const maxWidth = Math.min(Math.floor(window.innerHeight * 16 / 9) - 300, 700);
            setContainerWidth(Math.min(maxWidth, videoContainerRef.current.offsetWidth));
        }
    }, [videoContainerRef]);

    // so first video can also autoplay on click like the others
    useEffect(() => {
        if (videoUrl !== videoMap.websiteNavigation) {
            clickTriggered.current = true;
        }
    }, [videoUrl]);

    // and return
    return (
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundImage: `url(${imageUrls.dataVisBackground})`, backgroundPositionX: 'right', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', padding: isXs ? '0 25px' : isMd ? '0 50px' : '0 140px' }}>
            <div style={{ position: 'absolute', height: '100%', width: '100%', background: 'black', opacity: '0.5', zIndex: '0', margin: isXs ? '0 -25px' : isMd ? '0 -50px' : '0 -140px' }} />
            <h1 style={{ position: 'inherit', zIndex: '1', color: '#f5f5f5', fontWeight: 600, fontSize: isXs ? '30px' : '48px', paddingTop: isXs ? '24px' : isMd ? '40px' : isLg ? '60px' : '82px' }}>
                {strings.title}
            </h1>
            <div style={{ position: 'inherit', width: '100%', zIndex: '1', display: 'flex', justifyContent: 'center', verticalAlign: 'center', marginTop: isLg ? '24px' : '40px', marginBottom: isLg ? '24px' : '40px' }} ref={videoContainerRef}>
                <ReactPlayer
                    url={videoUrl}
                    playing={!isFirstVideo}
                    width={containerWidth}
                    height={containerWidth * 9 / 16}
                    controls
                />
            </div>
            <div style={{ maxWidth: '1200px', position: 'inherit', zIndex: '1', display: 'flex', width: '100%', flexFlow: 'wrap', columnGap: '12px', rowGap: '20px', justifyContent: 'center', marginBottom: isMd ? '24px' : '180px' }}>
                {Object.keys(videoMap).map(videoTitle => {
                    const url = videoMap[videoTitle];
                    const string = strings[videoTitle];
                    return <ClickableVideoIcon onClick={() => setVideoUrl(url)} string={string} key={string} />;
                })}
            </div>
        </div>
    );
}

export default HelpVideos;
