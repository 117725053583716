
import Axios from 'axios';
import config from '../config';

export const getTokenFromLocalStorage = () => {
    // set correct localStorage, grab token
    const storage = localStorage || window.localStorage;
    const token = storage.getItem('auth-token') || '';

    // if no token found, set to empty string in localStorage
    if (!token) {
        if (storage) { storage.setItem('auth-token', ''); }
    }

    // and return token
    return token;
};

export const checkTokenValidity = async (token) => {
    // check if token is valid
    const apiBaseUrl = config.url.API_URL;
    const tokenResponse = await Axios.post(`${apiBaseUrl}/users/token-is-valid`, null, { headers: { 'x-auth-token': token } });

    // if valid, and if new token returned in /token-is-valid, then set to local storage
    const isValid = tokenResponse.data.isValid;
    const newToken = tokenResponse.data.token;
    // console.log('isValid & token: ', tokenResponse.data);
    if (isValid && newToken) {
        localStorage.setItem('auth-token', newToken);
    }

    // return bool and potential new token
    return {
        isValid: isValid,
        token: newToken || token
    };
};
