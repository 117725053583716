
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import GlobalContext from '../../context/GlobalContext';
import { showInfoModal, hideInfoModal } from '../../utils/TableHelpers';

// The Utils for the Modal
const getHexColor1 = (obj, id) => {
    const hexColor = obj && obj[id] ? obj[id].hexColor1 : '#444444';
    return hexColor;
};
const getBulletsObj = (divisionId) => {
    const bulletsObj = {
        // %iles bullets
        teamAggPctilesPbp: { title: 'Percentiles:', desc: `Black numbers reflect stat values, and large red/green numbers reflect each team's 0 - 100 percentile rating for each team in each stat, rated against all qualifying Division-${divisionId} teams. To qualify for percentiles, the latest LiveStats play-by-play data must be available for at least 50% of the team's games.` },
        teamAggPctilesBox: { title: 'Percentiles:', desc: `Black numbers reflect stat values, and large red/green numbers reflect each team's 0 - 100 percentile rating for each team in each stat, rated against all qualifying Division-${divisionId} teams.` },
        teamAggRbzPctiles: { title: 'Percentiles:', desc: `Black numbers reflect stat values, and large red/green numbers reflect each team's 0 - 100 percentile rating for rebounding stats within each shooting zone, rated against all qualifying Division-${divisionId} teams. To qualify for percentiles, the latest LiveStats play-by-play data must be available for at least 50% of the team's games.` },
        teamAggAbzPctiles: { title: 'Percentiles:', desc: `Black numbers reflect stat values, and large red/green numbers reflect each team's 0 - 100 percentile rating for assists stats within each shooting zone, rated against all qualifying Division-${divisionId} teams. To qualify for percentiles, the latest LiveStats play-by-play data must be available for at least 50% of the team's games.` },
        teamAggSbzPctiles: { title: 'Percentiles:', desc: `Black numbers reflect stat values, and large red/green numbers reflect each team's 0 - 100 percentile rating for the shooting stats in each zone, rated against all qualifying Division-${divisionId} teams. To qualify for percentiles, the latest LiveStats play-by-play data must be available for at least 50% of the team's games.` },
        teamPbpRecord: { title: 'Team Record:', desc: `Like other stats in this table, the teams' overall and conference records are their records only in games played where LiveStats play-by-play data is available.` },

        teamGameTotals: { title: 'Totals Row:', desc: `The "Totals" row at the top of this table updates as the scope (last 5 games, full-season, etc.) changes. However, this totals row does not update for changes to the in-table minimum & maximum value filters.` },
        teamGamePctiles: { title: 'Percentiles:', desc: `Black numbers reflect stat values, and large red/green numbers reflect the team's 0 - 100 percentile rating in each stat for each game, rated against all Division-${divisionId} games.` },
        teamGamePctilesT: { title: 'Totals Percentiles:', desc: `Percentile ratings in the top "Totals" row are rated against the full-season stats for all Division-${divisionId} teams.` },
        teamGameRbzPctiles: { title: 'Percentiles:', desc: `Black numbers reflect stat values, and large red/green numbers reflect each team's 0 - 100 percentile rating for rebounding stats within each shooting zone for each game, rated against rebounding in each zone across all Division-${divisionId} games where LiveStats play-by-play data is available.` },
        teamGameSbzPctiles: { title: 'Percentiles:', desc: `Black numbers reflect stat values, and large red/green numbers reflect each team's 0 - 100 percentile rating for the shooting stats within each zone for each game, rated against shooting in each zone across all Division-${divisionId} games where LiveStats play-by-play data is available.` },

        lineupTotals: { title: 'Totals Row:', desc: `The "Totals" row at the top of this table updates as the scope (last 5 games, full-season, etc.) changes, and updates as the selected players on & off court changes. However, this totals row does not update for changes to the in-table minimum & maximum value filters.` },
        lineupPctiles: { title: 'Percentiles:', desc: `Black numbers reflect stat values, and large red/green numbers reflect each lineup's 0 - 100 percentile rating in each stat, rated against all qualifying Division-${divisionId} lineups. To qualify for percentiles, a lineup must meet a minutes-played minimum within the selected scope.` },
        lineupPctilesT: { title: 'Totals Percentiles:', desc: `Percentile ratings in the top "Totals" row are rated against player on-court stats rather than lineup stats, because the samples in player on-court stats align better with the aggregation of lineup stats. Worded differently, samples in lineup stats tend to be small, and comparing against lineup stats for the totals row wouldn't properly assess how well the aggregation of lineups has performed.` },

        playerGameTotals: { title: 'Totals Row:', desc: `The "Per Game" row at the top of this table updates as the scope (last 5 games, full-season, etc.) changes. However, this row does not update in response to changes to the in-table minimum & maximum value filters.` },
        playerGamePctiles: { title: 'Percentiles:', desc: `Black numbers reflect stat values, and large red/green numbers reflect player 0 - 100 percentile ratings for each game in each stat, rated against all qualifying Division-${divisionId} player-games.` },
        playerGamePctilesByPosT: { title: 'Totals Percentiles:', desc: `Percentile ratings in the top "Per Game" row are rated against the full-season stats for other Division-${divisionId} players at their position (Guard, Forward, Center). Players with no position listed are rated relative to all Division-${divisionId} players. If you prefer for these percentile ratings to be relative to all other Division-${divisionId} players (not by position), you can update this in your Account Settings.` },
        playerGamePctilesNotByPosT: { title: 'Totals Percentiles:', desc: `Percentile ratings in the top "Per Game" row are rated against the full-season stats for all Division-${divisionId} players. If you prefer for these percentile ratings to be relative to all other Division-${divisionId} players at their position (Guard, Forward, Center), you can update this in your Account Settings.` },

        playerAggPctileByPosition: { title: 'Percentiles By Position:', desc: `Percentile ratings for each player are relative to other Division-${divisionId} players at their position (Guard, Forward, Center). Players with no position listed are rated relative to all Division-${divisionId} players. If you prefer for these percentile ratings to be relative to all other Division-${divisionId} players (not by position), you can update this in your Account Settings.` },
        playerAggPctileNotByPosition: { title: 'Percentiles Not By Position:', desc: `Percentile ratings for each player are relative to other all other Division-${divisionId} players. If you prefer for these percentile ratings to be relative to all other Division-${divisionId} players at their position (Guard, Forward, Center), you can update this in your Account Settings.` },
        playerAggSbzPctiles: { title: 'Percentiles:', desc: `Black numbers reflect stat values, and large red/green numbers reflect each player's 0 - 100 percentile rating for the shooting stats in each zone, rated against all qualifying Division-${divisionId} players. To qualify for percentiles for stats in a shooting zone, a player must be in the top 50% of all players in Division-${divisionId} in field goal attempts in that zone.` },
        playerAggPbpPctiles: { title: 'Percentiles:', desc: `Black numbers reflect stat values, and large red/green numbers reflect each player's 0 - 100 percentile ratings for each player in each stat, rated against qualifying Division-${divisionId} players. To qualify for percentiles, a player must be in the top 50% of all players in Division-${divisionId} in field goal attempts.` },
        playerAggBoxPctiles: { title: 'Percentiles:', desc: `Black numbers reflect stat values, and large red/green numbers reflect each player's 0 - 100 percentile rating for each player in each stat, rated against qualifying Division-${divisionId} players. To qualify for percentiles, a player must be in the top 67% in minutes played relative to Division-${divisionId} players.` },

        onOffPctilesDiff: { title: 'Percentiles:', desc: `Black numbers reflect the difference in the team's stats with each player on vs. off the court, and large red/green numbers reflect each player's on-off 0 - 100 percentile rating in each stat, rated against all qualifying Division-${divisionId} players.` },
        onOffPctilesOn: { title: 'Percentiles:', desc: `Black numbers reflect the team's stat values with each player on-court, and large red/green numbers reflect each player's team-on 0 - 100 percentile rating in each stat, rated against all qualifying Division-${divisionId} players.` },
        onOffPctilesOff: { title: 'Percentiles:', desc: `Black numbers reflect the team's stat values with each player off-court. Note that we do not compute 0-100 percentiles for team stats with each player off-court.` },
        onOffPctilesAll: { title: 'Percentiles:', desc: `Black numbers reflect the team's stat values with each player on-court (On), off-court (Off), and the on/off differential (Diff). For (On), large red/green numbers reflect each player's team-on 0 - 100 percentile rating in each stat, rated against all qualifying Division-${divisionId} players. For (Diff), large red/green numbers reflect each player's on-off 0 - 100 percentile rating in each stat, rated against all qualifying Division-${divisionId} players. We do not compute percentiles for (Off) stats.` },

        conferenceAggPctiles: { title: 'Percentiles:', desc: `Black numbers reflect stat values, and large red/green numbers reflect each conferences's 0 - 100 percentile rating in each stat, rated against all qualifying Division-${divisionId} teams. Worded differently, for each conference, these are the percentiles that a hypothetical Division-${divisionId} teams with these statistics would have.` },
        wowyPctiles: { title: 'Percentiles:', desc: `Black numbers reflect stat values, and large red/green numbers reflect each combo's 0 - 100 percentile rating in each stat. For combos, percentiles are not computed relative to other lineup combos. Rather, percentiles are computed relative to Division-${divisionId} player on-court stats, as the majority of lineup combos have very small sample sizes of minutes played, and player on-court stats better convey how good or bad each stat is.` },

        // other bullets
        miniGameCardBoxData: { title: '', desc: 'The tables and graphs on this page are computed using official NCAA box score data. Statistics from exhibition games are excluded.' },
        miniGameCardPbpData: { title: '', desc: 'The tables and graphs on this page are computed using official NCAA play-by-play data. Note that play-by-play data is not available for all NCAA teams or games. Statistics from exhibition games are excluded from calculations.' },
        pbpData: { title: 'PBP Data:', desc: `This table is computed using LiveStats play-by-play (PBP) data, which is not currently available for all NCAA games, and stats computed in this table only include data from games where the PBP data is available.` },
        onOffFilters: { title: 'On/Off Filters:', desc: `Use the dropdowns above to filter for lineups with specific players selected on-court and off-court. As players are selected, stats in the "Totals" row pinned to the top of the table will update for the lineups that meet the criteria of selected players.` },
        lineupFlowTable: { title: 'Overview:', desc: `Each row in this table represents a lineup stint of 5 players, listed sequentially as the lineups appeared throughout the game. We include points and efficiency on offense & defense, and a few other basic shooting and boxscore statistics. The top row then represents the team's starting lineup, whereas the last row is for team's closing lineup.` },
        lineupFlowGraph: { title: 'Lineup Flow Graphs', desc: `The 1-2-3-4-5 ordering of players in the lineup flow graph below is determined based on each players listed position (G, F), with their listed height used to sort within position (the shortest guard on the floor will be the 1). If players are not appearing in the correct location, their listed position or height may be missing or inaccurate in our database. To improve sorting, send us an email at info@cbbanalytics.com to update the player positions or heights.` },
        chncVsPoss: { title: 'Possession vs. Chance:', desc: `Possessions and chances are often used interchangeably, however there is a distinct difference between the two. Most notably, there can be more than one chance in a given possession. When a team grabs an offensive rebound, or when the ball goes out of bounds off the defense and the offense retains the ball, this is considered a new chance within the same possession.` },
        recordBySplits: { title: 'Team Record By Splits', desc: 'for groupings of games played. How well does each team play at home, on the road, in their last 5 and 10 games, against the toughest (Quad 1) and the easiest (Quad 4) competition?' },

        // shot chart info bullets
        hexSize: { title: 'Size:', desc: `Hexagon size represents the player or team's absolute frequency of shots at each individual hexagon` },
        hexColor: { title: 'Color:', desc: `Hexagon color represents the player or team's net FG% at a hexagon + its bordering 6 hexagons (7-hexagon area), compared to D-${divisionId}'s FG% across the 7-hexagon area.` },
        shotMarkers: { title: 'Markers:', desc: `Markers represent missed shots, assisted made shots, and unassisted made shots.` },
        netEfgPctText: { title: 'Text:', desc: `Text values for each zone represent the player or team's net eFG% in each zone, compared to the D-${divisionId} overall eFG%.` },
        absEfgPctText: { title: 'Text:', desc: `Text values for each zone represent the player or team's total eFG% in each zone.` },
        efgPctColor: { title: 'Color:', desc: `Colors for each zone represent the player or team's net eFG% in each zone, compared to D-${divisionId}'s overall eFG%. This color helps gauge how efficient shots are in each zone compared to a baseline level of efficiency for any shot in D-${divisionId}.` },
        netFgPctText: { title: 'Text:', desc: `Text values for each zone represent the player or team's net FG% in each zone, compared to the average D-${divisionId} FG% in each zone.` },
        absFgPctText: { title: 'Text:', desc: `Text values for each zone represent the player or team's total FG% in each zone.` },
        fgPctColor: { title: 'Color:', desc: `Colors for each zone represent the player or team's net FG% in each zone, compared to the average D-${divisionId} FG% in each zone. This color helps gauge how well the player or team shoots in each zone compared to the rest of D-${divisionId}.` },
        netFgaFreqText: { title: 'Text:', desc: `Text values for each zone represent the player or team's net % of shots in each zone, compared to the average D-${divisionId} % of shots in the zone.` },
        absFgaFreqText: { title: 'Text:', desc: `Text values for each zone represent the player or team's total % of shots in each zone.` },
        fgaFreqColor: { title: 'Color:', desc: `Colors for each zone represent the player or team's net % of shots in each zone, compared to the average D-${divisionId} % of shots in each zone. This color helps gauge whether the player or team takes more or less shots in each zone compared to the rest of D-${divisionId}.` },

        // helper / tips bullet
        tip1: { title: 'When multiple scopes are selected,', desc: 'stats for the first, left-most scope are displayed in the logo graphs below. Additionally, for the "Select Your Own Games" split, the logo graphs become very sparse, with stats computed only using the selected games stats.' },
        dateRangeSlow: { title: 'Slow to Compute Date Range Stats:', desc: 'Whereas statistics for most other scopes (full season, last 5 & 10 games, home & away, etc.) are pre-computed for faster website performance, date-range statistics are computed live using game statistics for games within the selected date range. For larger tables, this will result in slower load times as additional data is fetched and metrics are computed on the fly.' },
        min20mins: { title: 'Minimum 20 mins:', desc: 'This table only includes players with >20 minutes played on-court during the selected scope.' },
        tourneySelect: { title: 'Selecting a different season', desc: 'will update the options available in the tournament dropdown.' },
        noPgPctilesForPeriodStats: { title: 'We do not compute', desc: 'stats / game for period stats or for clutch stats, instead only computing stats / 40 minutes and stats / 100 possessions. This is for two reasons. First, stats / game inherently do not make much sense for period stats or clutch stats. Second, we cannot apply accurate percentiles to period stats / game or clutch stats / game, since these metrics are of an entirely different scale from standard full-game per-game statistics.' },
        sortedByPctiles: { title: '', desc: 'Sorting metrics by percentiles in the tables below can help identify the strengths and weaknesses of a team on offense and defense. Note that 4 metrics duplicated in the "Standard Table Layout" (ORtg, DRtg, TOV%, FTA Rate) are not duplicated in the "Metrics Sorted By %iles" Layout, resulting in the last table stats having only 4 metrics.' },
        astdPctInZones6Only: { title: 'In Simple Zones:', desc: 'Assisted percentage (ASTD%) by zone is currently only available for the Simple Zones shooting-zone breakdown.' },
        neutralReport: { title: 'Neutral Report vs Specific Team Focus:', desc: `This dropdown is disabled if this page's game is not the most recent game played for either team. If enabled, select a team to see their stats allowed to opponents (in place of the opposing team's stats) in the tables below.` },
        shotClockFilter1: { title: '', desc: `The shot clock is not tracked in the official NCAA play-by-play data. To introduce a shot clock filter, we approximate the shot clock based on the time passed since the previous rebound, made basket or turnover.` },
        shotClockFilter2: { title: '', desc: `This estimate can be incorrect due to a number of reasons, including (i) how long it takes a team to inbound the ball after a made basket, (ii) discrepencies on when the shot clock was reset after a rebound, (iii) the occurrence kick balls, which reset the shot clock but which are not tagged in the play-by-play data, and (iv) the occurrence of events being out-of-order in the play-by-play data.` },
        shotClockFilter3: { title: '', desc: ` We put forth a significant effort approximating the shot clock as accurately as possible, and believe our estimates are strong enough to utilize in shooting analyses that incorporate shot clock breakdowns.` },
        adjustStats: { title: 'Approach:', desc: `we have computed adjusted statistics for the Core Stats table - i.e. for ratings (net, offensive, defensive), pace and the four factors. Adjustments are based on the average stats allowed by the team's opponents in its opponents' other games throughout the season.` },
        adjustStatsEg: { title: 'For Example:', desc: `if a team's opponents allow on average an eFG% 3% lower than the Division average in their other games throughout the season, then the team would receive a +3% increase in its adjusted eFG% metric. This reflects the fact that the team played a schedule against opponents who held their opponents 3% below average in eFG%.` },
        adjustStatsObser: { title: 'Observation:', desc: `teams in the top conferences are more likely to see an increase in their adjusted statistics, whereas teams in lower conferences are more likely to see a decrease in their adjusted statistcs.` },
        adjustStatsDisabled: { title: 'Disabled:', desc: `we do not compute adjusted stats for the following splits: by period (quarter, half, overtime), for tournaments, or for clutch stats. If one of these splits is selected, adjusted stats will automatically update to unadjusted.` },
        nightOfPostGame1: { title: 'The CBB Analytics database', desc: `updates once-daily overnight, with all statistics from the prior day. However, many coaches have asked for same-night postgame reports. Stats are typically available in the NCAA's database 30 - 60 minutes after a game ends, once the person tagging the game uploads the data. By toggling this dropdown to Fetch from NCAA Database, this page will fetch the latest stats directly from the NCAA's database, circumventing the prior need to wait until the following morning for post-game statistics.` },
        nightOfPostGame2: { title: 'In short,', desc: `toggle this dropdown to Fetch from NCAA Database to generate night-of postgame reports, typically available 30 - 60 minutes after each game ends.` }
    };

    return bulletsObj;
};
const getModalInfo = (divisionId, userData) => {
    // console.log('getModalInfo params: ', { divisionId, userData });
    const obj = getBulletsObj(divisionId, userData);
    const playerPctilesType = userData && userData.user && userData.user.playerPctilesType ? userData.user.playerPctilesType.value : 'byPosition';
    return {
        assistNetsTip: { title: 'Assist Network Tips', bullets: [{ title: 'Single Game Network Graphs:', desc: `To create single-game assist → scorer network graphs, use the "Select Your Own Games" option from the Scope Select above, and select a single game.` }, { title: 'Other Players:', desc: `You can toggle to add "Other-Player" nodes to the graph. This adds an additional node for each of the N Players, and these new graph nodes represent all other players on the team not in the Top N. The links show the assists → scores between each player and those other players.` }] },
        customGamesTip: { title: 'Selecting Specific Games', bullets: [{ title: 'Select & Submit:', desc: `Select the games to include for the statistical calculations, and click on the submit button. Stats do not recompute when selecting games. Stats only recompute upon clicking the submit button.` }] },
        teamPlayerStatsGraphs: { title: 'Scope for Graphs', bullets: [obj.tip1, { title: 'Replace with full season:', desc: `If (a) date range, (b) custom games, or (c) tournament stats is selected in the Scope Select dropdown above, the scope's stats are replaced with full-season stats in the scatterplot graphs below. To see conference player scatterplots within a date-range, visit the conference' Player Stats page. To see tournament player scatterplots, visit the tournament's Player Stats page.` }] },
        teamOverviewLogoGraphs: { title: 'Scope for Graphs', bullets: [obj.tip1] },
        slowTeamLanding: { title: 'Slow to Compute Date Range Stats', bullets: [obj.dateRangeSlow] },
        slowConfGameRecaps: { title: 'Slow To Update Table Types', bullets: [{ title: 'Many Tables:', desc: 'This page renders a table for each date during the season where at least 1 team in the conference played a game. By the end of the season, this will result in more than 100 tables rendered on this page, for most conferences. Due to the large number of tables, switching between the table types (four factors, boxscore, etc.) takes a few seconds as the tables are rendered. Switching to the "Date Range" scope and selecting a narrow range of dates would result in a speed-up to the table type select.' }] },
        dateRangeSlow: { title: 'Live Computations for Date Range', bullets: [{ title: 'May Be Slow:', desc: 'Whereas statistics for most other scopes (full season, last 5 games, etc.) are pre-computed for faster website performance, date-range statistics must be computed live using game statistics for games within the selected date range. For larger tables (conference stats), this will result in slower load times as additional data is fetched and metrics are computed on the fly.' }] },
        boxscores: { title: 'Box Score Data', bullets: [obj.miniGameCardBoxData] },
        pbp: { title: 'Play-By-Play Data', bullets: [obj.miniGameCardPbpData] },
        lineupFlow: { title: 'Lineup Flow Graphs', bullets: [obj.lineupFlowGraph] },
        gameOverviewTeamSelect: { title: 'Neutral Report vs Specific Team Focus', bullets: [obj.neutralReport] },
        shotClockNote: { title: 'The Shot Clock Approximation', bullets: [obj.shotClockFilter1, obj.shotClockFilter2, obj.shotClockFilter3] },

        // Info Modals for tables
        lineupStats1: { title: 'Lineup Stats Tables', bullets: [obj.pbpData, obj.onOffFilters, obj.lineupPctiles, obj.lineupPctilesT] },
        lineupStats2: { title: 'Lineup Stats Tables', bullets: [obj.pbpData, obj.lineupPctiles] },
        onOffStatsDiff: { title: 'Team Difference w/ Player On vs. Off Court', bullets: [obj.onOffPctilesDiff, obj.pbpData, obj.min20mins] },
        onOffStatsOn: { title: 'Team Stats w/ Player On-Court', bullets: [obj.onOffPctilesOn, obj.pbpData, obj.min20mins] },
        onOffStatsOff: { title: 'Team Stats w/ Player Off-Court', bullets: [obj.onOffPctilesOff, obj.pbpData, obj.min20mins] },
        onOffStatsAll3: { title: 'Team Stats w/ Player On-Court, Off-Court, and On/Off Diffs', bullets: [obj.onOffPctilesAll, obj.pbpData, obj.min20mins] },
        onOffStatsLong: { title: 'Team Stats w/ Player On-Court, Off-Court, & Differential', bullets: [obj.onOffPctilesAll, obj.pbpData] },
        wowyCombos: { title: 'All Combos of Select Players On/Off Court', bullets: [obj.wowyPctiles, obj.pbpData] },

        // Player Game Stats Tables
        playerGameRecaps: { title: 'Player Game Stats Table', bullets: [obj.playerGameTotals, obj.playerGamePctiles, playerPctilesType === 'byPosition' ? obj.playerGamePctilesByPosT : obj.playerGamePctilesNotByPosT] },
        gameBoxscores: { title: 'Player Game Stats Table', bullets: [obj.playerGamePctiles] },

        // Player Agg Pctiles
        playerAggSbz: { title: 'Player Shooting By Zone Table', bullets: [obj.playerAggSbzPctiles, playerPctilesType === 'byPosition' ? obj.playerAggPctileByPosition : obj.playerAggPctileNotByPosition, obj.pbpData] },
        playerAggPbpStats: { title: 'Player Stats from PBP Data', bullets: [obj.playerAggPbpPctiles, playerPctilesType === 'byPosition' ? obj.playerAggPctileByPosition : obj.playerAggPctileNotByPosition, obj.pbpData] },
        playerByShotClock: { title: 'Player Shooting By Shot Clock Intervals', bullets: [obj.playerAggSbzPctiles, playerPctilesType === 'byPosition' ? obj.playerAggPctileByPosition : obj.playerAggPctileNotByPosition, obj.pbpData] },
        playerAggBox: { title: 'Player Stats Table', bullets: [obj.playerAggBoxPctiles, playerPctilesType === 'byPosition' ? obj.playerAggPctileByPosition : obj.playerAggPctileNotByPosition] },

        // Team Agg Stats/SBZ/RBZ/ABZ Tables
        teamGameRbz: { title: 'Team Rebounding By Zone Table', bullets: [obj.teamGameRbzPctiles, obj.pbpData] },
        teamGameSbz: { title: 'Team Shooting By Zone Table', bullets: [obj.teamGameSbzPctiles, obj.pbpData] },
        teamAggRbz: { title: 'Team Rebounding By Zone Table', bullets: [obj.teamAggRbzPctiles, obj.pbpData] },
        teamAggAbz: { title: 'Team Assists By Zone Table', bullets: [obj.teamAggAbzPctiles, obj.pbpData] },
        teamAggBox: { title: 'Team Stats Table', bullets: [obj.teamAggPctilesBox] },
        teamAggPbpStats: { title: 'Team Stats from PBP Data', bullets: [obj.teamAggPctilesPbp, obj.pbpData, obj.teamPbpRecord] },
        teamAggSbz: { title: 'Team Shooting By Zone Table', bullets: [obj.teamAggSbzPctiles, obj.pbpData] },
        teamByShotClock: { title: 'Team Shooting By Shot Clock Intervals', bullets: [obj.teamAggSbzPctiles, obj.pbpData] },
        confAggBox: { title: 'Conference Stats Table', bullets: [obj.conferenceAggPctiles] },

        // Team Game Stats Tables
        teamGameRecapsBox: { title: 'Team Game Stats Table', bullets: [obj.teamGamePctiles, obj.teamGameTotals, obj.teamGamePctilesT] },
        teamGameRecapsPbp: { title: 'Team Game Stats Table', bullets: [obj.pbpData, obj.teamGamePctiles, obj.teamGameTotals, obj.teamGamePctilesT] },
        confGameRecapsBox: { title: 'Team Game Stats Table', bullets: [obj.teamGamePctiles] },
        confGameRecapsPbp: { title: 'Team Game Stats Table', bullets: [obj.teamGamePctiles, obj.pbpData] },
        tourneyGameRecapsBox: { title: 'Team Game Stats Table', bullets: [obj.teamGamePctiles] },
        tourneyGameRecapsPbp: { title: 'Team Game Stats Table', bullets: [obj.teamGamePctiles, obj.pbpData] },
        gameLandingBox: { title: 'Team Game Stats Table', bullets: [obj.teamGamePctiles] },
        gameOverviewBox: { title: 'Team Game Stats Table', bullets: [obj.teamGamePctiles] },

        // PPPC By Qualifier, by poss/chnc
        possByStartType: { title: 'PPP & Shooting By Possession Start Type', bullets: [obj.chncVsPoss, obj.pbpData] },
        chncByStartType: { title: 'PPC & Shooting By Chance Start Type', bullets: [obj.chncVsPoss, obj.pbpData] },
        possByTime: { title: 'PPP & Shooting By Length of Possession', bullets: [obj.chncVsPoss, obj.pbpData] },
        chncByTime: { title: 'PPC & Shooting By Length of Chance', bullets: [obj.chncVsPoss, obj.pbpData] },

        // Shot Charts
        hexShotChart: { title: 'Heat Map Shot Chart', bullets: [obj.hexSize, obj.hexColor] },
        markerShotChart: { title: 'Marker Shot Chart', bullets: [obj.shotMarkers] },
        netFgPctShotChart: { title: 'Net FG% Shot Chart', bullets: [obj.netFgPctText, obj.fgPctColor] },
        absFgPctShotChart: { title: 'Total FG% Shot Chart', bullets: [obj.absFgPctText, obj.fgPctColor] },
        netEfgPctShotChart: { title: 'Shooting By Zone: Net eFG%', bullets: [obj.netEfgPctText, obj.efgPctColor] },
        absEfgPctShotChart: { title: 'Shoting By Zone: Total eFG%', bullets: [obj.absEfgPctText, obj.efgPctColor] },
        netFgaFreqShotChart: { title: 'Net % of Shots', bullets: [obj.netFgaFreqText, obj.fgaFreqColor] },
        absFgaFreqShotChart: { title: 'Total % of Shots', bullets: [obj.absFgaFreqText, obj.fgaFreqColor] },

        // Other Tables
        lineupFlowTable: { title: 'Team Lineup Flow Table', bullets: [obj.lineupFlowTable] },
        foulsRebsLong: { title: 'Fouls & Rebounding Table', bullets: [obj.pbpData] },
        tourneySelect: { title: 'Tournament Select', bullets: [obj.tourneySelect] },
        noPgPctilesForPeriodStats: { title: 'No Stats / Game for Period Stats or Clutch Stats', bullets: [obj.noPgPctilesForPeriodStats] },
        sortedLongTables: { title: 'Sort Stats By Percentiles', bullets: [obj.sortedByPctiles] },
        astdPctInZones6Only: { title: 'Assisted Percentage', bullets: [obj.astdPctInZones6Only] },
        recordBySplits: { title: 'Win/Loss Records By Split', bullets: [obj.recordBySplits] },
        adjustStats: { title: 'Adjusting Stats for Opponent Quality', bullets: [obj.adjustStats, obj.adjustStatsEg, obj.adjustStatsObser, obj.adjustStatsDisabled] },
        cbbVsGeniusApi: { title: 'Fetch from NCAA for Night-Of Postgame Reports', bullets: [obj.nightOfPostGame1, obj.nightOfPostGame2] }
    };
};


// The Modal Component Itself
function InfoModal({ type, teamId, divisionId, conferenceId = null, style = {} }) {
    // Get Params
    const params = useParams();
    const paramConferenceId = Number(params.conferenceId);
    const paramTeamId = Number(params.teamId);

    // Set State
    const { teamInfosObj, conferenceInfosObj, userData } = useContext(GlobalContext);

    // Set Modal Color
    let modalColor = getHexColor1(teamInfosObj, teamId);
    if (paramTeamId) { modalColor = getHexColor1(teamInfosObj, paramTeamId); }
    if (conferenceId) { modalColor = getHexColor1(conferenceInfosObj, conferenceId); }
    if (paramConferenceId) { modalColor = getHexColor1(conferenceInfosObj, paramConferenceId); }

    // Grab Text for Modal
    let thisDivisionId = teamInfosObj && teamInfosObj[teamId] ? teamInfosObj[teamId].divisionId : divisionId;
    let modalInfoObj = getModalInfo(thisDivisionId, userData);
    let modalObject = modalInfoObj[type];

    // And Return
    return (
        <div style={{ position: 'relative', marginBottom: 14, ...style }}>
            <button
                className='info-button hide-print'
                style={{ backgroundColor: modalColor }}
                onMouseOver={(e) => showInfoModal({ modalObject, e })}
                onMouseOut={() => hideInfoModal()}
            >
                <span>i</span>
            </button>
        </div>
    );
}

export default InfoModal;
