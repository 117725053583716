
import { useEffect, useState } from 'react';
import config from '../config';
import useCBBQuery from './useCBBQuery';
import { tidy, mutate, select, rename, filter, leftJoin, everything } from '@tidyjs/tidy';
import { getEnhPps, getTeamGameStats } from '../utils/EnhanceRawData';


// console.log('config: ', config);
const useLiveStreamApi = ({ gameId, pass = false }) => {
    // console.log('useLiveStreamApi params: ', { gameId, pass });
    const logToConsole = false;

    // Data from Streaming State
    let [liveStreamData, setLiveStreamData] = useState({
        pbpData: [],
        teamBoxScores: [],
        playerBoxScores: [],
        status: {},
        connectionStatus: 'NOTCONNECTED'
    });
    let wsClient = null;

    // useQuery: Fetch Game, Team, Player Info from CBB API
    const gameInfoCbb = useCBBQuery({ ep: 'games', cfg: { gameId, pass: !gameId } });
    const gameInfoCbbData = gameInfoCbb?.data || {};
    const { competitionId, homeId, awayId } = gameInfoCbbData || {};
    const teamIds = !gameInfoCbbData ? [] : [homeId, awayId];
    const { data: teamInfos = [], isLoading: isTeamsLoading, isError: isTeamsError } = useCBBQuery({ ep: 'teams', cfg: { teamIds, pass: teamIds.length === 0 } });
    const { data: playerInfos = [], isLoading: isPlayersLoading, isError: isPlayersError } = useCBBQuery({ ep: 'players', cfg: { teamIds, pass: teamIds.length === 0 } });
    if (isTeamsError || isPlayersError) { console.log('Data Fetching Error!'); }
    // console.log('loading stuff: ', { isTeamsLoading, teamInfos, playerInfos, isPlayersLoading });

    useEffect(() => {
        // Pass if pass === true
        if (pass === true) {
            // console.log('passing');
            setLiveStreamData({
                pbpData: [],
                teamBoxScores: [],
                playerBoxScores: [],
                status: {},
                connectionStatus: liveStreamData.connectionStatus
            });
        }

        // Otherwise, fetch streaming data
        else {
            // Connect to gameId with Streaming API
            // console.log('connecting to socket');
            wsClient = new WebSocket(`${config.streamingApi}?game_id=${gameId}`);
            wsClient.onclose = (event) => {
                console.log('WebSocket closed:', event);
            };

            // Update data with each new message pushed
            wsClient.onmessage = (ev) => {
                // Convert message from string to JS array of objects
                const eventData = JSON.parse(ev.data);
                if (logToConsole) { console.log('raw event: ', { ev, eventData }); } // Message Event

                // check if eventData.data is empty object
                const eventDataArray = (Object.keys(eventData.data).length === 0 && eventData.data.constructor === Object) ? [] : eventData.data;

                // check if notConnected
                const connectionsArray = eventDataArray.filter(d => d.type === 'connection');
                const latestConnectionStatus = connectionsArray.length === 0 // not quite right?
                    ? 'NOTCONNECTED'
                    : connectionsArray[connectionsArray.length - 1].status;

                // Grab Latest Teams
                const teamEvents = eventDataArray.filter(row => row.type === 'teams');
                const teamsArray = teamEvents.length > 0 && teamEvents[teamEvents.length - 1] && teamEvents[teamEvents.length - 1].teams; // last 2 arrays
                const teamInfo1 = teamsArray && teamsArray[0] ? teamsArray[0] : {};
                const slot1Info = teamInfo1.detail || {};
                const slot2Info = teamsArray && teamsArray[1] && teamsArray[1].detail || {};
                const isHomeInSlot1 = slot1Info.isHomeCompetitor;
                const latestHomeInfo = isHomeInSlot1 ? slot1Info : slot2Info;
                const latestAwayInfo = isHomeInSlot1 ? slot2Info : slot1Info;
                // console.log('infos: ', { slot1Info, slot2Info, isHomeInSlot1, latestHomeInfo, latestAwayInfo });

                // Grab teamIdMap, playerIdMap, playerIdObj
                let teams1 = teamsArray ? { ...teamsArray[0].detail, teamNumber: teamsArray[0].teamNumber } : {};
                let teams2 = teamsArray ? { ...teamsArray[1].detail, teamNumber: teamsArray[1].teamNumber } : {};
                let teamIdMap = tidy([teams1, teams2],
                    rename({ teamName: 'teamMarket' }),
                    select(['teamNumber', 'teamId', 'teamMarket']));

                let players1 = teamsArray ? tidy(teamsArray[0].players, mutate({ teamNumber: teamsArray[0].teamNumber })) : [];
                let players2 = teamsArray ? tidy(teamsArray[1].players, mutate({ teamNumber: teamsArray[1].teamNumber })) : [];
                let playerIdMap = tidy([...players1, ...players2],
                    rename({ personId: 'playerId' }),
                    rename({ shirtNumber: 'jerseyNum' }),
                    rename({ playingPosition: 'position' }),
                    mutate({ fullName: d => `${d.firstName} ${d.familyName}` }),
                    select(['pno', 'teamNumber', 'playerId', 'fullName', 'jerseyNum', 'position']));

                let playerIdObj = { 1: {}, 2: {} };
                playerIdMap.forEach(d => {
                    if (d.teamNumber && d.pno) {
                        playerIdObj[d.teamNumber][d.pno] = d;
                    }
                });

                // Grab Boxscore Events
                const boxScoreEvents = eventDataArray.filter(row => row.type === 'boxscore');
                const latestBoxScoreEvent = boxScoreEvents.length > 0 && boxScoreEvents[boxScoreEvents.length - 1] && boxScoreEvents[boxScoreEvents.length - 1].teams || [];
                let teamBoxScoresNew = latestBoxScoreEvent.length !== 2 ? [] : [
                    { ...latestBoxScoreEvent[0].total.team, teamNumber: latestBoxScoreEvent[0].teamNumber },
                    { ...latestBoxScoreEvent[1].total.team, teamNumber: latestBoxScoreEvent[1].teamNumber }];

                // Team Boxscores, Join Correct teamId and Enhance
                teamBoxScoresNew = tidy(teamBoxScoresNew,
                    leftJoin(teamIdMap, { by: { teamNumber: 'teamNumber' } }), // join teamId
                    mutate({ competitionId: competitionId, matchId: gameId, periodNumber: 0 }));
                teamBoxScoresNew = getTeamGameStats(teamBoxScoresNew);
                teamBoxScoresNew = tidy(teamBoxScoresNew,
                    mutate({ scope: 'game' }));

                // Player Boxscores, Join Correct teamId, playerId and Enhance
                let playerBoxScoresNew = latestBoxScoreEvent.length !== 2 ? [] : [
                    ...tidy(latestBoxScoreEvent[0].total.players, mutate({ teamNumber: latestBoxScoreEvent[0].teamNumber })),
                    ...tidy(latestBoxScoreEvent[1].total.players, mutate({ teamNumber: latestBoxScoreEvent[1].teamNumber }))];

                playerBoxScoresNew = tidy(playerBoxScoresNew,
                    leftJoin(playerIdMap, { by: { pno: 'pno', teamNumber: 'teamNumber' } }), // join playerId, fullName, pos, jerseyNum
                    leftJoin(teamIdMap, { by: { teamNumber: 'teamNumber' } }), // join teamId
                    mutate({ competitionId: competitionId, matchId: gameId, periodNumber: 0, scope: 'game' }));

                playerBoxScoresNew = getEnhPps(playerBoxScoresNew, teamBoxScoresNew);
                playerBoxScoresNew = tidy(playerBoxScoresNew,
                    filter(d => d.mins !== 0), // remove 0 minutes
                    leftJoin(playerIdMap, { by: 'playerId' }), // re-join playerInfo
                    leftJoin(teamIdMap, { by: 'teamId' }), // re-join teamInfo
                    mutate({ scope: 'game' }),
                    mutate({ isQualified: d => d.mins >= 10 }));


                // Grab and Format Latest PBP Data
                let pbpEvents = eventDataArray.filter(row => row.type === 'playbyplay'); // grab last object in array, grab 'actions' key for L800 array
                let latestPbp = pbpEvents.length > 0 && pbpEvents[pbpEvents.length - 1] && pbpEvents[pbpEvents.length - 1].actions || [];
                latestPbp = tidy(latestPbp,
                    filter(d => !['clock', 'substitution'].includes(d.actionType)),
                    filter(d => !d.deleted), // streaming API has "deleted" key for actions that should be removed
                    leftJoin(playerIdMap, { by: { pno: 'pno', teamNumber: 'teamNumber' } }), // join playerId
                    leftJoin(teamIdMap, { by: { teamNumber: 'teamNumber' } }), // join teamId
                    mutate({ matchId: gameId, homeId: homeId, awayId: awayId, personId: d => d.playerId })); // add gameId, homeId, awayId

                // latest PBP, create valid playersTeam1 (dig from d.players.team, map for every playerIdMap)
                latestPbp = tidy(latestPbp,
                    mutate({ t1p1: d => d.players && d.players.team1 && playerIdObj[1][d.players.team1[0]] ? playerIdObj[1][d.players.team1[0]].playerId : 0 }),
                    mutate({ t1p2: d => d.players && d.players.team1 && playerIdObj[1][d.players.team1[1]] ? playerIdObj[1][d.players.team1[1]].playerId : 0 }),
                    mutate({ t1p3: d => d.players && d.players.team1 && playerIdObj[1][d.players.team1[2]] ? playerIdObj[1][d.players.team1[2]].playerId : 0 }),
                    mutate({ t1p4: d => d.players && d.players.team1 && playerIdObj[1][d.players.team1[3]] ? playerIdObj[1][d.players.team1[3]].playerId : 0 }),
                    mutate({ t1p5: d => d.players && d.players.team1 && playerIdObj[1][d.players.team1[4]] ? playerIdObj[1][d.players.team1[4]].playerId : 0 }),
                    mutate({ t2p1: d => d.players && d.players.team2 && playerIdObj[2][d.players.team2[0]] ? playerIdObj[2][d.players.team2[0]].playerId : 0 }),
                    mutate({ t2p2: d => d.players && d.players.team2 && playerIdObj[2][d.players.team2[1]] ? playerIdObj[2][d.players.team2[1]].playerId : 0 }),
                    mutate({ t2p3: d => d.players && d.players.team2 && playerIdObj[2][d.players.team2[2]] ? playerIdObj[2][d.players.team2[2]].playerId : 0 }),
                    mutate({ t2p4: d => d.players && d.players.team2 && playerIdObj[2][d.players.team2[3]] ? playerIdObj[2][d.players.team2[3]].playerId : 0 }),
                    mutate({ t2p5: d => d.players && d.players.team2 && playerIdObj[2][d.players.team2[4]] ? playerIdObj[2][d.players.team2[4]].playerId : 0 }),
                    mutate({ playersTeam1: d => `${d.t1p1};${d.t1p2};${d.t1p3};${d.t1p4};${d.t1p5};` }),
                    mutate({ playersTeam2: d => `${d.t2p1};${d.t2p2};${d.t2p3};${d.t2p4};${d.t2p5};` }));

                // drop unnecessary t1p1, etc. (for some odd reason, x & y were getting dropped, need to explicitly keep them, annoying)
                latestPbp = tidy(latestPbp,
                    select([everything(), 'x', 'y', '-t1p1', '-t1p2', '-t1p3', '-t1p4', '-t1p5', '-t2p1', '-t2p2', '-t2p3', '-t2p4', '-t2p5']));


                // Grab Latest Game Status (Game Info)
                const statusEvents = eventDataArray.filter(row => row.type === 'status');
                let latestStatus = statusEvents.length > 0 && statusEvents[statusEvents.length - 1] && statusEvents[statusEvents.length - 1] || {};
                latestStatus.homeId = latestHomeInfo.teamId;
                latestStatus.homeMarket = latestHomeInfo.teamName;
                latestStatus.awayId = latestAwayInfo.teamId;
                latestStatus.awayMarket = latestAwayInfo.teamName;
                latestStatus.gameDate = latestPbp[0] && latestPbp[0].timeActual ? latestPbp[0].timeActual.slice(0, 10) : ' -- ';

                let utcSeconds = latestStatus.LastUpdated;
                let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
                d.setUTCSeconds(utcSeconds);
                latestStatus.updated = d;

                // And Set States to effectively return the data (return as one object for 1 re-render)
                let updatedLiveStreamData = {
                    pbpData: latestPbp || [],
                    teamBoxScores: teamBoxScoresNew || [],
                    playerBoxScores: playerBoxScoresNew || [],
                    status: latestStatus || {},
                    connectionStatus: latestConnectionStatus || 'NOTCONNECTED'
                };
                setLiveStreamData(updatedLiveStreamData);
            };
        }

        return () => {
            if (wsClient) { wsClient.close(); }
        };
    }, [pass, gameId]);

    // Add Live Scores to gameInfo (isHomeCompetitor: 1 --> homeScore --> is the first team, i.e. scores[0])
    let status = liveStreamData.status;
    let gameInfo = gameInfoCbb.isLoading ? {} : {
        ...gameInfoCbbData,
        ...(!gameInfoCbbData.homeScore && status && status.scores && status.scores[0] && { homeScore: status.scores[0].score }),
        ...(!gameInfoCbbData.awayScore && status && status.scores && status.scores[1] && { awayScore: status.scores[1].score })
    };

    // and Return!
    return {
        gameInfo: gameInfo,
        teamInfos: isTeamsLoading ? [] : teamInfos,
        playerInfos: isPlayersLoading ? [] : playerInfos,
        ...liveStreamData
    };
};

export default useLiveStreamApi;


// Like to look at ORB + TOV differential in wins, losses.
// Sum of FTMs + 3PMs
// Feature request: compare 2 - 4
